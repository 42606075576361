import { makeStyles } from '@material-ui/styles'

export const useQuestionResultStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  response: {
    display: 'flex',
  },
  responseText: {
    fontWeight: 'bold',
    marginLeft: '2px',
  },
  responseWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.grey,
  },
  spacer: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  questionTitle: {
    marginTop: theme.spacing(1),
  },
}))
