import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createMarkup } from 'helpers/formatHelpers'

const useStyles = makeStyles((theme) => ({
  richTextBlock: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& p': {
      ...theme.typography.body1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& h1': {
      ...theme.typography.h1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& h2': {
      ...theme.typography.h2,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& h3': {
      ...theme.typography.h3,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& h4': {
      ...theme.typography.h4,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& h5': {
      ...theme.typography.h5,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& h6': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      ...theme.typography.h6,
    },
    '& ul': {
      ...theme.typography.body1,
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& ol': {
      ...theme.typography.body1,
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}))

const RichTextBlock = (props) => {
  const { content } = props
  const classes = useStyles()

  return <div className={classes.richTextBlock} dangerouslySetInnerHTML={createMarkup(content)}></div>
}

export default RichTextBlock
