import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Container } from '@material-ui/core';

import AccountDetails from './components/accountDetails';
import Notifications from './components/notifications';
import Password from './components/password';
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const EditProfile = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Profile | Express Evaluations</title>
        <meta name="description" content="Employee Evaluations Made Easy | Powerful features, a focus on usability, and supercharged insights to employee performance." />
      </Helmet>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <AccountDetails />
        </Grid>
        <Grid
          item
          md={7}
          xs={12}
        >
          <Notifications />
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
        >
          <Password />
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditProfile;
