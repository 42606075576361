import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardHeader, CardContent, CardActions, Divider, Grid, TextField } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { SaveCircularStatusButton } from 'components/Buttons'

const useStyles = makeStyles(() => ({
  root: {},
}))

const EmployeeDetails = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  const handleHireDateChange = (date) => {
    props.setEmployee({ ...props.employee, hireDate: '2019-05-20T00:00:00' })
  }

  const handleFirstNameChange = (e) => {
    props.setEmployee({ ...props.employee, firstName: e.target.value })
  }

  const handleLastNameChange = (e) => {
    props.setEmployee({ ...props.employee, lastName: e.target.value })
  }

  const handleEmailChange = (e) => {
    props.setEmployee({ ...props.employee, email: e.target.value })
  }

  const handleJobTitleChange = (e) => {
    props.setEmployee({ ...props.employee, jobTitle: e.target.value })
  }

  const handleManagerChange = (e) => {
    props.setEmployee({ ...props.employee, manager: e.target.value })
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="firstName"
                required
                onChange={handleFirstNameChange}
                value={props.employee.firstName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                required
                onChange={handleLastNameChange}
                value={props.employee.lastName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                required
                onChange={handleEmailChange}
                value={props.employee.email || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Job Title"
                margin="dense"
                name="jobTitle"
                onChange={handleJobTitleChange}
                value={props.employee.jobTitle || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Manager"
                margin="dense"
                name="manager"
                id="manager"
                required
                select
                placeholder={'NA'}
                value={props.employee.manager}
                onChange={handleManagerChange}
                //TODO: Selecting a manager is not currently working
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined">
                {props.allEmployees.map((e) => (
                  <option key={e.firstName} value={e}>
                    {e.firstName} {e.lastName}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  style={{ width: '100%', height: '100px' }}
                  variant="inline"
                  inputVariant="outlined"
                  label="Hire Date"
                  margin="dense"
                  placeholder={props.employee.hireDate}
                  value={props.employee.hireDate}
                  onChange={(date) => handleHireDateChange(date)}
                  format="MM/dd/yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <SaveCircularStatusButton
            prelabel="Save Employee"
            postlabel="Employee Saved"
            savefunction={async () =>
              props.updateEmployeeProfile(props.employee, props.employee.id)
            }></SaveCircularStatusButton>
        </CardActions>
      </form>
    </Card>
  )
}

EmployeeDetails.propTypes = {
  className: PropTypes.string,
}

export default EmployeeDetails
