import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Typography } from '@material-ui/core'
import { useAuth0 } from 'react-auth0-spa'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: theme.palette.secondary.main,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}))

const Profile = (props) => {
  const { className, ...rest } = props

  const { user, appMetadata } = useAuth0()
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt="Person"
        className={classes.avatar}
        color="secondary"
      >
        {user.name.match(/\b(\w)/g).join('')}
      </Avatar>
      <Typography className={classes.name}>{user.name}</Typography>
      <Typography variant="body2">{appMetadata.organization}</Typography>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string,
}

export default Profile
