import { getIn } from 'immutable'
import actionTypes from 'redux/actionTypes'
import { updateEvaluationTemplate, createEvaluationTemplate, getEvaluationTemplateById } from 'api'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { FormatDate } from 'utils/DateTime/FormatDate'
import { newSection, newQuestion, calculateMaxScore } from 'helpers/templateBuilderHelper'
import { updateUnsavedchanges } from './focusViewActions'

const getSectionScore = (questions) => {
  let updateScore = 0
  questions.forEach((question) => {
    updateScore = updateScore + question.config.maxScore * question.weight
  })

  return updateScore || 0
}

export const initTemplateBuilderStarted = () => ({
  type: actionTypes.initializeTemplateBuilderStarted,
})

export const initNewTemplateBuilderSucceeded = (isPulse) => ({
  type: actionTypes.initializeNewTemplateBuilderSucceeded,
  isPulse,
})

export const initEditTemplateBuilderSucceeded = (template) => ({
  type: actionTypes.initializeEditTemplateBuilderSucceeded,
  template,
})

export const initPreviewTemplateBuilderSucceeded = (template) => ({
  type: actionTypes.initializePreviewTemplateBuilderSucceeded,
  template,
})

export const setUnsavedChanges = (unsavedChanges) => ({
  type: actionTypes.setUnsavedTemplateBuilderChanges,
  unsavedChanges,
})

export const updateTemplateSucceeded = (template) => ({
  type: actionTypes.updateTemplateSucceeded,
  template,
})

export const updateTemplateScoreSucceeded = (score) => ({
  type: actionTypes.updateTemplateScore,
  score,
})

export const updateTemplatePropertySucceeded = (name, value) => ({
  type: actionTypes.updateTemplateProperty,
  name,
  value,
})

export const updateSectionSucceeded = (sections) => ({
  type: actionTypes.updateSectionSucceeded,
  sections,
})

export const updateQuestionSucceeded = (sections) => ({
  type: actionTypes.updateQuestionSucceeded,
  sections,
})

export const addQuestionSucceeded = (sections) => ({
  type: actionTypes.addQuestionSucceeded,
  sections,
})

export const incrementBuilderTrackerId = () => ({
  type: actionTypes.incrementBuilderTrackerId,
})

export const addSectionSucceeded = (sections) => ({
  type: actionTypes.addSectionSucceeded,
  sections,
})

export const updateTimestampSucceeded = (time) => ({
  type: actionTypes.updateTimestampSucceeded,
  time,
})

export const moveSectionSucceeded = (sections) => ({
  type: actionTypes.moveSectionSucceeded,
  sections,
})

export const copyTemplateSucceeded = (template) => ({
  type: actionTypes.copyTemplateSucceeded,
  template,
})

export const initializeTemplateBuilder = (templateId, isPulse = false) => async (dispatch, getState) => {
  dispatch(initTemplateBuilderStarted())
  if (templateId) {
    if (!getIn(getState(), ['evaluation', 'isPreview'])) {
      getEvaluationTemplateById(templateId).then((response) => {
        const numberedSections = response.data.sections.map((section) => {
          section.sys_id = getIn(getState(), ['templateBuilder', 'builderTrackingId'])
          dispatch(incrementBuilderTrackerId())
          section.questions.forEach((question) => {
            question.sys_id = getIn(getState(), ['templateBuilder', 'builderTrackingId'])
            dispatch(incrementBuilderTrackerId())
          })
          return section
        })
        dispatch(updateTimestampSucceeded(FormatDate(response.data.lastUpdatedAt, true)))
        dispatch(
          initEditTemplateBuilderSucceeded({
            ...response.data,
            sections: numberedSections,
            isPulse: isPulse,
          }),
        )
        dispatch(setUnsavedChanges(false))
        dispatch(updateUnsavedchanges(false))
      })
    } else {
      dispatch(initPreviewTemplateBuilderSucceeded(isPulse))
    }
  } else {
    dispatch(initNewTemplateBuilderSucceeded(isPulse))
  }
}

export const saveTemplate = (history) => async (dispatch, getState) => {
  const template = getIn(getState(), ['templateBuilder', 'template'])
  if (getIn(getState(), ['templateBuilder', 'isEdit'])) {
    updateEvaluationTemplate(template).then((response) => {
      if (response.status === 200) {
        dispatch(updateTimestampSucceeded(FormatDate(response.data.lastUpdatedAt, true)))
        dispatch(setUnsavedChanges(false))
        dispatch(updateUnsavedchanges(false))
        dispatch(enqueueSuccessNotification('Template saved successfully'))
      } else {
        dispatch(
          enqueueErrorNotification(
            'There was a problem trying to save the template. Try again or contact support if you continue to experience this problem.',
          ),
        )
      }
    })
  } else {
    createEvaluationTemplate(template).then((response) => {
      if (response.status === 200) {
        dispatch(setUnsavedChanges(false))
        dispatch(updateUnsavedchanges(false))
        dispatch(enqueueSuccessNotification('Template created successfully'))
        history.push(`/evaluation/template/build/${response.data.templateId}`)
      } else {
        dispatch(
          enqueueErrorNotification(
            'There was a problem trying to create the template. Try again or contact support if you continue to experience this problem.',
          ),
        )
      }
    })
  }
}

export const updateTemplateProperty = (name, value) => async (dispatch, getState) => {
  dispatch(updateTemplatePropertySucceeded(name, value))
  dispatch(setUnsavedChanges(true))
  dispatch(updateUnsavedchanges(true))
  if (name === 'isPulseTemplate') {
    if (value) {
      const newSections = getIn(getState(), ['templateBuilder', 'template', 'sections']).map((section) => {
        return Object.assign({}, section, {
          showInLead: false,
          showInPeer: false,
          showInSelf: true,
        })
      })
      dispatch(updateTemplatePropertySucceeded('sections', newSections))
    }
  }
}

export const updateTemplate = (template) => async (dispatch, getState) => {
  dispatch(updateTemplateSucceeded(template))
}

export const updateTemplateScore = () => async (dispatch, getState) => {
  let updateScore = 0
  getIn(getState(), ['templateBuilder', 'template', 'sections']).forEach((section) => {
    getIn(section, ['questions']).forEach((question) => {
      updateScore = updateScore + question.config.maxScore * question.weight
    })
  })
  dispatch(updateTemplateScoreSucceeded(updateScore))
}

export const addQuestion = (sectionId) => async (dispatch, getState) => {
  const newSections = getIn(getState(), ['templateBuilder', 'template', 'sections']).map((section) => {
    if (section.sys_id === sectionId) {
      return Object.assign({}, section, {
        questions: [...section.questions, newQuestion(getIn(getState(), ['templateBuilder', 'builderTrackingId']))],
      })
    } else {
      return section
    }
  })

  dispatch(addQuestionSucceeded(newSections))
  dispatch(incrementBuilderTrackerId())
}

export const addSection = () => async (dispatch, getState) => {
  dispatch(
    addSectionSucceeded([
      ...getIn(getState(), ['templateBuilder', 'template', 'sections']),
      newSection(
        getIn(getState(), ['templateBuilder', 'builderTrackingId']),
        getIn(getState(), ['templateBuilder', 'template', 'isPulseTemplate']),
      ),
    ]),
  )
  dispatch(incrementBuilderTrackerId())
}

export const deleteQuestion = (sectionId, questionId) => async (dispatch, getState) => {
  var newSections = getIn(getState(), ['templateBuilder', 'template', 'sections']).map((section) => {
    if (section.sys_id === sectionId) {
      return Object.assign({}, section, {
        questions: section.questions.filter((question) => question.sys_id !== questionId),
      })
    } else {
      return section
    }
  })

  dispatch(updateTemplate({ ...getIn(getState(), ['templateBuilder', 'template']), sections: newSections }))
}

export const deleteSection = (sectionId) => async (dispatch, getState) => {
  dispatch(
    updateTemplate({
      ...getIn(getState(), ['templateBuilder', 'template']),
      sections: getIn(getState(), ['templateBuilder', 'template', 'sections']).filter(
        (section) => section.sys_id !== sectionId,
      ),
    }),
  )
}

export const copySection = (sectionId) => async (dispatch, getState) => {
  // TODO: Copy Section
}

export const copyQuestion = (sectionId, question) => async (dispatch, getState) => {
  const newCopyQuestion = Object.assign({}, question)
  newCopyQuestion.sys_id = getIn(getState(), ['templateBuilder', 'builderTrackingId'])
  newCopyQuestion.id = 0
  dispatch(incrementBuilderTrackerId())
  const newSections = getIn(getState(), ['templateBuilder', 'template', 'sections']).map((section) => {
    if (section.sys_id === sectionId) {
      return Object.assign({}, section, {
        questions: [...section.questions, newCopyQuestion],
      })
    } else {
      return section
    }
  })

  dispatch(addQuestionSucceeded(newSections))
}

export const updateQuestion = (sectionId, updatedQuestion) => async (dispatch, getState) => {
  var newSections = getIn(getState(), ['templateBuilder', 'template', 'sections']).map((section) => {
    if (section.sys_id === sectionId) {
      var newQuestions = section.questions.map((question) => {
        if (question.sys_id === updatedQuestion.sys_id) {
          return {
            ...updatedQuestion,
            config: { ...updatedQuestion.config, maxScore: calculateMaxScore(updatedQuestion.config) },
          }
        } else {
          return question
        }
      })
      const sectionScore = getSectionScore(newQuestions)
      return Object.assign({}, section, {
        questions: newQuestions,
        score: sectionScore,
      })
    } else {
      return section
    }
  })
  dispatch(updateQuestionSucceeded(newSections))
  if (getIn(getState(), ['templateBuilder', 'unsavedChanges']) === false) {
    dispatch(setUnsavedChanges(true))
    dispatch(updateUnsavedchanges(true))
  }
}

export const moveQuestion = (sectionIndex, questionIndex, up = true) => async (dispatch, getState) => {
  const currentSection = getIn(getState(), ['templateBuilder', 'template', 'sections', sectionIndex])
  const questionArray = getIn(getState(), ['templateBuilder', 'template', 'sections', sectionIndex, 'questions'])
  const question = getIn(getState(), [
    'templateBuilder',
    'template',
    'sections',
    sectionIndex,
    'questions',
    questionIndex,
  ])
  const newPosition = up ? questionIndex - 1 : questionIndex + 1

  if (newPosition < getIn(currentSection, ['questions']).length && newPosition >= 0) {
    if (questionIndex >= getIn(currentSection, ['questions']).length) {
      var k = newPosition - questionArray.length + 1
      while (k--) {
        questionArray.push(undefined)
      }
    }

    const newSections = getIn(getState(), ['templateBuilder', 'template', 'sections']).map((section) => {
      if (section.sys_id === currentSection.sys_id) {
        return Object.assign({}, section, { questions: questionArray })
      } else {
        return section
      }
    })

    questionArray.splice(newPosition, 0, questionArray.splice(questionIndex, 1)[0])
    dispatch(updateTemplate({ ...getIn(getState(), ['templateBuilder', 'template']), sections: newSections }))
  } else if (!up) {
    const newCopyQuestion = Object.assign({}, question)
    newCopyQuestion.sys_id = getIn(getState(), ['templateBuilder', 'builderTrackingId'])
    dispatch(incrementBuilderTrackerId())
    if (sectionIndex < getIn(getState(), ['templateBuilder', 'template', 'sections']).length - 1) {
      let copySections = getIn(getState(), ['templateBuilder', 'template', 'sections']).map((section) => {
        if (
          section.sys_id === getIn(getState(), ['templateBuilder', 'template', 'sections', sectionIndex + 1]).sys_id
        ) {
          if (section.sys_id !== currentSection.sys_id) {
            return Object.assign({}, section, {
              questions: [newCopyQuestion, ...section.questions],
            })
          } else {
            return Object.assign({}, section, {
              questions: [newCopyQuestion, ...currentSection.questions],
            })
          }
        } else if (section.sys_id === currentSection.sys_id) {
          return Object.assign({}, section, {
            questions: section.questions.slice(0, section.questions.length - 1),
          })
        } else {
          return section
        }
      })
      dispatch(updateTemplate({ ...getIn(getState(), ['templateBuilder', 'template']), sections: copySections }))
    }
  } else if (up) {
    const newCopyQuestion = Object.assign({}, question)
    if (sectionIndex !== 0) {
      let copySections = getIn(getState(), ['templateBuilder', 'template', 'sections']).map((section) => {
        if (
          section.sys_id === getIn(getState(), ['templateBuilder', 'template', 'sections', sectionIndex - 1]).sys_id
        ) {
          return Object.assign({}, section, {
            questions: [...section.questions, newCopyQuestion],
          })
        } else if (section.sys_id === currentSection.sys_id) {
          return Object.assign({}, section, {
            questions: section.questions.slice(1),
          })
        } else {
          return section
        }
      })
      dispatch(updateTemplate({ ...getIn(getState(), ['templateBuilder', 'template']), sections: copySections }))
    }
  }
}

export const moveSection = (from, to) => async (dispatch, getState) => {
  const sections = getIn(getState(), ['templateBuilder', 'template', 'sections'])
  const f = sections.splice(from, 1)[0]
  sections.splice(to, 0, f)
  dispatch(moveSectionSucceeded(sections))
}

export const updateSection = (sectionSysId, updateSection) => async (dispatch, getState) => {
  const updatedSections = getIn(getState(), ['templateBuilder', 'template', 'sections']).map((section, index) => {
    if (section.sys_id === sectionSysId) {
      return updateSection
    } else {
      return section
    }
  })

  dispatch(updateSectionSucceeded(updatedSections))
  if (getIn(getState(), ['templateBuilder', 'unsavedChanges']) === false) {
    dispatch(setUnsavedChanges(true))
    dispatch(updateUnsavedchanges(true))
  }
}

export const copyTemplate = (id) => async (dispatch, getState) => {
  getEvaluationTemplateById(id).then((response) => {
    const numberedSections = response.data.sections.map((section) => {
      section.sys_id = getIn(getState(), ['templateBuilder', 'builderTrackingId'])
      dispatch(incrementBuilderTrackerId())
      section.questions.forEach((question) => {
        question.sys_id = getIn(getState(), ['templateBuilder', 'builderTrackingId'])
        dispatch(incrementBuilderTrackerId())
      })
      return section
    })
    response.data.availableToEdit = true
    dispatch(copyTemplateSucceeded({ ...response.data, sections: numberedSections }))
    dispatch(setUnsavedChanges(true))
    dispatch(updateUnsavedchanges(true))
    dispatch(enqueueSuccessNotification('Template copied successfully'))
  })
}
