import { makeStyles } from '@material-ui/styles'

export const useRecurrenceFormStyles = makeStyles((theme) => ({
  eeRepeatAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 16,
  },
  checkedEeRepeatAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 16,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.textContrast,
  },
  repeatEveryText: {
    ...theme.typography.h6,
    marginRight: theme.spacing(2),
  },
  intervalInput: {
    marginRight: theme.spacing(2),
    width: 60,
  },
}))
