import React from 'react'
import { Editor } from '@tinymce/tinymce-react'

const RichTextArea = (props) => {
  const { initialValue, value, onChange, disabled } = props

  return (
    <Editor
      apiKey="xtdkjz6qmu7zrs0rjq2an0z52gi2csub4wbffywanhlqilht"
      initialValue={initialValue}
      value={value}
      init={{
        height: 250,
        menubar: false,
        plugins: [
          'advlist autoresize autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar: 'undo redo | formatselect | bold italic | bullist numlist outdent indent | help',
        browser_spellcheck: true,
        contextmenu: false,
      }}
      onEditorChange={onChange}
      disabled={disabled}
    />
  )
}

export default RichTextArea
