import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import Footer from './components/marketingFooter'
import { Helmet } from 'react-helmet'
import hexToRGB from 'helpers/hexToRGB'
import { useTransition, animated } from 'react-spring'
import Topbar from './components/Topbar'
import Pricing from './components/PricingSection'
import Features from './components/Features'
import BookDemo from './components/BookDemo'
import PromoHero from './components/PromoHero'
import CallToAction from './components/CallToAction'
const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    background:
      'linear-gradient(90deg,' +
      hexToRGB(theme.palette.primary.main) +
      '0%,' +
      hexToRGB(theme.palette.secondary.main) +
      ' 120%)',
    height: '380px',
    color: 'white',
    position: 'relative',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '320px',
    },
  },
  body: {
    marginTop: '-200px',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  heroContent: {
    paddingTop: '120px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: '100px',
    },
  },
  title: {
    fontSize: '65px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
      marginBottom: theme.spacing(1),
    },
  },
  cta: {
    margin: theme.spacing(2),
  },
  disclaimer: {
    maxWidth: '300px',
    margin: 'auto',
  },
  emailSignup: {
    width: '100%',
    minHeight: '350px',
  },
  launchText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      marginBottom: theme.spacing(1),
    },
  },
  wave: {
    position: 'absolute',
    bottom: 0,
  },
}))

const Homepage = () => {
  const classes = useStyles()
  const [index, setIndex] = useState(0)
  const [items] = useState([
    { title: 'Easier to Administrate', id: 0 },
    { title: 'Cost-Effective', id: 1 },
    { title: 'Faster', id: 2 },
    { title: 'A Better Experience', id: 3 },
    { title: 'More Insightful', id: 4 },
  ])

  const fadingTextPropsTransition = useTransition(items[index], (item) => item.id, {
    from: { opacity: 0, transform: 'translate3d(0,20%,20%)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: -1, transform: 'translate3d(0,-20%,-20%)' },
    config: { tension: 220, friction: 120 },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => (state + 1) % items.length)
    }, 4000)
    return () => clearInterval(interval)
  }, [items.length])

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Express Evaluations</title>
        <meta
          name="description"
          content="Employee Evaluations Made Easy | Powerful features, a focus on usability, and supercharged insights to employee performance."
        />
      </Helmet>
      <Topbar />
      <header className={classes.header} id="top">
        <div className={classes.heroContent}>
          <Typography component="h2" variant="h1" align="center" className={classes.subtitle} color="inherit">
            Making Employee Evaluations
          </Typography>
          {fadingTextPropsTransition.map(({ item, props, key }) => (
            <animated.div key={key} style={{ ...props, position: 'absolute', width: '100%' }}>
              <Typography component="h1" variant="h1" align="center" color="inherit" className={classes.title}>
                {item.title}
              </Typography>
            </animated.div>
          ))}
        </div>
      </header>
      <CallToAction />
      <PromoHero />
      <Features />
      <BookDemo />
      <Pricing />
      <Footer />
    </div>
  )
}

export default Homepage
