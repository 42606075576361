import React from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import PropTypes from 'prop-types'
import { getIcon } from 'utils/GetIcon'

const VerticalIconListItem = (props) => {
  const { clickFunction, disabled, displayName, icon, index, isSelected, multiple, name, selectedIndex } = props

  return (
    <ListItem
      button
      disabled={disabled}
      selected={multiple ? isSelected : selectedIndex === index}
      style={{ maxWidth: 300 }}
      onClick={() => clickFunction(name, index)}>
      <ListItemIcon style={{ minWidth: 30 }}>{icon}</ListItemIcon>
      <ListItemText primary={displayName} />
      {(multiple ? isSelected : selectedIndex === index) && (
        <ListItemIcon style={{ minWidth: 30 }}>{getIcon('complete')}</ListItemIcon>
      )}
    </ListItem>
  )
}

VerticalIconListItem.propTypes = {
  clickFunction: PropTypes.func,
  disabled: PropTypes.bool,
  displayName: PropTypes.string,
  icon: PropTypes.any,
  index: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  selectedIndex: PropTypes.number,
}

VerticalIconListItem.defaultProps = {
  clickFunction: () => {},
  disabled: false,
  displayName: '',
  icon: <div />,
  index: 0,
  multiple: false,
  name: '',
}
export default VerticalIconListItem
