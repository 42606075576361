import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Box, ButtonGroup, Avatar, Typography, Grid, Fade } from '@material-ui/core'
import PermissionCheck from 'utils/PermissionCheck'
import { FormatDateIgnoreTz } from 'utils/DateTime'
import { PeopleDrawer } from 'views/People'
import { roles } from 'utils/roles'
import ButtonWithPrompt from 'components/Buttons/ButtonWithPrompt'
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/notificationsActions'
import { useDispatch } from 'react-redux'
import { getPersonById, resetPassword } from 'api'
import { useParams } from 'react-router'
import { getSearchChip } from 'utils/evaluations/chips'

const useStyles = makeStyles((theme) => ({
  root: {
    passing: theme.spacing(2),
    display: 'flex',
  },
  details: {
    display: 'flex',
  },
  avatar: {
    marginLeft: theme.spacing(1),
    height: 80,
    width: 80,
    fontSize: 30,
    backgroundColor: theme.palette.secondary.main,
  },
  actionGroup: {
    marginRight: theme.spacing(1),
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginLeft: 'auto',
  },
  spacer: {
    flexGrow: 1,
  },
  summary: {
    paddingLeft: theme.spacing(2),
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
}))

const ProfileSummary = (props) => {
  const { className } = props
  const { personId } = useParams()
  const classes = useStyles()
  const dispatch = useDispatch()
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args))
  const [person, setPerson] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getPersonById(personId).then((response) => {
      setPerson(response)
      setIsLoading(false)
    })
  }, [personId])
  const handlePasswordReset = async () => {
    resetPassword({ email: person.email }).then((response) => {
      enqueueSnackbar({
        message: `${person.fullName}'s password has been reset. They will receive an email shortly.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      })
    })
  }

  return (
    <Fade in={!isLoading}>
      <Grid container spacing={2} className={clsx(classes.root, className)}>
        <Box>
          <Avatar className={classes.avatar}>
            {(person && person.fullName && person.fullName.match(/\b(\w)/g).join('')) || ''}
          </Avatar>
        </Box>
        <Box className={classes.summary}>
          <Typography variant="h4">{person.fullName || ''}</Typography>
          <Typography variant="subtitle2">{person.email || ''}</Typography>
          {person.manager && <Typography variant="subtitle2">Manager: {person.manager.fullName}</Typography>}

          <div className={classes.tags}>{person?.tags?.map((tag) => getSearchChip(tag, null, null, 'small'))}</div>
          <Typography variant="caption">Hired on: {FormatDateIgnoreTz(person.hireDate)}</Typography>
          <br />
          <Typography variant="caption">Employee Id: {person.employeeId}</Typography>
        </Box>
        <span className={classes.spacer} />

        <ButtonGroup orientation="vertical" color="primary" variant="text" className={classes.actionGroup}>
          <PermissionCheck
            key={`edit-person-button`}
            roles={[roles.Admin]}
            yes={() => (
              <PeopleDrawer
                callback={() =>
                  getPersonById(personId).then((response) => {
                    setPerson(response)
                  })
                }
                person={person}
                type="edit"
                buttonType="button"
              />
            )}
          />
          <PermissionCheck
            key={`edit-person-reset-password`}
            roles={[roles.Admin]}
            yes={() => (
              <ButtonWithPrompt
                positiveButtonLabel="Reset Password"
                title={`Are you sure you want to reset ${person.fullName}'s password?`}
                buttonText="Reset Password"
                clickFunction={handlePasswordReset}
              />
            )}
          />
        </ButtonGroup>
      </Grid>
    </Fade>
  )
}

ProfileSummary.propTypes = {
  className: PropTypes.string,
}

export default ProfileSummary
