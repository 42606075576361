import { updateGoal, createGoal, getGoalById, createMetric, removeMetric as removeMetricApi } from 'api'
import { getIn } from 'immutable'
import actionTypes from 'redux/actionTypes'
import validate from 'validate.js'
import { goalDrawerValidations } from 'validations'
import { FormatDateIgnoreTz } from 'utils/DateTime'
import { removeItem } from 'helpers/arrays'
import { enqueueSuccessNotification, enqueueErrorNotification } from 'redux/actions/notificationsActions'
import { initializeFocusView, updateUnsavedchanges } from './focusViewActions'
import moment from 'moment'

export const initializeGoalFormSucceeded = (values) => ({
  type: actionTypes.initializeGoalFormSucceeded,
  values,
})

export const initializeGoalEditFormSucceeded = (values) => ({
  type: actionTypes.initializeGoalEditFormSucceeded,
  values,
})

export const updateGoalFormSucceeded = (form) => ({
  type: actionTypes.updateGoalFormSucceeded,
  form,
})

export const resetGoalForm = () => ({
  type: actionTypes.resetGoalForm,
})

export const validateGoalFormStarted = () => ({
  type: actionTypes.validateGoalFormStarted,
})

export const validateGoalFormSucceeded = (isValid, errors) => ({
  type: actionTypes.validateGoalFormSucceeded,
  isValid,
  errors,
})

export const startSaving = () => ({
  type: actionTypes.startSavingGoalForm,
})

export const endSaving = () => ({
  type: actionTypes.endSavingGoalForm,
})

export const resetReviewCycle = () => ({
  type: actionTypes.resetReviewCycle,
})

export const startEvaluationGoalCreation = (evaluationId, person) => ({
  type: actionTypes.startEvaluationGoalCreation,
  evaluationId,
  person,
})

export const initializeGoalForm = (personId, inEvaluation) => async (dispatch, getState) => {
  const goalFormValues = getIn(getState(), ['goalForm', 'values'])
  goalFormValues.people = { id: personId }
  if (inEvaluation) {
    goalFormValues.dueDate = moment().add(1, 'year').format('YYYY-MM-DD')
  }

  dispatch(initializeGoalFormSucceeded(goalFormValues))
  if (!inEvaluation) {
    dispatch(initializeFocusView('Create A Goal', '/goals', '/goals'))
  }
}

export const initializeEditGoalForm = (goalId, history) => async (dispatch, getState) => {
  getGoalById(goalId).then((response) => {
    if (response.status === 200) {
      dispatch(initializeGoalEditFormSucceeded(response.data))
      dispatch(initializeFocusView(`Edit ${response.data.name}`, '/goals', '/goals'))
    } else if (response.status === 403) {
      history.push('/not-allowed')
    }
  })

  dispatch(updateUnsavedchanges(false))
}
export const setGoalFormErrors = (errors) => ({
  type: actionTypes.setGoalFormErrors,
  errors,
})

export const updateGoalForm = (event, type, name) => async (dispatch, getState) => {
  const goalForm = getIn(getState(), ['goalForm'])
  if (type === 'value') {
    dispatch(
      updateGoalFormSucceeded({
        ...goalForm,
        values: {
          ...goalForm.values,
          [name]: name === 'dueDate' ? FormatDateIgnoreTz(event) : event,
        },
        touched: {
          ...goalForm.touched,
          [name]: true,
        },
        isSaving: false,
      }),
    )
  } else {
    dispatch(
      updateGoalFormSucceeded({
        ...goalForm,
        values: {
          ...goalForm.values,
          [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        },
        touched: {
          ...goalForm.touched,
          [event.target.name]: true,
        },
      }),
    )
  }
  dispatch(validateGoalForm())
  dispatch(updateUnsavedchanges(true))
}

export const validateGoalForm = () => async (dispatch, getState) => {
  dispatch(validateGoalFormStarted())
  validate.extend(validate.validators.datetime, {
    parse: function (value, options) {
      return +moment.utc(value)
    },
    format: function (value, options) {
      var format = options.dateOnly ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mm:ss'
      return moment.utc(value).format(format)
    },
  })
  const errors = validate(getIn(getState(), ['goalForm', 'values']), goalDrawerValidations())

  dispatch(validateGoalFormSucceeded(errors ? false : true, errors || []))
}

export const handleUpdateGoal = (history) => async (dispatch, getState) => {
  dispatch(startSaving())
  const goalForm = getIn(getState(), ['goalForm'])
  if (goalForm.isValid) {
    updateGoal(goalForm.values.id, goalForm.values).then((response) => {
      if (response.status === 200) {
        dispatch(updateUnsavedchanges(false))
        dispatch(resetGoalForm())
        dispatch(enqueueSuccessNotification('Your goal was successfully updated.'))
        dispatch(endSaving())
        history.push('/goals')
      } else {
        dispatch(setGoalFormErrors(response.data.errors))
        dispatch(enqueueErrorNotification('The system encountered an error. Your goal was not updated.'))
        dispatch(endSaving())
      }
    })
  }
}

export const handleCreateGoal = (history, callback = () => {}) => async (dispatch, getState) => {
  dispatch(startSaving())
  const goalForm = getIn(getState(), ['goalForm'])
  if (goalForm.isValid) {
    createGoal(goalForm.values).then((response) => {
      if (response.status === 200) {
        dispatch(updateUnsavedchanges(false))
        dispatch(resetGoalForm())
        dispatch(enqueueSuccessNotification('Your goal was successfully created.'))
        dispatch(endSaving())
        callback()
        if (!goalForm.values.createdInEvaluation) {
          history.push('/goals')
        }
      } else {
        dispatch(setGoalFormErrors(response.data.errors))
        dispatch(enqueueErrorNotification('The system encountered an error. Your goal was not created.'))
        dispatch(endSaving())
      }
    })
  }
}

export const addMetric = (metric, usingGoalForm = true) => async (dispatch, getState) => {
  if (usingGoalForm) {
    const goalForm = getIn(getState(), ['goalForm'])

    dispatch(
      updateGoalFormSucceeded({
        ...goalForm,
        values: {
          ...goalForm.values,
          metrics: [...goalForm.values.metrics, metric],
        },
        touched: {
          ...goalForm.touched,
          metrics: true,
        },
      }),
    )

    dispatch(validateGoalForm())
  } else {
    createMetric(metric).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Metric was successfully created'))
      } else {
        dispatch(enqueueErrorNotification('The server encountered an error. The Metric was not created'))
      }
    })
  }
}

export const removeMetric = (metric, usingGoalForm = true) => async (dispatch, getState) => {
  if (usingGoalForm) {
    const goalForm = getIn(getState(), ['goalForm'])
    dispatch(
      updateGoalFormSucceeded({
        ...goalForm,
        values: {
          ...goalForm.values,
          metrics: removeItem(goalForm.values.metrics, metric),
        },
        touched: {
          ...goalForm.touched,
          metrics: true,
        },
      }),
    )
  } else {
    removeMetricApi(metric.id).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Metric was successfully removed'))
      } else {
        dispatch(enqueueErrorNotification('The server encountered an error. The Metric was not removed'))
      }
    })
  }
}
