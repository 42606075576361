import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import { DialogActions, DialogContent, Button, ListItemSecondaryAction, Divider } from '@material-ui/core'
import { ButtonWithPrompt } from 'components/Buttons'
import { Grid, Typography, List, ListItem, ListItemIcon, ListItemText, CircularProgress } from '@material-ui/core'
import EmployeeSearch from 'components/Search/EmployeeSearch'
import { createSignature, deleteSignature, getEvaluationSignatures } from 'api'
import { useDispatch } from 'react-redux'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { signatureStatus } from 'utils/evaluations/status'
import { Alert, AlertTitle } from '@material-ui/lab'
import { getIcon } from 'utils/GetIcon'

const useStyles = makeStyles({ dialog: { minWidth: 400 }, dialogContent: { minWidth: 400 } })

const SignaturesModal = (props) => {
  const classes = useStyles()

  const { evaluationBatchId, evaluationId, setOpen, open, title } = props
  const dispatch = useDispatch()
  const [peopleToSign, setPeopleToSign] = useState([])
  const [signatures, setSignatures] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handleClose = () => {
    setOpen(false)
    setPeopleToSign([])
  }

  const handleDelete = (signatureId) => {
    deleteSignature(evaluationBatchId, signatureId).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Signature request successfully deleted'))
        fetchSignatures()
      } else {
        dispatch(enqueueErrorNotification('Unable to delete signature request'))
      }
    })
  }

  const submitCreateNewSignaturesRequest = () => {
    let request = peopleToSign
    request.forEach((item) => {
      item.signedById = item.id
      item.evaluationId = evaluationId
      item.evaluationBatchId = evaluationBatchId
    })

    createSignature(evaluationBatchId, request).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Signature request successfully created'))
        fetchSignatures()
        setPeopleToSign([])
      } else {
        dispatch(enqueueErrorNotification('Server encountered an error. Signature request was not created.'))
      }
    })
  }

  const fetchSignatures = useCallback(() => {
    setIsLoading(true)
    if (evaluationBatchId) {
      getEvaluationSignatures(evaluationBatchId).then((response) => {
        setSignatures(response.data)
        setIsLoading(false)
      })
    }
  }, [evaluationBatchId])

  useEffect(() => {
    fetchSignatures()
  }, [fetchSignatures])

  return (
    <Dialog
      id={`${evaluationBatchId}_signature_modal`}
      onClose={handleClose}
      aria-labelledby="signature-dialog-title"
      open={open}
      className={classes.dialog}>
      <DialogContent>
        <Grid className={classes.dialogContent} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" id="signature-dialog-title">{title}</Typography>
            <Typography variant="h5">Signatures assigned to evaluation</Typography>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Typography variant="body2">
              Anyone with a signature request will be able to see evaluation results - Only request signatures from
              individuals that should have access to the evaluated employee’s files
            </Typography>
            {isLoading ? (
              <CircularProgress />
            ) : signatures && signatures.length > 0 ? (
              <List dense>
                {signatures &&
                  signatures.map((value, index) => (
                    <ListItem divider>
                      <ListItemIcon>{signatureStatus(value.status)}</ListItemIcon>
                      <ListItemText
                        primary={value.signedByName}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={value.status}
                      />
                      <ListItemSecondaryAction>
                        <ButtonWithPrompt
                          iconButton
                          icon={getIcon('delete')}
                          title="Delete signature"
                          description="Are you sure you want to delete the selected signature request?"
                          buttonText={getIcon('delete')}
                          color="primary"
                          clickFunction={() => handleDelete(value.id)}
                          positiveButtonLabel="Delete Signatures"
                          positiveButtonFunction
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            ) : (
              <Alert severity="info">
                <AlertTitle>No signatures requested for this evaluation</AlertTitle>
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <EmployeeSearch
              label="Request Additional Signatures"
              multiple
              onChange={(e) => setPeopleToSign(e)}
              returnType="object"
              value={peopleToSign}
              excludedIds={signatures && signatures.map((signature) => signature.signedById)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <ButtonWithPrompt
          title="Request additional people to sign"
          description="Are you sure you want to request the selected people to sign this evaluation?"
          buttonText="Submit"
          color="primary"
          clickFunction={submitCreateNewSignaturesRequest}
          positiveButtonLabel="Request Signatures"
          positiveButtonFunction
          disabled={isLoading || (peopleToSign && peopleToSign.length === 0)}
        />
      </DialogActions>
    </Dialog>
  )
}

SignaturesModal.propTypes = {
  evaluationId: PropTypes.number.isRequired,
  evaluationBatchId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

export default SignaturesModal
