import API from '../api'

export const st_getAllOrgs = async () => {
  return await API.get(`system/orgs/all`).then((response) => {
    return response.data
  })
}

export const st_actAsOrg = async (request) => {
  return await API.post(`system/orgs/act`, request).then((response) => {
    return response
  })
}

export const st_updateOrgFeatures = async (request) => {
  return await API.post(`system/orgs/features`, request).then((response) => {
    return response
  })
}
