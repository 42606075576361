import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Drawer } from '@material-ui/core'
import { Profile, SidebarNav } from './components'
import { getIcon } from 'utils/GetIcon'
import Footer from '../Footer'
import { getIn } from 'immutable'
import { useSelector } from 'react-redux'
import { Check } from 'utils/PermissionCheck'
import { roles } from 'utils/roles'
import { useAuth0 } from 'react-auth0-spa'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 210,
    [theme.breakpoints.up('md')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  drawerNoTools: {
    width: 210,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
      height: 'calc(100% - 64px)',
    },
  },
  drawerWithTools: {
    width: 210,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(16),
      height: 'calc(100% - 128px)',
    },
  },
  currentlyActingAs: {
    width: 210,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(12),
      height: 'calc(100% - 96px)',
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props
  const { appMetadata } = useAuth0()
  const classes = useStyles()
  const showTools = useSelector((state) => getIn(state, ['systemTools', 'showSystemTools']))
  const currentlyActingAsOrg = useSelector((state) => getIn(state, ['systemTools', 'currentlyActingAsOrg']))
  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      role: [roles.Member, roles.TeamLead, roles.Admin],
      icon: getIcon('dashboard'),
    },
    {
      title: 'Evaluations',
      href: Check([roles.Admin]) ? '/evaluations/all' : '/evaluations',
      role: [roles.Member, roles.TeamLead, roles.Admin],
      icon: getIcon('evaluation'),
    },
    {
      title: 'Pulse Surveys',
      href: '/pulse',
      role: [roles.Admin],
      icon: getIcon('pulse'),
      shouldHide: !appMetadata.accessPulse || false,
    },
    {
      title: 'Goals',
      href: '/goals',
      role: [roles.Member, roles.TeamLead, roles.Admin],
      icon: getIcon('goal'),
      shouldHide: !appMetadata.accessGoals || false,
    },
    // {
    //   title: 'Templates',
    //   href: '/templates',
    //   role: [roles.Admin],
    //   icon: getIcon('template'),
    // },
    // {
    //   title: 'Schedules',
    //   href: '/schedules',
    //   role: [roles.Admin],
    //   icon: getIcon('schedule'),
    // },
    {
      title: 'People',
      href: Check([roles.Admin]) ? '/people' : '/people/teams',
      role: [roles.TeamLead, roles.Admin],
      icon: getIcon('people'),
    },
    {
      title: 'Know Your Talents™',
      href: '/talents',
      role: [roles.Admin],
      icon: getIcon('kyt'),
      shouldHide: !appMetadata.accessKyt || false,
    },
    {
      title: 'Reports',
      href: '/reports',
      role: [roles.Admin],
      icon: getIcon('report'),
    },
    {
      title: 'Settings',
      href: '/settings/general/notifications',
      role: [roles.Admin],
      icon: getIcon('setting'),
    },
  ]

  return (
    <Drawer
      anchor="left"
      classes={{
        paper:
          currentlyActingAsOrg && showTools
            ? classes.currentlyActingAs
            : showTools
            ? classes.drawerWithTools
            : classes.drawerNoTools,
      }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
      <Footer />
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
