import React from 'react'
import MaterialTable from 'material-table'
import { ButtonGroup, Button } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { getAllPeople } from 'api'
import PropTypes from 'prop-types'

const PeopleTable = (props) => {
  const { pageSize, pageSizeOptions } = props

  const headCells = [
    {
      field: 'fullName',
      title: 'Name',
    },
    {
      field: 'email',
      title: 'Email',
      hidden: true,
    },
    {
      field: 'jobTitle',
      title: 'Job Title',
    },
    {
      field: 'hireDate',
      title: 'Hire Date',
      hidden: true,
      type: 'date',
    },
    {
      field: 'isTeamLead',
      title: 'Manager?',
      type: 'boolean',
    },
    {
      field: 'isOrgAdmin',
      title: 'Org Admin?',
      type: 'boolean',
      hidden: true,
    },

    {
      field: 'team.name',
      title: 'Team',
      defaultFilter: null,
    },
    // { field: 'team.lead', title: 'Reports to' }, //TODO: people response team lead name is broken
    {
      field: 'userSignupComplete',
      title: 'Completed Signup?',
      type: 'boolean',
    },
    {
      field: 'edit',
      title: 'Actions',
      filtering: false,
      render: (person) => (
        <ButtonGroup variant="text">
          <Button component={RouterLink} to={`/profile/${person.id}`}>
            Profile
          </Button>
        </ButtonGroup>
      ),
    },
  ]

  return (
    <MaterialTable
      columns={headCells}
      data={(query) =>
        new Promise((resolve, reject) => {
          console.log('pt query', query)
          getAllPeople(
            query.page,
            query.pageSize,
            query.search,
            (query.orderBy && query.orderBy.field) || '',
            query.orderDirection || '',
          ).then((response) => {
            if (response.status === 200) {
              resolve({
                data: response.data.data,
                page: query.page,
                totalCount: response.data.totalCount,
              })
            } else {
              reject()
            }
          })
        })
      }
      options={{
        actionsColumnIndex: -1,
        columnsButton: true,
        emptyRowsWhenPaging: false,
        exportButton: true,
        exportAddData: true,
        exportFileName: `Employees as of ${new Date().toDateString()}`,
        filtering: true,
        grouping: true,
        maxBodyHeight: 600,
        pageSize: pageSize,
        pageSizeOptions: pageSizeOptions,
        cellStyle: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
      title="Employees"
    />
  )
}

export default PeopleTable

PeopleTable.propTypes = {
  callback: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
}

PeopleTable.defaultProps = {
  pageSize: 1000,
  pageSizeOptions: [1000, 2000, 3000],
}
