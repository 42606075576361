import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  featuresWrapper: {
    width: '100%',
    flexGrow: 1,
    margin: 'unset',
  },
  description: {
    ...theme.typography.subtitle2,
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  featureImage: {
    width: '100%',
    height: '300px',
    [theme.breakpoints.down('xs')]: {
      height: '175px',
    },
  },
  featureWrapper: {
    minHeight: '300px',
    margin: 'unset',
  },
  sectionTitle: {},
}))

export default function Pricing() {
  const classes = useStyles()

  const features = [
    {
      title: 'Customizable Evaluations',
      tier: 'Basic',
      subtitle: ``,
      description: `Your organization is unique- Our evaluation builder lets you customize your evaluations in order to make them powerful, reusable, and insightful.
      
      We've worked with a variety of businesses, government entities, and schools to ensure that Express Evaluations can fit any organization's needs.`,
      image: 'images/jessica-lewis-fJXv46LT7Xk-unsplash.jpg',
    },
    {
      title: 'Advanced Scheduling Features',
      tier: 'Basic',
      subtitle: '',
      image: 'images//eric-rothermel-FoKO4DpXamQ-unsplash.jpg',
      description: `Keeping track of your busy calendar is a pain- Adding hundreds of evaluations makes it worse. 
      
      Our system lets you set recurring evaluations by person, team, or organization. We'll make sure your evaluations repeat when needed (monthly, annually, etc)`,
    },
    {
      title: 'Powerful Scoring Engine',
      tier: 'Basic',
      subtitle: ``,
      image: 'images/john-schnobrich-FlPc9_VocJ4-unsplash.jpg',
      description: `It's difficult to analyze dozens (or hundreds) of evaluation results. Our evaluation scoring helps easily identify trends in evaluation results, indentify bias, and quickly understand the bigger picture.
      
      If you don't want to score your employees- Don't sweat it. You can disable it.`,
    },
    {
      title: '100% Digital',
      tier: 'Basic',
      subtitle: ``,
      image: 'images/tetiana-shyshkina-TswOPhWcrmQ-unsplash.jpg',
      description: `It's 2020 - No more filing documents, emailing forms, or messaging people to remind them to complete evaluations. 
      
      Express Evaluations will automatically email employees before evaluations are due, notify managers when completed, and send out reminders when they are overdue. 
      
      After completion, evaluation results are stored securely online with customizable export options to fit your needs.`,
    },
  ]

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container className={classes.featuresWrapper} spacing={4} id="features">
        <Grid item xs={12}>
          <Typography variant="h2" align="center" className={classes.sectionTitle}>
            Features
          </Typography>
        </Grid>

        {features.map((feature, index) => (
          <Grid
            container
            item
            direction={index % 2 ? 'row' : 'row-reverse'}
            spacing={4}
            className={classes.featureWrapper}
            alignItems="center">
            <Grid item xs={12} sm={6}>
              <Card>
                <CardMedia image={feature.image} className={classes.featureImage} />
              </Card>
            </Grid>
            <Grid item key={feature.title} xs={12} sm={6}>
              <Typography variant="h3">{feature.title}</Typography>
              <p className={classes.description}>{feature.description}</p>
              <Typography variant="caption">{feature.caption}</Typography>
              {feature.cta && (
                <Button
                  variant={feature.buttonVariant}
                  color="secondary"
                  className={classes.actionButton}
                  disabled={feature.buttonDisabled}>
                  {feature.cta}
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
