import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ListItem, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const Text = (props) => {
  const classes = useStyles()
  const { config, updateConfig, allowMultiple } = props

  const handleValueChange = (e) => {
    config.questionText = e.target.value
    updateConfig(config)
  }

  return (
    <div className={classes.formControl}>
      <ListItem>
        <TextField
          fullWidth
          label="Response will be entered in this box during use"
          margin="dense"
          multiline={allowMultiple}
          onChange={handleValueChange}
          rows="6"
          value={config.questionText}
          variant="outlined"
          disabled
        />
      </ListItem>
    </div>
  )
}

Text.propTypes = {
  multiLine: PropTypes.bool,
}

export default Text
