import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Card, CardHeader, CardContent, CardActions, Divider, Button } from '@material-ui/core'
import { getResultsByTemplateReport } from 'api'
import { EvaluationTemplateSearch } from 'components/Search/EvaluationTemplateSearch'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}))

const ResultsByTemplate = (props) => {
  const [template, setTemplate] = useState({})
  const { className, ...rest } = props
  useEffect(() => {}, [])
  const handleDownload = () => {
    getResultsByTemplateReport({ template: template }).then((response) => {
      if (response.status === 200) {
        return response
      }
    })
  }
  const classes = useStyles()
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Evaluation Results by Templates Report" />
      <Divider />
      <CardContent>
        <EvaluationTemplateSearch setTemplate={(e) => setTemplate(e)} value={template} allowAllVersions />
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button disabled={!template} variant="contained" color="primary" onClick={handleDownload}>
          Download Report
        </Button>
      </CardActions>
    </Card>
  )
}

export default ResultsByTemplate
