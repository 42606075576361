const actionTypes = {
  addQuestionSucceeded: 'templateBuilder/addQuestionSucceeded',
  addSectionSucceeded: 'templateBuilder/addSectionSucceeded',
  addUnsavedCommentsGoalId: 'evaluation/addUnsavedCommentsGoalId',
  copyTemplateSucceeded: 'templateBuilder/copyTemplateSucceeded',
  closeModal: 'modal/close',
  completeGoalCreationSucceeded: 'evaluation/goalCreationCompleted',
  completeGoalReviewSucceeded: 'evaluation/goalReviewCompleted',
  endSavingGoalForm: 'goalForm/endSaving',
  endGoalReviewSucceeded: 'evaluation/endGoalReview',
  endGoalCreationSucceeded: 'evaluation/endGoalCreation',
  incrementBuilderTrackerId: 'templateBuilder/incrementBuilderTrackerId',
  initializeFocusView: 'focusView/initialize',
  initializeGoalEditFormSucceeded: 'goalForm/initializeEditSucceeded',
  initializeGoalFormSucceeded: 'goalForm/initializeSucceeded',
  initializeModal: 'modal/initialize',
  initializeScheduleFormSucceeded: 'scheduleForm/initializeSucceeded',
  initializePulseScheduleFormSucceeded: 'scheduleForm/initializePulseSucceeded',
  initializeEditScheduleFormSucceeded: 'scheduleForm/initializeEditSucceeded',
  initializeTemplateBuilderStarted: 'templateBuilder/initializeTemplateBuilderStarted',
  initializeNewTemplateBuilderSucceeded: 'templateBuilder/initializedNewSucceeded',
  initializeEditTemplateBuilderSucceeded: 'templateBuilder/initializeEditSucceeded',
  initializePreviewTemplateBuilderSucceeded: 'templateBuilder/initializePreviewSucceeded',
  loadTemplateTableStarted: 'template/loadTableStarted',
  loadTemplateTableSucceeded: 'template/loadTableSucceeded',
  loadArchivedTemplateTableSucceeded: 'template/loadArchivedTableSucceeded',
  loadPulseTemplateTableSucceeded: 'template/loadPulseTableSucceeded',
  moveSectionSucceeded: 'templateBuilder/moveSectionSucceeded',
  openModal: 'modal/open',
  removeUnsavedCommentsGoalId: 'evaluation/removeUnsavedCommentsGoalId',
  resetEvaluationToDefaults: 'evaluation/resetToDefaults',
  resetGoalForm: 'goalForm/resetGoalForm',
  resetReviewCycle: 'goalForm/resetReviewcycle',
  resetScheduleForm: 'scheduleForm/resetScheduleForm',
  setGoalFormErrors: 'goalForm/setErrors',
  setScheduleFormErrors: 'scheduleForm/setErrors',
  setUnsavedTemplateBuilderChanges: 'templateBuilder/setUnsavedChanges',
  startEvaluationGoalCreation: 'goalForm/startEvaluationCreation',
  startGoalCreationSucceeded: 'evaluation/startGoalCreation',
  startGoalReviewSucceeded: 'evaluation/startGoalReview',
  startSavingGoalForm: 'goalForm/startSaving',
  startSavingScheduleForm: 'scheduleForm/startSaving',
  endSavingScheduleForm: 'scheduleForm/endSaving',
  toggleAutosave: 'evaluation/toggleAutosave',
  toggleTemplateScoring: 'templateBuilder/toggleTemplateScoring',
  updateCompletedSectionsSucceeded: 'evaluation/updateCompletedSectionsSucceeded',
  updateFocusViewBottomDescription: 'focusView/updateBottomDescription',
  updateEvaluationGoals: 'evaluation/updateEvaluationGoals',
  updateGoalFormSucceeded: 'goalForm/updatedFormSucceeded',
  updateQuestionSucceeded: 'templateBuilder/updateQuestionSucceeded',
  updateReadyToSubmit: 'evaluation/updateReadyToSubmit',
  updateScheduleFormSucceeded: 'scheduleForm/updateFormSucceeded',
  updateSectionSucceeded: 'templateBuilder/updateSectionSucceeded',
  updateTemplateProperty: 'templateBuilder/updateTemplateProperty',
  updateTemplateScore: 'templateBuilder/updateTemplateScore',
  updateTemplateSucceeded: 'templateBuilder/updateTemplateSucceeded',
  updateTimestampSucceeded: 'templateBuilder/updateTimestampSucceeded',
  updateUnsavedChanges: 'focusView/updateUnsavedChanges',
  validateGoalFormStarted: 'goalForm/validateStarted',
  validateGoalFormSucceeded: 'goalForm/validateSucceeded',
  validateScheduleFormStarted: 'scheduleForm/validateStarted',
  validateScheduleFormSucceeded: 'scheduleForm/validateSucceeded',
}

export default actionTypes
