import { FormatDate } from 'utils/DateTime'

const initialState = {
  evaluation: {
    isLoading: true,
    completedSections: [],
    unsavedCommentGoalIds: [],
  },
  focusView: {
    title: '',
    backUrl: '',
    closeUrl: '',
    bottomDescription: '',
    actions: [],
    unsavedChanges: false,
  },
  goalForm: {
    isValid: false,
    values: {
      id: 0,
      name: '',
      description: '',
      reviewCycle: {},
      metrics: [],
      type: 'person',
      batchId: '',
      teams: {},
      people: {},
      jobTitles: '',
      oneOff: false,
      isPersonal: true,
      dueDate: FormatDate(Date.now()),
    },
    touched: {},
    errors: {},
    isLoading: true,
    isEdit: false,
  },
  import: {
    people: [],
  },
  notifications: [],
  people: {},
  templateBuilder: {
    loading: true,
    template: {
      name: 'Evaluation',
      title: '',
      description: '',
      isActive: true,
      sections: [
        {
          title: '',
          description: '',
          position: 0,
          isActive: true,
          showInSelf: true,
          showInPeer: false,
          showInLead: true,
          score: 0,
          evaluation: {},
          questions: [],
          sys_id: 0,
        },
      ],
      evaluations: [],
      scoringEnabled: true,
      isPulseTemplate: false,
      score: 0,
      showGoals: false,
    },
  },
  templates: {
    active: [],
    archived: [],
    pulse: [],
    loading: true,
  },
  scheduleForm: {
    isLoading: true,
    isValid: false,
    isSaving: false,
    values: {
      evaluatedPeople: [],
      peerEvaluators: [],
      evaluatedJobTitles: [],
      sendToPeers: false,
      sendToLead: true,
      sendToSelf: true,
      dueDate: new Date(),
      dueDateType: 'date',
      type: 'person',
      teams: [],
      name: 'New Schedule',
      isPulse: false,
      startDateOffset: 0,
      endDateOffset: 30,
      startOffsetDirection: 'before',
      endOffsetDirection: 'after',
      recurrence: {
        dueDate: new Date(),
        repeatType: 'month',
        interval: 1,
        repeat: false,
        repeatOnDays: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
        ends: false,
        endsType: 'never',
      },
    },
    touched: {},
    errors: {},
  },
  systemTools: {
    showSystemTools: false,
  },
}

export default initialState
