import actionTypes from 'redux/actionTypes'
import { addDays, addMonths } from 'utils/DateTime/FormatDate'
const initialState = {
  isLoading: true,
  isValid: false,
  isSaving: false,
  values: {
    reviewees: [],
    peerEvaluators: [],
    evaluatedJobTitles: [],
    sendToPeers: false,
    sendToLead: true,
    sendToSelf: true,
    requireSelfSignature: false,
    requireLeadSignature: false,
    requirePeerSignature: false,
    dueDate: addDays(new Date(), 1),
    dueDateType: 'date',
    type: 'person',
    teams: [],
    name: '',
    isPulse: false,
    startDateOffset: -6,
    endDateOffset: 30,
    startOffsetDirection: 'before',
    endOffsetDirection: 'after',
    includeGoalsRange: 365,
    repeatType: 'month',
    interval: 1,
    repeat: false,
    repeatOnDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    ends: true,
    endsType: 'on',
    endsOn: addMonths(addDays(new Date(), 1), 1),
  },
  touched: {},
  errors: {},
  isEdit: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.initializeScheduleFormSucceeded:
      return Object.assign({}, state, {
        values: action.values,
        isLoading: false,
      })
    case actionTypes.initializePulseScheduleFormSucceeded:
      return Object.assign({}, state, {
        values: { ...action.values, isPulse: true, recurrence: { ...action.values.recurrence, repeat: true } },
        isLoading: false,
      })
    case actionTypes.initializeEditScheduleFormSucceeded:
      return Object.assign({}, state, {
        values: action.values,
        isLoading: false,
        isEdit: true,
      })
    case actionTypes.updateScheduleFormSucceeded:
      return Object.assign({}, state, {
        ...action.form,
      })
    case actionTypes.validateScheduleFormStarted:
      return Object.assign({}, state, {
        errors: [],
      })
    case actionTypes.validateScheduleFormSucceeded:
      return Object.assign({}, state, {
        isValid: action.isValid,
        errors: action.errors,
      })
    case actionTypes.setScheduleFormErrors:
      return Object.assign({}, state, {
        errors: action.errors,
      })
    case actionTypes.resetScheduleForm:
      return Object.assign({}, state, {
        ...initialState,
      })
    case actionTypes.startSavingScheduleForm:
      return Object.assign({}, state, {
        isSaving: true,
      })
    case actionTypes.endSavingScheduleForm:
      return Object.assign({}, state, {
        isSaving: false,
      })
    default:
      return state
  }
}
