import API from '../api'

export const getAllEvaluationTemplates = async () => {
  return await API.get('evaluations/template/')
}

export const getEvaluationById = async (id) => {
  return await API.get(`evaluations/${id}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getEvaluationTemplateById = async (id) => {
  return await API.get(`evaluations/template/${id}`)
}

export const getMyEvaluations = async (id) => {
  return await API.get(`evaluations/assigned/${id}`)
}

export const createEvaluation = async (data) => {
  return await API.post('evaluations/create', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const createPulseEvaluation = async () => {
  return await API.get('evaluations/pulse/create')
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const updateEvaluation = async (data) => {
  return await API.put('evaluations/update', data).then((response) => {
    return response.data
  })
}

export const submitEvaluation = async (data) => {
  return await API.put('evaluations/submit', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const createEvaluationTemplate = async (data) => {
  return await API.post('evaluations/template/create', data).then((response) => {
    return response.data
  })
}

export const updateEvaluationTemplate = async (data) => {
  return await API.put(`evaluations/template/${data.evaluationTemplate.id}/update`, data.evaluationTemplate).then(
    (response) => {
      return response
    },
  )
}

export const deleteEvaluation = async (data) => {
  return await API.post(`evaluations/delete`, data).then((response) => {
    return response.data
  })
}

export const getEvaluationsByAssignedToId = async (
  personId,
  page = 0,
  pageSize = 0,
  searchQuery = '',
  sortBy = '',
  sortDirection = '',
  excludedStatuses = [],
) => {
  const statusParam = excludedStatuses.reduce((total, status) => `${total}&excludedStatuses=${status}`, '')
  return await API.get(
    `evaluations/person/${personId}/assigned?currentPage=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchQuery=${searchQuery}${statusParam}`,
  )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getEvaluationsByEvaluatedId = async (
  personId,
  page = 0,
  pageSize = 0,
  searchQuery = '',
  excludedStatuses = [],
  sortBy = '',
  sortDirection = '',
) => {
  const statusParam = excludedStatuses.reduce((total, status) => `${total}&excludedStatuses=${status}`, '')
  return await API.get(
    `evaluations/person/${personId}/evaluated?currentPage=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchQuery=${searchQuery}${statusParam}`,
  )
}

export const getEvaluations = async ({
  page = 0,
  pageSize = 0,
  searchQuery = '',
  excludedStatuses = [],
  includedStatuses = [],
  excludedRevieweeIds = [],
  includedRevieweeIds = [],
  excludedReviewerIds = [],
  includedReviewerIds = [],
  sortBy = '',
  sortDirection = '',
}) => {
  const searchParams = new URLSearchParams({
    page: page,
    pageSize: pageSize,
    searchQuery: searchQuery,
    sortBy: sortBy,
    sortDirection: sortDirection,
  })

  excludedStatuses.forEach((status) => searchParams.append('excludedStatuses', status))
  includedStatuses.forEach((status) => searchParams.append('includedStatuses', status))
  excludedRevieweeIds.forEach((id) => searchParams.append('excludedRevieweeIds', id))
  includedRevieweeIds.forEach((id) => searchParams.append('includedRevieweeIds', id))
  excludedReviewerIds.forEach((id) => searchParams.append('excludedReviewerIds', id))
  includedReviewerIds.forEach((id) => searchParams.append('includedReviewerIds', id))

  return await API.get(`evaluations?${searchParams}`)
}

export const getSelfEvaluationResults = async (id) => {
  return await API.get(`evaluations/results/self/${id}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getCombinedEvaluationResults = async (id) => {
  return await API.get(`evaluations/results/combined/${id}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getCompletedEvaluations = async () => {
  return await API.get('evaluations/completed')
}

export const getTeamEvaluations = async (
  id,
  limit = 0,
  status = null,
  page = 0,
  pageSize = 0,
  searchQuery = '',
  excludedStatuses = [],
  sortBy = '',
  sortDirection = '',
) => {
  const statusParam = excludedStatuses.reduce((total, status) => `${total}&excludedStatuses=${status}`, '')
  return await API.get(
    `evaluations/team/${id}?limit=${limit}&status=${status}&currentPage=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchQuery=${searchQuery}${statusParam}`,
  )
}

export const saveEvaluationProgress = async (data) => {
  return await API.put('evaluations/save', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const unlockEvaluation = async (id) => {
  return await API.put(`evaluations/unlock/${id}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const askForSignature = async (data) => {
  return await API.post(`evaluations/askToSign`, data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getPastEvaluations = async (
  page = 0,
  pageSize = 0,
  searchQuery = '',
  excludedStatuses = [],
  sortBy = '',
  sortDirection = '',
) => {
  const statusParam = excludedStatuses.reduce((total, status) => `${total}&excludedStatuses=${status}`, '')
  return await API.get(
    `evaluations/past?currentPage=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchQuery=${searchQuery}${statusParam}`,
  )
}

export const finalizeEvaluation = async (data) => {
  return await API.post(`evaluations/finalize`, data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const sendEvaluationNotifications = async (evaluationId, feedbackIds) => {
  return await API.post(`evaluations/${evaluationId}/notify`, feedbackIds)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getEvaluationsActions = async () => {
  return await API.get(`evaluations/actions`)
}
