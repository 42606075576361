import React, { useState } from 'react'
import { useModernCardStyles } from 'styles'
import { Grid, Paper, Typography } from '@material-ui/core'

const ModernCard = (props) => {
  const classes = useModernCardStyles()

  const [showDetails, setShowDetails] = useState(true)
  const {
    children,
    headerTitle,
    collapsable,
    disableElevation,
    outlineHeader,
    leftSubtitle,
    rightSubtitle,
    spacing,
    ...rest
  } = props
  return (
    <Paper className={classes.paper} elevation={disableElevation ? 0 : 1} {...rest}>
      <div
        className={outlineHeader ? classes.paperOutlineHeader : classes.paperHeader}
        onClick={() => collapsable && setShowDetails(!showDetails)}>
        <Grid container>
          <Grid item xs={11}>
            {leftSubtitle ||
              (rightSubtitle && (
                <div className={classes.subtitleBox}>
                  <div className={classes.alignLeft}>{leftSubtitle}</div>
                  <div className={classes.alignRight}>{rightSubtitle}</div>
                </div>
              ))}
            <Typography color="inherit" variant="h5">
              {headerTitle}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {showDetails && (
        <div className={spacing === 'compact' ? classes.compactPaperContent : classes.paperContent}>{children}</div>
      )}
    </Paper>
  )
}

export default ModernCard
