import React from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Question from '../Question'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { RichTextArea } from 'components/Inputs'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  addSection,
  deleteSection,
  addQuestion,
  updateSection,
  moveSection,
} from 'redux/actions/templateBuilderActions'
import { getIn } from 'immutable'
import { useSectionBuilderStyles } from 'styles'
import { useAuth0 } from 'react-auth0-spa'
import { getIcon } from 'utils/GetIcon'

const Section = (props) => {
  const { index, availableToEdit } = props
  const classes = useSectionBuilderStyles()
  const dispatch = useDispatch()
  const template = useSelector((state) => getIn(state, ['templateBuilder', 'template']))
  const section = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'sections', index]), shallowEqual)
  const isPulse = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'isPulseTemplate']))
  const { appMetadata } = useAuth0()

  return (
    <div className={`${classes.root} section-block`} id={section.sys_id + '_section_block'} stepid={index}>
      <Card className={classes.sectionCard}>
        <form autoComplete="off" noValidate>
          <CardHeader
            title={isPulse ? 'Instructions' : `Section ${index + 1} of ${template.sections.length}`}
            subheader={
              isPulse
                ? 'Enter instructions for employees below'
                : template.scoringEnabled && `Section points: ${section.score}`
            }
            subheaderTypographyProps={{ color: 'secondary' }}
          />
          <CardContent className={classes.sectionHeader}>
            <Grid container spacing={2}>
              {!isPulse && (
                <Grid item xs={12}>
                  <TextField
                    autoFocus={props.shouldAutoFocus}
                    fullWidth
                    label="Section Title"
                    margin="dense"
                    onChange={(e) => dispatch(updateSection(section.sys_id, { ...section, title: e.target.value }))}
                    required
                    value={section.title}
                    variant="outlined"
                    disabled={!availableToEdit}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {!isPulse && <Typography className={classes.sectionInstructionTitle}>Section Instructions</Typography>}
                <RichTextArea
                  initialValue={''}
                  value={section.description}
                  onChange={(content, editor) =>
                    dispatch(updateSection(section.sys_id, { ...section, description: content }))
                  }
                  disabled={!availableToEdit}
                />
              </Grid>
              <Collapse in={!isPulse}>
                <Grid item xs={12} className={classes.showSectionWrapper}>
                  <FormControl
                    className={classes.showSectionDropdown}
                    error={!section.showInSelf && !section.showInLead && !section.showInPeer}>
                    <FormLabel component="legend">Who should complete this section of the evaluation?</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={section.showInSelf}
                            onChange={(e) =>
                              dispatch(updateSection(section.sys_id, { ...section, showInSelf: !section.showInSelf }))
                            }
                            name={'showInSelf'}
                            disabled={!availableToEdit}
                          />
                        }
                        label={'Employee'}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={section.showInLead}
                            onChange={(e) =>
                              dispatch(updateSection(section.sys_id, { ...section, showInLead: !section.showInLead }))
                            }
                            name={'showInLead'}
                            disabled={!availableToEdit || isPulse}
                          />
                        }
                        label={'Manager'}
                      />
                      {appMetadata.accessPeerEvaluations && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={section.showInPeer}
                              onChange={(e) =>
                                dispatch(updateSection(section.sys_id, { ...section, showInPeer: !section.showInPeer }))
                              }
                              name={'showInPeer'}
                              disabled={!availableToEdit || isPulse}
                            />
                          }
                          label={'Peers'}
                        />
                      )}
                    </FormGroup>
                    <FormHelperText>At least one option must be selected</FormHelperText>
                  </FormControl>
                </Grid>
              </Collapse>
            </Grid>
          </CardContent>
          <Divider />
        </form>
        {!isPulse && (
          <CardActions>
            <div style={{ flexGrow: '1' }} />
            <Divider flexItem orientation="vertical" />
            <IconButton aria-label="delete section" onClick={() => dispatch(deleteSection(section.sys_id))}>
              <DeleteIcon />
            </IconButton>
            <Divider flexItem orientation="vertical" />
            <ButtonGroup size="small" variant="text">
              <Button onClick={() => dispatch(moveSection(index, index + 1))}>
                <ArrowDownwardIcon />
              </Button>
              <Button onClick={() => dispatch(moveSection(index, index - 1))}>
                <ArrowUpwardIcon />
              </Button>
            </ButtonGroup>
          </CardActions>
        )}
      </Card>
      {section.questions.map((question, questionIndex) => (
        <Question
          key={`s-${section.sys_id}-q-${question.sys_id}`}
          numQuestions={section.questions.length}
          sectionSysId={section.sys_id}
          sectionIndex={index}
          questionIndex={questionIndex}
          availableToEdit={availableToEdit}
          shouldAutoFocus={question.shouldAutoFocus}
        />
      ))}
      <ButtonGroup variant="contained">
        {props.index === template.sections.length - 1 && !isPulse && (
          <Button
            startIcon={getIcon('add')}
            className={isPulse ? classes.buttonColorPulse : classes.buttonColor}
            onClick={() => dispatch(addSection())}
            disabled={!availableToEdit}>
            Section
          </Button>
        )}
        <Button
          startIcon={getIcon('add')}
          className={isPulse ? classes.buttonColorPulse : classes.buttonColor}
          onClick={() => dispatch(addQuestion(section.sys_id))}
          disabled={!availableToEdit}>
          Question
        </Button>
      </ButtonGroup>
    </div>
  )
}

Section.propTypes = {
  copyQuestion: PropTypes.func,
  index: PropTypes.number,
  onDeleteClick: PropTypes.func,
  questionData: PropTypes.object,
  questions: PropTypes.array,
  sectionData: PropTypes.object,
  setTemplate: PropTypes.func,
}

export default Section
