import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, Tooltip, Typography, Switch, FormGroup, FormControlLabel } from '@material-ui/core'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  startGoalCreation,
  startGoalReview,
  submitCompletedEvaluation,
  toggleEvaluationAutoSave,
  updateStep,
} from 'redux/actions/evaluationActions'
import { getIn } from 'immutable'
import { saveEvaluation } from 'redux/actions/evaluationActions'
import { SaveCircularStatusButton } from 'components/Buttons'
import { useParams } from 'react-router'

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  buttonHelpText: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
  tooltipText: {
    color: theme.palette.primary.contrastText,
  },
}))

const ActionButtons = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const activeStep = useSelector((state) => state.evaluation.currentSectionIndex)
  const numberOfSections = useSelector((state) => state.evaluation.numberOfSections)
  const isPreview = useSelector((state) => state.evaluation.isPreview)
  const isPulse = useSelector((state) => state.evaluation.isPulse)
  const readyForSubmit = useSelector((state) => state.evaluation.readyForSubmit)
  const completedSections = useSelector((state) => getIn(state, ['evaluation', 'completedSections']))
  const includeReviewingGoals = useSelector((state) => getIn(state, ['evaluation', 'includeReviewingGoals']))
  const reviewingGoals = useSelector((state) => getIn(state, ['evaluation', 'reviewingGoals']))
  const includeGoalCreation = useSelector((state) => getIn(state, ['evaluation', 'includeGoalCreation']))
  const creatingGoals = useSelector((state) => getIn(state, ['evaluation', 'creatingGoals']))
  const unsavedCommentGoalIds = useSelector((state) => getIn(state, ['evaluation', 'unsavedCommentGoalIds']), [
    shallowEqual,
  ])
  const autoSaveEnabled = useSelector((state) => getIn(state, ['evaluation', 'autoSaveEnabled']))
  const { token } = useParams()

  const showNextButton = () => {
    if (activeStep === numberOfSections - 1) {
      let showButton = true
      if (includeGoalCreation && includeReviewingGoals && !creatingGoals) {
        showButton = true
      } else if (includeReviewingGoals && !includeGoalCreation && !reviewingGoals) {
        showButton = true
      } else if (!includeReviewingGoals && includeGoalCreation && !creatingGoals) {
        showButton = true
      } else {
        return false
      }

      return showButton
    } else {
      return true
    }
  }

  const handleNextButtonClick = () => {
    if (activeStep !== numberOfSections - 1) {
      dispatch(updateStep('next'))
    } else if (includeReviewingGoals && !reviewingGoals) {
      dispatch(startGoalReview())
    } else if (includeGoalCreation) {
      dispatch(startGoalCreation())
    }
  }

  const handleBackButtonClick = () => {
    if (reviewingGoals) {
      dispatch(updateStep('reviewGoalsPrevious'))
    } else if (creatingGoals) {
      dispatch(updateStep('createGoalsPrevious'))
    } else {
      dispatch(updateStep('previous'))
    }
  }

  return (
    <>
      <div className={classes.buttons}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch checked={autoSaveEnabled} onChange={() => dispatch(toggleEvaluationAutoSave())} name="checkedA" />
            }
            label="Autosave"
          />
        </FormGroup>
        {!isPulse && (
          <Tooltip
            title={
              isPreview
                ? 'Because you are previewing this evaluation, you will not be able to save these results'
                : completedSections.length < numberOfSections
            }>
            <span>
              <Button disabled={isPreview} onClick={() => dispatch(saveEvaluation())} variant="outlined">
                Save
              </Button>
            </span>
          </Tooltip>
        )}

        {(activeStep !== 0 || reviewingGoals || creatingGoals) && (
          <Button variant="outlined" className={classes.button} onClick={() => handleBackButtonClick()}>
            Back
          </Button>
        )}
        {!showNextButton() && (
          <Tooltip
            arrow
            title={
              <Typography color="inherit" variant="h6">
                {isPreview
                  ? 'Because you are previewing this evaluation, you will not be able to submit these results'
                  : completedSections.length < numberOfSections
                  ? 'There are required questions that have not been completed at this time- Please review your evaluation before you try to submit again'
                  : 'After clicking submit, your current response will not be editable. If changes are required, you will need to reach out to your HR manager. If your submission has any errors, please save your evaluation and try again at a later time or reach out to your HR manager.'}
              </Typography>
            }>
            <span>
              <SaveCircularStatusButton
                className={classes.button}
                color="primary"
                disabled={!readyForSubmit}
                preLabel="Submit"
                postLabel="Submitting..."
                singleSubmit
                onClick={async () => dispatch(submitCompletedEvaluation(token))}
                variant="contained"
              />
            </span>
          </Tooltip>
        )}
        {showNextButton() && (
          <Tooltip
            title={
              unsavedCommentGoalIds.length !== 0 && reviewingGoals ? (
                <Typography className={classes.tooltipText} variant="h6">
                  Unsaved comments on goal(s). Make sure to save your comments on each goal before clicking next.
                </Typography>
              ) : (
                ''
              )
            }
            open={true}
            arrow
            className={classes.tooltip}>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => handleNextButtonClick()}
              variant="contained">
              Next
            </Button>
          </Tooltip>
        )}
      </div>
    </>
  )
}

ActionButtons.propTypes = {
  back: PropTypes.func,
  isPreview: PropTypes.bool,
  next: PropTypes.func,
  numberOfSections: PropTypes.number,
  submitEvaluation: PropTypes.func,
}

ActionButtons.defaultProps = {
  back: () => {},
  isPreview: false,
  next: () => {},
  numberOfSections: 0,
  submitEvaluation: () => {},
}

export default ActionButtons
