import { makeStyles } from '@material-ui/styles'

export const useEvaluationResultStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  responseSectionCard: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  sectionTitle: {
    color: theme.palette.primary.contrastText,
  },
  responseQuestionCard: {
    width: '98%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '94%',
      margin: 'auto',
    },
  },
  column: {
    padding: theme.spacing(2),
  },
  detailCard: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: '0px',
  },
  evaluationMeta: {
    display: 'block',
  },
  resultsTitle: {
    fontSize: 50,
  },
  resultsSubTitle: {
    fontSize: 30,
  },
  evaluationTitle: {
    textTransform: 'Capitalize',
    marginTop: '2px',
    marginBottom: '2px',
  },
  optionToggle: {
    width: '100%',
    marginRight: 0,
  },
  spacer: {
    flexGrow: 1,
  },
  headingCard: {
    backgroundColor: theme.palette.white,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderRadius: 15,
  },
  sectionHeading: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  resultsHeader: {
    borderRadius: 'inherit',
    display: 'flex',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    '& h2, h3, h4, h5': {
      color: theme.palette.primary.contrastText,
    },
    '& h6': {
      color: theme.palette.text.lightGrey,
    },
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
  },
  resultsBody: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  signatureBox: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  scoreBox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  type: {},
  evaluationResult: {
    marginBottom: theme.spacing(12),
  },
  actions: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signature: {
    fontFamily: 'Homemade Apple',
    fontSize: 24,
  },
  signatureButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionBanner: {
    padding: theme.spacing(2),
  },
  grow: {
    display: 'flex',
  },
  download: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
  },
  actionButton: {
    marginLeft: theme.spacing(2),
  },
  subTopItem: {
    marginLeft: theme.spacing(4),
  },
  subItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))
