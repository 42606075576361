import React from 'react'
import DateFnsUtils from '@date-io/date-fns' // choose your lib
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

function DatePicker(props) {
  const { value, setValue, minDate, maxDate, helperText, disabled, variant, style, ref } = props

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={{ ...style, width: '100%' }}
        variant="inline"
        inputVariant={variant ? variant : 'outlined'}
        helperText={helperText}
        label={props.title}
        value={value}
        onChange={setValue}
        minDate={minDate}
        maxDate={maxDate}
        format="MM/dd/yyyy"
        disabled={disabled}
        autoOk
        ref={ref}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
