import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Button, Card, CardHeader, CardContent, Grid, Divider, Typography, TextField } from '@material-ui/core'
import { getOrganization, updateOrg } from 'api'
import { useDispatch } from 'react-redux'
import { enqueueSuccessNotification, enqueueErrorNotification } from 'redux/actions/notificationsActions'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  notifyInput: {
    marginLeft: theme.spacing(2),
    textAlign: 'center',
  },
  notifyUnit: {
    minWidth: 80,
  },
  orgButton: {
    marginTop: theme.spacing(2),
  },
  orgEvents: {
    maxWidth: '350px',
  },
}))

const GeneralSettings = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const [org, setOrg] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    getOrganization().then((response) => {
      setOrg(response)
    })
  }, [])

  const updateOrgDetails = () => {
    updateOrg({ name: org.name }).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Your company name has been updated'))
      } else {
        dispatch(
          enqueueErrorNotification('The server encountered an error.  The company name was not able to be updated'),
        )
      }
    })
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subheader="Manage your company's general settings" title="General" />
      <Divider />
      <CardContent>
        <Grid container spacing={2} wrap="wrap">
          <Grid item xs={8}>
            <Typography variant="h6">Company Name</Typography>
            <TextField
              id="edit_company_name"
              variant="outlined"
              value={org.name}
              onChange={(e) => setOrg({ ...org, name: e.target.value })}
              fullWidth
              helperText="If you have a large number of people in your organization, changing the name may take up to a minute to process"
            />
            <Button variant="contained" color="primary" className={classes.orgButton} onClick={updateOrgDetails}>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

GeneralSettings.propTypes = {
  className: PropTypes.string,
}

export default GeneralSettings
