import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'
import { getAllOrgs, selectUseAsOrg } from 'redux/actions/systemToolsActions'
import { getIn } from 'immutable'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  search: {
    width: 200,
    backgroundColor: 'white',
    borderRadius: 4,
  },
}))

const OrgSearch = (props) => {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0
  const dispatch = useDispatch()
  const orgSelected = (e, selectedOrg) => {
    dispatch(selectUseAsOrg(selectedOrg))
  }
  const classes = useStyles()
  dispatch(getAllOrgs())
  const orgs = useSelector((state) => getIn(state, ['systemTools', 'orgs']))
  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    ;(async () => {
      if (active) {
        const alphaOrgs = orgs.map((org) => {
          const firstLetter = org.name[0].toUpperCase()
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...org,
          }
        })
        setOptions(alphaOrgs)
      }
    })()

    return () => {
      active = false
    }
  }, [loading, orgs])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <Autocomplete
      getOptionLabel={(option) => (option.name !== undefined ? `${option.name} | ${option.userCount} Users` : '')}
      groupBy={(option) => option.firstLetter}
      id="org-search"
      loading={loading}
      limitTags={2}
      onChange={orgSelected}
      onClose={() => {
        setOpen(false)
      }}
      onOpen={() => {
        setOpen(true)
      }}
      open={open}
      size="small"
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={props.helperText}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={props.title}
          variant="outlined"
        />
      )}
      renderOption={(option) => <div>{option.name}</div>}
      className={classes.search}
      fullWidth
      value={props.value}
      disabled={props.disabled}
    />
  )
}

export default OrgSearch
