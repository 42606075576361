import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { incompleteEvaluations } from 'api'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  evalTable: {
    width: '100%',
  },
}))

const IncompleteEvaluationsTable = () => {
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const classes = useStyles()
  const location = useLocation()
  const urlParams = queryString.parse(location.search)
  const overdueFilter = urlParams.defaultFilter === 'overdue'

  const headCells = [
    { field: 'evaluationTitle', title: 'Evaluation' },
    { field: 'evaluatedPersonName', title: 'Evaluated Person' },
    { field: 'evaluatorName', title: 'Evaluator' },
    {
      field: 'evaluationType',
      title: 'Eval Type',
      lookup: { self: 'Self', lead: 'Lead' },
    },
    {
      field: 'status',
      title: 'Status',
      lookup: { ready: 'Not Started', inProgress: 'In Progress' },
    },
    {
      field: 'dueDate',
      title: 'Due Date',
      type: 'date',
    },
    {
      field: 'isOverdue',
      title: 'Overdue?',
      defaultFilter: overdueFilter ? 'true' : '',
      hidden: true,
    },
    {
      field: 'batchEvaluationId',
      title: 'Batch ID',
      hidden: true,
    },
  ]

  useEffect(() => {
    incompleteEvaluations().then((response) => {
      setRows(response)
      setIsLoading(false)
    })
  }, [])

  return (
    <MaterialTable
      className={classes.evalTable}
      columns={headCells}
      data={rows}
      options={{
        actionsColumnIndex: -1,
        columnsButton: urlParams.defaultFilter !== 'overdue',
        emptyRowsWhenPaging: false,
        exportButton: true,
        exportAddData: true,
        exportFileName: `${overdueFilter ? 'Overdue' : 'Incomplete'} Evaluations as of ${new Date().toDateString()}`,
        filtering: true,
        grouping: true,
        maxBodyHeight: '550px',
        pageSize: 100,
        pageSizeOptions: [100, 250, 500, 1000],
        search: false,
      }}
      title={`${overdueFilter ? 'Overdue' : 'Incomplete'} Evaluations`}
      isLoading={isLoading}
    />
  )
}

export default IncompleteEvaluationsTable
