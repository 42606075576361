import React, { useState, useEffect, createRef, useCallback } from 'react'
import MaterialTable from 'material-table'
import { Link as RouterLink } from 'react-router-dom'
import { Button, ButtonGroup, Chip, Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import CombinedEvaluationsDetailTable from './CombinedEvaluationsDetailTable'
import { getCombinedEvaluationPdf, getSignatureSettings } from 'api'
import ParticipantsModal from 'components/Modals/ParticipantsModal'
import SignaturesModal from 'components/Modals/SignaturesModal'
import { getStatusCircle, getTypeChip } from 'utils/evaluations'
import { FormatDateIgnoreTz } from 'utils/DateTime'
import TableFilterBar from './TableFilterBar'
import { Link, useLocation } from 'react-router-dom'
import { getIcon } from 'utils/GetIcon'
import { filterBarObject } from 'utils/Filters'
import PropTypes from 'prop-types'
import { useCombinedEvaluationsTableStyles } from 'styles'
import { useDispatch } from 'react-redux'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { Check } from 'utils/PermissionCheck'
import { roles } from 'utils/roles'
import { useAuth0 } from 'react-auth0-spa'

const CombinedEvaluationsTable = (props) => {
  const {
    allowDelete,
    allowUnlock,
    disableRowClick,
    fetchData,
    hideDetailPanel,
    hideFilters,
    hidePaper,
    pageSize,
    pageSizeOptions,
    refreshDataOnChange,
    title,
    usePdfButton,
    onlyPastEvaluations,
  } = props
  const [signaturesEnabled, setSignaturesEnabled] = useState(false)
  const [excludedStatuses, setExcludedStatuses] = useState(onlyPastEvaluations ? [] : ['finalized'])
  const [includedStatuses] = useState(onlyPastEvaluations ? ['finalized'] : [])
  const tableRef = createRef()
  const dispatch = useDispatch()
  const classes = useCombinedEvaluationsTableStyles()
  const { appMetadata } = useAuth0()
  const showAdminManagerActions = Check([roles.Admin, roles.TeamLead])
  const [includedRevieweeIds] = useState(showAdminManagerActions ? [] : [appMetadata.personId])
  const [includedReviewerIds] = useState([])

  const useQuery = () => new URLSearchParams(useLocation().search)
  const urlQuery = useQuery()

  const groupByTags = [
    { label: 'Manager', field: 'manager', icon: getIcon('manager') },
    { label: 'Team', field: 'team', icon: getIcon('team') },
    { label: 'Department', field: 'department', icon: getIcon('department') },
    { label: 'Location', field: 'location', icon: getIcon('location') },
  ]
  const [openParticipants, setOpenParticipants] = useState(false)
  const [openSignatures, setOpenSignatures] = useState(false)
  const [currentEvaluationId, setCurrentEvaluationId] = useState()
  const [currentBatchId, setCurrentBatchId] = useState()
  const [modalTitle, setModalTitle] = useState('')

  const handleParticipantsClick = (batchId, evaluationId, title) => {
    setOpenParticipants(true)
    setCurrentEvaluationId(evaluationId)
    setCurrentBatchId(batchId)
    setModalTitle(title)
  }

  const handleSignatureClick = (batchId, evaluationId, title) => {
    setOpenSignatures(true)
    setCurrentEvaluationId(evaluationId)
    setCurrentBatchId(batchId)
    setModalTitle(title)
  }
  const resetFilters = () => {
    setExcludedStatuses([])
    tableRef.current.onQueryChange()
  }

  function toggleStatus(value) {
    var tempStatuses = [...excludedStatuses]
    const index = tempStatuses.indexOf(value)

    if (index === -1) {
      tempStatuses.push(value)
    } else {
      tempStatuses.splice(index, 1)
    }

    setExcludedStatuses(tempStatuses)
    tableRef.current.onQueryChange()
  }

  const primaryFilters = [
    {
      title: 'Status',
      items: [
        filterBarObject('ready', excludedStatuses, toggleStatus),
        filterBarObject('inProgress', excludedStatuses, toggleStatus),
        filterBarObject('complete', excludedStatuses, toggleStatus),
        filterBarObject('waitingForReview', excludedStatuses, toggleStatus),
        filterBarObject('waitingForSignature', excludedStatuses, toggleStatus),
        filterBarObject('signed', excludedStatuses, toggleStatus),
      ],
    },
  ]

  const handleDownloadEvaluation = (row, ignoreFilters) => {
    getCombinedEvaluationPdf({
      evaluationBatchId: row.batchId,
      signatures: true,
      scoring: true,
      comments: true,
      leadSections: true,
      excludedSections: [],
      excludedResponseTypes: [],
      ignoreFilters: ignoreFilters || false,
    }).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Successfully generated evaluation PDF'))
      } else {
        dispatch(enqueueErrorNotification('The server encountered an error. Unable to generate PDF.'))
      }
    })
  }

  const refreshData = useCallback(() => {
    tableRef.current.onQueryChange()
  }, [tableRef])

  useEffect(() => {
    getSignatureSettings().then((response) => {
      setSignaturesEnabled(response.data.isEnabled)
    })
  }, [])

  useEffect(() => {
    refreshData()
  }, [refreshDataOnChange, refreshData])

  const headCells = [
    {
      field: 'evaluatedPersonName',
      title: 'Evaluation',
      cellStyle: { minWidth: 360 },
      render: (row) => {
        return (
          <>
            <Button
              startIcon={getIcon('person')}
              component={Link}
              size="small"
              variant="text"
              className={classes.link}
              to={`/profile/${row.evaluatedPersonId}`}
              color="primary">
              {row.evaluatedPersonName}
            </Button>
            {' - '}
            <Tooltip
              title={
                <Typography color="inherit" variant="h6">
                  Template: {row.templateName}
                </Typography>
              }>
              <div style={{ display: 'inline-block' }}>{row.scheduleName}</div>
            </Tooltip>
          </>
        )
      },
    },
    {
      field: 'team',
      title: 'Team',
      defaultGroupOrder: urlQuery?.get('groupBy') === 'team' ? 0 : undefined,
      hidden: true,
      width: 0,
    },
    {
      field: 'department',
      title: 'Department',
      defaultGroupOrder: urlQuery?.get('groupBy') === 'department' ? 0 : undefined,
      hidden: true,
      width: 0,
    },
    {
      field: 'location',
      title: 'Location',
      defaultGroupOrder: urlQuery?.get('groupBy') === 'location' ? 0 : undefined,
      hidden: true,
      width: 0,
    },
    {
      field: 'manager',
      title: 'Manager',
      defaultGroupOrder: urlQuery?.get('groupBy') === 'manager' ? 0 : undefined,
      hidden: true,
      width: 0,
    },
    {
      field: 'dueDate',
      title: 'Due',
      render: (row) => (
        <Tooltip
          title={
            <Typography color="inherit" variant="h6">
              Available: {FormatDateIgnoreTz(row.availabilityDate)}
            </Typography>
          }>
          <div>{FormatDateIgnoreTz(row.dueDate)}</div>
        </Tooltip>
      ),
    },
    {
      field: 'dateCompleted',
      title: 'Completed',
      render: (row) => row.isComplete && FormatDateIgnoreTz(row.dateCompleted),
      hidden: !usePdfButton,
    },
    {
      field: 'selfEvaluation',
      title: getTypeChip('self'),
      sorting: false,
      render: (row) => {
        return (
          row.selfEvaluation && (
            <Button
              startIcon={getStatusCircle(row.selfEvaluation.status)}
              size="small"
              variant="text"
              className={classes.link}
              color="primary"
              onClick={(event) => event.preventDefault()}>
              {row.selfEvaluation.completedBy[0]}
            </Button>
          )
        )
      },
    },
    {
      field: 'leadEvaluation',
      title: getTypeChip('lead'),
      sorting: false,
      render: (row) => {
        return (
          row.leadEvaluation && (
            <Button
              startIcon={getStatusCircle(row.leadEvaluation.status)}
              size="small"
              variant="text"
              className={classes.link}
              color="primary"
              onClick={(event) => event.preventDefault()}>
              {row.leadEvaluation.completedBy[0]}
            </Button>
          )
        )
      },
    },
    {
      field: 'signaturesCompleted',
      sorting: false,
      render: (row) => {
        return (
          <div style={{ marginTop: '4px', marginBottom: '4px' }}>
            <Chip
              label={`${row.evaluations.length} Feedback Request${row.evaluations.length === 1 ? '' : 's'}`}
              onClick={() =>
                showAdminManagerActions &&
                handleParticipantsClick(
                  row.batchId,
                  row.evaluations[0].id,
                  `${row.scheduleName} for ${row.evaluatedPersonName}`,
                )
              }
              icon={getIcon('person')}
              size="small"
              style={{ minWidth: '160px', marginTop: '2px', marginBottom: '2px' }}
              variant="outlined"
            />
            {setSignaturesEnabled && (
              <Chip
                label={
                  row.signaturesRequested
                    ? `${row.signaturesCompleted} / ${row.signaturesRequested} Signatures`
                    : 'No Signatures'
                }
                onClick={() =>
                  showAdminManagerActions &&
                  handleSignatureClick(
                    row.batchId,
                    row.evaluations[0].id,
                    `${row.scheduleName} for ${row.evaluatedPersonName}`,
                  )
                }
                icon={getIcon('edit')}
                size="small"
                style={{ minWidth: '160px', marginTop: '2px', marginBottom: '2px' }}
                variant="outlined"
              />
            )}
          </div>
        )
      },
    },
    {
      title: '',
      sorting: false,
      render: (row) => (
        <div>
          <ButtonGroup variant="text">
            {usePdfButton ? (
              <Button startIcon={getIcon('download')} onClick={() => handleDownloadEvaluation(row, true)} to={row.url}>
                Finalized Evaluation
              </Button>
            ) : (
              <Tooltip
                title={
                  !row.isComplete || row.batchId === '00000000-0000-0000-0000-000000000000'
                    ? 'Evaluation is still in progress - Results will be available when feedback requests are completed.'
                    : ''
                }>
                <span>
                  <Button
                    disabled={!row.isComplete || row.batchId === '00000000-0000-0000-0000-000000000000'}
                    component={RouterLink}
                    to={`/evaluation/results/combined/${row.batchId}`}>
                    Results
                  </Button>
                </span>
              </Tooltip>
            )}
          </ButtonGroup>
        </div>
      ),
    },
  ]

  return (
    <Grid container spacing={2} className={classes.root}>
      {!hideFilters && (
        <Grid item xs={12}>
          <TableFilterBar
            hidePaper={hidePaper}
            resetFunction={resetFilters}
            primaryFilters={primaryFilters}
            groupByItems={groupByTags}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <MaterialTable
          tableRef={tableRef}
          components={{ Container: (props) => <Paper {...props} elevation={hidePaper ? 0 : 1} /> }}
          columns={headCells}
          data={(query) =>
            new Promise((resolve, reject) => {
              fetchData({
                page: query.page,
                pageSize: query.pageSize,
                searchQuery: query.search,
                excludedStatuses: excludedStatuses,
                orderBy: (query.orderBy && query.orderBy.field) || '',
                orderDirection: query.orderDirection || '',
                includedStatuses: includedStatuses,
                includedRevieweeIds: includedRevieweeIds,
                includedReviewerIds: includedReviewerIds,
              }).then((response) => {
                if (response.status === 200) {
                  resolve({
                    data: response.data.data,
                    page: query.page,
                    totalCount: response.data.totalCount,
                  })
                } else {
                  reject()
                }
              })
            })
          }
          options={{
            emptyRowsWhenPaging: false,
            selection: false,
            pageSize: pageSize,
            pageSizeOptions: pageSizeOptions,
            disableRowClick: !showAdminManagerActions,
            padding: 'dense',
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '600px',
            cellStyle: { height: 20, paddingTop: 0, paddingBottom: 0 },
            debounceInterval: 600,
          }}
          detailPanel={
            !hideDetailPanel && showAdminManagerActions
              ? (row) => {
                  return (
                    <CombinedEvaluationsDetailTable
                      rows={row.evaluations}
                      refreshCallback={() => {
                        tableRef.current.onQueryChange()
                      }}
                      allowUnlock={allowUnlock}
                      allowDelete={allowDelete}
                      signaturesEnabled={signaturesEnabled}
                      usePdfButton={usePdfButton}
                      evaluationBatchId={row.batchId}
                      evaluatedPersonName={row.evaluatedPersonName}
                    />
                  )
                }
              : undefined
          }
          isLoading={openSignatures || openParticipants}
          onRowClick={disableRowClick ? () => {} : (event, rowData, togglePanel) => togglePanel()}
          title={title}
        />
      </Grid>
      {currentBatchId && (
        <SignaturesModal
          open={openSignatures}
          setOpen={setOpenSignatures}
          evaluationId={currentEvaluationId}
          evaluationBatchId={currentBatchId}
          title={modalTitle}
        />
      )}
      {currentBatchId && (
        <ParticipantsModal
          open={openParticipants}
          setOpen={setOpenParticipants}
          evaluationId={currentEvaluationId}
          evaluationBatchId={currentBatchId}
          title={modalTitle}
          disableChanges={onlyPastEvaluations}
        />
      )}
    </Grid>
  )
}

export default CombinedEvaluationsTable

CombinedEvaluationsTable.propTypes = {
  allowDelete: PropTypes.bool,
  allowUnlock: PropTypes.bool,
  dense: PropTypes.bool,
  disableRowClick: PropTypes.bool,
  fetchData: PropTypes.func.isRequired,
  hideDetailPanel: PropTypes.bool,
  hideFilters: PropTypes.bool,
  hidePaper: PropTypes.bool,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  refreshDataOnChange: PropTypes.any,
  title: PropTypes.string,
  usePdfButton: PropTypes.bool,
}

CombinedEvaluationsTable.defaultProps = {
  allowDelete: false,
  allowUnlock: false,
  dense: false,
  disableRowClick: false,
  hideDetailPanel: false,
  hideFilters: false,
  hidePaper: false,
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
  title: 'All Evaluations',
  usePdfButton: false,
}
