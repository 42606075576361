import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const useStyles = makeStyles((theme) => ({
  summary: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  finishIcon: {
    fontSize: 96,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    display: 'block',
  },
  returnButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(4),
    display: 'flex',
  },
  pulseIcon: {
    color: theme.palette.pulse.main,
  },
  evaluationIcon: {
    color: theme.palette.success.main,
  },
}))
const ThankYou = (props) => {
  const { loadAsComplete, isPulse } = props
  const classes = useStyles()
  return (
    <div className={classes.summary}>
      <AssignmentTurnedInIcon className={[classes.finishIcon, isPulse ? classes.pulseIcon : classes.evaluationIcon]} />
      {loadAsComplete && (
        <Typography align="center" variant="subtitle1">
          {isPulse ? 'You have already completed this survey.' : 'This evaluation has already been completed'}
        </Typography>
      )}
      {!loadAsComplete && (
        <Typography align="center" variant="subtitle1">
          {isPulse ? 'Thanks for completing the pulse survey' : 'Thank you for completing your evaluation'}
        </Typography>
      )}
      <Link to={'/dashboard'}>
        <Button className={classes.returnButton} variant="contained" color="primary" endIcon={<ArrowForwardIosIcon />}>
          Go back to dashboard
        </Button>
      </Link>
    </div>
  )
}

export default ThankYou
