export const getScheduleName = (formState, setFormState, templateTitle) => {
  let recurrenceType
  let sendToType = []
  let sendToDisplayText

  if (formState.values.recurrence === 'annually') {
    recurrenceType = 'Annual'
  } else if (formState.values.recurrence === 'quarterly') {
    recurrenceType = 'Quarterly'
  } else if (formState.values.recurrence === 'semi-annually') {
    recurrenceType = 'Semi-Annually'
  } else {
    recurrenceType = 'One Time'
  }

  if (formState.values.sendToSelf) {
    sendToType.push('Employee(s)')
  }

  if (formState.values.sendToLead) {
    sendToType.push('Team Lead(s)')
  }

  if (formState.values.sendToPeers) {
    sendToType.push('Peer(s)')
  }

  sendToDisplayText = [sendToType.slice(0, -1).join(', '), sendToType.slice(-1)[0]].join(
    sendToType.length < 2 ? '' : ' and ',
  )

  setFormState((formState) => ({
    ...formState,
    values: {
      ...formState.values,
      name: `${recurrenceType} ${templateTitle} Evaluation for ${sendToDisplayText}`,
    },
    touched: {
      ...formState.touched,
      name: true,
    },
  }))
}
