import { Typography } from '@material-ui/core'
import { RichTextArea } from 'components/Inputs'

import React from 'react'
import { Controller } from 'react-hook-form'

const RichTextControlled = ({ control, name, label, controllerDefaultValue, placeholder, labelVariant, autoFocus=false, ...rest }) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <>
          {label && <Typography variant={labelVariant ? labelVariant : "h4"}>{label}</Typography>}
          <RichTextArea
            initialValue={placeholder}
            {...field}
            {...rest}
          />
        </>
      )}
      rules={{ required: true }}
      name={name}
      control={control}
      defaultValue={!placeholder ? controllerDefaultValue : ''}
    />
  )
}

export default RichTextControlled
