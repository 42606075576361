import { useAuth0 } from 'react-auth0-spa'

export const Check = (checkRoles) => {
  const { claim } = useAuth0()
  let claimRoles = []
  if (claim) {
    claimRoles = claim['https://expressevaluations.com/roles'] || ''
  } else {
    return true
  }

  const hasRole = (role) => checkRoles.includes(role)

  if (!claimRoles) {
    // role is not present in the rules
    return false
  }

  if (claimRoles.some(hasRole)) {
    return true
  }

  if (claimRoles.includes('system_admin')) {
    return true
  }

  return false
}

const PermissionCheck = (props) => (Check(props.roles) ? props.yes() : props.no())

PermissionCheck.defaultProps = {
  yes: () => null,
  no: () => null,
}

export default PermissionCheck
