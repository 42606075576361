import React, { useRef, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, CircularProgress } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  saveRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export const SaveCircularStatusButton = (props) => {
  const timer = useRef()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const { className, color, disabled, onClick, postLabel, preLabel, variant, singleSubmit } = props

  const clickButton = () => {
    setLoading(true)
    timer.current = window.setTimeout(() => {
      onClick().then(() => {
        if (!singleSubmit) {
          setLoading(false)
        }
      })
    }, 1500)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  return (
    <div className={classes.saveRoot}>
      <Button
        className={className}
        color={color}
        disabled={loading || disabled}
        onClick={clickButton}
        variant={variant}
        fullWidth>
        {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
        {loading ? postLabel : preLabel}
      </Button>
    </div>
  )
}

SaveCircularStatusButton.propTypes = {
  className: PropTypes.any,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  postLabel: PropTypes.string.isRequired,
  preLabel: PropTypes.string.isRequired,
  singleSubmit: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'text', 'outlined']).isRequired,
}

SaveCircularStatusButton.defaultProps = {
  color: 'secondary',
  postLabel: 'Saved',
  singleSubmit: false,
  variant: 'contained',
}
