import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Card, CardHeader, CardContent, Grid, Typography } from '@material-ui/core'
import NotificationSchedule from './NotificationSchedule'
import HelpDrawer from 'views/HelpDrawer'
import { helpCenterArticleIds } from 'utils/zendesk'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  notifyInput: {
    marginLeft: theme.spacing(2),
    textAlign: 'center',
  },
  notifyUnit: {
    minWidth: 80,
  },
}))

const NotificationSettings = (props) => {
  const { className, type, title, ...rest } = props
  const classes = useStyles()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        subheader={
          <>
            <Typography>Manage your employees default notification preferences</Typography>{' '}
            <HelpDrawer
              openButtonLabel="How it works"
              variant="text"
              articleId={helpCenterArticleIds.emailReminderSettings}
            />
          </>
        }
        title="Notifications"
      />
      <CardContent>
        <Grid container spacing={6} wrap="wrap">
          <Grid item xs={12}>
            <Typography variant="h6">{title} Reminder Settings</Typography>
            <NotificationSchedule type={type} title={title} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

NotificationSettings.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default NotificationSettings
