import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useMediaQuery, CircularProgress, Typography } from '@material-ui/core'
// import { Alert, AlertTitle } from '@material-ui/lab'
import PasswordReset from 'components/Auth/PasswordReset'
import { Sidebar, Topbar } from './components'
import { useAuth0 } from 'react-auth0-spa'
import Notifier from 'components/Notifier/Notifier'
import { useSelector } from 'react-redux'
import { getIn } from 'immutable'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(7),
    },
  },
  rootNoSettings: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(8),
    },
  },
  rootWithSettings: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(12),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(10),
    },
  },
  rootActingAs: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(12),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(9),
    },
  },
  shiftContent: {
    paddingLeft: 200,
  },
  content: {
    height: '100%',
  },
  spinnerBox: {
    position: 'absolute',
    left: '50%',
    marginLeft: '-4em',
    top: '30%',
  },
  spinnerText: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRigth: 'auto',
  },
  spinner: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

const Main = (props) => {
  const { children } = props

  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  const showSystemTools = useSelector((state) => getIn(state, ['systemTools', 'showSystemTools']))
  const currentlyActingAsOrg = useSelector((state) => getIn(state, ['systemTools', 'currentlyActingAsOrg']))
  const { loading, user } = useAuth0()
  let showResetPassword = false

  //Check to display password modal
  if (!loading) {
    showResetPassword =
      (user['https://expressevaluations.com/metadata'] &&
        user['https://expressevaluations.com/metadata']['force_password_reset']) ||
      false
  }

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar

  if (loading) {
    return (
      <div className={classes.spinnerBox}>
        <CircularProgress className={classes.spinner} size={160} thickness={1} />
        <Typography className={classes.spinnerText} variant="h4">
          Express Evaluations
        </Typography>
      </div>
    )
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.rootNoSettings]: !showSystemTools && !currentlyActingAsOrg,
        [classes.rootWithSettings]: showSystemTools,
        [classes.shiftContent]: isDesktop,
      })}>
      {showResetPassword && <PasswordReset display={showResetPassword} />}
      <Topbar onSidebarOpen={handleSidebarOpen} />
      {/* {process.env.REACT_APP_MAINTENANCE && (
        <Alert severity="warning">
          <AlertTitle>Site Maintenance</AlertTitle>
          {process.env.REACT_APP_MAINTENANCE_MESSAGE}
        </Alert>
      )} */}
      <Sidebar onClose={handleSidebarClose} open={shouldOpenSidebar} variant={isDesktop ? 'persistent' : 'temporary'} />
      <main className={classes.content}>{children}</main>
      <Notifier />
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
