import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TabPanel } from './index'
import { Check } from 'utils/PermissionCheck'

import { Tab, Tabs } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    boxShadow: 'none',
    marginBottom: theme.spacing(3),
  },
  tabs: {
    minHeight: theme.spacing(2),
  },
  tabView: {},
}))

const TabView = (props) => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(0)
  const history = useHistory()
  const location = useLocation()

  const availableTabs = props.tabs.filter((tab) => Check(tab.roles) && <Tab label={tab.label} />)
  const handleTabChange = (event, newValue) => {
    const tab = availableTabs[newValue]
    if (tab.route) {
      history.push(tab.route)
    } else {
      setCurrentTab(newValue)
    }
  }

  useEffect(() => {
    let tabIndex = 0
    availableTabs.filter((t, index) => {
      if (location.pathname === t.route) {
        tabIndex = index
        return location.pathname === t.route
      } else {
        return false
      }
    })
    setCurrentTab(tabIndex)
  }, [availableTabs, location.pathname])

  return (
    <React.Fragment>
      <Tabs
        aria-label="page tabs"
        className={classes.tabs}
        onChange={handleTabChange}
        value={currentTab}
        variant="scrollable">
        {availableTabs.length > 1 && availableTabs.map((tab) => <Tab key={`Tab_${tab.label}`} label={tab.label} />)}
      </Tabs>
      {availableTabs
        ? availableTabs.map((tab, index) => (
            <TabPanel
              key={`TabView_${index}_${tab.label}`}
              index={index}
              value={currentTab}
              className={classes.tabView}>
              {tab.view}
            </TabPanel>
          ))
        : null}
    </React.Fragment>
  )
}

export default TabView
