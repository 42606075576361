import { removeItem } from 'helpers/arrays'
import actionTypes from 'redux/actionTypes'

const initialState = {
  currentSection: {},
  currentSectionIndex: 0,
  isLoading: true,
  template: {
    sections: [],
  },
  results: [],
  requiredQuestionsAnswered: false,
  isComplete: false,
  isPulse: false,
  completedSections: [],
  goals: [],
  includeReviewingGoals: false,
  includeGoalCreation: false,
  reviewingGoals: false,
  creatingGoals: false,
  readyForSubmit: false,
  reviewGoalInstructions: '',
  goalCreationInstructions: '',
  goalReviewComplete: false,
  goalCreationComplete: false,
  unsavedCommentGoalIds: [],
  autoSaveEnabled: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_EVALUATION_PAGE_STARTED':
      return Object.assign({}, state, {
        isPreview: action.isPreview,
        isLoading: true,
        isComplete: false,
      })
    case 'LOAD_EVALUATION_PAGE_SUCCEEDED':
      return Object.assign({}, state, {
        evaluation: action.evaluation,
        currentSectionIndex: action.currentSectionIndex,
        currentSection: action.evaluation.template.sections[action.currentSectionIndex],
        numberOfSections: action.evaluation.template.sections.length,
        title: action.evaluation.template.title,
        currentProgress: action.progress,
        results: {},
        isLoading: false,
        requiredQuestionsAnswered: false,
        isPulse: action.evaluation.isPulse || action.evaluation.template.isPulseTemplate,
        goals: action.goals,
        reviewGoalInstructions: action.reviewGoalInstructions,
        includeReviewingGoals: action.includeReviewingGoals,
        goalCreationInstructions: action.goalCreationInstructions,
        includeGoalCreation: action.includeGoalCreation,
        autoSaveEnabled: true,
        reviewingGoals: false,
        creatingGoals: false,
        readyForSubmit: false,
        goalReviewComplete: false,
        goalCreationComplete: false,
      })
    case 'CHANGE_EVALUATION_CURRENT_SECTION':
      return Object.assign({}, state, {
        currentSection: action.section,
        currentSectionIndex: action.sectionIndex,
        currentProgress: action.progress,
      })
    case 'UPDATE_EVALUATION_RESULTS_SUCCEEDED':
      return Object.assign({}, state, {
        results: action.results,
        unsavedChanges: action.unsavedChanges,
      })
    case 'UPDATE_LAST_UPDATED_TIME':
      return Object.assign({}, state, {
        lastUpdatedAt: action.time,
      })
    case 'UPDATE_CURRENT_PROGRESS':
      return Object.assign({}, state, {
        currentProgress: action.progress,
      })
    case 'CHECK_REQUIRED_QUESTIONS_SUCCEEDED':
      return Object.assign({}, state, {
        requiredQuestionsAnswered: action.answered,
      })
    case 'MARK_EVALUATION_AS_COMPLETE':
      return Object.assign({}, state, {
        isComplete: true,
        unsavedChanges: false,
      })
    case 'LOAD_AS_COMPLETED':
      return Object.assign({}, state, {
        isComplete: true,
        currentSection: 1,
        numberOfSections: 1,
        loadAsCompleted: true,
        isLoading: false,
        unsavedChanges: false,
      })
    case actionTypes.updateCompletedSectionsSucceeded:
      return Object.assign({}, state, {
        completedSections: action.sections,
      })
    case actionTypes.resetEvaluationToDefaults:
      return initialState
    case actionTypes.startGoalReviewSucceeded:
      return Object.assign({}, state, {
        reviewingGoals: true,
      })
    case actionTypes.endGoalReviewSucceeded:
      return Object.assign({}, state, {
        reviewingGoals: false,
      })
    case actionTypes.startGoalCreationSucceeded:
      return Object.assign({}, state, {
        creatingGoals: true,
      })
    case actionTypes.endGoalCreationSucceeded:
      return Object.assign({}, state, {
        creatingGoals: false,
      })
    case actionTypes.updateReadyToSubmit:
      return Object.assign({}, state, {
        readyForSubmit: action.isReady,
      })
    case actionTypes.completeGoalReviewSucceeded:
      return Object.assign({}, state, {
        goalReviewComplete: true,
      })
    case actionTypes.completeGoalCreationSucceeded:
      return Object.assign({}, state, {
        goalCreationComplete: true,
      })
    case actionTypes.addUnsavedCommentsGoalId:
      return Object.assign({}, state, {
        unsavedCommentGoalIds: [...state.unsavedCommentGoalIds, action.goalId],
      })
    case actionTypes.removeUnsavedCommentsGoalId:
      return Object.assign({}, state, {
        unsavedCommentGoalIds: removeItem(state.unsavedCommentGoalIds, action.goalId),
      })
    case actionTypes.toggleAutosave:
      return Object.assign({}, state, {
        autoSaveEnabled: !state.autoSaveEnabled,
      })
    case actionTypes.updateEvaluationGoals:
      return Object.assign({}, state, {
        goals: action.goals,
      })
    default:
      return state
  }
}
