import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, Link } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))

const Footer = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="caption" color="secondary">
        &copy;{' '}
        <Link component="a" href="/" color="secondary">
          Express Evaluations
        </Link>{' '}
        {new Date().getFullYear()}
        {/* <br/><Link color="secondary" href="#">Sitemap</Link>
        {" | "}
        <Link color="secondary" href="#">FAQ</Link>
        {" | "}
        <Link color="secondary" href="#">Privacy Policy</Link>
        {" | "}
        <Link color="secondary" href="#">Terms</Link> */}
      </Typography>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
