import React, { useEffect, useCallback } from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import Toolbar from 'layouts/Main/components/Toolbar/Toolbar'
import { Helmet } from 'react-helmet'
import { useImportStyles } from 'styles'
import { useDispatch, useSelector } from 'react-redux'
import { initializeImport } from 'redux/actions/importActions'
import { getIn } from 'immutable'
import ImportStepper from './ImportStepper'
import ImportErrorsTable from './ImportErrorsTable'
import ImportValidTable from './ImportValidTable'
import ImportComplete from './ImportComplete'
import ImportUpload from './ImportUpload'
import { getIcon } from 'utils/GetIcon'
import { Link as RouterLink } from 'react-router-dom'
import ImportValidTags from './ImportValidTags'

const Import = () => {
  const classes = useImportStyles()
  const dispatch = useDispatch()

  const activeStep = useSelector((state) => getIn(state, ['import', 'activeStep']))

  const initImport = useCallback(() => {
    dispatch(initializeImport())
  }, [dispatch])

  useEffect(() => {
    initImport()
  }, [initImport])

  const importContent = [
    <ImportUpload />,
    <ImportErrorsTable />,
    <ImportValidTable />,
    <ImportValidTags />,
    <ImportComplete />,
  ]

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Import | Express Evaluations</title>
      </Helmet>
      <Toolbar
        title="Import People"
        buttons={[
          <Button component={RouterLink} to={'/people'} variant="outlined" startIcon={getIcon('back')}>
            Back to People
          </Button>,
        ]}
      />
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <ImportStepper content={importContent[activeStep]} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Import
