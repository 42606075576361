import React, { useEffect } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { downloadTalentsReport } from 'api'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  evalTable: {
    width: '100%',
  },
  inviteCard: {
    marginTop: theme.spacing(4),
    maxWidth: '50%',
  },
}))

const KnowYourTalentsActiveTable = (props) => {
  const { callback, isLoading, surveys } = props
  const classes = useStyles()
  const handleReportDownload = (reportId) => {
    downloadTalentsReport(reportId)
  }
  const headCells = [
    { field: 'key', title: 'Survey Key' },
    { field: 'person.fullName', title: 'Person' },
    { field: 'results.basicTraits.structure', title: 'Structure' },
    { field: 'results.basicTraits.logic', title: 'Logic' },
    { field: 'results.basicTraits.highestTrait', title: 'Highest Trait' },
    { field: 'results.basicTraits.lowestTrait', title: 'Lowest Trait' },
    {
      field: 'results',
      title: 'Results',
      render: (row) => <Button onClick={() => handleReportDownload(row.reports[0].id)}>Download Report</Button>,
    },
  ]

  useEffect(() => {
    callback()
  }, [callback])

  return (
    <div className={classes.evalTable}>
      <MaterialTable
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => callback(),
            isFreeAction: true,
          },
        ]}
        columns={headCells}
        data={surveys}
        options={{
          emptyRowsWhenPaging: false,
          selection: false,
          showTitle: false,
          actionsColumnIndex: -1,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          cellStyle: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        title="Scheduled Evaluations"
        isLoading={isLoading}
      />
    </div>
  )
}

export default KnowYourTalentsActiveTable
