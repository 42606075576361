import { makeStyles } from '@material-ui/styles'

export const useModernCardStyles = makeStyles((theme) => ({
  root: { paddingTop: theme.spacing(4) },
  goalDetailWrapper: { padding: theme.spacing(3) },
  goalButtons: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  goalHeader: {
    marginBottom: theme.spacing(2),
  },
  goalList: {
    padding: theme.spacing(2),
  },
  newMeasureButton: {
    marginLeft: theme.spacing(1),
  },
  paper: {
    borderRadius: '15px',
  },
  headerPaper: {
    borderRadius: '15px',
    borderLeftColor: theme.palette.secondary.main,
    borderLeftStyle: 'solid',
    borderLeftWidth: theme.spacing(2),
  },
  paperHeader: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  paperOutlineHeader: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  paperHeaderWhite: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  paperContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  compactPaperContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  metricsBlock: {
    paddingTop: theme.spacing(2),
  },
  focusBottomBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.white,
  },
  childlessSectionHeading: {
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
  },
  alignLeft: {
    float: 'left',
  },
  alignRight: {
    float: 'right',
  },
  subtitleBox: {
    minHeight: 18,
  },
}))
