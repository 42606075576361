import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@material-ui/core'

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      aria-labelledby={`full-width-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      role="tabpanel"
      style={{ width: '100%' }}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
