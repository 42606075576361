import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import RichTextBlock from 'components/RichText'
import { useEvaluationGoalReviewStyles } from 'styles/Evaluation/evaluationGoalReview'
import GoalDetailsCard from 'components/Goals/GoalDetailsCard'
import { useSelector, useDispatch } from 'react-redux'
import { getIn } from 'immutable'
import { ModernCard } from 'components/Cards'
import { syncCompletedGoalStatus } from 'redux/actions/evaluationActions'

const EvaluationGoalReview = () => {
  const classes = useEvaluationGoalReviewStyles()
  const goals = useSelector((state) => getIn(state, ['evaluation', 'goals']))
  const instructions = useSelector((state) => getIn(state, ['evaluation', 'reviewGoalInstructions']))
  const dispatch = useDispatch()

  const handleSync = (id) => {
    dispatch(syncCompletedGoalStatus(id))
  }
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <ModernCard headerTitle="Review Goals">
          <Typography color="inherit" variant="h3">
            Instructions
          </Typography>
          <RichTextBlock content={instructions} />
        </ModernCard>
      </Grid>
      {goals.map((value, index) => (
        <Grid item xs={12} key={`goal_review_card_${index}`}>
          <GoalDetailsCard
            goalId={value.id}
            personId={value.people.id}
            name={value.name}
            description={value.description}
            dueDate={value.dueDate}
            isComplete={value.isComplete}
            goalNumber={index + 1}
            comments={value.comments}
            collapsable
            createdBy={value.createdBy}
            callback={handleSync}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default EvaluationGoalReview
