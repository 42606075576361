import API from '../api'

export const getOrganization = async () => {
  return await API.get(`organization`).then((response) => {
    return response.data
  })
}

export const updateOrg = async (request) => {
  return await API.put('organization/update', request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getPaymentCards = async () => {
  return await API.get('organization/payment/cards').then((response) => {
    return response.data.result
  })
}

export const getNotificationScheduleItems = async (type) => {
  return await API.get(`organization/notification/schedule/${type}`).then((response) => {
    return response
  })
}

export const getSetupIntent = async () => {
  return await API.get('organization/payment/setup').then((response) => {
    return response.data.result
  })
}

export const getSubscriptionPlans = async () => {
  return await API.get('organization/payment/plans').then((response) => {
    return response.data.result
  })
}

export const getSubscription = async () => {
  return await API.get('organization/subscription').then((response) => {
    return response.data.result
  })
}

export const updateSubscription = async (data) => {
  return await API.post('organization/subscription/update', data).then((response) => {
    return response.data.result
  })
}

export const createNotificationScheduleItem = async (scheduleItem) => {
  return await API.post('organization/notification/schedule/create', scheduleItem).then((response) => {
    return response
  })
}

export const deleteNotificationScheduleItem = async (id) => {
  return await API.delete(`organization/notification/schedule/delete/${id}`).then((response) => {
    return response
  })
}

export const getOrgSettings = async (category) => {
  return await API.get(`organization/setting/${category}`).then((response) => {
    return response
  })
}

export const getOrgSettingByName = async (category, name) => {
  return await API.get(`organization/setting/${category}, name`).then((response) => {
    return response
  })
}

export const getScoringBands = async () => {
  return await API.get(`organization/setting/scoringBands`).then((response) => {
    return response
  })
}

export const updateScoringBands = async (data) => {
  return await API.post('organization/setting/scoringBands/update', data)
    .then((response) => {
      return response
    })
    .catch((response) => {
      return response
    })
}

export const getSignatureSettings = async () => {
  return await API.get(`organization/setting/evaluation/signatures`).then((response) => {
    return response
  })
}

export const updateSignatureSettings = async (data) => {
  return await API.post('organization/setting/evaluation/signatures/update', data)
    .then((response) => {
      return response
    })
    .catch((response) => {
      return response
    })
}

export const updateOrgSetting = async (setting) => {
  return await API.post('organization/setting/update', setting)
    .then((response) => {
      return response
    })
    .catch((response) => {
      return response
    })
}

export const getUnassignedPeople = async () => {
  return await API.get('organization/team/unassigned').then((response) => {
    return response
  })
}

export const getAdminDashboardStats = async () => {
  return await API.get('organization/dashboard/admin/stats').then((response) => {
    return response
  })
}

export const getStripePortal = async () => {
  return await API.post('organization/payment/portal')
    .then((response) => {
      return response
    })
    .catch((response) => {
      return response
    })
}
