import API from '../api'

const BASE_SCHEDULE_URL = 'schedules'

export const getSchedules = async ({
  page = 0,
  pageSize = 0,
  searchQuery = '',
  sortBy = '',
  sortDirection = '',
  isPulse = false,
}) => {
  const searchParams = new URLSearchParams({
    page: page,
    pageSize: pageSize,
    searchQuery: searchQuery,
    sortBy: sortBy,
    sortDirection: sortDirection,
    isPulse: isPulse,
  })
  return await API.get(`${BASE_SCHEDULE_URL}?${searchParams}`)
}

export const getScheduleEvaluationDetails = async (scheduleId) => {
  return await API.get(`${BASE_SCHEDULE_URL}/${scheduleId}`)
}

export const createSchedule = async (data) => {
  return await API.post(`${BASE_SCHEDULE_URL}`, data)
}

export const updateSchedule = async (id, data) => {
  return await API.put(`${BASE_SCHEDULE_URL}/${id}`, data)
}

export const deleteSchedule = async (id) => {
  return await API.delete(`${BASE_SCHEDULE_URL}/${id}`)
}
