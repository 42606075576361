import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { getHelpCenterArticle } from 'zendesk-api'
import RichTextBlock from 'components/RichText'
import { FormatDate } from 'utils/DateTime'
import { getIcon } from 'utils/GetIcon'
import { Help } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(),

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '600px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '800px',
    },
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    marginTop: theme.spacing(1),
  },
  caption: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    marginTop: theme.spacing(2),
  },

  link: {
    marginBottom: theme.spacing(3),
  },
}))

const HelpDrawer = (props) => {
  const { articleId, openCallback, openButtonLabel, color, variant } = props

  const classes = useStyles()
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const [body, setBody] = useState('')
  const [title, setTitle] = useState('')
  const [updated_at, setDate] = useState('')
  const [html_url, setUrl] = useState('')

  useEffect(() => {
    getHelpCenterArticle(articleId).then((response) => {
      console.log(response)
      setBody(response.data.article.body)
      setTitle(response.data.article.title)
      setDate(response.data.article.updated_at)
      setUrl(response.data.article.html_url)
    })
  }, [articleId])

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open })
    if (openCallback && {}.toString.call(openCallback) === '[object Function]') {
      openCallback(open)
    }
  }

  return (
    <React.Fragment key={'left'}>
      <Button
        color={color}
        className={classes.button}
        endIcon={<Help />}
        size="small"
        variant={variant}
        onClick={() => toggleDrawer('left', true)}
        style={props.style}>
        {openButtonLabel}
      </Button>

      <SwipeableDrawer
        anchor={'left'}
        onClose={() => {
          toggleDrawer('left', false)
          //cancelFunction()
        }}
        onOpen={() => toggleDrawer('left', true)}
        open={state['left']}>
        <Grid item xs={11} className={classes.drawerContainer}>
          <Grid justify="space-between">
            <Grid align="right">
              <Button
                className={classes.buttonSpacing}
                onClick={() => {
                  toggleDrawer('left', false)
                  //cancelFunction()
                }}>
                {getIcon('clear')}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid variant="caption">
              <Typography align="center" className={classes.title} variant="h2">
                {title}
              </Typography>
            </Grid>
            <Typography align="right" className={classes.caption}>
              Last updated: {FormatDate(updated_at)}
            </Typography>

            <RichTextBlock content={body} />
            <Typography align="right" className={classes.link} variant="h6">
              <a href={html_url} target="_blank" rel="noopener noreferrer">
                Click Here to Learn More about "{title}"
              </a>
            </Typography>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </React.Fragment>
  )
}

HelpDrawer.propTypes = {
  buttonType: PropTypes.string,
  openButtonLabel: PropTypes.string,
  title: PropTypes.string,
}

HelpDrawer.defaultProps = {
  buttonType: 'button',
}
export default HelpDrawer
