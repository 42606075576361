import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import TabView from 'layouts/Main/components/TabView/TabView'
import Toolbar from 'layouts/Main/components/Toolbar/Toolbar'
import PermissionCheck from 'utils/PermissionCheck'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core'
import { Container } from '@material-ui/core'
import FeatureCheck from 'utils/PermissionCheck/FeatureCheck'
import { Hint } from 'components'
import PulseSchedules from './PulseSchedules'
import { roles } from 'utils/roles'
import ActivePulseTemplates from 'views/EvaluationTemplates/components/ActivePulseTemplates'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  scheduleTable: {
    width: '100%',
  },
}))

const PulseEvaluations = () => {
  const classes = useStyles()

  const tabs = [
    {
      label: 'Pulse Surveys',
      view: <PulseSchedules />,
      roles: [roles.Admin],
      route: '/pulse/schedules',
    },
    {
      label: 'Pulse Templates',
      view: <ActivePulseTemplates />,
      roles: [roles.Admin],
      route: '/pulse/templates',
    },
  ]

  return (
    <FeatureCheck
      yes={() => (
        <div className={classes.root} spacing={2}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Pulse Surveys | Express Evaluations</title>
          </Helmet>
          <Toolbar
            buttons={[
              <PermissionCheck
                key={`schedule-eval-button`}
                roles={[roles.Admin]}
                yes={() => (
                  <Button variant="contained" component={RouterLink} to={'/pulse/template/build'}>
                    Create New Pulse Survey Template
                  </Button>
                )}
              />,
              <PermissionCheck
                key={`schedule-eval-button`}
                roles={[roles.Admin]}
                yes={() => (
                  <Button variant="contained" color="primary" component={RouterLink} to={`/pulse/schedules/create`}>
                    Create New Pulse Survey Schedule
                  </Button>
                )}
              />,
            ]}
            title="Pulse"
          />
          <Container>
            <Grid item xs={12}>
              <Hint
                severity={'info'}
                backgroundColor={'#e7f5fe'}
                title={'Tips'}
                text={
                  'Pulse evaluations are anonymous surveys that can help your company gain valuable insights about your employees job satisfaction, current pain points, and needs. '
                }
                LinkText1={'1. Create a pulse template'}
                then
                LinkURL1={'/pulse/template/build'}
                LinkText2={'2. Schedule a pulse evaluation'}
                LinkURL2={'/pulse/schedules/create'}
              />
            </Grid>

            <TabView tabs={tabs} />
          </Container>
        </div>
      )}
      accessFeature="accessPulse"
    />
  )
}

export default PulseEvaluations
