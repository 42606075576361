export const DaysUntil = (date, returnText = false) => {
  const today = new Date().setHours(0, 0, 0, 0)
  const standardizedTime = new Date(date).setHours(0, 0, 0, 0)
  const days = Math.round((standardizedTime - today) / (1000 * 3600 * 24))
  const text = `${days >= 0 ? 'Due in' : ''} ${Math.abs(days)} day${Math.abs(days) !== 1 ? 's' : ''} ${
    days < 0 ? ' overdue' : ''
  }`
  const daysUntil = returnText ? text : days
  return daysUntil
}
