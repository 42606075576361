import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const black = '#000000'

export default {
  black,
  primary: {
    contrastText: white,
    dark: '#1B1A3B',
    main: '#25425c',
    light: '#31526F',
  },
  secondary: {
    contrastText: white,
    dark: '#263238',
    main: '#1D96AF',
    light: '#68afbf',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: '#17BD7C',
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: '#4381C1',
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: '#FAA916',
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: '#AD343E',
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    lightGrey: '#BDBDBD',
    grey: '#828282',
    link: colors.blue[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  grey: {
    light: colors.grey[100],
    main: colors.grey[200],
    dark: colors.grey[400],
  },
  white: white,
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  pulse: {
    contrastText: white,
    main: '#E05263',
  },
  engagement: {
    primary: {
      contrastText: white,
      main: '#4E5283',
      light: '#71749b',
    },
    secondary: {
      contrastText: white,
      main: '#E05263',
    },
  },
}
