import actionTypes from 'redux/actionTypes'
import { productOfferingTypes } from 'data/constants'
const initialState = {
  title: '',
  backUrl: '',
  closeUrl: '',
  bottomDescription: '',
  actions: [],
  unsavedChanges: false,
  productOfferingType: productOfferingTypes.performance,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.initializeFocusView:
      return Object.assign({}, state, {
        title: action.title,
        backUrl: action.backUrl,
        closeUrl: action.closeUrl,
        bottomDescription: action.bottomDescription,
        actions: action.actions,
        productOfferingType: action.productOfferingType,
      })
    case actionTypes.updateUnsavedChanges:
      return Object.assign({}, state, {
        unsavedChanges: action.unsavedChanges,
      })
    case actionTypes.updateFocusViewBottomDescription:
      return Object.assign({}, state, {
        bottomDescription: action.description,
      })
    default:
      return state
  }
}
