import { TextField } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'

const TextFieldControlled = ({ control, name, label, controllerDefaultValue, placeholder, variant, autoFocus=false, ...rest }) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <TextField
          error={error ? true : false}
          helperText={error?.message}
          {...field}
          label={label}
          placeholder={placeholder}
          {...rest}
          autoFocus={autoFocus}
          variant={variant}
        />
      )}
      rules={{ required: true }}
      name={name}
      control={control}
      defaultValue={!placeholder ? controllerDefaultValue : ''}
    />
  )
}

export default TextFieldControlled
