import React from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getIn } from 'immutable'
import { closeModal } from 'redux/actions/modalActions'

const ReduxModal = (props) => {
  const { title, body, onActionClick, actionButtonLabel, open } = useSelector((state) => getIn(state, ['modal']))
  const dispatch = useDispatch()
  const handleActionClick = () => {
    onActionClick()
    dispatch(closeModal())
  }

  const handleCancelClick = () => {
    dispatch(closeModal())
  }

  const handleClose = () => {}
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button autoFocus color="primary" onClick={handleActionClick}>
          {actionButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReduxModal
