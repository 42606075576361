import { makeStyles } from '@material-ui/styles'

export const useTeamTableStyles = makeStyles((theme) => ({
  teamDetailsNoLeadMembers: {
    marginLeft: theme.spacing(8),
    margin: theme.spacing(2),
  },
  unassignedRoot: {
    marginTop: theme.spacing(4),
  },
  unassignedTitle: {
    fontStyle: 'italic',
  },
}))
