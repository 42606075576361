import { makeStyles } from '@material-ui/styles'

export const useCreateScheduleStyles = makeStyles((theme) => ({
  headingPaper: {
    backgroundColor: theme.palette.white,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderRadius: 15,
  },
  paperRoot: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  root: {
    padding: theme.spacing(5),
    width: '100%',
  },
  fullList: {
    width: 'auto,',
    height: '250px',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    textTransform: 'capitalize',
  },
  formControl: {
    width: '100%',
  },
  summary: {
    marginBottom: theme.spacing(1),
  },
  summaryAvatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  summaryAvatarPulse: {
    backgroundColor: theme.palette.pulse.main,
  },
  subheading: {
    marginTop: theme.spacing(1),
  },
  typographyLine: {
    display: 'inline-block',
    marginRight: 5,
    paddingBottom: 6,
  },
  goalOffsetInput: {
    width: 50,
    marginTop: -4,
  },
  goalSettingDescription: {
    marginBottom: theme.spacing(2)
  },
  repeatEveryText: {
    ...theme.typography.h6,
    marginRight: theme.spacing(2),
  },
  intervalInput: {
    marginRight: theme.spacing(2),
    width: 60,
  },
  dateOffsetLabel: {
    width: 70,
    paddingBottom: 6,
  },
  dateOffsetDaysLabel: {
    paddingBottom: 6,
  },
  dateOffset: {
    minWidth: 50,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  detailsSpacing: {
    marginBottom: theme.spacing(2)
  },
  indentSection: {
    marginLeft: theme.spacing(2),
    borderLeftColor: theme.palette.grey[300],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    paddingLeft: theme.spacing(1),
  },
}))
