import React, { useState, useEffect, createRef } from 'react'
import { Button, Paper, ButtonGroup, Grid } from '@material-ui/core'
import MaterialTable from 'material-table'
import { Link as RouterLink } from 'react-router-dom'
import { getGoalStatus } from 'utils/evaluations/chips'
import moment from 'moment'
import GoalActionsMenu from 'components/GoalActionsMenu'
const GoalTable = (props) => {
  const { fetchData, hidePaper, type } = props
  const [goals, setGoals] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const tableRef = createRef()

  const getSortOrder = (goal) => {
    if (!goal.isComplete && moment(goal.dueDate).isBefore(moment.now())) {
      return 0
    } else if (goal.isComplete) {
      return 2
    } else {
      return 1
    }
  }

  const callback = () => {
    fetchData().then((response) => {
      if (response.status === 200) {
        setGoals(response.data)
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    fetchData().then((response) => {
      if (response.status === 200) {
        setGoals(response.data)
        setIsLoading(false)
      }
    })
  }, [fetchData])

  const headCells = [
    {
      field: 'name',
      title: 'Goal',
      searchable: true,
    },
    {
      field: 'people.fullName',
      title: 'Assigned To',
      hidden: type === 'personal',
    },
    {
      field: 'dueDate',
      title: 'Due Date',
      type: 'date',
    },
    {
      field: 'isComplete',
      title: 'Status',
      customSort: (a, b) => {
        return getSortOrder(a) - getSortOrder(b)
      },
      render: (goal) => {
        if (!goal.isComplete && moment(goal.dueDate).isBefore(moment.now())) {
          return getGoalStatus('inComplete')
        } else if (goal.isComplete) {
          return getGoalStatus('completed')
        } else {
          return getGoalStatus('inProgress')
        }
      },
    },
    {
      field: 'actions',
      title: 'Actions',
      render: (goal) => (
        <ButtonGroup variant="text">
          <Button component={RouterLink} to={`/goals/${goal.id}/view`}>
            View
          </Button>
          <GoalActionsMenu goal={goal} callback={callback} />
        </ButtonGroup>
      ),
    },
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MaterialTable
          tableRef={tableRef}
          components={{ Container: (props) => <Paper {...props} elevation={hidePaper ? 0 : 1} /> }}
          columns={headCells}
          data={goals}
          isLoading={isLoading}
          padding="dense"
          options={{
            emptyRowsWhenPaging: false,
            cellStyle: { height: 20, paddingTop: 0, paddingBottom: 0 },
            actionsColumnIndex: -1,
            selection: false,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30],
            toolbar: type !== 'personal',
            search: type !== 'personal',
          }}
          title=""
        />
      </Grid>
    </Grid>
  )
}

export default GoalTable
