import API from '../api'

export const getAllEvaluationTemplates = async () => {
  return await API.get('evaluation/template/')
}

export const getArchivedTemplates = async () => {
  return await API.get('evaluation/template/archived')
}

export const getPulseTemplates = async () => {
  return await API.get('evaluation/template/pulse')
}

export const getEvaluationTemplateById = async (id) => {
  return await API.get(`evaluation/template/${id}`)
}

export const createEvaluationTemplate = async (data) => {
  return await API.post('evaluation/template/create', data).then((response) => {
    return response
  })
}

export const updateEvaluationTemplate = async (data) => {
  return await API.put(`evaluation/template/${data.id}/update`, data).then((response) => {
    return response
  })
}

export const deactivateTemplate = async (id) => {
  return await API.put(`evaluation/template/${id}/deactivate`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const restoreTemplate = async (id) => {
  return await API.put(`evaluation/template/${id}/restore`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const createTemplateVersion = async (data) => {
  return await API.post(`evaluation/template/create/version/`, data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}
