const initialState = {
  showSystemTools: false,
  orgs: [],
  useAsOrg: {},
  currentlyActingAsOrg: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_SHOW_SYSTEM_TOOLS':
      return Object.assign({}, state, {
        showSystemTools: !state.showSystemTools,
      })
    case 'FETCH_ORGS_SUCCEEDED':
      return Object.assign({}, state, {
        orgs: action.orgs,
      })
    case 'SELECT_USE_AS_ORG':
      return Object.assign({}, state, {
        useAsOrg: action.org,
      })
    case 'ACTING_AS_ORG_SUCCEEDED':
      return Object.assign({}, state, {
        currentlyActingAsOrg: true,
        showSystemTools: true,
      })
    default:
      return state
  }
}
