import auth0 from 'auth0-js'

const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  responseType: process.env.REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE || '',
  redirectUri: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URI || '',
})

const setSession = (authResult) => {
  // Set the time that the access token will expire at
  let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
  localStorage.setItem('access_token', authResult.accessToken)
  localStorage.setItem('id_token', authResult.idToken)
  localStorage.setItem('expires_at', expiresAt)
}

const loginUser = (email, password, errorCallback) => {
  webAuth.login(
    {
      realm: process.env.REACT_APP_AUTH0_LOGIN_REALM,
      email: email,
      password: password,
    },
    (err, authResult) => {
      if (err) {
        errorCallback(err)
      } else {
        setSession(authResult)
      }
    },
  )
}

export default loginUser
