import React, { useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { useTemplateBuilderStyles } from 'styles'
import {
  Button,
  Card,
  CircularProgress,
  Collapse,
  Container,
  FormGroup,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Toolbar from '../../layouts/Main/components/Toolbar/Toolbar'
import Section from './components/Section'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import BuilderNav from './components/BuilderNav'
import { useDispatch, useSelector } from 'react-redux'
import {
  initializeTemplateBuilder,
  updateTemplate,
  updateTemplateScore,
  updateTemplateProperty,
  // copyTemplate,
} from 'redux/actions/templateBuilderActions'
import { createTemplateVersionAction } from 'redux/actions/templateActions'
import { getIn } from 'immutable'
// import { EvaluationTemplateSearch } from 'components/Search/EvaluationTemplateSearch'
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useHistory, useLocation } from 'react-router-dom'
import { getIcon } from 'utils/GetIcon'
import { initializeFocusView } from 'redux/actions/focusViewActions'
import { productOfferingTypes } from 'data/constants'

const TemplateBuilder = () => {
  const classes = useTemplateBuilderStyles()
  const history = useHistory()
  const location = useLocation()
  const loading = useSelector((state) => getIn(state, ['templateBuilder', 'loading']))
  const availableToEdit = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'availableToEdit']))
  const template = useSelector((state) => getIn(state, ['templateBuilder', 'template']))
  const isPulse = useSelector(() => getIn(template, ['isPulseTemplate']))
  const dispatch = useDispatch()
  const { urlTemplateId } = useParams()

  dispatch(
    initializeFocusView(
      getIn(template, ['title']),
      isPulse ? '/pulse/templates' : '/templates',
      isPulse ? '/pulse/templates' : '/templates',
      null,
      null,
      isPulse ? productOfferingTypes.engagement : productOfferingTypes.performance,
    ),
  )

  const initBuilder = useCallback(() => {
    dispatch(initializeTemplateBuilder(urlTemplateId, location.pathname.includes('/pulse')))
  }, [urlTemplateId, dispatch, location.pathname])

  useEffect(() => {
    initBuilder()
  }, [initBuilder])

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Build | Express Evaluations</title>
      </Helmet>
      <Container>
        <Toolbar title={isPulse ? <>{getIcon('pulse')} Pulse Survey Template Builder</> : 'Template Builder'} />
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid className={classes.builderRoot} container direction="row" spacing={2}>
            <Grid item xs={8}>
              {!availableToEdit && (
                <Alert
                  severity="info"
                  action={
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => dispatch(createTemplateVersionAction(template.id, template.title, history))}>
                      Create Version
                    </Button>
                  }>
                  <AlertTitle>
                    {
                      'This version of the template is being used in evaluations. In order to make edits, you need to create a new version of this template.'
                    }
                  </AlertTitle>
                </Alert>
              )}
            </Grid>
            <Grid id="builder_top_header" item xs={12}>
              <Card className={clsx(classes.root)}>
                <Grid container spacing={2} alignItems="center">
                  <Grid className={classes.evalName} item md={12} xs={12}>
                    <TextField
                      autoFocus
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Template Name"
                      margin="dense"
                      name="evaluationName"
                      onChange={(e) => dispatch(updateTemplateProperty('title', e.target.value))}
                      placeholder="Untitled Template"
                      required
                      value={getIn(template, ['title'])}
                      variant="outlined"
                      disabled={!availableToEdit}
                    />
                  </Grid>
                  {/* Only allow copy template if template Id url is not set */}
                  {/* {!urlTemplateId ? (
                    <>
                      <Grid item xs={12} md={1}>
                        <Typography variant="h6" align="right">
                          Copy From Template:
                        </Typography>
                      </Grid>
                      <Grid item xs={11} md={3}>
                        <EvaluationTemplateSearch
                          pulseTemplates={isPulse}
                          setTemplate={(e) => dispatch(copyTemplate(e.id))}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Copies all sections and questions from another question. This action is not reversible.">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </Grid>
                    </>
                  ) : (
                    <Grid item md={5} xs={12}></Grid>
                  )} */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Description"
                      margin="dense"
                      multiline
                      name="evaluationDescription"
                      onChange={(e) => dispatch(updateTemplateProperty('description', e.target.value))}
                      placeholder="Description of the intended use for this template"
                      required
                      rows="4"
                      value={getIn(template, ['description'])}
                      variant="outlined"
                      disabled={!availableToEdit}
                    />
                  </Grid>
                </Grid>
                <Collapse in={!isPulse}>
                  <Typography variant="h6" className={classes.templateSettingsTitle}>
                    Evaluation Settings
                  </Typography>
                  <div className={classes.templateSettings}>
                    <Tooltip title="If a template has scoring enabled, each question will have point values assigned to them and result in a final score that can be used to assess performance.">
                      <FormGroup className={classes.requiredToggle}>
                        <FormControlLabel
                          className={classes.scoreToggle}
                          control={
                            <Switch
                              checked={getIn(template, ['scoringEnabled'])}
                              onChange={() =>
                                dispatch(updateTemplateProperty('scoringEnabled', !getIn(template, ['scoringEnabled'])))
                              }
                              disabled={!availableToEdit}
                            />
                          }
                          label={getIn(template, ['scoringEnabled']) ? 'Scoring Enabled' : 'Scoring Disabled'}
                        />
                      </FormGroup>
                    </Tooltip>
                  </div>
                </Collapse>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} xl={2}>
              <BuilderNav availableToEdit={availableToEdit} />
            </Grid>
            <Grid item xs={12} sm={8} md={9} xl={10}>
              {getIn(template, ['sections']).map((section, index) => (
                <Grid container key={`section_${index}_${section.sys_id}`} spacing={2}>
                  <Grid item key={section.sys_id} xs={12}>
                    <Section
                      index={index}
                      key={index + '-section-block'}
                      setTemplate={(template) => dispatch(updateTemplate(template))}
                      updateTemplateScore={() => dispatch(updateTemplateScore())}
                      updateTemplate={updateTemplate}
                      availableToEdit={availableToEdit}
                      shouldAutoFocus={section.shouldAutoFocus}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  )
}

export default TemplateBuilder
