import actionTypes from 'redux/actionTypes'

const initialState = {
  actionButtonLabel: 'Submit',
  body: '',
  onActionClick: () => {},
  open: false,
  title: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.closeModal:
      return Object.assign({}, state, {
        open: false,
      })
    case actionTypes.openModal:
      return Object.assign({}, state, {
        actionButtonLabel: action.actionButtonLabel,
        body: action.body,
        onActionClick: action.onActionClick,
        open: true,
        title: action.title,
      })
    default:
      return state
  }
}
