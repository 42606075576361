import { makeStyles } from '@material-ui/styles'

export const useFocusStyles = makeStyles((theme) => ({
  focusTopBar: {
    backgroundColor: theme.palette.white,
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
    position: 'fixed',
    textTransform: 'capitalize',
  },
  focusTopBarPulse: {
    backgroundColor: theme.palette.white,
    borderBottom: `5px solid ${theme.palette.pulse.main}`,
    position: 'fixed',
  },
  root: {
    paddingTop: theme.spacing(8),
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(7),
    },
  },
  rootNoSettings: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(8),
    },
  },
  rootWithSettings: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(12),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(10),
    },
  },
  rootActingAs: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(12),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(9),
    },
  },
  shiftContent: {
    paddingLeft: 200,
  },
  content: {
    height: '100%',
    paddingTop: theme.spacing(8),
  },
  spinnerBox: {
    position: 'absolute',
    left: '50%',
    marginLeft: '-4em',
    top: '30%',
  },
  spinnerText: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRigth: 'auto',
  },
  spinner: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    textTransform: 'capitalize',
  },
  focusBottomBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.white,
  },
  grow: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0.5),
  },
  extendedIcon: {
    marginRight: theme.spacing(0.5),
  },
}))
