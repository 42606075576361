/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { List, ListItem, Button } from '@material-ui/core'
import PermissionCheck from 'utils/PermissionCheck'
import { useSidebarNavStyles } from 'styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { roles } from 'utils/roles'

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const openWidget = () => {
  window.zE('webWidget', 'show')
  window.zE('webWidget', 'open')
}

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props

  const classes = useSidebarNavStyles()
  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map(
        (page, index) =>
          !page.shouldHide && (
            <PermissionCheck
              key={`${index}_page`}
              roles={page.role}
              yes={() => (
                <ListItem className={classes.item} disableGutters key={page.title}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to={page.href}>
                    <div className={classes.icon}>{page.icon}</div>
                    {page.title}
                  </Button>
                </ListItem>
              )}
            />
          ),
      )}
      <PermissionCheck
        key={`show-help-button`}
        roles={[roles.Admin]}
        yes={() => (
          <ListItem className={classes.item} disableGutters>
            <Button id="myLauncher" onClick={openWidget}>
              <div className={classes.icon}>
                <HelpOutlineIcon />
              </div>
              Help
            </Button>
          </ListItem>
        )}
      />
    </List>
  )
}

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
}

export default SidebarNav
