import React from 'react'
import { Button, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { evaluationStatusDisplay, evaluationStatusIcon, evaluationStatusStyle } from 'utils/evaluations/status'
import { getIcon } from 'utils/GetIcon'

const EvaluationStatusButton = (props) => {
  const { disabled, status, text, to } = props

  return (
    <Tooltip title={evaluationStatusDisplay(status)}>
      <Button
        size="small"
        style={{ ...evaluationStatusStyle(status), paddingRight: 10, paddingLeft: 10 }}
        startIcon={evaluationStatusIcon(status)}
        endIcon={!disabled && getIcon('forward')}
        disabled={disabled}
        fullWidth
        component={RouterLink}
        variant="contained"
        to={to}>
        {text}
      </Button>
    </Tooltip>
  )
}

export default EvaluationStatusButton

EvaluationStatusButton.propTypes = {
  disabled: PropTypes.bool,
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
}

EvaluationStatusButton.defaultProps = {
  disabled: false,
  status: '',
  text: '',
  to: '',
}
