import React from 'react'
import { ButtonGroup, Button } from '@material-ui/core'
import { useAuth0 } from 'react-auth0-spa'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  authButth: {
    color: theme.palette.primary.contrastText,
  },
}))

const AccountMenu = () => {
  const { logout, loginWithRedirect, isAuthenticated } = useAuth0()
  const classes = useStyles()
  return (
    <ButtonGroup variant="text">
      {!isAuthenticated && (
        <Button
          className={classes.authButth}
          onClick={() => loginWithRedirect({ redirect_uri: window.location.origin + '/dashboard' })}>
          Log in
        </Button>
      )}
      {isAuthenticated && (
        <Button className={classes.authButth} component={RouterLink} to="/dashboard">
          Dashboard
        </Button>
      )}
      {isAuthenticated && (
        <Button className={classes.authButth} onClick={logout}>
          Log Out
        </Button>
      )}
    </ButtonGroup>
  )
}

export default AccountMenu
