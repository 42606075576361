import actionTypes from 'redux/actionTypes'

export const closeModal = () => ({
  type: actionTypes.closeModal,
})

export const openModal = (title, body, actionButtonLabel, onActionClick) => ({
  type: actionTypes.openModal,
  actionButtonLabel,
  onActionClick,
  title,
  body,
})
