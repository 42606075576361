import React, { useCallback } from 'react'
import { getAutoCompleteTemplates } from 'api'
import BaseAutocompleteSearch from './BaseAutocompleteSearch'

export const EvaluationTemplateSearch = (props) => {
  const { allowAllVersions, pulseTemplates, ...rest } = props

  const getOptionsFunc = useCallback(
    async () => getAutoCompleteTemplates({ allowAllVersions: allowAllVersions, pulseTemplates: pulseTemplates }),
    [allowAllVersions, pulseTemplates],
  )

  return (
    <BaseAutocompleteSearch
      {...rest}
      fetchOptions={getOptionsFunc}
      filterSelectedOptions
      getOptionLabel={(option) => `${option.title || ''}`}
      groupBy={(option) => option.firstLetter}
      label={pulseTemplates ? 'Pulse Survey Template' : 'Evaluation Template'}
      variant="outlined"
    />
    // <Autocomplete
    //   disabled={disabled}
    //   getOptionLabel={(option) => createOptionLabel(option.title, option.version)}
    //   id="evaluation-template-search"
    //   // groupBy={(option) => option.firstLetter} // Without many options this looks weird
    //   loading={loading}
    //   onChange={templateSelected}
    //   onClose={() => {
    //     setOpen(false)
    //   }}
    //   onOpen={() => {
    //     setOpen(true)
    //   }}
    //   open={open}
    //   openOnFocus
    //   options={options}
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       InputProps={{
    //         ...params.InputProps,
    //         endAdornment: (
    //           <React.Fragment>
    //             {loading ? <CircularProgress color="inherit" size={20} /> : null}
    //             {params.InputProps.endAdornment}
    //           </React.Fragment>
    //         ),
    //       }}
    //       label={pulseTemplates ? "Pulse Survey Template" : "Evaluation Template"}
    //       variant="outlined"
    //     />
    //   )}
    //   value={value}
    // />
  )
}
