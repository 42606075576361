import * as React from 'react'
import { FormHelperText } from '@material-ui/core'

export const InlineErrorCheck = (field, errors) => {
  if (!errors) {
    return false
  }

  if (!errors[field]) {
    return false
  }

  return true
}

const InlineError = ({ field, errors }) => {
  if (!errors) {
    return null
  }

  if (!errors[field]) {
    return null
  }

  return (
    <div className="errors-container">
      <ul style={{ listStyleType: 'none' }}>
        {errors[field].map((error) => (
          <li key={`error_${error}`}>
            <FormHelperText error>{error}</FormHelperText>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default InlineError
