import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'
import { Grid, Typography, IconButton, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    padding: theme.spacing(5),
    maxWidth: '500px',
  },
  title: {
    marginTop: theme.spacing(1),
  },
}))

const EditDrawer = (props) => {
  const {
    buttonType,
    buttonIcon,
    cancelFunction,
    disableSaveButton,
    openButtonLabel,
    openCallback,
    otherProps,
    saveButtonLabel,
    savefunction,
    children,
    title,
  } = props

  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open })
    if (openCallback && {}.toString.call(openCallback) === '[object Function]') {
      openCallback(open)
    }
  }

  const handleSaveFunction = async () => {
    const response = await savefunction()
    if (response) {
      toggleDrawer('right', false)
    }
  }

  return (
    <React.Fragment key={'right'}>
      {buttonType === 'button' ? (
        <Button
          color="primary"
          onClick={() => toggleDrawer('right', true)}
          variant="contained"
          style={props.style}
          {...otherProps}>
          {openButtonLabel}
        </Button>
      ) : buttonType === 'text' ? (
        <Button color="primary" onClick={() => toggleDrawer('right', true)} {...otherProps}>
          {openButtonLabel}
        </Button>
      ) : (
        <IconButton color="primary" onClick={() => toggleDrawer('right', true)} variant="contained" {...otherProps}>
          <Tooltip title="Edit">{buttonIcon}</Tooltip>
        </IconButton>
      )}

      <SwipeableDrawer
        anchor={'right'}
        onClose={() => {
          toggleDrawer('right', false)
          cancelFunction()
        }}
        onOpen={() => toggleDrawer('right', true)}
        open={state['right']}>
        <Grid className={classes.drawerContainer} container spacing={2}>
          <Grid item xs={12}>
            <Typography align="center" className={classes.title} variant="h2">
              {title}
            </Typography>
          </Grid>
          {children}
          <Grid container justify="space-between">
            <Grid>
              <Button
                onClick={() => {
                  toggleDrawer('right', false)
                  cancelFunction()
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid>
              <Button color={'primary'} onClick={handleSaveFunction} variant={'contained'} disabled={disableSaveButton}>
                {saveButtonLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </React.Fragment>
  )
}

EditDrawer.propTypes = {
  buttonType: PropTypes.string,
  buttonIcon: PropTypes.any,
  disableSaveButton: PropTypes.bool,
  children: PropTypes.any,
  openButtonLabel: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  savefunction: PropTypes.func,
  title: PropTypes.string,
}

EditDrawer.defaultProps = {
  buttonType: 'button',
  buttonIcon: <EditIcon />,
  disableSaveButton: false,
}
export default EditDrawer
