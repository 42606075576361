import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import hexToRGB from 'helpers/hexToRGB'
import AccountMenu from 'layouts/Main/components/Topbar/components/AccountMenu'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background:
      'linear-gradient(130deg,' +
      hexToRGB(theme.palette.primary.main) +
      '10%,' +
      hexToRGB(theme.palette.secondary.main) +
      ' 120%)',
    opacity: 0.9,
    backdropFilter: 'saturate(180%) blur(5px)',
  },
  logoAndTitle: {
    display: 'flex',
  },
  button: {
    color: 'white',
  },
  logo: {
    height: '30px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
    width: '40px',
    height: '20px',
    fontSize: '16px',
    lineHeight: 1,
  },
}))

const Topbar = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary" position="fixed">
      <Toolbar>
        <RouterLink to="/">
          <div className={classes.logoAndTitle}>
            <img alt="Express Evaluations Logo" src="/Express-Evaluations-Logo-Thick.svg" className={classes.logo} />
            <Typography variant="h2" className={classes.title}>
              Express Evaluations
            </Typography>
          </div>
        </RouterLink>
        <div className={classes.flexGrow} />
        <AccountMenu />
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
}

export default Topbar
