import API from '../api'
import fileDownload from 'js-file-download'

export const pastDueEvaluations = async () => {
  return await API.get('report/evaluations/pastDue').then((response) => {
    return response.data
  })
}

export const scoredEvaluations = async () => {
  return await API.get('report/evaluations/scored').then((response) => {
    return response.data
  })
}

export const completedEvaluations = async () => {
  return await API.get('report/evaluations/completed').then((response) => {
    return response.data
  })
}

export const incompleteEvaluations = async () => {
  return await API.get('report/evaluations/incomplete').then((response) => {
    return response.data
  })
}

export const getResultsByTemplateReport = async (data) => {
  return await API.post('report/evaluations/results/template', data, {
    body: data,
    headers: {
      responseType: 'blob',
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)
      link.click()
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getSignedEvaluationsReport = async () => {
  return await API.get('report/evaluations/signatures').then((response) => {
    return response.data
  })
}

export const getSingleEvaluationPdf = async (request) => {
  return await API.post(`report/evaluations/pdf`, request, {
    responseType: 'blob',
  })
    .then((response) => {
      fileDownload(response.data, `EvaluationReport.pdf`)
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getCombinedEvaluationPdf = async (request) => {
  return await API.post(`report/evaluations/combined/pdf`, request, {
    responseType: 'blob',
  })
    .then((response) => {
      fileDownload(response.data, `EvaluationCombinedReport.pdf`)
      return response
    })
    .catch((error) => {
      return error.response
    })
}
