import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import ScheduleForm from './components/ScheduleForm'

import { Container } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  formControl: {
    width: '100%',
  },
  scheduleTable: {
    width: '100%',
  },
}))

const ScheduleEdit = () => {
  const classes = useStyles()

  return (
    <div className={classes.root} spacing={2}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Schedules | Express Evaluations</title>
      </Helmet>
      <Container>
        <ScheduleForm />
      </Container>
    </div>
  )
}

export default ScheduleEdit
