import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from './react-auth0-spa'
import history from './utils/history'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import initialState from 'redux/initialState'
import rootReducer from 'redux/store'
import { composeWithDevTools } from 'redux-devtools-extension'
import { SnackbarProvider } from 'notistack'
import theme from './theme'
import { ThemeProvider } from '@material-ui/styles'
import HttpsRedirect from 'react-https-redirect'
import { supportedBrowserCheck } from 'utils/supportedBrowserCheck'
import { Alert, AlertTitle } from '@material-ui/lab'
import ReduxModal from 'components/Modals/ReduxModal'

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : '/dashboard')
}

const middlewares = [thunk]
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

const validBrowser = supportedBrowserCheck()

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)))}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }} maxSnack={3}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirect_uri={window.location.origin}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation="localstorage">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Express Evaluations</title>
              <meta
                name="description"
                content="Employee Evaluations Made Easy | A powerful and intuitive suite of tools that will help you understand your employees' growth and progress."
              />
              s
              <meta name="theme-color" content="#263238" />
              <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
              {/* <!-- Twitter Card data --> */}
              <meta name="twitter:card" content="summary" />
              {/* <meta name="twitter:site" content="@publisher_handle"/> */}
              <meta name="twitter:title" content="Express Evaluations" />
              <meta
                name="twitter:description"
                content="Employee Evaluations Made Easy | A powerful and intuitive suite of tools that will help you understand your employees' growth and progress."
              />
              {/* <meta name="twitter:creator" content="@author_handle"/> */}
              {/* <-- Twitter Summary card images must be at least 120x120px --> */}
              <meta name="twitter:image" content="/Express-Evaluations-Logo-With-Background.png" />
              {/* <!-- Open Graph data --> */}
              <meta property="og:title" content="Express Evaluations" />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="http://www.expressevaluations.com/" />
              <meta property="og:image" content="/Express-Evaluations-Logo-With-Background.png" />
              <meta property="og:description" content="s Evaluations" />
              <meta
                name="twitter:description"
                content="Employee Evaluations Made Easy | A powerful and intuitive suite of tools that will help you understand your employees' growth and progress."
              />
              <meta property="og:site_name" content="Express Evaluations" />
            </Helmet>
            {validBrowser && (
              <Alert style={{ marginTop: 64 }} severity="warning">
                <AlertTitle>Unsupported Browser</AlertTitle>
                {validBrowser}
              </Alert>
            )}
            <App />
            <ReduxModal />
          </Auth0Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root'),
)

serviceWorker.unregister()
