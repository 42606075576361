import React from 'react'
import { Chip, Paper, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getIn } from 'immutable'
import MaterialTable from 'material-table'
import { FormatDate } from 'utils/DateTime'
import { useImportStyles } from 'styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Alert from '@material-ui/lab/Alert'

const ImportValidTable = () => {
  const rows = useSelector((state) => getIn(state, ['import', 'validPeople']))
  const classes = useImportStyles()
  const headCells = [
    {
      title: '',
      render: (rowData) => (
        <div className={classes.textIcon}>
          <CheckCircleIcon size="small" className={classes.confirmColumnIcon} />
          {rowData.tableData.id + 1}
        </div>
      ),
    },
    {
      field: 'lastName',
      title: 'Name',
      render: (row) => (
        <>
          {row.firstName}{' '}
          {row.lastName ? row.lastName : <Chip className={classes.missingChip} size="small" label="Missing Last" />}
        </>
      ),
    },
    {
      field: 'email',
      title: 'Email',
      render: (row) => (row.email ? row.email : <Chip className={classes.missingChip} size="small" label="Missing" />),
    },
    {
      field: 'secondaryEmail',
      title: 'Secondary Email',
    },
    {
      field: 'employeeId',
      title: 'Employee Id',
    },
    {
      field: 'managerEmail',
      title: 'Manager',
    },
    {
      field: 'jobTitle',
      title: 'Job Title',
    },
    {
      field: 'team',
      title: 'Team',
    },
    {
      field: 'location',
      title: 'Location',
    },
    {
      field: 'department',
      title: 'Department',
    },
    {
      field: 'hireDate',
      title: 'Hire Date',
      render: (row) => FormatDate(row.hireDate),
    },
  ]
  return (
    <>
      <Typography variant="h3" className={classes.title}>
        People to be Imported
      </Typography>
      <Alert severity="info">
        The records listed below have valid data and will be imported into your organization.
      </Alert>
      <div className={classes.content}>
        <MaterialTable
          columns={headCells}
          data={rows}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            selection: false,
            showTitle: false,
            paging: false,
            padding: 'dense',
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '600px',
            search: false,
            exportButton: false,
            toolbar: false,
            cellStyle: {
              paddingTop: 1,
              paddingBottom: 1,
            },
          }}
          title="Scheduled Evaluations"
        />
      </div>
    </>
  )
}

export default ImportValidTable
