import { Button, Menu, MenuItem } from '@material-ui/core'
import { ButtonWithPrompt, DeleteButton } from 'components/Buttons'
import React, { useState } from 'react'
import { getIcon } from 'utils/GetIcon'
import { useDispatch } from 'react-redux'
import { deleteEvaluation, sendEvaluationNotifications, unlockEvaluation } from 'api'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import FeedbackDateModal from 'components/Modals/FeedbackDateModal'
import { Check } from 'utils/PermissionCheck'
import { roles } from 'utils/roles'

const FeedbackActionsMenu = (props) => {
  const { evaluation, callback, evaluatedPersonName, evaluationBatchId } = props
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [menuIcon, setMenuIcon] = useState(getIcon('arrowRight'))
  const [openAdjustedDueDateModal, setOpenAdjustedDueDateModal] = useState(false)

  const handleClick = (event) => {
    setMenuIcon(getIcon('arrowDown'))
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setMenuIcon(getIcon('arrowRight'))
    setAnchorEl(null)
  }

  const handleAdjustedDueDateClick = (value) => {
    setOpenAdjustedDueDateModal(true)
    setAnchorEl(null)
  }

  const handleUnlock = (id, title) => {
    if (id) {
      unlockEvaluation(id).then((response) => {
        if (response.status === 200) {
          dispatch(
            enqueueSuccessNotification(
              `${title} has been unlocked. The user should be able to now edit their evaluation.`,
            ),
          )
          callback()
        } else {
          dispatch(
            enqueueErrorNotification(`Unlocking ${title} encountered an error. Please try again or contact support.`),
          )
        }
        handleClose()
      })
    }
  }

  const handleSendNotification = (batchId, feedbackIds) => {
    sendEvaluationNotifications(batchId, feedbackIds).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Reminders have been sent successfully'))
      } else {
        dispatch(
          enqueueErrorNotification('Reminders unable to be sent. If this problem persists, please contact support.'),
        )
      }
      handleClose()
    })
  }

  const handleDeleteEvaluation = (evaluationId, type) => {
    deleteEvaluation({ evaluationId: evaluationId, type: type }).then(() => {
      dispatch(enqueueSuccessNotification(`The evaluation has been successfuly deleted`))
      callback()
      handleClose()
    })
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} endIcon={menuIcon}>
        Actions
      </Button>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          disabled={evaluation.status !== 'complete' && evaluation.status !== 'waitingForReview'}
          onClick={() => handleUnlock(evaluation.id, evaluation.title)}>
          <Button>Unlock</Button>
        </MenuItem>
        <MenuItem>
          <ButtonWithPrompt
            variant="text"
            title={`Send reminder email to ${evaluation.completedByName}`}
            description={`Are you sure you want to send an email to ${evaluation.completedByName} and remind them to complete their evalution of ${evaluatedPersonName}?`}
            buttonText="Send Reminder"
            color="primary"
            positiveButtonLabel="Send"
            clickFunction={() => handleSendNotification(evaluationBatchId, [evaluation.id])}
            negativeButtonLabel="Cancel">
            Send Reminder
          </ButtonWithPrompt>
        </MenuItem>
        <MenuItem disabled={!Check([roles.Admin])} onClick={() => handleAdjustedDueDateClick()}>
          <Button>Adjust Due Date</Button>
        </MenuItem>
        <MenuItem
          disabled={
            evaluation.status === 'complete' ||
            evaluation.status === 'signed' ||
            evaluation.status === 'waitingForReview' ||
            evaluation.status === 'waitingForSignature' ||
            evaluation.status === 'finalized'
          }>
          <DeleteButton
            key="goal_delete_btn"
            deleteFunction={() =>
              handleDeleteEvaluation(evaluation.id, evaluation.status === 'assigned' ? 'assignment' : 'evaluation')
            }
            disabled={
              evaluation.status === 'complete' ||
              evaluation.status === 'signed' ||
              evaluation.status === 'waitingForReview' ||
              evaluation.status === 'waitingForSignature' ||
              evaluation.status === 'finalized'
            }
            name={evaluation.title}
            buttonType="text"
          />
        </MenuItem>
      </Menu>
      <FeedbackDateModal
        open={openAdjustedDueDateModal}
        setOpen={setOpenAdjustedDueDateModal}
        evaluationId={evaluationBatchId}
        feedbackId={evaluation.id}
        currentDueDate={evaluation.dueDate}
        callback={callback}
        title={`${evaluation.title} - ${evaluatedPersonName}`}
      />
    </div>
  )
}

export default FeedbackActionsMenu
