export const removeItem = (array, item) => {
  const index = array.indexOf(item)
  if (index > -1) {
    array.splice(index, 1)
    return array
  }
}

export const normalizeArray = (array, indexKey) => {
  const normalizedObject = {}
  for (let i = 0; i < array.length; i++) {
    const key = array[i][indexKey]
    normalizedObject[key] = array[i]
  }
  return normalizedObject
}
