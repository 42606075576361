import React from 'react'
import { List } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import VerticalIconListItem from 'components/Lists/VerticalIconListItem'

const useStyles = makeStyles((theme) => ({
  typeList: {
    flexDirection: 'row',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}))
const VerticalIconList = (props) => {
  const { clickFunction, listItems, multiple, selectedIndex } = props

  const classes = useStyles()
  return (
    <List className={classes.typeList} component="nav">
      {listItems.map((item, index) => (
        <VerticalIconListItem
          key={`VertIconListItem_${index}_${item.name}`}
          clickFunction={clickFunction}
          displayName={item.displayName}
          name={item.name}
          icon={item.icon}
          index={index}
          selectedIndex={selectedIndex}
          isSelected={item.isSelected}
          multiple={multiple}
          disabled={item.disabled}
        />
      ))}
    </List>
  )
}

VerticalIconList.propTypes = {
  clickFunction: PropTypes.func,
  listItems: PropTypes.array,
  multiple: PropTypes.bool,
  selectedIndex: PropTypes.number,
  selectedIndexes: PropTypes.array,
}

VerticalIconList.defaultProps = {
  clickFunction: () => {},
  listItems: [],
  multiple: false,
  selectedIndexes: [],
}
export default VerticalIconList
