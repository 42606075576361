import React from 'react'
import { Grid, Button, Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useImportStyles } from 'styles'
import { useDispatch, useSelector } from 'react-redux'
import { preImportPeople } from 'redux/actions/importActions'
import { getIn } from 'immutable'
import { DropzoneArea } from 'material-ui-dropzone'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const ImportUpload = () => {
  const classes = useImportStyles()
  const dispatch = useDispatch()
  const fields = useSelector((state) => getIn(state, ['import', 'fields'])) || []
  const fileColumns = useSelector((state) => getIn(state, ['import', 'fileColumns'])) || []

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography variant="h3" className={classes.title}>
          Instructions
        </Typography>
        <Typography variant="body2">
          Import a CSV file containing the people you want to add to your organization. Your CSV needs to have the
          following columns, spelled and formatted as they are below.
          <br />
          <br />
          After you upload your file, our system will notify you of any identified errors in your data. After reviewing
          any relevant errors and/or valid records, you can then submit the employee recores to be saved to the system.
        </Typography>
        <div className={classes.content}>
          <Typography variant="h4">Required Fields:</Typography>
          <List className={classes.fieldList} dense={true}>
            {fields.map((value, index) => (
              <ListItem key={`required_field_upload_${index}`} className={classes.listItem}>
                <ListItemIcon>
                  {fileColumns.includes(value) ? (
                    <CheckCircleIcon className={classes.confirmColumnIcon} fontSize="small" />
                  ) : (
                    <RadioButtonUncheckedIcon className={classes.columnIcon} fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText>{value}</ListItemText>
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          href={`${process.env.PUBLIC_URL}/sample_people_import.csv`}
          color="primary"
          download
          variant="outlined"
          className={classes.downloadCsvBtn}>
          Click here to download sample CSV file
        </Button>
        <DropzoneArea
          acceptedFiles={['.csv, application/vnd.ms-excel, text/csv']}
          cancelButtonText={'cancel'}
          maxFileSize={5000000}
          filesLimit={1}
          showPreviews={false}
          showPreviewsInDropzone
          onChange={(files) => {
            if (files.length !== 0) {
              dispatch(preImportPeople(files))
            }
          }}
          submitButtonText={'submit'}
        />
        {/* <Typography variant="h5" className={classes.options}>
          Import Options
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={sendInvites} onChange={() => dispatch(toggleSendImportInvites())} name="sendInvite" />
            }
            label={`Send Invitation Email During Import (${sendInvites ? 'Active' : 'Not Active'})`}
          />
        </FormGroup>
        <FormHelperText>
          If selected, this will send imported users an invite with a temporary password to login in. Otherwise, you can
          invite them to the system at a future time from <Link to="/people">the people page</Link>.
        </FormHelperText> */}
      </Grid>
    </Grid>
  )
}

export default ImportUpload
