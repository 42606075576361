import React from 'react'
import { FormGroup, FormControlLabel, Switch, Checkbox } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  requiredToggle: {
    minWidth: '135px',
    paddingLeft: theme.spacing(2),
  },
}))

const OptionToggle = (props) => {
  const classes = useStyles()

  return (
    <FormGroup className={classes.requiredToggle}>
      <FormControlLabel
        control={
          props.toggleType === 'switch' ? (
            <Switch checked={props.option} onChange={props.setOption} disabled={props.disabled} />
          ) : (
            <Checkbox checked={props.option} onChange={props.setOption} disabled={props.disabled} />
          )
        }
        label={props.option ? props.trueLabel : props.falseLabel}
      />
    </FormGroup>
  )
}

OptionToggle.propTypes = {
  option: PropTypes.bool,
  setOption: PropTypes.func,
  trueLabel: PropTypes.string,
  falseLabel: PropTypes.string,
  toggleType: PropTypes.string,
}

export default OptionToggle
