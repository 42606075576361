import { getIcon } from 'utils/GetIcon'

export const evaluationStatusDisplay = (status) => {
  switch (status) {
    case 'ready':
      return 'Ready to Start'
    case 'inProgress':
      return 'In Progress'
    case 'complete':
      return 'Submitted'
    case 'waitingForReview':
      return 'Waiting for Review'
    case 'reviewed':
      return 'Reviewed'
    case 'waitingForSignature':
      return 'Waiting for Signature'
    case 'signed':
      return 'Signed'
    case 'assigned':
      return 'Ready to Start'
    default:
      return 'No Action'
  }
}

export const evaluationStatusIcon = (status) => {
  switch (status) {
    case 'ready':
      return getIcon('new')
    case 'inProgress':
      return getIcon('started')
    case 'complete':
      return getIcon('complete')
    case 'waitingForReview':
      return getIcon('event')
    case 'reviewed':
      return getIcon('complete')
    case 'waitingForSignature':
      return getIcon('signature')
    case 'signed':
      return getIcon('signature')
    case 'assigned':
      return getIcon('new')
    default:
      return getIcon('new')
  }
}

export const evaluationStatusStyle = (status) => {
  switch (status) {
    case 'ready':
      return { color: 'white', backgroundColor: '#7fbf82', borderRadius: '10px' }
    case 'assigned':
      return { color: 'white', backgroundColor: '#35a3d3', borderRadius: '10px' }
    case 'inProgress':
      return { color: 'white', backgroundColor: '#35a3d3', borderRadius: '10px' }
    case 'complete':
      return { color: 'black', backgroundColor: 'white', borderRadius: '10px' }
    case 'waitingForReview':
      return { color: 'black', backgroundColor: 'white', borderRadius: '10px' }
    case 'reviewed':
      return { color: 'black', backgroundColor: 'white', borderRadius: '10px' }
    case 'waitingForSignature':
      return { color: 'white', backgroundColor: '#7fbf82', borderRadius: '10px' }
    case 'signed':
      return { color: 'black', backgroundColor: 'white', borderRadius: '10px' }
    default:
      return { color: 'black', backgroundColor: 'white', borderRadius: '10px' }
  }
}

export const signatureStatus = (status) => {
  switch (status) {
    case 'Created':
      return getIcon('new')
    case 'Requested':
      return getIcon('requested')
    case 'Signed':
      return getIcon('signed')
    default:
      getIcon('new')
  }
}
