const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_NOTIFICATIONS':
      return Object.assign({}, state, {
        user: action.notifications,
      })
    case 'DISMISS_USER_NOTIFICATION':
      return Object.assign({}, state, {
        user: action.notifications,
      })
    case 'ENQUEUE_SNACKBAR':
      return [
        ...state,
        {
          key: action.key,
          ...action.notification,
        },
      ]

    case 'CLOSE_SNACKBAR':
      return state.map((notification) => (action.dismissAll || notification.key === action.key ? { ...notification, dismissed: true } : { ...notification }))

    case 'REMOVE_SNACKBAR':
      return state.filter((notification) => notification.key !== action.key)
    default:
      return state
  }
}
