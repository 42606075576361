import React from 'react'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/actions/modalActions'

const ButtonWithPrompt = (props) => {
  const {
    title,
    description,
    disabled,
    disabledReason,
    clickFunction,
    positiveButtonLabel,
    buttonText,
    buttonColor,
    icon,
    iconButton,
    variant,
    className,
  } = props
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(openModal(title, description, positiveButtonLabel, clickFunction))
  }

  const button = () => {
    if (iconButton) {
      return (
        <Tooltip title={disabled ? disabledReason : ""}>
          <span>
            <IconButton className={className} size="small" disabled={disabled} onClick={handleClick} color={buttonColor}>
              {icon}
            </IconButton>
          </span>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title={disabled ? disabledReason : ""}>
          <span>
            <Button className={className} disabled={disabled} variant={variant} onClick={handleClick} color={buttonColor}>
              {buttonText}
            </Button>
          </span>
        </Tooltip>
      )
    }
  }

  return button()
}

export default ButtonWithPrompt

ButtonWithPrompt.propTypes = {
  buttonText: PropTypes.string,
  deleteFunction: PropTypes.func,
  disabled: PropTypes.bool,
  iconButton: PropTypes.bool,
  name: PropTypes.string,
  clickFunction: PropTypes.func,
  variant: PropTypes.string,
}

ButtonWithPrompt.defaultProps = {
  disabled: false,
  iconButton: false,
  variant: 'outlined',
}
