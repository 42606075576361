import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types'

const SaveModal = (props) => {

  const handleClose = () => {
    props.setSaveModalStatus(false);
  };

  return (
    <React.Fragment>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={props.saveModalStatus}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            {props.negativeButtonLabel}
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={props.positiveButtonFunction}
          >
            {props.positiveButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

SaveModal.propTypes = {
  description: PropTypes.string,
  negativeButtonLabel: PropTypes.string,
  positiveButtonFunction: PropTypes.func,
  positiveButtonLabel: PropTypes.string,
  saveModalStatus: PropTypes.bool,
  setSaveModalStatus: PropTypes.func,
  title: PropTypes.string,

}

export default SaveModal
