import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid, Paper, Fade, Typography } from '@material-ui/core'
import { useAuth0 } from 'react-auth0-spa'
import { completeGoal, getGoalById } from 'api'
import Comments from './components/Comments'
import { Helmet } from 'react-helmet'
import Container from '@material-ui/core/Container'
import { useHistory, useParams } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import RichTextBlock from 'components/RichText'
import { useDispatch } from 'react-redux'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { ButtonWithPrompt } from 'components/Buttons'
import { initializeFocusView } from 'redux/actions/focusViewActions'
import { getGoalStatus } from 'utils/evaluations/chips'
import moment from 'moment'

export const useStyles = makeStyles((theme) => ({
  root: { paddingTop: theme.spacing(4) },
  goalDetailWrapper: { padding: theme.spacing(3) },
  goalButtons: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  goalHeader: {
    marginBottom: theme.spacing(2),
  },
  goalList: {
    padding: theme.spacing(2),
  },
  newMeasureButton: {
    marginLeft: theme.spacing(1),
  },
  paper: {
    borderRadius: '15px',
  },
  paperHeader: {
    padding: theme.spacing(2),
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  paperContent: {
    padding: theme.spacing(4),
  },
  metricsBlock: {
    paddingTop: theme.spacing(2),
  },
  focusBottomBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.white,
  },
}))

const EmployeeGoals = () => {
  const classes = useStyles()
  const { appMetadata } = useAuth0()
  const [currentGoal, setCurrentGoal] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { goalId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const calculateGoalStatus = (isComplete, dueDate) => {
    if (!isComplete && moment(dueDate).isBefore(moment.now())) {
      return getGoalStatus('inComplete')
    } else if (isComplete) {
      return getGoalStatus('completed')
    } else {
      return getGoalStatus('inProgress')
    }
  }

  const handleCompleteGoal = useCallback(
    (goalId) => {
      completeGoal(goalId).then((response) => {
        if (response.status === 200) {
          history.push('/goals')
          dispatch(enqueueSuccessNotification('Your goals has been marked as complete.'))
        } else if (response.status === 403) {
          history.push('/not-allowed')
        } else {
          dispatch(enqueueErrorNotification('The server encountered an error. Unable to mark goal as complete'))
        }
      })
    },
    [dispatch, history],
  )

  const fetchGoals = useCallback(() => {
    getGoalById(goalId).then((response) => {
      if (response) {
        if (response.status === 200) {
          setCurrentGoal(response.data)
          setIsLoading(false)
          dispatch(
            initializeFocusView(`Goal Details | ${response.data.name}`, '/goals', '/goals', 'Goal Actions', [
              !response.data.isComplete && (
                <ButtonWithPrompt
                  key="complete-goal-btn"
                  title="Are you sure you want to mark this goal as complete?"
                  clickFunction={() => handleCompleteGoal(response.data.id)}
                  buttonText="Mark As Complete"
                  positiveButtonLabel="Mark As Complete"
                />
              ),
              !response.data.isComplete && (
                <Button
                  key="edit-goal-btn"
                  disabled={!response.data.userCanEdit}
                  component={RouterLink}
                  to={`/goals/${response.data.id}/edit`}>
                  Edit
                </Button>
              ),
            ]),
          )
        } else if (response.status === 403) {
          history.push('/not-allowed')
        } else {
          dispatch(enqueueErrorNotification('The server encountered an error and is unable to load this goal.'))
        }
      } else {
        dispatch(enqueueErrorNotification('The server encountered an error and is unable to load this goal.'))
      }
    })
  }, [goalId, dispatch, history, handleCompleteGoal])

  useEffect(() => {
    fetchGoals()
  }, [fetchGoals])

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goal Details | Express Evaluations</title>
      </Helmet>

      <Fade in={!isLoading}>
        <Container>
          <Grid container spacing={4} className={classes.root}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div className={classes.paperHeader}>
                  <Typography color="inherit" variant="h5">
                    Goal
                  </Typography>
                </div>
                <div className={classes.paperContent}>
                  <Typography variant="h1">{currentGoal?.name}</Typography>
                  <Typography variant="subtitle2">
                    {currentGoal?.isComplete
                      ? `Completed on ${moment(currentGoal?.dueDate).format('MM/DD/yyyy')}`
                      : `Due on ${moment(currentGoal?.dueDate).format('MM/DD/yyyy')}`}
                    {currentGoal && currentGoal.createdBy && ` | Created By: ${currentGoal.createdBy.fullName}`}
                  </Typography>
                  {currentGoal && calculateGoalStatus(currentGoal.isComplete, currentGoal.dueDate)}
                  <RichTextBlock content={currentGoal?.description} />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div className={classes.paperHeader}>
                  <Typography color="inherit" variant="h5">
                    Activity Log
                  </Typography>
                </div>
                <div className={classes.paperContent}>
                  {currentGoal && (
                    <Comments
                      comments={currentGoal.comments}
                      employeeId={appMetadata.personId}
                      fetchGoals={fetchGoals}
                      goalId={currentGoal.id}
                    />
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Fade>
    </div>
  )
}

export default EmployeeGoals
