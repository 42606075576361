import React, { useEffect, useState } from 'react'
import { EvaluationTemplateSearch } from 'components/Search/EvaluationTemplateSearch'
import { useCreateScheduleStyles } from 'styles'
import {
  Grid,
  Backdrop,
  CircularProgress,
  Container,
  Collapse,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import InfoIcon from '@material-ui/icons/Info'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import { getScheduleFormTitle } from 'utils/scheduleFormUtils'
import { getTypeChip } from 'utils/evaluations/chips'
import ScheduleSummary from './ScheduleSummary'
import { Skeleton } from '@material-ui/lab'
import { ModernCard } from 'components'
import PeopleSelectionTool from 'views/People/components/PeopleSelectionTool'
import { Controller, useForm } from 'react-hook-form'
import { recurrenceRepeatTypes } from 'utils/scheduleFormUtils'
import moment from 'moment'
import CheckboxControlled from 'components/Forms/CheckboxControlled'
import SwitchControlled from 'components/Forms/SwitchControlled'
import TextFieldControlled from 'components/Forms/TextFieldControlled'
import RichTextControlled from 'components/Forms/RichTextControlled'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import DatePickerControlled from 'components/Forms/DatePickerControlled'
import SelectControlled from 'components/Forms/SelectControlled'
import { createSchedule, getScheduleEvaluationDetails, updateSchedule } from 'api'
import { useDispatch } from 'react-redux'
import { getIcon } from 'utils/GetIcon'
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
  enqueueWarningNotification,
} from 'redux/actions/notificationsActions'
import { initializeScheduleFormFocusedView } from 'redux/actions/scheduleFormActions'

export default function ScheduleForm() {
  const classes = useCreateScheduleStyles()
  const location = useLocation()
  const { search } = useLocation()
  const history = useHistory()
  const { scheduleId } = useParams()
  const isEdit = scheduleId && true
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  let query = new URLSearchParams(search)
  const schema = yup.object().shape({
    name: yup.string().required('Required'),
    reviewees: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number(),
          fullName: yup.string(),
          email: yup.string(),
          firstLetter: yup.string(),
          employeeId: yup.string().nullable(),
        }),
      )
      .min(isEdit ? 0 : 1, 'At least one employee must be selected'),
    tags: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number(),
          fullName: yup.string(),
          email: yup.string(),
          firstLetter: yup.string(),
          employeeId: yup.string(),
        }),
      )
      .nullable(),
    template: yup.object().required('Required').nullable(),
    requireEmployeeSignature: yup.boolean(),
    requireManagerSignature: yup.boolean(),
    dueDate: yup.date().required('Required').min(0),
    startDateOffset: yup.number(),
    endDateOffset: yup.number(),
    dueDateType: yup.string().oneOf(['date', 'hireDate', 'Anniversary']).required('Required'),
    repeat: yup.boolean(),
    isPulse: yup.boolean(),
    includeGoalReview: yup.boolean(),
    includeGoalsRange: yup.number(),
    goalReviewInstructions: yup.string().nullable(),
    includeGoalCreation: yup.boolean(),
    goalCreationInstructions: yup.string().nullable(),
    includeInReadout: yup.boolean(),
    repeatType: yup.string().oneOf(['day', 'week', 'Biweekly', 'month', 'year']),
    interval: yup.number(),
    repeatOnDays: yup.array().of(yup.object()).nullable(),
    ends: yup.boolean(),
    endsType: yup.string().nullable(),
  })
  const { handleSubmit, control, getValues, watch, reset, formState, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const onSubmit = (data) => {
    data = {
      ...data,
      tags: data.tags.filter((t) => t.type !== 'Person' && t.type !== 'Organization'),
      repeat: isPulse,
      isPulse: isPulse,
      ends: true,
      endDateOffset: data.endOffsetDirection === 'before' ? data.endDateOffset * -1 : data.endDateOffset,
      startDateOffset: Math.sign(data.startDateOffset) === -1 ? data.startDateOffset : data.startDateOffset * -1,
    }
    if (isEdit) {
      setIsLoading(true)
      updateSchedule(scheduleId, data).then((response) => {
        if (response.status === 204) {
          dispatch(enqueueSuccessNotification('Schedule updated successfully'))
          history.push(`${isPulse ? '/pulse' : '/schedules'}`)
        } else if (response.status === 400) {
          dispatch(enqueueWarningNotification('Your schedule needs to be fixed before it can be created'))
        } else {
          dispatch(
            enqueueErrorNotification(
              'Something went wrong. Your schedule was not able to be created. Please try again or contact support',
            ),
          )
        }
        setIsLoading(false)
      })
    } else {
      createSchedule(data).then((response) => {
        if (response.status === 201) {
          dispatch(enqueueSuccessNotification('Your schedule was successfully created.'))
          history.push(`${isPulse ? '/pulse' : '/schedules'}`)
        } else if (response.status === 400) {
          dispatch(enqueueWarningNotification('Your schedule needs to be fixed before it can be created'))
        } else {
          dispatch(
            enqueueErrorNotification(
              'Something went wrong. Your schedule was not able to be created. Please try again or contact support',
            ),
          )
        }
      })
    }
  }
  const isPulse = location.pathname.includes('/pulse/schedules/create') || watch('isPulse')
  const watchName = watch('name', '')
  const watchTemplate = watch('template')
  const watchDueDateType = watch('dueDateType', 'date')
  const watchDueDate = watch('dueDate', moment().add(1, 'month').toDate())
  const watchStartDateOffset = watch('startDateOffset', -30)
  const watchEndDateOffset = watch('endDateOffset', 30)
  const watchEndDateOffsetDirection = watch('endOffsetDirection', 'before')
  const watchReviewees = watch('reviewees', [])
  const watchIncludeGoalReview = watch('includeGoalReview', false)
  const watchIncludeGoalCreation = watch('includeGoalCreation', false)
  const watchRequireEmployeeSignature = watch('requireEmployeeSignature', true)
  const watchrequireManagerSignature = watch('requireManagerSignature', true)
  const [previousReviewees, setPreviousReviewees] = useState([])

  useEffect(() => {
    setIsLoading(true)
    dispatch(initializeScheduleFormFocusedView(isEdit, isPulse))
    if (isEdit) {
      getScheduleEvaluationDetails(scheduleId).then((response) => {
        if (response.status === 200) {
          const fields = [
            'name',
            'template',
            'requireEmployeeSignature',
            'requireManagerSignature',
            'dueDate',
            'startDateOffset',
            'endDateOffset',
            'dueDateType',
            'repeat',
            'isPulse',
            'includeGoalReview',
            'includeGoalsRange',
            'goalReviewInstructions',
            'includeGoalCreation',
            'goalCreationInstructions',
            'includeInReadout',
            'repeatType',
            'interval',
            'repeatOnDays',
            'ends',
            'endsType',
          ]
          fields.forEach((field) =>
            setValue(field, field === 'startDateOffset' ? Math.abs(response.data[field]) : response.data[field]),
          )
          setPreviousReviewees(response.data.reviewees)
          setIsLoading(false)
        } else {
          dispatch(enqueueErrorNotification(`Something went wrong. Unable to get schedule (${scheduleId})`))
          setIsLoading(false)
        }
      })
    } else {
      setIsLoading(false)
    }
  }, [isEdit, isPulse, dispatch, scheduleId, setValue])

  return (
    <Container>
      <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.paperRoot}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            {isLoading ? (
              <Skeleton animation="wave" height={500} style={{ top: '-96px' }} />
            ) : (
              <StickyBox offsetBottom={20} offsetTop={80}>
                <ModernCard headerTitle="Summary" spacing="compact">
                  {query.get('debug') && (
                    <>
                      <Button
                        onClick={() =>
                          console.log({
                            isValid: formState.isValid,
                            isDirty: formState.isDirty,
                            errors: formState.errors,
                            dirtyFields: formState.dirtyFields,
                            touchedFields: formState.touchedFields,
                            values: getValues(),
                          })
                        }>
                        DEBUG
                      </Button>
                      <Button
                        onClick={() =>
                          reset({
                            name: 'New Schedule',
                            requireEmployeeSignature: false,
                            requireManagerSignature: false,
                            dueDateType: 'date',
                          })
                        }>
                        RESET
                      </Button>
                    </>
                  )}
                  <ScheduleSummary
                    peopleCount={watchReviewees?.length}
                    isPulse={isPulse}
                    isEdit={isEdit}
                    dueDateType={watchDueDateType}
                    template={watchTemplate}
                    includeGoalCreation={watchIncludeGoalCreation}
                    includeGoalReview={watchIncludeGoalReview}
                    employeeSignature={watchRequireEmployeeSignature}
                    managerSignature={watchrequireManagerSignature}
                  />
                  <Grid item sm={12}>
                    <Button
                      type="submit"
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      disabled={formState.isSubmitting}>
                      Save Schedule
                    </Button>
                  </Grid>
                </ModernCard>
              </StickyBox>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            {isLoading ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Skeleton animation="wave" height={100} />
                  <Skeleton animation="wave" height={300} />
                  <Skeleton animation="wave" height={300} />
                  <Skeleton animation="wave" height={300} />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ModernCard headerTitle={getScheduleFormTitle(isEdit, isPulse, watchName)}>
                    <TextFieldControlled
                      name="name"
                      label="Schedule Name"
                      fullWidth
                      multiline
                      control={control}
                      placeholder="New Schedule"
                      autoFocus={true}
                      variant="outlined"
                    />
                    <div className={classes.detailsSpacing} />
                    <Controller
                      rules={{ required: true }}
                      render={({ field, fieldState: { error }, ref }) => (
                        <EvaluationTemplateSearch
                          pulseTemplates={isPulse}
                          disabled={isEdit}
                          {...field}
                          error={error}
                          helperText={error?.message}
                          ref={ref}
                        />
                      )}
                      name="template"
                      control={control}
                    />
                    {!isPulse && (
                      <Collapse in={watchTemplate}>
                        <Grid container spacing={1} className={classes.templateSectionsWrapper}>
                          <Grid item xs={12} className={classes.subheading}>
                            <Typography variant="subtitle2">Template includes sections for:</Typography>
                          </Grid>
                          <Grid item>{watchTemplate?.selfSections && getTypeChip('self')}</Grid>
                          <Grid item>{watchTemplate?.leadSections && getTypeChip('lead')}</Grid>
                          <Grid item>{watchTemplate?.peerSections && getTypeChip('peer')}</Grid>
                        </Grid>
                      </Collapse>
                    )}
                  </ModernCard>
                </Grid>
                {!isPulse && (
                  <Grid item sm={12} xs={12}>
                    <ModernCard headerTitle="Signatures">
                      <Typography variant="h4" className={classes.title}>
                        Require Signatures From:
                      </Typography>
                      <CheckboxControlled
                        label="Employee"
                        name="requireEmployeeSignature"
                        control={control}
                        defaultValue={true}
                        disabled={isEdit}
                      />
                      <CheckboxControlled
                        label="Manager"
                        name="requireManagerSignature"
                        control={control}
                        defaultValue={true}
                        disabled={isEdit}
                      />
                      {isEdit && <Typography variant="subtitle2">Editing signatures on launched evaluation settings is not supported at this time, but our team is working on it soon.</Typography>}
                    </ModernCard>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <ModernCard headerTitle="Assignment">
                    {isPulse && (
                      <Typography variant="subtitle2">
                        Pulse surveys are intended to provide anonymous results, so you need to select at least 5
                        employees for your Pulse Schedule.
                      </Typography>
                    )}
                    <PeopleSelectionTool control={control} excludedItems={previousReviewees} customListTitle={isEdit && "New Assignments"} defaultChecked={['Person']} />
                    {isEdit && (
                      <Card variant="outlined" style={{marginTop: '16px'}} elevation={0}>
                        <CardHeader 
                          title={`Existing Assignments (${previousReviewees.length})`} 
                          subheader={
                            `The ability to remove existing assignments is in development - 
                            In the meantime you can delete assigned evaluations on the "All Evaluations" page. 
                            If you deleted an assigned evaluation and need to recover it, please contact support.`
                          } 
                        />
                        <CardContent>
                          <List dense style={{ maxHeight: 300, overflowY: 'scroll' }}>
                          {previousReviewees.length > 0 &&
                            previousReviewees.map((person, index) => (
                              <ListItem id={`selected_list_item_${index}`}>
                                <ListItemIcon>{getIcon('person')}</ListItemIcon>
                                <ListItemText 
                                  primary={<Typography variant="h6">{index + 1}. {person.fullName}</Typography>} 
                                />
                              </ListItem>
                            ))}
                            {previousReviewees.length === 0 && (
                              <ListItem>
                                <Typography variant="subtitle2">No existing assigments for this evaluation</Typography>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    )}
                  </ModernCard>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <ModernCard headerTitle="Schedule">
                    <FormControl className={classes.formControl} variant="outlined">
                      <InputLabel id="date-selection-type">Due Date Based On</InputLabel>
                      <SelectControlled
                        name="dueDateType"
                        control={control}
                        label="Due Date Based On"
                        fullWidth
                        variant="outlined"
                        defaultValue="date"
                        disabled={isPulse || isEdit}>
                        <MenuItem value={'date'}>Date Selection</MenuItem>
                        <MenuItem value={'hireDate'}>Hire Date</MenuItem>
                        <MenuItem value={'Anniversary'}>Anniversary Date</MenuItem>
                      </SelectControlled>
                    </FormControl>
                    <Grid container spacing={2}>
                      {(watchDueDateType === 'hireDate' || watchDueDateType === 'Anniversary') && (
                        <Grid item xs={12}>
                          <div className={classes.indentSection}>
                            <Typography variant="h5" className={classes.title}>
                              Evaluation Period
                            </Typography>
                            <Grid container spacing={1} alignItems="flex-end">
                              <Grid item>
                                <DateRangeIcon />
                              </Grid>
                              <Grid item>
                                <Typography variant="h6" className={classes.dateOffsetLabel}>
                                  Start:{' '}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <DatePickerControlled
                                  name="evaluationPeriodStartDate"
                                  control={control}
                                  defaultValue={moment().startOf('year').toDate()}
                                />
                              </Grid>

                              <Grid item>
                                <Tooltip title="The date when the evaluation will be due for a given review cycle.">
                                  <IconButton size="small" aria-label="delete">
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} alignItems="flex-end">
                              <Grid item>
                                <DateRangeIcon />
                              </Grid>
                              <Grid item>
                                <Typography variant="h6" className={classes.dateOffsetLabel}>
                                  End:{' '}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <DatePickerControlled
                                  name="evaluationPeriodEndDate"
                                  control={control}
                                  defaultValue={moment().endOf('year').startOf('day').toDate()}
                                />
                              </Grid>

                              <Grid item>
                                <Tooltip title="The date when the evaluation will be due for a given review cycle.">
                                  <IconButton size="small" aria-label="delete">
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Typography variant="h6">
                                If an employee's {watchDueDateType === 'hireDate' ? 'hire date' : 'work anniversary'} is
                                outside of the evaluation period selected above, no evaluation will be created for the
                                employee. Because of this, we recommend that you make the evaluation period for this
                                type of assignment 365 days or carefully review the selected employees to confirm their{' '}
                                {watchDueDateType === 'hireDate' ? 'hire date' : 'work anniversary'} falls within this
                                date range.
                              </Typography>
                            </Grid>
                          </div>
                        </Grid>
                      )}

                      <Grid item sm={12} xs={12}>
                        <div className={classes.indentSection}>
                          <Typography variant="h5" className={classes.title}>
                            Feedback Submission Period
                          </Typography>

                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                              <DateRangeIcon />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" className={classes.dateOffsetLabel}>
                                Due:{' '}
                              </Typography>
                            </Grid>
                            {watchDueDateType === 'date' && (
                              <Grid item>
                                <DatePickerControlled
                                  name="dueDate"
                                  control={control}
                                  defaultValue={moment().add(1, 'month').toDate()}
                                  disabled={isEdit}
                                />
                              </Grid>
                            )}
                            {(watchDueDateType === 'hireDate' || watchDueDateType === 'Anniversary') && (
                              <>
                                <Grid item>
                                  <TextFieldControlled
                                    name="endDateOffset"
                                    control={control}
                                    controllerDefaultValue={30}
                                    type="number"
                                    className={classes.dateOffset}
                                    disabled={isEdit}
                                    inputProps={{ min: 0, max: 180 }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography variant="body1" className={classes.dateOffsetDaysLabel}>
                                    days
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <SelectControlled
                                    name="endOffsetDirection"
                                    control={control}
                                    defaultValue="after"
                                    className={classes.beforeAfterOffset}
                                    disabled={isEdit || watchDueDateType === 'hireDate'}>
                                    <MenuItem value="before">
                                      before employee {watchDueDateType === 'hireDate' ? 'hire' : 'work anniversary'}{' '}
                                      date
                                    </MenuItem>
                                    <MenuItem value="after">
                                      after employee {watchDueDateType === 'hireDate' ? 'hire' : 'work anniversary'}{' '}
                                      date
                                    </MenuItem>
                                  </SelectControlled>
                                </Grid>
                              </>
                            )}
                            <Grid item>
                              <Tooltip title="The date when the evaluation will be due for a given review cycle.">
                                <IconButton size="small" aria-label="delete">
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                              <DateRangeIcon />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" className={classes.dateOffsetLabel}>
                                Available:{' '}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <TextFieldControlled
                                name="startDateOffset"
                                control={control}
                                controllerDefaultValue={14}
                                className={classes.dateOffset}
                                type="number"
                                inputProps={{ min: 1, max: 180 }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" className={classes.dateOffsetDaysLabel}>
                                days before the assigned due date
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Tooltip title="The number of days before the provided due date when the employee will be notified and the evaluation will be available to be completed for a given review cycle.">
                                <IconButton size="small" aria-label="delete">
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1} alignItems="flex-end">
                            {watchDueDateType === 'date' && (
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  Example: If the due date is {moment(watchDueDate).format('MM/DD/yyyy')} then the
                                  evaluation will be available to start on{' '}
                                  {moment(watchDueDate)
                                    .add(watchStartDateOffset * -1, 'day')
                                    .format('MM/DD/yyyy')}
                                  .
                                </Typography>
                              </Grid>
                            )}
                            {(watchDueDateType === 'Anniversary' || watchDueDateType === 'hireDate') && (
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  Example: If the employee's{' '}
                                  {watchDueDateType === 'hireDate' ? 'hire date' : 'work anniversary'} is{' '}
                                  {moment().format('MM/DD/yyyy')} then the evaluation will be due on{' '}
                                  {moment()
                                    .add(
                                      watchEndDateOffsetDirection === 'before'
                                        ? watchEndDateOffset * -1
                                        : watchEndDateOffset,
                                      'day',
                                    )
                                    .format('MM/DD/yyyy')}{' '}
                                  ({watchEndDateOffset} days {watchEndDateOffsetDirection}) and will be available to
                                  start on{' '}
                                  {moment()
                                    .add(
                                      watchEndDateOffsetDirection === 'before'
                                        ? watchEndDateOffset * -1
                                        : watchEndDateOffset,
                                      'day',
                                    )
                                    .add(watchStartDateOffset * -1, 'day')
                                    .format('MM/DD/yyyy')}
                                  .
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                      {isPulse && (
                        <Grid item xs={12}>
                          <Typography variant="h4">Recurring Schedule</Typography>
                        </Grid>
                      )}

                      {isPulse && (
                        <div className={classes.indentSection}>
                          <Grid item xs={12}>
                            <span className={classes.repeatEveryText}>Repeat </span>

                            <SelectControlled name="repeatType" control={control} defaultValue="month">
                              {recurrenceRepeatTypes(isPulse).map((type) => (
                                <MenuItem value={type.value}>{type.title}</MenuItem>
                              ))}
                            </SelectControlled>
                          </Grid>
                          <Grid item xs={12}>
                            <span className={classes.repeatEveryText}>Ends </span>

                            <DatePickerControlled
                              name="endDate"
                              control={control}
                              defaultValue={moment().add(3, 'month').toDate()}
                            />
                          </Grid>
                        </div>
                      )}
                    </Grid>
                  </ModernCard>
                </Grid>
                {!isPulse && (
                  <Grid item sm={12} xs={12}>
                    <ModernCard headerTitle="Goals">
                      <div className={classes.goalSettingDescription}>
                        <div style={{ display: 'flex' }}>
                          <Typography variant="h4" className={classes.title}>
                            Review Employee's Goals During Evaluation Period
                          </Typography>
                          <SwitchControlled
                            name="includeGoalReview"
                            control={control}
                            defaultValue={watchIncludeGoalReview}
                            label={watchIncludeGoalReview ? 'Active' : 'Inactive'}
                          />
                        </div>
                        <Typography variant="subtitle2">
                          This allows the employee and manager to view the employee's active goals and add comments to
                          the goal during the evaluation.
                        </Typography>
                      </div>
                      <Collapse in={watchIncludeGoalReview}>
                        <div className={classes.indentSection}>
                          <Grid container spacing={1} alignItems="flex-end" className={classes.detailsSpacing}>
                            <Grid item>
                              <DateRangeIcon />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" className={classes.typographyLine}>
                                Show goals from the past{' '}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <TextFieldControlled
                                fullWidth
                                control={control}
                                controllerDefaultValue={365}
                                className={classes.goalOffsetInput}
                                name="includeGoalsRange"
                                variant="standard"
                                type="number"
                                color="secondary"
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" className={classes.typographyLine}>
                                {' '}
                                days before the evaluation due date
                              </Typography>
                            </Grid>
                          </Grid>
                          <RichTextControlled
                            name="goalReviewInstructions"
                            label="Goal Review Instructions"
                            control={control}
                          />
                        </div>
                      </Collapse>
                      <Divider className={classes.divider} />
                      <div className={classes.goalSettingDescription}>
                        <div style={{ display: 'flex' }}>
                          <Typography variant="h4" className={classes.title}>
                            Include Creation of New Goals During Evaluation
                          </Typography>
                          <SwitchControlled
                            name="includeGoalCreation"
                            control={control}
                            defaultValue={watchIncludeGoalCreation}
                            label={watchIncludeGoalCreation ? 'Active' : 'Inactive'}
                          />
                        </div>
                        <Typography variant="subtitle2">
                          If enabled, the evaluated employee and their manager will be able to create new goals that the
                          employee should work on in the upcoming review cycle. If your organization would like specific
                          types of goals, or a specific number of goals, please specify in the section instructions.
                        </Typography>
                      </div>
                      <Collapse in={watchIncludeGoalCreation}>
                        <div className={classes.indentSection}>
                          <RichTextControlled
                            name="goalCreationInstructions"
                            label="Goal Creation Instructions"
                            control={control}
                          />
                        </div>
                      </Collapse>
                    </ModernCard>
                  </Grid>
                )}
                <Grid item direction="row-reverse" xs={12} style={{ display: 'flex' }}>
                  <Button variant="contained" color="primary" type="submit" disabled={formState.isSubmitting}>
                    Save Schedule
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}
