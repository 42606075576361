import { makeStyles } from '@material-ui/styles'

export const useSignatureCardStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  signature: {
    fontFamily: 'Homemade Apple',
    fontSize: 24,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontFamily: 'Roboto',
  },
}))
