import React, { useState, useEffect } from 'react'
import DatePicker from 'components/Inputs/DatePicker'
import { EditDrawer } from 'components'
import PropTypes from 'prop-types'
import { updateEmployee, createEmployee } from 'api'
import { InlineError } from 'helpers'
import { usePeopleDrawerStyles } from 'styles'
import { validate } from 'validate.js'
import { peopleDrawerValidations } from 'validations'
import { useDispatch } from 'react-redux'
import { enqueueSuccessNotification, enqueueErrorNotification } from 'redux/actions/notificationsActions'

import {
  Divider,
  TextField,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  FormHelperText,
} from '@material-ui/core'
import { TagSearch } from 'components/Search'
import { useAuth0 } from 'react-auth0-spa'
import { Alert, AlertTitle } from '@material-ui/lab'
import EmployeeSearch from 'components/Search/EmployeeSearch'
import { getIcon } from 'utils/GetIcon'

const PeopleDrawer = (props) => {
  const { callback, person, type, buttonType = 'icon', ...otherProps } = props

  const classes = usePeopleDrawerStyles()
  const dispatch = useDispatch()
  const [formErrors, setFormErrors] = useState([])
  const { appMetadata } = useAuth0()
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      hireDate: new Date(),
      isOrgAdmin: false,
    },
    touched: {},
    errors: {},
  })

  const [disableForm, setDisableForm] = useState(false)

  useEffect(() => {
    setFormErrors([])
    const errors = validate(formState.values, peopleDrawerValidations())

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  useEffect(() => {
    if (appMetadata.peopleExternallyManaged) {
      setDisableForm(true)
    }
  }, [appMetadata])

  const handleValueChange = (name, value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    }))
  }

  const handleSearchChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        manager: value,
      },
      touched: {
        ...formState.touched,
        manager: true,
      },
    }))
  }

  const handleDateChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        hireDate: value,
      },
      touched: {
        ...formState.touched,
        hireDate: true,
      },
    }))
  }

  const handleChange = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value === ''
            ? null
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  useEffect(() => {
    if (type === 'edit' && person.firstName) {
      setFormState((formState) => ({
        ...formState,
        values: {
          firstName: person.firstName,
          middleName: person.middleName,
          lastName: person.lastName,
          email: person.email,
          secondaryEmail: person.secondaryEmail,
          employeeId: person.employeeId,
          manager: person.manager,
          hireDate: person.hireDate,
          jobTitle: person.tags.filter((tag) => tag.type === 'JobTitle')[0],
          isOrgAdmin: person.isOrgAdmin,
          isTeamLead: person.isTeamLead,
          team: person.tags.filter((tag) => tag.type === 'Team')[0],
          department: person.tags.filter((tag) => tag.type === 'Department')[0],
          location: person.tags.filter((tag) => tag.type === 'Location')[0],
        },
      }))
    }
  }, [type, person])

  const addPerson = async () => {
    let closeDrawer
    setFormErrors([])
    await createEmployee({
      firstName: formState.values.firstName,
      middleName: formState.values.middleName,
      lastName: formState.values.lastName,
      email: formState.values.email,
      secondaryEmail: formState.values.secondaryEmail,
      employeeId: formState.values.employeeId,
      hireDate: formState.values.hireDate,
      tags: [formState.values.team, formState.values.department, formState.values.location, formState.values.jobTitle],
      isOrgAdmin: formState.values.isOrgAdmin,
      isTeamLead: formState.values.isTeamLead,
      sendInvite: formState.values.sendInvite,
      manager: formState.values.manager,
    }).then((response) => {
      if (response && response.status === 200) {
        callback()
        closeDrawer = true
        resetFormState()
        dispatch(
          enqueueSuccessNotification(
            `Successfully added ${formState.values.firstName} ${formState.values.lastName} to your organization`,
          ),
        )
      } else {
        setFormErrors(response.data.errors)
        closeDrawer = false
        if (response && response.status === 500) {
          dispatch(
            enqueueErrorNotification(
              `Encountered a server error. ${formState.values.firstName} ${formState.values.lastName} was not added to your organization`,
            ),
          )
        }
      }
    })

    return closeDrawer
  }

  const updatePerson = async () => {
    let closeDrawer
    setFormErrors([])
    console.log(
      '>>>',
      formState.values,
      [formState.values.team, formState.values.department, formState.values.location, formState.values.jobTitle].filter(
        (a) => a !== undefined,
      ),
    )
    await updateEmployee({
      id: person.id,
      firstName: formState.values.firstName,
      middleName: formState.values.middleName,
      lastName: formState.values.lastName,
      email: formState.values.email,
      secondaryEmail: formState.values.secondaryEmail,
      employeeId: formState.values.employeeId,
      hireDate: formState.values.hireDate,
      manager: formState.values.manager,
      tags: [
        formState.values.team,
        formState.values.department,
        formState.values.location,
        formState.values.jobTitle,
      ].filter((a) => a !== undefined),
      isOrgAdmin: formState.values.isOrgAdmin,
      isTeamLead: formState.values.isTeamLead,
    }).then((response) => {
      if (response.status === 200) {
        callback()
        closeDrawer = true
        resetFormState()
        dispatch(
          enqueueSuccessNotification(
            `Successfully updated ${formState.values.firstName} ${formState.values.lastName}'s profile`,
          ),
        )
      } else {
        setFormErrors(response.data.errors)
        closeDrawer = false
        if (response && response.status === 500) {
          dispatch(
            enqueueErrorNotification(
              `Encountered a server error. ${formState.values.firstName} ${formState.values.lastName} was not added to your organization`,
            ),
          )
        }
      }
    })

    return closeDrawer
  }

  const resetFormState = () => {
    if (type === 'new') {
      setFormState({
        isValid: false,
        values: {
          hireDate: new Date(),
          isOrgAdmin: false,
        },
        touched: {},
        errors: {},
      })
    } else if (type === 'edit') {
      setFormState({
        ...formState,
        values: {
          firstName: person.firstName,
          middleName: person.middleName,
          lastName: person.lastName,
          email: person.email,
          secondaryEmail: person.secondaryEmail,
          employeeId: person.employeeId,
          manager: person.manager,
          hireDate: person.hireDate,
          jobTitle: person.jobTitle,
          isOrgAdmin: person.isOrgAdmin,
          isTeamLead: person.isTeamLead,
          location: person.location,
        },
      })
    }
  }

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false)
  console.log('>>> check', formState.isValid, disableForm)
  return (
    <EditDrawer
      buttonType={buttonType}
      openButtonLabel={type === 'new' ? 'New Person' : `Edit`}
      saveButtonLabel={'Save Person'}
      savefunction={type === 'new' ? addPerson : updatePerson}
      title={type === 'new' ? 'Add New Person' : `Edit ${person.fullName}`}
      cancelFunction={resetFormState}
      disableSaveButton={disableForm || !formState.isValid}
      otherProps={otherProps}>
      {appMetadata.peopleExternallyManaged && (
        <Grid item xs={12}>
          <Alert severity="info">
            <AlertTitle>People are managed by Employee Navigator</AlertTitle>
            <Typography variant="body1">
              Your organization is set up to sync your people with Employee Navigator. With the integration turned on,
              you will not be able to indivdiually edit people's profile information.
            </Typography>
          </Alert>
        </Grid>
      )}
      <Grid item sm={6} xs={12}>
        <TextField
          autoFocus
          fullWidth
          error={hasError('firstName')}
          helperText={hasError('firstName') ? formState.errors.firstName[0] : null}
          onChange={handleChange}
          value={formState.values.firstName || ''}
          label="First Name"
          name="firstName"
          variant="outlined"
          disabled={disableForm}
        />
        <InlineError errors={formErrors} field="FirstName" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          label="Middle Name"
          name="middleName"
          error={hasError('middleName')}
          helperText={hasError('middleName') ? formState.errors.middleName[0] : null}
          onChange={handleChange}
          value={formState.values.middleName || ''}
          variant="outlined"
          disabled={disableForm}
        />
        <InlineError errors={formErrors} field="MiddleName" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Last Name"
          name="lastName"
          error={hasError('lastName')}
          helperText={hasError('lastName') ? formState.errors.lastName[0] : null}
          onChange={handleChange}
          value={formState.values.lastName || ''}
          variant="outlined"
          disabled={disableForm}
        />
        <InlineError errors={formErrors} field="LastName" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email (Username)"
          name="email"
          error={hasError('email')}
          helperText={hasError('email') ? formState.errors.email[0] : null}
          onChange={handleChange}
          value={formState.values.email || ''}
          type="email"
          variant="outlined"
          disabled={disableForm}
        />
        <InlineError errors={formErrors} field="Email" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Secondary Email"
          name="secondaryEmail"
          error={hasError('secondaryEmail')}
          helperText={hasError('secondaryEmail')}
          onChange={handleChange}
          value={formState.values.secondaryEmail || ''}
          type="email"
          variant="outlined"
          disabled={disableForm}
        />
        <InlineError errors={formErrors} field="SecondaryEmail" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Employee Id"
          name="employeeId"
          error={hasError('employeeId')}
          helperText={hasError('employeeId')}
          onChange={handleChange}
          value={formState.values.employeeId || ''}
          variant="outlined"
          disabled={disableForm}
        />
        <InlineError errors={formErrors} field="EmployeeId" />
      </Grid>
      <Grid item sm={12} xs={12}>
        <EmployeeSearch
          startAdornment={getIcon('manager')}
          label="Assign a manager"
          onChange={handleSearchChange}
          value={formState.values.manager || ''}
          returnType="object"
          variant="outlined"
        />
        <InlineError errors={formErrors} field="TeamId" />
      </Grid>
      <Grid item sm={12} xs={12}>
        <DatePicker
          setValue={handleDateChange}
          title={'Hire Date'}
          error={hasError('hireDate')}
          value={formState.values.hireDate || ''}
          disabled={disableForm}
        />
        <InlineError errors={formErrors} field="HireDate" />
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <Typography className={classes.title} variant="subtitle1">
          Optional Values
        </Typography>
      </Grid>
      <Grid item sm={12} xs={12}>
        <TagSearch
          label="Team"
          disabled={disableForm}
          onChange={(value) => handleValueChange('team', value)}
          filterTypes={['Team']}
          variant="outlined"
          value={formState.values.team || ''}
        />
        <InlineError errors={formErrors} field="Team" />
      </Grid>
      <Grid item sm={12} xs={12}>
        <TagSearch
          label="Department"
          disabled={disableForm}
          onChange={(value) => handleValueChange('department', value)}
          filterTypes={['Department']}
          variant="outlined"
          value={formState.values.department || ''}
        />
        <InlineError errors={formErrors} field="Team" />
      </Grid>
      <Grid item sm={12} xs={12}>
        <TagSearch
          label="Job Title"
          disabled={disableForm}
          onChange={(value) => handleValueChange('jobTitle', value)}
          filterTypes={['JobTitle']}
          variant="outlined"
          value={formState.values.jobTitle || ''}
        />
        <InlineError errors={formErrors} field="Job Title" />
      </Grid>
      <Grid item sm={12}>
        <TagSearch
          label="Location"
          disabled={disableForm}
          onChange={(value) => handleValueChange('location', value)}
          filterTypes={['Location']}
          variant="outlined"
          value={formState.values.location || ''}
        />
        <InlineError errors={formErrors} field="Location" />
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <FormControl component="fieldset">
          {/* {type === 'new' && (
            <>
              <FormLabel component="legend">
                <Typography className={classes.title} variant="subtitle1">
                  Email Notification
                </Typography>
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formState.values.sendInvite}
                      onChange={handleChange}
                      name="sendInvite"
                      size="small"
                      disabled={disableForm}
                    />
                  }
                  label="Send Invitation Email"
                />
              </FormGroup>
              <FormHelperText>
                If selected, this will create a login and password for this person and send them an invite with a
                temporary password to login in.
              </FormHelperText>{' '}
            </>
          )} */}
          <FormLabel component="legend">
            <Typography className={classes.title} variant="subtitle1">
              Organization Permissions
            </Typography>
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={disableForm || (type === 'new' && !formState.values.sendInvite)}
                  checked={formState.values.isOrgAdmin}
                  onChange={handleChange}
                  name="isOrgAdmin"
                  size="small"
                />
              }
              label="Make Admin"
            />
          </FormGroup>
          <FormHelperText>
            Making someone an Admin will allow them to change all settings, and add and remove other admins.
          </FormHelperText>
        </FormControl>
      </Grid>
    </EditDrawer>
  )
}

PeopleDrawer.propTypes = {
  addPerson: PropTypes.func,
  person: PropTypes.object,
  type: PropTypes.string.isRequired,
  updatePerson: PropTypes.func,
}

export default PeopleDrawer
