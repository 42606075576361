import { productOfferingTypes } from 'data/constants'
import actionTypes from 'redux/actionTypes'

export const initializeFocusView = (
  title,
  backUrl,
  closeUrl,
  bottomDescription = '',
  actions = [],
  productOfferingType = productOfferingTypes.performance,
) => ({
  type: actionTypes.initializeFocusView,
  title,
  backUrl,
  closeUrl,
  bottomDescription,
  actions,
  productOfferingType,
})

export const updateBottomDescription = (description) => ({
  type: actionTypes.updateFocusViewBottomDescription,
  description,
})

export const updateUnsavedchanges = (unsavedChanges) => ({
  type: actionTypes.updateUnsavedChanges,
  unsavedChanges,
})
