import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { DialogActions, DialogContent, Button, Divider } from '@material-ui/core'
import { Grid, Typography } from '@material-ui/core'
import { updateFeedbackDueDate } from 'api'
import { useDispatch } from 'react-redux'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import DatePicker from 'components/Inputs/DatePicker'

const useStyles = makeStyles((theme) => ({
  dialog: { minWidth: 600 },
  dialogContent: { minWidth: 580, padding: theme.spacing(2) },
  divider: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
}))

const FeedbackDateModal = (props) => {
  const classes = useStyles()

  const { evaluationId, feedbackId, currentDueDate, setOpen, open, title, callback } = props
  const dispatch = useDispatch()
  const [adjustedDueDate, setAdjustedDueDate] = useState(currentDueDate)

  const handleClose = () => {
    setOpen(false)
    setAdjustedDueDate('')
  }

  const handleDueDateUpdate = () => {
    updateFeedbackDueDate(evaluationId, feedbackId, adjustedDueDate).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification(`${title} due date was updated successfully`))
        callback()
        handleClose()
      } else {
        dispatch(
          enqueueErrorNotification(
            'The due date was not able to update. Please try again or contact support if you continue to see this message',
          ),
        )
      }
    })
  }

  return (
    <Dialog
      id={`${feedbackId}_duedate_modal`}
      onClose={handleClose}
      aria-labelledby="duedate-dialog-title"
      open={open}
      className={classes.dialog}>
      <DialogTitle id="duedate-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Grid className={classes.dialogContent} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Adjust feedback due date</Typography>
            <Divider className={classes.divider} />
            <Typography variant="body1">
              Adjusting a feedback due date will only affect the individual you are adjusting the due date for. The new
              due date will drive the reminder email notifications, allowing, for example, employees to get reminder
              emails to complete their feedback before the manager gets reminders to complete their feedback.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DatePicker title="Due Date" value={adjustedDueDate} setValue={(value) => setAdjustedDueDate(value)} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDueDateUpdate}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FeedbackDateModal.propTypes = {
  evaluationId: PropTypes.string.isRequired,
  feedbackId: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export default FeedbackDateModal
