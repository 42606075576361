import React, { createRef } from 'react'
import MaterialTable from 'material-table'
import { getArchivedPeople, activatePerson } from 'api'
import { Typography, ButtonGroup, Button } from '@material-ui/core'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
const ArchivedEmployeeTable = ({ pageSize, pageSizeOptions }) => {
  const dispatch = useDispatch()
  const tableRef = createRef()

  const handleRestoreEmployee = (id) => {
    activatePerson(id).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('You have successfully restored the user to your org'))
        tableRef.current.onQueryChange()
      } else {
        dispatch(enqueueErrorNotification('The server encountered an error. The user was not able to be restored'))
      }
    })
  }

  const headCells = [
    {
      field: 'fullName',
      title: 'Name',
      render: (person) => (
        <div>
          <Typography>{person.fullName}</Typography>
          <Typography variant="caption">{person.jobTitle}</Typography>
        </div>
      ),
    },
    {
      field: 'email',
      title: 'Email',
    },
    {
      field: 'hireDate',
      title: 'Hire Date',
      type: 'date',
    },
    {
      field: 'edit',
      title: 'Actions',
      render: (person) => (
        <ButtonGroup variant="text">
          <Button onClick={() => handleRestoreEmployee(person.id)}>Rehire</Button>
        </ButtonGroup>
      ),
    },
  ]

  return (
    <MaterialTable
      tableRef={tableRef}
      columns={headCells}
      data={(query) =>
        new Promise((resolve, reject) => {
          getArchivedPeople(
            query.page,
            query.pageSize,
            query.search,
            (query.orderBy && query.orderBy.field) || '',
            query.orderDirection || '',
          ).then((response) => {
            if (response.status === 200) {
              resolve({
                data: response.data.data,
                page: query.page,
                totalCount: response.data.totalCount,
              })
            } else {
              reject()
            }
          })
        })
      }
      options={{
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        selection: false,
        pageSize: pageSize,
        pageSizeOptions: pageSizeOptions,
        cellStyle: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
      title="Terminated Employees"
    />
  )
}

export default ArchivedEmployeeTable

ArchivedEmployeeTable.propTypes = {
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
}

ArchivedEmployeeTable.defaultProps = {
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
}
