import DatePicker from 'components/Inputs/DatePicker'
import React from 'react'
import { Controller } from 'react-hook-form'

const DatePickerControlled = ({ control, name, defaultValue, placeholder, disabled, ...rest }) => {
  return (
    <Controller
      render={({ field: { onChange, ...field }, ref }) => (
        <DatePicker setValue={onChange} variant="standard" {...field} ref={ref} disabled={disabled} />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  )
}

export default DatePickerControlled
