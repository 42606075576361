import actionTypes from 'redux/actionTypes'

const initialState = {
  loading: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.initializeTemplateBuilderStarted:
      return Object.assign({}, state, {
        loading: true,
        unsavedChanges: false,
        builderTrackingId: 10000,
        lastUpdated: '',
        isPreview: false,
        isEdit: false,
        template: {
          name: 'Evaluation',
          title: '',
          description: '',
          isActive: true,
          availableToEdit: false,
          sections: [],
          evaluations: [],
          scoringEnabled: true,
          isPulseTemplate: false,
          score: 0,
        },
      })
    case actionTypes.initializeEditTemplateBuilderSucceeded:
      return Object.assign({}, state, {
        loading: false,
        template: action.template,
        unsavedChanges: false,
        isEdit: true,
      })
    case actionTypes.initializeNewTemplateBuilderSucceeded:
      return Object.assign({}, state, {
        loading: false,
        unsavedChanges: false,
        builderTrackingId: 10000,
        lastUpdated: '',
        isPreview: false,
        isEdit: false,
        template: {
          name: 'Evaluation',
          title: '',
          description: '',
          isActive: true,
          availableToEdit: true,
          sections: [
            {
              title: action.isPulse ? 'Pulse Survey Response' : '',
              description: '',
              position: 0,
              isActive: true,
              showInSelf: true,
              showInPeer: false,
              showInLead: action.isPulse ? false : true,
              score: 0,
              evaluation: {},
              questions: [],
              sys_id: action.templateId,
            },
          ],
          evaluations: [],
          scoringEnabled: true,
          isPulseTemplate: action.isPulse,
          score: 0,
        },
      })
    case actionTypes.copyTemplateSucceeded:
      return Object.assign({}, state, {
        loading: false,
        template: action.template,
        unsavedChanges: false,
        isEdit: false,
      })
    case actionTypes.setUnsavedTemplateBuilderChanges:
      return Object.assign({}, state, {
        unsavedChanges: action.unsavedChanges,
      })
    case actionTypes.updateTemplateSucceeded:
      return Object.assign({}, state, {
        template: action.template,
        unsavedChanges: true,
      })
    case actionTypes.updateTemplateScore:
      return Object.assign({}, state, {
        template: {
          ...state.template,
          score: action.score,
        },
      })
    case actionTypes.updateTemplateProperty:
      return Object.assign({}, state, {
        template: {
          ...state.template,
          [action.name]: action.value,
        },
      })
    case actionTypes.updateQuestionSucceeded:
      return Object.assign({}, state, {
        template: {
          ...state.template,
          sections: action.sections,
        },
      })
    case actionTypes.updateSectionSucceeded:
      return Object.assign({}, state, {
        template: {
          ...state.template,
          sections: action.sections,
        },
      })
    case actionTypes.addQuestionSucceeded:
      return Object.assign({}, state, {
        template: {
          ...state.template,
          sections: action.sections,
        },
      })
    case actionTypes.addSectionSucceeded:
      return Object.assign({}, state, {
        template: {
          ...state.template,
          sections: action.sections,
        },
      })
    case actionTypes.incrementBuilderTrackerId:
      return Object.assign({}, state, {
        builderTrackingId: state.builderTrackingId + 1,
      })
    case actionTypes.updateTimestampSucceeded:
      return Object.assign({}, state, {
        lastUpdated: action.time,
      })
    case actionTypes.moveSectionSucceeded:
      return Object.assign({}, state, {
        template: {
          ...state.template,
          sections: action.sections,
        },
      })
    default:
      return state
  }
}
