import React, { useState, useEffect } from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import PropTypes from 'prop-types'

const PasswordValidation = (props) => {
  const { password, confirmPassword, setIsValid, setError } = props

  const [validPassword, setValidPassword] = useState({
    length: false,
    characters: false,
    special: false,
  })

  const validConditions = [
    {
      checkValue: validPassword.length,
      displayText: 'Longer than 8 characters',
    },
    {
      checkValue: validPassword.characters,
      displayText: 'Contains a uppercase and lowercase character and a number',
    },
    {
      checkValue: validPassword.special,
      displayText: 'Contains one special character (i.e. !@#$%^&*)',
    },
  ]
  useEffect(() => {
    setError({})
    const lengthRegEx = new RegExp('^(?=.{8,})')
    const charRegEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])')
    const specialRegEx = new RegExp('^(?=.*[!@#$%^&*])')

    setValidPassword({
      length: lengthRegEx.test(password),
      characters: charRegEx.test(password),
      special: specialRegEx.test(password),
    })
  }, [password, setError])

  useEffect(() => {
    setError({})
    if (password !== confirmPassword) {
      setError({ confirmPassword: 'Your passwords do not match' })
    }
  }, [confirmPassword, password, setError])

  useEffect(() => {
    const showError = !validPassword.length || !validPassword.characters || !validPassword.special
    showError && setError({ password: 'Password must meet conditions' })
  }, [validPassword, setError])

  useEffect(() => {
    if (
      !validPassword.length ||
      !validPassword.characters ||
      !validPassword.special ||
      confirmPassword.length === 0 ||
      confirmPassword !== password
    ) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [password, confirmPassword, setIsValid, validPassword.length, validPassword.characters, validPassword.special])

  return (
    <List dense={true}>
      {validConditions.map((value) => (
        <ListItem>
          <ListItemIcon>{value.checkValue ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}</ListItemIcon>
          <ListItemText primary={value.displayText} />
        </ListItem>
      ))}
    </List>
  )
}

PasswordValidation.propTypes = {
  confirmPassword: PropTypes.string.isRequired,
  error: PropTypes.object,
  isValid: PropTypes.bool,
  password: PropTypes.string.isRequired,
  setError: PropTypes.func,
}

PasswordValidation.defaultProps = {
  confirmPassword: '',
  error: {},
  isValid: false,
  password: PropTypes.string,
  setError: () => {},
}

export default PasswordValidation
