import React, { useEffect, useCallback } from 'react'
import { InlineError } from 'helpers'
import { useGoalFormStyles } from 'styles'
import {
  Divider,
  TextField,
  Grid,
  Typography,
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core'
import { RichTextArea } from 'components/Inputs'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'immutable'
import {
  initializeGoalForm,
  initializeEditGoalForm,
  resetGoalForm,
  updateGoalForm,
  handleUpdateGoal,
  handleCreateGoal,
} from 'redux/actions/goalFormActions'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import EmployeeSearch from 'components/Search/EmployeeSearch'
import DatePicker from 'components/Inputs/DatePicker'
import PermissionCheck from 'utils/PermissionCheck'
import { roles } from 'utils/roles'
import { useAuth0 } from 'react-auth0-spa'
import { Hint } from 'components'
import moment from 'moment'

const GoalForm = (props) => {
  const classes = useGoalFormStyles()
  const { appMetadata } = useAuth0()
  const { goalId, goalEditType } = useParams()
  const formState = useSelector((state) => getIn(state, ['goalForm']))
  const formErrors = useSelector((state) => getIn(state, ['goalForm', 'errors']))
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { callback, inEvaluation } = props
  const resetForm = useCallback(() => {
    dispatch(resetGoalForm())
  }, [dispatch])

  const handleBack = () => {
    if (inEvaluation) {
      resetForm()
    } else {
      history.goBack()
    }
  }

  const handleIsPersonalToggle = () => {
    dispatch(updateGoalForm(!formState.values.isPersonal, 'value', 'isPersonal'))
    const personValue = !formState.values.isPersonal ? { id: appMetadata.personId } : {}
    dispatch(updateGoalForm(personValue, 'value', 'people'))
  }

  const hasError = (field) =>
    getIn(formState, ['touched', field]) && getIn(formState, ['errors', field]) ? true : false

  const showAssignTo = !getIn(formState, ['isEdit']) && !getIn(formState, ['values', 'isPersonal']) ? true : false

  const handleGoalCreation = () => {
    dispatch(handleCreateGoal(history, callback))
  }
  useEffect(() => {
    resetForm()
    if (location.pathname.includes('edit')) {
      dispatch(initializeEditGoalForm(goalId, history))
    } else {
      dispatch(initializeGoalForm(appMetadata.personId, inEvaluation))
    }
  }, [location.pathname, goalId, resetForm, dispatch, goalEditType, appMetadata.personId, history, inEvaluation])

  return (
    <div className={classes.paperRoot} id="create_goal_form">
      {formState.isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid key="goal_edit_name" item md={12} xs={12}>
            <Typography variant="h5">Goal Name:</Typography>
            <TextField
              autoFocus
              fullWidth
              label="Goal Name"
              margin="dense"
              name="name"
              error={hasError('name')}
              helperText={hasError('name') ? formState.errors.name[0] : null}
              onChange={(e) => dispatch(updateGoalForm(e))}
              value={formState.values.name || ''}
              variant="outlined"
              required
            />
            <InlineError key="goal_edit_name_errors" errors={formErrors} field="Name" />
          </Grid>
          <Grid item xs={12}>
            <Hint
              severity={'info'}
              backgroundColor={'#e7f5fe'}
              text={
                'Goals will be shared between an employee and their manager. Use the goal name as a quick overview and include details in the description to explain intent, timelines, etc.'
              }
            />
          </Grid>

          <Grid key="goal_edit_description" item xs={12}>
            <Typography variant="h5">Description: </Typography>
            <Divider className={classes.divider} />
            <RichTextArea
              initialValue={'<p>Enter description here</p>'}
              value={formState.values.description || ''}
              onChange={(content, editor) => dispatch(updateGoalForm(content, 'value', 'description'))}
            />
          </Grid>
          <InlineError key="goal_edit_description_errors" errors={formErrors} field="description" />
          {!formState.values.createdInEvaluation && (
            <>
              <PermissionCheck
                key={`sys_tools_bar`}
                roles={[roles.SysAdmin, roles.TeamLead, roles.Admin]}
                yes={() => (
                  <Grid item xs={12}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formState.values.isPersonal}
                            onChange={() => handleIsPersonalToggle()}
                            disabled={formState.isEdit}
                            name="personal-goal-toggle"
                          />
                        }
                        label="Personal Goal"
                      />
                    </FormGroup>
                  </Grid>
                )}></PermissionCheck>
              {showAssignTo && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">Assign goal to:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <EmployeeSearch
                      directReports
                      helperText="You can search for a person or select from the list"
                      returnType="object"
                      setEmployees={(e) => dispatch(updateGoalForm(e, 'value', 'assignedPerson'))}
                      title="Employee to be assigned goal"
                      value={formState.values.assignedPerson || ''}
                    />
                  </Grid>
                </>
              )}
            </>
          )}

          <Grid item xs={5}>
            <Typography variant="h5">Due Date: </Typography>
            <Divider />
            <DatePicker
              setValue={(value) => dispatch(updateGoalForm(value, 'value', 'dueDate'))}
              error={hasError('dueDate')}
              value={formState.values.dueDate}
              minDate={new Date()}
              maxDate={moment().add(1, 'years')}
            />
            <InlineError key="goal_edit_description_errors" errors={formErrors} field="dueDate" />
          </Grid>
          <Grid container justify="flex-end">
            <Button onClick={handleBack}>Cancel</Button>
            {formState.isEdit ? (
              <Button
                disabled={!formState.isValid}
                variant="contained"
                color="secondary"
                onClick={() => dispatch(handleUpdateGoal(history))}>
                Update Goal
              </Button>
            ) : (
              <Button
                disabled={!formState.isValid}
                variant="contained"
                color="secondary"
                onClick={() => handleGoalCreation()}>
                Save Goal
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default GoalForm
