import { makeStyles } from '@material-ui/styles'

export const useEvaluationNavStyle = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  evalInfo: {
    marginBottom: theme.spacing(2),
    '& h4': {
      marginBottom: theme.spacing(1)
    },
    '& p': {
      margin: theme.spacing(0,1),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingBottom: theme.spacing(1)
    },
  },
  stepper: {
    padding: theme.spacing(3, 0),
  },
  stepperRoot: {
    padding: theme.spacing(2),
  },
  stepperCard: {
    marginBottom: theme.spacing(2),
  },
  stepperTitle: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  lastUpdateTimestamp: {
    marginTop: theme.spacing(1),
  },
  progressAvatar: {
    backgroundColor: theme.palette.secondary.main
  }
}))
