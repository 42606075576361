import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Slider from '@material-ui/core/Slider'
import { getIcon } from 'utils/GetIcon'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  heroContent: {
    padding: theme.spacing(2),
  },
  actionButton: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  cardHeader: {},
  cardPricing: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  employeeCountWrapper: {
    marginTop: theme.spacing(2),
  },
  included: {
    color: theme.palette.success.main,
  },
  priceSubtitle: {
    color: theme.palette.text.secondary,
    minHeight: '14px',
    fontSize: '11px',
    letterSpacing: '0.33px',
    lineHeight: '13px',
  },
  priceCard: {
    padding: theme.spacing(3),
  },
  pricingFeature: {
    ...theme.typography.h5,
  },
  switchLabel: {
    margin: 'auto 0',
  },
  switchWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  titleWrapper: {
    minHeight: theme.spacing(7),
  },
  tooltip: {
    color: theme.palette.white,
  },
}))

export default function Pricing() {
  const classes = useStyles()
  const [paymentAnnual, setPaymentAnnual] = useState(true)
  const [employeeCount, setEmployeeCount] = useState(1)

  const handleSliderChange = (event, newValue) => {
    setEmployeeCount(newValue)
  }

  const priceInput = ['1 - 50', '51 - 125', '126 - 250', '251 - 500', '501 - 750', '751 - 1000', '1000+']

  const [basicPricing] = useState({
    monthly: [100, 150, 300, 450, 600, 850],
    annually: [1000, 1500, 3000, 4500, 6000, 8500],
  })
  const [proPricing] = useState({
    monthly: [150, 225, 450, 675, 900, 1275],
    annually: [1500, 2250, 4500, 6750, 9000, 12750],
  })

  const tiers = [
    {
      title: 'Basic',
      price: paymentAnnual ? basicPricing.annually[employeeCount] : basicPricing.monthly[employeeCount],
      priceSubtitle: paymentAnnual ? `2 months free compared to monthly` : ' ',
      buttonText: 'Get Started',
      buttonVariant: 'contained',
      ctaLink: '/signup',
    },
    {
      title: 'Pro',
      price: paymentAnnual ? proPricing.annually[employeeCount] : proPricing.monthly[employeeCount],
      priceSubtitle: paymentAnnual ? `2 months free compared to monthly` : ' ',
      buttonText: 'Get Started',
      buttonVariant: 'contained',
      ctaLink: '/signup',
    },
  ]

  const features = [
    {
      title: 'Unlimited Evaluations',
      tier: 'Basic',
      subtitle: ``,
      description: `You may do evaluations once a year, every quarter, or even more frequently. Whatever your preference, don't worry- Your Express Evaluations have no limits and no extra charge for additional usage.`,
    },
    {
      title: 'Advanced Scheduling Features',
      tier: 'Basic',
      subtitle: ``,
      description: `Keeping track of your busy calendar is a pain- Adding hundreds of evaluations makes it even worse. Express Evaluations lets you set recurring evaluations by person, team, or organization. After you've set it, forget it. We'll make sure your evaluations repeat on the cadence your organization needs (monthly, annually, etc)`,
    },
    {
      title: 'Custom Evaluation Builder',
      tier: 'Basic',
      subtitle: ``,
      description: `Your organization is unique- Our powerful evaluation builder lets you customize your evaluations in order to make them powerful, reusable, and insightful.`,
    },
    {
      title: 'Powerful Scoring Engine',
      tier: 'Basic',
      subtitle: ``,
      description: `It's difficult to analyze dozens (or hundreds) of evaluations' results. If enabled, this can help you identify general trends in evaluation results and quickly undrestand overall evaluation sentiment. If you don't want to have any form of score- Don't sweat it. We let you disable it if you want.`,
    },
    {
      title: 'Automated Email Reminders',
      tier: 'Basic',
      subtitle: ``,
      description: `We get it- Evaluations are hard to remember. Our advanced customization engine allows you to automatically email employees before evaluations are due, notify managers when evaluations are completed, and remind employees whose evaluations are overdue. Let us take that off your plate- You've got better things to do.`,
    },
    {
      title: 'Peer Evaluations',
      tier: 'Pro',
      subtitle: ``,
      description: `Gather feedback from your employees' peers and gain a greater understanding how they work.`,
    },
    {
      title: 'Pulse Evaluations',
      tier: 'Pro',
      subtitle: ``,
      description: `Check your organization's overall health by engaging your employees with frequent, short pulse evaluations regarding employee happiness, engagement, and growth.`,
    },
    {
      title: 'Employee/Team/Org Goals',
      tier: 'Pro',
      subtitle: ``,
      description: `Help direct employee growth through setting actionable goals - Individually, as a Team Lead, or across the entire organization.`,
    },
    {
      title: 'Premium Template Library',
      tier: 'Pro',
      subtitle: ``,
      description: `Access professionally built evaluations that are fine tuned to help you gain valuable insights on your employees' performance`,
    },
    {
      title: 'Advanced Customization',
      tier: 'Pro',
      subtitle: ``,
      description: `Customize scoring metrics, emails, language, and more to help Express Evaluations fit your company's unique culture.`,
    },
  ]

  const togglePaymentType = (event) => {
    setPaymentAnnual(!paymentAnnual)
  }

  return (
    <Container maxWidth="md" component="main">
      {/* Hero unit */}
      <Container maxWidth="xs" component="main" className={classes.heroContent} id="pricing">
        <Typography component="h2" variant="h2" align="center" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="subtitle1" align="center" component="p">
          Find the right plan that works for your organization
        </Typography>
        <div className={classes.employeeCountWrapper}>
          <Typography variant="h4" align="center">
            # of Employees:
          </Typography>
          <Typography variant="h2" align="center">
            {priceInput[employeeCount]}
          </Typography>
          <Slider
            aria-labelledby="employee-count-slider"
            min={0}
            max={6}
            value={employeeCount || 0}
            onChange={handleSliderChange}
          />
        </div>
        <div className={classes.switchWrapper}>
          <Typography className={classes.switchLabel} variant={!paymentAnnual ? 'h5' : 'subtitle2'}>
            Monthly
          </Typography>
          <Switch checked={paymentAnnual} onChange={(e) => togglePaymentType(e)} name="paymentType" color="primary" />
          <Typography className={classes.switchLabel} variant={paymentAnnual ? 'h5' : 'subtitle2'}>
            Annually
          </Typography>
        </div>
      </Container>
      {/* End hero unit */}
      <Paper>
        <Grid container>
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={6} className={classes.priceCard}>
              <div className={classes.titleWrapper}>
                <Typography variant="h2" align="center">
                  {tier.title}
                </Typography>
                <Typography variant="subtitle2" align="center">
                  {tier.subheader}
                </Typography>
              </div>
              <div className={classes.cardPricing}>
                {paymentAnnual ? (
                  <>
                    <Typography variant="h3" color="textSecondary" align="center">
                      {employeeCount < 6 ? `$${tier.price}` : `Let's Talk`}
                    </Typography>
                    <Typography variant="h5" color="textSecondary" align="center">
                      {employeeCount < 6 && `/year`}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h3" color="textSecondary" align="center">
                      {employeeCount < 6 ? `$${tier.price}` : `Let's Talk`}
                    </Typography>
                    <Typography variant="h5" color="textSecondary" align="center">
                      {employeeCount < 6 && `/month`}
                    </Typography>
                  </>
                )}
              </div>
              <Typography color="textSecondary" align="center" className={classes.priceSubtitle}>
                {employeeCount < 6 && tier.priceSubtitle}
              </Typography>
              <Button
                fullWidth
                variant={tier.buttonVariant}
                color="secondary"
                className={classes.actionButton}
                component={RouterLink}
                to={tier.ctaLink}
                disabled={tier.buttonDisabled}>
                {tier.buttonText}
              </Button>

              <Divider />
              <List dense={true}>
                {features.map((feature, index) => (
                  <Tooltip title={<Typography className={classes.tooltip}>{feature.description}</Typography>} arrow>
                    <ListItem key={`${tier.title}-feature-${index}`} className={`${tier.title}-feature-${index}`}>
                      <ListItemIcon
                        className={clsx({ [classes.included]: tier.title === feature.tier || tier.title === 'Pro' })}
                        key={`${tier.title}-${feature.title}-indicator`}>
                        {tier.title === feature.tier || tier.title === 'Pro' ? getIcon('done') : getIcon('clear')}
                      </ListItemIcon>
                      <ListItemText
                        key={`${tier.title}-${feature.title}-text`}
                        primary={feature.title}
                        secondary={feature.subtitle}
                        className={classes.pricingFeature}
                      />
                    </ListItem>
                  </Tooltip>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  )
}
