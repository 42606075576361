import actionTypes from 'redux/actionTypes'
import { FormatDate } from 'utils/DateTime'

const initialState = {
  isValid: false,
  values: {
    id: 0,
    name: '',
    description: '',
    metrics: [],
    type: 'person',
    assignedPerson: {},
    isLoading: true,
    isEdit: false,
    oneOff: false,
    isPersonal: true,
    dueDate: FormatDate(Date.now()),
    createdInEvaluation: false,
    evaluationId: '',
  },
  touched: {},
  errors: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.initializeGoalFormSucceeded:
      return Object.assign({}, state, {
        values: action.values,
        isEdit: false,
        isLoading: false,
      })
    case actionTypes.initializeGoalEditFormSucceeded:
      return Object.assign({}, state, {
        values: action.values,
        isEdit: true,
        isLoading: false,
      })
    case actionTypes.resetGoalForm:
      return Object.assign({}, state, {
        ...initialState,
      })
    case actionTypes.resetReviewCycle:
      return Object.assign({}, state, {
        reviewCycle: {},
      })
    case actionTypes.updateGoalFormSucceeded:
      return Object.assign({}, state, {
        ...action.form,
      })
    case actionTypes.validateGoalFormStarted:
      return Object.assign({}, state, {
        errors: [],
      })
    case actionTypes.validateGoalFormSucceeded:
      return Object.assign({}, state, {
        isValid: action.isValid,
        errors: action.errors,
      })
    case actionTypes.startSavingGoalForm:
      return Object.assign({}, state, {
        isSaving: true,
      })
    case actionTypes.endSavingGoalForm:
      return Object.assign({}, state, {
        isSaving: false,
      })
    case actionTypes.startEvaluationGoalCreation:
      return Object.assign({}, state, {
        values: {
          ...state.values,
          isPersonal: false,
          assignedPerson: action.person,
          evaluationId: action.evaluationId,
          createdInEvaluation: true,
        },
      })
    default:
      return state
  }
}
