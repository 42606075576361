import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Container } from '@material-ui/core'
import ActiveEvaluationTemplatesTable from './components/ActiveEvaluationTemplatesTable'
import TabView from 'layouts/Main/components/TabView/TabView'
import Toolbar from '../../layouts/Main/components/Toolbar/Toolbar'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ArchivedTemplatesTable from './components/ArchivedTemplatesTable'
import { getIcon } from 'utils/GetIcon'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))

const EvaluationTemplates = () => {
  const classes = useStyles()

  const tabs = []
  tabs.push({
    label: 'Evaluation Templates',
    view: <ActiveEvaluationTemplatesTable />,
    roles: ['org_admin'],
    route: '/templates',
  })
  tabs.push({
    label: 'Archived Templates',
    view: <ArchivedTemplatesTable />,
    roles: ['org_admin'],
    route: '/templates/archive',
  })

  const buttons = [
    <Button component={RouterLink} to={'/evaluations/all'} variant="outlined" startIcon={getIcon('back')}>
      Back to Evaluations
    </Button>,
    <Button color="primary" component={RouterLink} to={'/evaluation/template/build'} variant="contained">
      Create New Evaluation Template
    </Button>,
  ]

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Templates | Express Evaluations</title>
      </Helmet>
      <Toolbar buttons={buttons} title="Evaluation Templates" />
      <Container>
        <TabView tabs={tabs} />
      </Container>
    </div>
  )
}

export default EvaluationTemplates
