import React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import { getGoals } from 'api'
import GoalTable from '../GoalTable'

const EmployeeGoals = () => {
  const { appMetadata } = useAuth0()

  return (
    <GoalTable
      fetchData={async (isComplete = false, limit = 1000) => getGoals(appMetadata.personId, limit, isComplete)}
      type="personal"
    />
  )
}

export default EmployeeGoals
