import React, { useState } from 'react'
import { Button, FormGroup, Paper, FormControlLabel, Switch, Grid, Typography, ButtonGroup } from '@material-ui/core'
import { useEvaluationResultsNavStyles } from 'styles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { red } from '@material-ui/core/colors'
import { getIcon } from 'utils/GetIcon'

const TableFilterBar = (props) => {
  const { resetFunction, primaryFilters, advancedFilters, hidePaper, groupByItems } = props
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const classes = useEvaluationResultsNavStyles()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const urlQuery = useQuery()

  return (
    <Paper elevation={hidePaper ? 0 : 1} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="h5">Filters</Typography>
        </Grid>
        {advancedFilters && (
          <Grid item>
            <Button
              variant={showAdvancedFilters ? 'outlined' : 'contained'}
              color="primary"
              size="small"
              startIcon={showAdvancedFilters ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
              onClick={() => setShowAdvancedFilters((saf) => !saf)}>
              Advanced Filters
            </Button>
          </Grid>
        )}
        {resetFunction && (
          <Grid item>
            <Button
              variant={'outlined'}
              color="primary"
              size="small"
              startIcon={<RotateLeftIcon />}
              onClick={resetFunction}>
              Reset Filters
            </Button>
          </Grid>
        )}
        {primaryFilters &&
          primaryFilters.map(
            (filterGroup, index) =>
              !filterGroup.hidden && (
                <Grid item xs={12} key={`filter_item_primary_filters_${index}_${filterGroup.title}`}>
                  <FormGroup row>
                    <span className={classes.resultsNavLabel}>{filterGroup.title}</span>
                    {filterGroup.items &&
                      filterGroup.items.map(
                        (filterItem, index) =>
                          !filterItem.hidden && (
                            <FormControlLabel
                              key={`filter_item_primary_filters_${index}_${filterItem.label}`}
                              className={classes.optionToggle}
                              control={
                                <Switch checked={filterItem.value} color="primary" onChange={filterItem.onChange} />
                              }
                              label={filterItem.label}
                            />
                          ),
                      )}
                  </FormGroup>
                </Grid>
              ),
          )}
        {showAdvancedFilters &&
          advancedFilters &&
          advancedFilters.map(
            (filterGroup, index) =>
              !filterGroup.hidden && (
                <Grid item xs={12}>
                  <FormGroup row>
                    <span className={classes.resultsNavLabel}>{filterGroup.title}</span>
                    {filterGroup.items &&
                      filterGroup.items.map(
                        (filterItem) =>
                          filterItem.hidden && (
                            <FormControlLabel
                              className={classes.optionToggle}
                              control={
                                <Switch checked={filterItem.value} color="primary" onChange={filterItem.onChange} />
                              }
                              label="Comments"
                            />
                          ),
                      )}
                  </FormGroup>
                </Grid>
              ),
          )}
        {groupByItems && groupByItems.length > 0 && (
          <Grid item xs={12}>
            <span style={{ marginRight: 8, display: 'block-inline', fontWeight: 700 }}> Group By </span>
            <ButtonGroup>
              {groupByItems.map((item) => (
                <Button
                  component={RouterLink}
                  to={{
                    pathname: '/evaluations/all',
                    search: `?groupBy=${item.field}`,
                  }}
                  variant={urlQuery?.get('groupBy') === item.field ? 'contained' : 'outlined'}
                  startIcon={item.icon}
                  color="secondary">
                  {item.label}
                </Button>
              ))}
              <Button
                component={RouterLink}
                to="/evaluations/all"
                style={{ borderColor: red[400], color: red[400] }}
                size="small"
                variant="outlined"
                endIcon={getIcon('clear')}>
                Clear Groupings
              </Button>
            </ButtonGroup>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default TableFilterBar

TableFilterBar.propTypes = {
  primaryFilters: PropTypes.array.isRequired,
  resetFunction: PropTypes.func,
}
