import { useAuth0 } from 'react-auth0-spa'
import { useHistory } from 'react-router-dom'

export const Check = (accessFeature) => {
  const { appMetadata } = useAuth0()
  const history = useHistory()

  if (!appMetadata[accessFeature]) {
    history.push('/feature-not-enabled')
  }

  if (appMetadata[accessFeature]) {
    return true
  }

  history.push('/feature-not-enabled')
}

const FeatureCheck = (props) => (Check(props.accessFeature) ? props.yes() : props.no())

FeatureCheck.defaultProps = {
  yes: () => null,
  no: () => null,
}

export default FeatureCheck
