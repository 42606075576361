import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { OptionToggle } from '../index'
import { useSelector } from 'react-redux'
import { getIn } from 'immutable'

export default function QuestionOptions(props) {
  const {
    isRequired,
    setIsRequired,
    showDescription,
    setShowDescription,
    allowMultiple,
    toggleAllowMultiple,
    questionType,
    availableToEdit,
  } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const isPulse = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'isPulseTemplate']))
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const options = [
    <OptionToggle
      falseLabel={'Optional'}
      option={isRequired}
      setOption={() => setIsRequired(!isRequired)}
      toggleType={'switch'}
      trueLabel={'Required'}
      disabled={!availableToEdit || isPulse}
    />,
    <OptionToggle
      falseLabel={'Show Description'}
      option={showDescription}
      setOption={() => setShowDescription(!showDescription)}
      toggleType={'switch'}
      trueLabel={'Hide Description'}
      disabled={!availableToEdit}
    />,
    ...(questionType === 'MultiChoice' && !isPulse
      ? [
          <OptionToggle
            falseLabel={'Allow Multiple'}
            option={allowMultiple}
            setOption={() => toggleAllowMultiple()}
            toggleType={'checkbox'}
            trueLabel={'Allow Multiple'}
            disabled={!availableToEdit}
          />,
        ]
      : []),
    ...(questionType === 'Text'
      ? [
          <OptionToggle
            falseLabel={'Multiple Lines'}
            option={allowMultiple}
            setOption={() => toggleAllowMultiple()}
            toggleType={'checkbox'}
            trueLabel={'Multiple Lines'}
            disabled={!availableToEdit}
          />,
        ]
      : []),
  ]

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem key={`option-${option.trueLabel}-${index}`}>{option}</MenuItem>
        ))}
      </Menu>
    </div>
  )
}
