import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  featuresWrapper: {
    width: '100%',
    flexGrow: 1,
    margin: 'unset',
  },
  featureWrapper: {
    minHeight: '300px',
    margin: 'unset',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}))

export default function PromoHero() {
  const classes = useStyles()

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.featuresWrapper}
        spacing={4}
        id="features">
        <Grid item xs={12} md={8}>
          <div className={classes.videoContainer}>
            <iframe
              title="YouTube Promo Video"
              src="https://www.youtube.com/embed/_zoVG_GuXUU?rel=0"
              frameBorder="0"
              allowFullScreen
              className={classes.video}></iframe>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
