import API from '../api'

export const signup = async (request) => {
  return await API.post('auth/signup', request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const changePassword = async (request) => {
  return await API.put('auth/password/change', request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const resetPassword = async (request) => {
  return await API.post('auth/password/reset', request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}
