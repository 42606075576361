import API from '../api'
import fileDownload from 'js-file-download'

export const getKytSurveys = async (request) => {
  return await API.get('pdp/surveys', request).then((response) => {
    return response.data
  })
}

export const getPersonKytSurveys = async (id) => {
  return await API.get(`pdp/surveys/${id}`).then((response) => {
    return response.data
  })
}

export const downloadTalentsReport = async (reportId) => {
  return await API.get(`pdp/reports/download/${reportId}`, {
    responseType: 'blob',
  })
    .then((response) => {
      fileDownload(response.data, `KnowYourTalents_Report.pdf`)
    })
    .catch((error) => {
      return error.response
    })
}

export const sendSurveyInvitation = async (request) => {
  return await API.post('pdp/invitation', request).then((response) => {
    return response
  })
}
