import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  ListItemIcon,
  CircularProgress,
} from '@material-ui/core'
import { blue, green } from '@material-ui/core/colors'
import { addCommentToGoal, getGoalActivityLog } from 'api'
import SendIcon from '@material-ui/icons/Send'
import SaveModal from 'components/Modals/SaveModal'
import PropTypes from 'prop-types'
import { FormatDate } from 'utils/DateTime/FormatDate'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import AddBoxIcon from '@material-ui/icons/AddBox'
import EditIcon from '@material-ui/icons/Edit'
import { getIcon } from 'utils/GetIcon'
import { addUnsavedCommentsGoalId, removeUnsavedCommentsGoalId } from 'redux/actions/evaluationActions'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'immutable'
import { useAuth0 } from 'react-auth0-spa'

export const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    backgroundColor: blue[500],
  },
  measureAvatar: {
    backgroundColor: green[500],
  },
  goalCard: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  measureCard: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(6),
  },
  saveCommentBtn: {
    marginTop: theme.spacing(1),
  },
  commentsInput: {
    marginTop: theme.spacing(2),
  },
  commentList: {
    maxHeight: 560,
    overflow: 'auto',
  },
  divider: {},
}))

const Comments = (props) => {
  const classes = useStyles()
  const [comments, setComments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [newComment, setNewComment] = useState()
  const [saveModalStatus, setSaveModalStatus] = useState(false)
  const dispatch = useDispatch()
  const unsavedCommentGoalIds = useSelector((state) => getIn(state, ['evaluation', 'unsavedCommentGoalIds']))
  const { goalId, filterTypes } = props
  const { appMetadata } = useAuth0()

  const handleNewCommentChange = (e) => {
    if (!unsavedCommentGoalIds.includes(goalId)) {
      dispatch(addUnsavedCommentsGoalId(goalId))
    }

    if (e.target.value.length === 0) {
      dispatch(removeUnsavedCommentsGoalId(goalId))
    }
    setNewComment(e.target.value)
  }

  const fetchActivityLog = useCallback(() => {
    getGoalActivityLog(goalId, filterTypes || []).then((response) => {
      if (response.status === 200) {
        setComments(response.data)
        setIsLoading(false)
      }
    })
  }, [goalId, filterTypes])

  const saveNewComment = () => {
    const saveComment = {
      comment: newComment,
      authorId: appMetadata.personId,
      goalId: goalId,
    }

    addCommentToGoal(goalId, saveComment).then(() => {
      dispatch(removeUnsavedCommentsGoalId(goalId))
      setSaveModalStatus(false)
      setNewComment('')
      fetchActivityLog()
    })
  }

  const openDialogModal = () => {
    setSaveModalStatus(true)
  }

  useEffect(() => {
    fetchActivityLog()
  }, [fetchActivityLog])

  return (
    <React.Fragment>
      <List className={classes.commentList} dense={true}>
        {isLoading && <CircularProgress />}
        {comments.map((comment, index) => (
          <ListItem key={`${index}_comment`}>
            <ListItemIcon>
              {comment.type === 'Updated' && <EditIcon />}
              {comment.type === 'Created' && <AddBoxIcon />}
              {comment.type === 'Comment' && <FormatQuoteIcon />}
              {comment.type === 'Completed' && getIcon('complete')}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontSize: 13 }}>
                  <span style={{ fontWeight: '700' }}>{comment.comment}</span> by {comment.authorName} on{' '}
                  {FormatDate(comment.lastUpdated)}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Grid alignContent={'center'} container direction="row">
        <Grid item xs={12}>
          <TextField
            className={classes.commentsInput}
            fullWidth
            id="outlined-multiline-flexible"
            margin="dense"
            multiline
            rows={4}
            onChange={handleNewCommentChange}
            rowsMax={6}
            value={newComment}
            variant="outlined"
            label={'Add comment to activity log'}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<SendIcon />}
            className={classes.saveCommentBtn}
            color="primary"
            onClick={openDialogModal}
            variant="contained">
            Save Comment
          </Button>
        </Grid>
      </Grid>
      <SaveModal
        description="Are you sure you want to save this comment?"
        negativeButtonLabel="Close"
        positiveButtonFunction={saveNewComment}
        positiveButtonLabel="Save"
        saveModalStatus={saveModalStatus}
        setSaveModalStatus={setSaveModalStatus}
        title="Save Comment"
      />
    </React.Fragment>
  )
}

Comments.propTypes = {
  comments: PropTypes.array,
  employeeId: PropTypes.number,
  fetchGoals: PropTypes.func,
  goalId: PropTypes.number,
}
export default Comments
