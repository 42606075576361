import linkTokenApi from 'linkTokenApi/linkTokenApi'

export const getSurveyById = async (id, token) => {
  linkTokenApi.defaults.headers = { Authorization: `Bearer ${token}` }
  return await linkTokenApi
    .get(`pulse/${id}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const submitSurvey = async (id, data, token) => {
  linkTokenApi.defaults.headers = { Authorization: `Bearer ${token}` }
  return await linkTokenApi
    .post(`pulse/${id}/submit`, data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.resposne
    })
}
