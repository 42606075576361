import React from 'react'
import { getTeamGoals } from 'api'
import GoalTable from '../GoalTable'

const TeamMemberGoals = () => {
  return (
    <GoalTable fetchData={async (isComplete = false, limit = 1000) => getTeamGoals(limit, isComplete)} type="team" />
  )
}

export default TeamMemberGoals
