import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, CardContent, Card, Typography, Container } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import Toolbar from '../../layouts/Main/components/Toolbar/Toolbar'
import { SettingsNav } from './components'

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    marginBottom: theme.spacing(2),
  },
}))

const Settings = () => {
  const classes = useStyles()
  const [currentSettingsPage, setCurrentSettingsPage] = useState()

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | Express Evaluations</title>
      </Helmet>
      <Toolbar className={classes.toolbar} title="Setting" />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Card>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Settings
                </Typography>
                <SettingsNav setCurrentSettingsPage={setCurrentSettingsPage} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8} md={9} lg={9} xl={10}>
            {currentSettingsPage}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Settings
