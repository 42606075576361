import { makeStyles } from '@material-ui/styles'

export const useGoalFormStyles = makeStyles((theme) => ({
  paperRoot: {},
  root: {
    padding: theme.spacing(5),
    maxWidth: '500px',
  },
  addMetricButton: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  typeHeading: {
    marginTop: theme.spacing(2),
  },
  addBox: {
    backgroundColor: '#f4f6f8',
    borderRadius: 4,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  metricCloseButton: {
    marginLeft: theme.spacing(1),
  },
  measureAvatar: {
    fontSize: '14px',
    backgroundColor: '#1B1A3B',
  },
}))
