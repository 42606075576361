import { makeStyles } from '@material-ui/styles'

export const useEvaluationResultsNavStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  resultsNavLabel: {
    lineHeight: '38px',
    marginRight: theme.spacing(1),
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    textAlign: 'right',
  },
  tooltipTitle: {
    color: 'white',
  },
}))
