import React from 'react'
import { InputAdornment, TextField, Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

export const ScoreInput = (props) => {
  return (
    <TextField
      appearance="texField"
      disabled={props.disabled}
      InputLabelProps={{
        shrink: !props.disabled && true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title={
                props.tooltip ||
                'Score can be anywhere from 0 to 10. If you want this questions answer to have a higher weight, use the weight above for this question as a multiplier for each potential response.'
              }>
              <InfoOutlinedIcon color="disabled" fontSize="small" />
            </Tooltip>
          </InputAdornment>
        ),
        inputProps: { min: 0, max: 10 },
      }}
      key={`score-${props.index}`}
      label={'Score'}
      margin="dense"
      onChange={(e) => props.handleScoreChange(props.index, e)}
      required={!props.disabled}
      style={{
        width: '160px',
        marginLeft: '16px',
      }}
      type="number"
      value={props.value}
      variant="outlined"
    />
  )
}
