import React from 'react'
import MaterialTable from 'material-table'
import { getFeedbacks } from 'api'
import { useAuth0 } from 'react-auth0-spa'
import { getTypeChip } from 'utils/evaluations'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import EvaluationStatusButton from 'components/Buttons'

const MyEvaluationsTable = (props) => {
  const { pageSize, pageSizeOptions } = props
  const { appMetadata } = useAuth0()

  const headCells = [
    {
      field: 'title',
      title: 'Evaluation',
      render: (row) => {
        return <Typography variant="h6">{row.title}</Typography>
      },
    },
    {
      field: 'reviewee.fullName',
      title: 'Evaluated Employee',
    },
    {
      field: 'type',
      title: 'Type',
      render: (row) => getTypeChip(row.type),
    },
    {
      field: 'dueDate',
      title: 'Due Date',
      type: 'date',
    },
    {
      title: '',
      render: (row) => {
        return (
          row.action && (
            <EvaluationStatusButton
              disabled={row.action.disabled}
              to={row.action.url}
              status={row.status}
              text={row.action.title}
            />
          )
        )
      },
    },
  ]

  return (
    <MaterialTable
      columns={headCells}
      data={(query) =>
        new Promise((resolve, reject) => {
          getFeedbacks({
            page: query.page,
            pageSize: query.pageSize,
            searchQuery: query.search,
            sortBy: (query.orderBy && query.orderBy.field) || '',
            sortDirection: query.orderDirection || '',
            excludedStatuses: ['waitingForReview', 'reviewed', 'waitingForSignature', 'signed', 'finalized'],
            includedReviewerIds: [appMetadata.personId],
          }).then((response) => {
            if (response.status === 200) {
              resolve({
                data: response.data.data,
                page: query.page,
                totalCount: response.data.totalCount,
              })
            } else {
              reject()
            }
          })
        })
      }
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        search: false,
        paging: true,
        toolbar: false,
        selection: false,
        showTitle: false,
        pageSize: pageSize,
        pageSizeOptions: pageSizeOptions,
        padding: 'dense',
        headerStyle: { position: 'sticky', top: 0, height: 0 },
        maxBodyHeight: '600px',
      }}
      title="My Evaluations"
    />
  )
}

export default MyEvaluationsTable

MyEvaluationsTable.propTypes = {
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
}

MyEvaluationsTable.defaultProps = {
  pageSize: 25,
  pageSizeOptions: [10, 25, 50],
}
