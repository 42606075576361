import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Toolbar from '../../layouts/Main/components/Toolbar/Toolbar'
import { Helmet } from 'react-helmet'
import EmployeeGoals from './components/EmployeeGoals'
import TeamMemberGoals from './components/TeamMemberGoals'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Container } from '@material-ui/core'
import { roles } from 'utils/roles'
import OrganizationGoals from './components/OrganizationGoals'
import { TabView } from 'layouts/Main/components'

export const useStyles = makeStyles((theme) => ({
  root: {},
  goalButtons: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  goalList: {
    padding: theme.spacing(2),
  },
  goalCategory: {
    color: theme.palette.secondary,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    fontWeight: 'bold',
  },
}))

const Goals = () => {
  const classes = useStyles()

  const tabs = [
    {
      label: 'My Goals',
      view: <EmployeeGoals />,
      roles: [roles.Member, roles.TeamLead, roles.Admin],
      route: '/goals',
    },
    {
      label: `Team Member's Goals`,
      view: <TeamMemberGoals />,
      roles: [roles.TeamLead],
      route: '/goals/team',
    },
    {
      label: 'All Goals',
      view: <OrganizationGoals />,
      roles: [roles.Admin],
      route: '/goals/organization',
    },
  ]

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goals | Express Evaluations</title>
      </Helmet>
      <Toolbar
        className={classes.toolbar}
        buttons={[
          <Button component={RouterLink} to="/goals/create" variant="contained" color="primary">
            Create a New Goal
          </Button>,
        ]}
        title="Goals"
      />
      <Container>
        <TabView tabs={tabs} />
      </Container>
    </div>
  )
}

export default Goals
