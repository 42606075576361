import { makeStyles } from '@material-ui/styles'

export const useEvaluationSectionStyle = makeStyles((theme) => ({
  root: {},
  actionButtons: {
    paddingBottom: theme.spacing(3),
  },
  sectionPoints: {
    color: theme.palette.text.grey,
    fontSize: '14px',
    fontWeight: '400px',
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    padding: theme.spacing(4),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  question: {
    padding: theme.spacing(3, 1),
  },
}))
