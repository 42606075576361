export const getScheduleFormTitle = (isEdit, isPulse, title) => {
  let returnTitle = ''
  if (isEdit) {
    returnTitle = `Edit ${isPulse ? "Pulse" : "Evaluation"} Schedule - ${title}`
  } else {
    if (isPulse) {
      returnTitle = `New Pulse Schedule`
    } else {
      returnTitle = `New Evaluation Schedule`
    }
  }
  return returnTitle
}

export const recurrenceRepeatTypes = (isPulse) => {
  if (isPulse) {
    return [
      { value: 'week', title: 'Weekly' },
      { value: 'Biweekly', title: 'Biweekly' },
      { value: 'month', title: 'Monthly' },
    ]
  } else {
    return [
      { value: 'week', title: 'week' },
      { value: 'month', title: 'month' },
      { value: 'year', title: 'year' },
    ]
  }
}
