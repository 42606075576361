import { getEvaluations } from 'api'
import CombinedEvaluationsTable from 'components/Tables'
import React from 'react'
import { Check } from 'utils/PermissionCheck'
import { roles } from 'utils/roles'

const PastEvaluationsTable = () => {
  return (
    <CombinedEvaluationsTable
      fetchData={getEvaluations}
      hideFilters={true}
      disableRowClick={true}
      title="Completed Evaluations"
      usePdfButton={true}
      hideDetailPanel={!Check([roles.Admin])}
      showAdminManagerActions={false}
      onlyPastEvaluations
    />
  )
}

export default PastEvaluationsTable
