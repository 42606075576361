import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar, Hidden, IconButton, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import hexToRGB from 'helpers/hexToRGB'
import { SystemToolsMenu } from 'components/SystemTools'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'immutable'
import { toggleShowSystemTools } from 'redux/actions/systemToolsActions'
import PermissionCheck from 'utils/PermissionCheck'
import { roles } from 'utils/roles'
import AccountMenu from './components/AccountMenu'
import { useAuth0 } from 'react-auth0-spa'
import { getCustomLogo } from 'utils/GetCustomLogo'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background:
      'linear-gradient(130deg,' +
      hexToRGB(theme.palette.primary.dark) +
      '-10%,' +
      hexToRGB(theme.palette.secondary.main) +
      ' 110%)',
  },
  logoAndTitle: {
    display: 'flex',
  },
  logo: {
    height: '30px',
  },
  customLogo: {
    height: '40px',
    marginBottom: '-6px',
  },
  customLogoText: {
    color: 'white',
    opacity: '.9',
  },
  title: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
    width: '40px',
    height: '20px',
    fontSize: '16px',
    lineHeight: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
  accountOptionsButton: {
    marginLeft: theme.spacing(1),
  },
  systemToolsIcon: {
    color: 'white',
  },
}))

const Topbar = (props) => {
  const { appMetadata } = useAuth0()
  const customLogo = getCustomLogo(appMetadata.organization)
  const { className, onSidebarOpen, ...rest } = props

  const classes = useStyles()

  const dispatch = useDispatch()
  const showSystemTools = useSelector((state) => getIn(state, ['systemTools', 'showSystemTools']))

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/dashboard">
          {customLogo ?
          <div>
            <img alt={`${appMetadata.organization} Logo`} src={customLogo} className={classes.customLogo} />
            <Typography variant="body2" align="center" className={classes.customLogoText}>Express Evaluations</Typography>
          </div>
          :
          <div className={classes.logoAndTitle}>
            <img alt="Express Evaluations Logo" src="/Express-Evaluations-Logo-Thick.svg" className={classes.logo} />
            <Typography variant="h2" className={classes.title}>
              Express Evaluations
            </Typography>
          </div>
          }
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <AccountMenu />
          <PermissionCheck
            key={`sys_tools_bar`}
            roles={roles.SysAdmin}
            yes={() => (
              <IconButton onClick={() => dispatch(toggleShowSystemTools())}>
                <SettingsApplicationsIcon className={classes.systemToolsIcon} />
              </IconButton>
            )}></PermissionCheck>
        </Hidden>
        <Hidden mdUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      {showSystemTools && (
        <PermissionCheck key={`sys_tools_bar`} roles={roles.SysAdmin} yes={() => <SystemToolsMenu />}></PermissionCheck>
      )}
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar
