import React, { createRef } from 'react'
import MaterialTable from 'material-table'
import { DeleteButton } from 'components/Buttons'
import { getSchedules, deleteSchedule } from 'api'
import { useDispatch } from 'react-redux'
import { enqueueSuccessNotification, enqueueErrorNotification } from 'redux/actions/notificationsActions'
import { Button, ButtonGroup } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import ReviewCyclesDetailPanel from './ReviewCyclesDetailPanel'
import { getIcon } from 'utils/GetIcon'

const SchedulesTable = (props) => {
  const { isPulse } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const tableRef = createRef()
  const fetchSchedules = async (tableRequest) => {
    return getSchedules(tableRequest)
  }

  const handleScheduleDelete = (id, name) => {
    deleteSchedule(id).then((response) => {
      if (response.status === 204) {
        dispatch(enqueueSuccessNotification(`Successfully deleted ${name}`))
        tableRef.current.onQueryChange()
      } else {
        dispatch(enqueueErrorNotification(`Encountered a server error. ${name} was not deleted`))
      }
    })
  }

  const actionBtn = (row) => {
    return (
      <ButtonGroup size="small" variant="text" color="primary" aria-label="text primary button group">
        {isPulse && <Button onClick={() => history.push(`/pulse/results/${row.id}`)}>Results</Button>}
        <Button onClick={() => history.push(`schedules/edit/${row.id}`)}>Edit</Button>
        <DeleteButton
          deleteFunction={() => handleScheduleDelete(row.id, row.name)}
          name={row.name}
          buttonType="text"
          description={`${row.revieweesCount} ${isPulse ? 'survey(s)' : 'evaluation(s)'} will be deleted.`}>
          Delete
        </DeleteButton>
      </ButtonGroup>
    )
  }

  const headCells = [
    {
      field: 'name',
      title: 'Name',
    },
    { field: 'template.title', title: 'Template' },
    {
      field: 'reviewees',
      title: isPulse ? 'Assignees' : 'Reviewees',
      render: (row) => (
        <Link
          to={{
            pathname: '/people',
            search: `?schedules=${row.id}`,
          }}>
          <Button disabled={row.revieweesCount === 0 || !row.revieweesCount} size="small" endIcon={getIcon('link')} variant="text">
            {row.revieweesCount} employees assigned
          </Button>
        </Link>
      ),
    },
    // TODO: In the future, create component to display what Tags the evaluation has assigned to it
    // {
    //   field: 'tags',
    //   title: 'Tags',
    //   render: (row) => (
    //     <Link
    //       to={{
    //         pathname: '/tags',
    //         search: `?schedules=${row.id}`,
    //       }}>
    //       <Button disabled={row.tags.length === 0} size="small" endIcon={getIcon('link')} variant="text">
    //         {row.tags.length} tags
    //       </Button>
    //     </Link>
    //   ),
    // },
    {
      field: 'actions',
      title: 'Actions',
      render: (row) => actionBtn(row),
    },
  ]

  return (
    <MaterialTable
      tableRef={tableRef}
      columns={headCells}
      data={(query) =>
        new Promise((resolve, reject) => {
          console.log(query)
          fetchSchedules({
            page: query.page,
            pageSize: query.pageSize,
            searchQuery: query.search,
            orderBy: (query.orderBy && query.orderBy.field) || '',
            orderDirection: query.orderDirection || '',
            isPulse: isPulse,
          }).then((response) => {
            if (response.status === 200) {
              resolve({
                data: response.data.data,
                page: query.page,
                totalCount: response.data.totalCount,
              })
            } else {
              reject()
            }
          })
        })
      }
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        exportButton: false,
        selection: false,
        showTitle: false,
        pageSize: 100,
        pageSizeOptions: [10, 25, 50, 100],
        padding: 'dense',
        debounceInterval: 600,
      }}
      detailPanel={
        !isPulse
          ? false
          : (row) => {
              return (
                <ReviewCyclesDetailPanel
                  reviewCycles={row.reviewCycles}
                  dateType={row.dateType}
                  startDateOffset={row.startDateOffset}
                  endDateOffset={row.endDateOffset}
                />
              )
            }
      }
      title="Organization Scheduled Evaluations"
    />
  )
}

export default SchedulesTable
