import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, List, ListItem, ListItemIcon, ListItemText, TextField } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { SaveCircularStatusButton } from 'components/Buttons'
import { changePassword } from 'api'
import { InlineError } from 'helpers'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: 250,
    left: '36%',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 0,
    borderRadius: 5,
  },
  form: {
    padding: theme.spacing(2),
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}))

const PasswordReset = (props) => {
  const { display } = props
  const classes = useStyles()

  const [open, setOpen] = React.useState(display)
  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState({
    length: false,
    characters: false,
    special: false,
  })
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState({
    confirmPassword: 'Your passwords do not match',
  })
  const [grizzlyErrors, setGrizzlyErrors] = useState([])

  const handleClose = () => {
    setOpen(false)
  }

  const validConditions = [
    {
      checkValue: validPassword.length,
      displayText: 'Longer than 8 characters',
    },
    {
      checkValue: validPassword.characters,
      displayText: 'Contains a uppercase and lowercase character and a number',
    },
    {
      checkValue: validPassword.special,
      displayText: 'Contains one special character (i.e. !@#$%^&*)',
    },
  ]
  useEffect(() => {
    setError({})
    const lengthRegEx = new RegExp('^(?=.{8,})')
    const charRegEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])')
    const specialRegEx = new RegExp('^(?=.*[!@#$%^&*])')

    setValidPassword({
      length: lengthRegEx.test(password),
      characters: charRegEx.test(password),
      special: specialRegEx.test(password),
    })
  }, [password])

  useEffect(() => {
    setGrizzlyErrors([])
    setError({})
    if (password !== confirmPassword) {
      setError({ confirmPassword: 'Your passwords do not match' })
    }
  }, [confirmPassword, password])

  useEffect(() => {
    setGrizzlyErrors([])
    const showError = !validPassword.length || !validPassword.characters || !validPassword.special
    console.log(showError)
    showError && setError({ password: 'Password must meet conditions' })
  }, [validPassword])

  const submitChangePassword = async () => {
    setGrizzlyErrors([])
    changePassword({
      password: password,
      confirmPassword: confirmPassword,
    }).then((response) => {
      if (response.status === 200) {
        handleClose()
      } else {
        setGrizzlyErrors(response.data.errors)
      }
    })
  }

  const body = (
    <div className={classes.paper}>
      <form className={classes.form}>
        <Typography className={classes.title} variant="h2">
          Update Your Password
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Before continuing, please enter a new password to update your current temporary password
        </Typography>
        <TextField
          className={classes.textField}
          fullWidth
          error={error.password}
          helperText={error.password}
          label="Password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          variant="outlined"
        />
        <InlineError errors={grizzlyErrors} field="Password" />
        <TextField
          className={classes.textField}
          fullWidth
          error={error.confirmPassword}
          helperText={error.confirmPassword}
          label="Confirm Password"
          name="confirmPassword"
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          variant="outlined"
        />
        <InlineError errors={grizzlyErrors} field="ConfirmPassword" />
        <List dense={true}>
          {validConditions.map((value) => (
            <ListItem>
              <ListItemIcon>
                {value.checkValue ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
              </ListItemIcon>
              <ListItemText primary={value.displayText} />
            </ListItem>
          ))}
        </List>
        <SaveCircularStatusButton
          className={classes.signInButton}
          size="large"
          postlabel="Resetting Password"
          prelabel="Reset Password"
          fullWidth
          //   disabled={
          //     !validPassword.length ||
          //     !validPassword.characters ||
          //     !validPassword.special ||
          //     confirmPassword.length === 0 ||
          //     confirmPassword !== password
          //   }
          savefunction={submitChangePassword}
          type="submit"
        />
      </form>
    </div>
  )

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        outline={0}>
        {body}
      </Modal>
    </div>
  )
}

export default PasswordReset
