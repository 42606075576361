import React from 'react'
import PropTypes from 'prop-types'
import Question from '../question'
import { useSelector } from 'react-redux'
import { getIn } from 'immutable'
import { useEvaluationSectionStyle } from 'styles'
import RichTextBlock from 'components/RichText'
import { ModernCard } from 'components/Cards'

const Section = (props) => {
  const { updateResults } = props
  const evaluation = useSelector((state) => state.evaluation)
  const classes = useEvaluationSectionStyle()
  const requiredQuestionsAnswered = useSelector((state) => getIn(state, ['evaluation', 'requiredQuestionsAnswered']))

  return (
    <ModernCard headerTitle={getIn(evaluation, ['currentSection', 'title'])}>
      <RichTextBlock content={getIn(evaluation, ['currentSection', 'description'])} />
      {getIn(evaluation, ['currentSection', 'questions']) &&
        getIn(evaluation, ['currentSection', 'questions']).map((question, index) => (
          <div className={classes.question} key={`eval_question_${index}`}>
            <Question
              config={question.config}
              helpText={question.helpText}
              id={`${question.id}`}
              isActive={question.isActive}
              key={`EvalQuestion-${index}`}
              title={question.title}
              type={question.type}
              updateResults={updateResults}
              weight={question.weight}
              comments={question.comments}
              index={index}
            />
          </div>
        ))}
      <div className={classes.actionButtons}>
        {!requiredQuestionsAnswered && <div className={classes.buttonHelpText}></div>}
      </div>
    </ModernCard>
  )
}

Section.propTypes = {
  isSelf: PropTypes.bool,
  numberOfSections: PropTypes.number,
  section: PropTypes.object,
  submitEvaluation: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  updateResults: PropTypes.func,
}

Section.defaultProps = {
  isSelf: false,
  numberOfSections: 0,
  section: {},
  submitEvaluation: PropTypes.func,
  title: '',
  type: '',
  updateResults: () => {},
}

export default Section
