import React, { createRef } from 'react'
import MaterialTable from 'material-table'
import PeopleDrawer from './PeopleDrawer'
import { DeleteButton, InviteToJoinButton } from 'components/Buttons'
import { deactivatePerson, getAllPeople, getDirectReports, getExtendedReports } from 'api'
import PropTypes from 'prop-types'
import { Typography, ButtonGroup, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { getIcon } from 'utils/GetIcon'
import { getRoleChip } from 'utils/evaluations/chips'
import { useDispatch } from 'react-redux'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { useLocation } from 'react-router'

const EmployeeTable = (props) => {
  const { pageSize, pageSizeOptions, peopleExternallyManaged, personId, title, type, managers } = props
  const dispatch = useDispatch()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const urlQuery = useQuery()

  const handleDeletePerson = (id) => {
    deactivatePerson(id).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('The employee has been succesfully terminated'))
        tableRef.current.onQueryChange()
      } else {
        dispatch(enqueueErrorNotification('The server encountered an error. The employee was unable to be terminated'))
      }
    })
  }

  const tableRef = createRef()

  const getPeople = (...props) => {
    switch (type) {
      case 'all':
        return getAllPeople(...props)
      case 'direct':
        return getDirectReports(personId, ...props)
      case 'extended':
        return getExtendedReports(personId, ...props)
      default:
        return getAllPeople(...props)
    }
  }
  const headCells = [
    {
      field: 'fullName',
      title: 'Name',
      render: (person) => (
        <div>
          <Typography>{person.fullName} </Typography>
          <Typography variant="caption">{person.email}</Typography>
        </div>
      ),
    },
    {
      field: 'isOrgAdmin',
      title: 'Role',
      render: (person) => person.isOrgAdmin && getRoleChip('admin'),
    },
    {
      field: 'employeeId',
      title: 'Employee ID',
    },
    {
      field: 'manager',
      title: 'Manager',
      //TODO: Sorting by manager is broken, we need to fix grizzly
      sorting: false,
      render: (person) => (
        <>
          {person.manager && (
            <Link to={`/profile/${person.manager.id}`}>
              <Typography>
                {person.manager.fullName} {getIcon('link-external')}
              </Typography>
            </Link>
          )}
        </>
      ),
    },
    {
      field: 'hireDate',
      title: 'Hire Date',
      type: 'date',
    },
    {
      field: 'edit',
      title: 'Actions',
      sorting: false,
      render: (person) => (
        <ButtonGroup variant="text">
          <Button component={Link} to={`/profile/${person.id}`}>
            Profile
          </Button>
          <PeopleDrawer
            buttonType="text"
            callback={() => tableRef.current.onQueryChange()}
            person={person}
            type="edit"
          />
          <DeleteButton
            buttonType="text"
            buttonText="Terminate"
            deleteFunction={() => handleDeletePerson(person.id)}
            name={person.fullName}
            disabled={peopleExternallyManaged}
          />
          {person.userSignupComplete || (
            <InviteToJoinButton
              personId={person.id}
              name={person.fullName}
              deletetype="single"
              buttonType="text"
              callback={() => tableRef.current.onQueryChange()}
            />
          )}
        </ButtonGroup>
      ),
    },
  ]

  return (
    <MaterialTable
      tableRef={tableRef}
      columns={headCells}
      data={(query) =>
        new Promise((resolve, reject) => {
          getPeople(
            query.page,
            query.pageSize,
            query.search,
            (query.orderBy && query.orderBy.field) || '',
            query.orderDirection || '',
            managers || false,
            urlQuery?.get('tags')?.split(','),
            urlQuery?.get('schedules')?.split(','),
          ).then((response) => {
            if (response.status === 200) {
              resolve({
                data: response.data.data,
                page: query.page,
                totalCount: response.data.totalCount,
              })
            } else {
              reject("This isn't working")
            }
          })
        })
      }
      options={{
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        selection: false,
        pageSize: pageSize,
        pageSizeOptions: pageSizeOptions,
        cellStyle: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        debounceInterval: 600,
      }}
      title={title}
    />
  )
}

export default EmployeeTable

EmployeeTable.propTypes = {
  callback: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  personId: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.oneOf(['all', 'direct', 'extended']),
}

EmployeeTable.defaultProps = {
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
  title: 'Employees',
  type: 'all',
}
