import React from 'react'
import { Card, CardActions, CardHeader, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    backgroundColor: '#F4F6F8',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& button': {
      width: '100%',
    },
    '& a': {
      width: '100%',
    },
  },

  title: {
    marginBottom: theme.spacing(1),
  },
}))
const SmallWidget = (props) => {
  const { avatar, title, primaryText, subText, primaryTextColor = 'primary', buttons = [] } = props
  const classes = useStyles()

  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Card>
        <CardHeader
          avatar={avatar}
          title={
            <Typography align={!avatar ? 'center' : 'left'} variant="subtitle1">
              {subText}
            </Typography>
          }
          subheader={
            <Typography align={!avatar ? 'center' : 'left'} variant="h4" color={primaryTextColor}>
              {primaryText}
            </Typography>
          }
          disableTypography={true}
        />
        {buttons.length > 0 && <CardActions className={classes.actions}>{buttons.map((button) => button)}</CardActions>}
      </Card>
    </div>
  )
}

export default SmallWidget
