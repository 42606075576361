import React from 'react'
import MaterialTable from 'material-table'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import PropTypes from 'prop-types'

const TemplateTable = (props) => {
  const { templates, isLoading, buttons, title } = props

  const headCells = [
    {
      field: 'title',
      title: 'Template Title',
      render: (row) => (
        <>
          {row.title} {row.isPulse && <GraphicEqIcon />}
        </>
      ),
    },
    {
      field: 'lastUpdated',
      title: 'Last Updated',
      type: 'datetime',
    },
    { field: 'Actions', title: 'Actions', render: (row) => buttons(row) },
  ]

  return (
    <MaterialTable
      columns={headCells}
      data={templates}
      title={title}
      options={{
        emptyRowsWhenPaging: false,
        selection: false,
        actionsColumnIndex: -1,
        pageSize: 25,
        pageSizeOptions: [25, 50, 100],
        cellStyle: {
          paddingTop: 5,
          paddingBottom: 5,
        },
        search: false,
      }}
      isLoading={isLoading}
    />
  )
}

TemplateTable.propTypes = {
  templates: PropTypes.array.isRequired,
  buttons: PropTypes.array.isRequired,
}

export default TemplateTable
