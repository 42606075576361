import { makeStyles } from '@material-ui/styles'

export const useEvaluationStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  gridItemPaper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(16),
    },
    borderRadius: '15px',
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  question: {
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  summary: {
    paddingTop: theme.spacing(2),
  },
  evaluationTitle: {
    textTransform: 'Capitalize',
    marginTop: '2px',
    marginBottom: '2px',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  spacer: {
    flexGrow: 1,
  },
  evaluationHeader: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 'inherit',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    '& h2, h3, h4': {
      color: 'white',
    },
    '& h6': {
      color: theme.palette.text.lightGrey,
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  evaluationHeaderTextWrapper: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  evalActions: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
  },
  evalBody: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  lastUpdateTimestamp: {
    color: 'white',
  },
  progressText: {
    color: '#ffffff',
  },
  gridItem: {
    //paddingTop: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}))
