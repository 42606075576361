import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { FormatDate } from 'utils/DateTime'
import { pastDueEvaluations } from 'api'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  evalTable: {
    width: '100%',
  },
}))

const OverdueEvaluationsTable = () => {
  const [rows, setRows] = useState([])
  const classes = useStyles()

  const headCells = [
    { field: 'evaluationTitle', title: 'Evaluation' },
    { field: 'evaluatedPersonName', title: 'Employee' },
    { field: 'evaluatorName', title: 'Evaluator' },
    {
      field: 'evaluationType',
      title: 'Eval Type',
    },

    {
      field: 'dueDate',
      title: 'Due Date',
      render: (row) => {
        return FormatDate(row.dueDate)
      },
    },
    {
      field: 'daysOverDue',
      title: 'Days Overdue',
    },
  ]

  useEffect(() => {
    pastDueEvaluations().then((response) => {
      setRows(response)
    })
  }, [])

  return (
    <MaterialTable
      // actions={[
      //   (row) => {
      //     if (row.isComplete) {
      //       return {
      //         icon: () => <AssignmentTurnedInIcon />,
      //         disabled: true,
      //         tooltip: 'Completed',
      //       }
      //     } else {
      //       return {
      //         icon: () => <AssignmentIcon />,
      //         disabled: false,
      //         tooltip: 'Complete Evaluation',
      //         onClick: (e, data) => {
      //           history.push(`/evaluation/view/${data.id}`)
      //         },
      //       }
      //     }
      //   },
      // ]}
      className={classes.evalTable}
      columns={headCells}
      data={rows}
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        exportButton: true,
        selection: false,
        showTitle: false,
        pageSize: 25,
        pageSizeOptions: [10, 25, 50, 100],
      }}
      title="Overdue Evaluations"
    />
  )
}

export default OverdueEvaluationsTable
