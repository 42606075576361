import { getIn } from 'immutable'
import { importPeople, validateImportPeople } from 'api'
import csv from 'csv'
import { enqueueErrorNotification } from './notificationsActions'

export const preImportPeopleStarted = () => ({
  type: 'PRE_IMPORT_PEOPLE_STARTED',
})

export const preImportPeopleSucceeded = (data) => ({
  type: 'PRE_IMPORT_PEOPLE_SUCCEEDED',
  data,
})

export const preImportColumnCheck = (columns) => ({
  type: 'PRE_IMPORT_COLUMN_CHECK',
  columns,
})

export const initializeImport = () => ({
  type: 'INITIALIZE_IMPORT_DRAWER',
})

export const changeActiveStep = (step) => ({
  type: 'CHANGE_ACTIVE_IMPORT_STEP',
  step,
})

export const toggleSendImportInvites = () => ({
  type: 'TOGGLE_SEND_IMPORT_INVITES',
})

export const importPeopleSucceeded = (peopleAdded, peopleNotAdded, peopleUpdated, teamsAdded) => ({
  type: 'IMPORT_PEOPLE_SUCCEEDED',
  peopleAdded,
  peopleNotAdded,
  peopleUpdated,
  teamsAdded,
})

export const importPeopleStarted = () => ({
  type: 'IMPORT_PEOPLE_STARTED',
})

export const updateImportStep = (type) => async (dispatch, getState) => {
  const currentStep = getIn(getState(), ['import', 'activeStep'])

  if (type === 'next') {
    if (currentStep === 3) {
      dispatch(importPeopleAndTeams())
    }
    dispatch(changeActiveStep(currentStep + 1))
  } else {
    dispatch(changeActiveStep(currentStep - 1))
  }
}

export const preImportPeople = (files) => async (dispatch, getState) => {
  dispatch(preImportPeopleStarted())
  const reader = new FileReader()
  reader.onload = () => {
    csv.parse(reader.result, { columns: true }, (err, data) => {
      dispatch(preImportColumnCheck(Object.keys(data[0])))
      validateImportPeople({ batchPeople: data }).then((response) => {
        if (response.status === 200) {
          dispatch(preImportPeopleSucceeded(response.data))
        } else {
          dispatch(enqueueErrorNotification(JSON.stringify(response.errors)))
        }
      })
    })
  }
  reader.readAsBinaryString(files[0])
}

export const importPeopleAndTeams = (type) => async (dispatch, getState) => {
  dispatch(importPeopleStarted())
  const people = getIn(getState(), ['import', 'validPeople'])
  importPeople({ people: people }).then((response) => {
    if (response.status === 200) {
      dispatch(
        importPeopleSucceeded(
          response.data.numberOfPeopleAdded,
          response.data.numberOfPeopleNotAdded,
          response.data.numberOfPeopleUpdated,
          response.data.numberOfTeamsCreated,
        ),
      )
    } else {
      dispatch(
        enqueueErrorNotification(
          'The server encountered an error. The import failed to complete, no records were updated or created. ',
        ),
      )
    }
  })
}
