import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Container } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import Toolbar from '../../layouts/Main/components/Toolbar/Toolbar'
import ReportNav from './components/ReportNav'
import ReportsTable from './components/ReportsTable'
import { Hint } from 'components'
const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    marginBottom: theme.spacing(2),
  },
}))

const Reports = () => {
  const classes = useStyles()
  const [currentReport, setCurrentReport] = useState()

  const handleSetCurrentReport = useCallback((value) => {
    setCurrentReport(value)
  }, [])

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reports | Express Evaluations</title>
      </Helmet>

      <Toolbar
        className={classes.toolbar}
        title="Reports"
        buttons={[<ReportNav currentReport={currentReport} setCurrentReport={handleSetCurrentReport} />]}
      />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Hint
              severity={'info'}
              backgroundColor={'#e7f5fe'}
              text={
                'More reports are coming soon! If you have ideas of the types of reports you would like to see, let our team know.'
              }
            />

            {currentReport || <ReportsTable />}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Reports
