import API from 'api/api'

export const getEvaluationParticipants = async (id) => {
  return await API.get(`evaluations/${id}/participants`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const addEvaluationParticipants = async (id, request) => {
  return await API.post(`evaluations/${id}/participants/add`, request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}
