import { combineReducers } from 'redux'
import evaluationReducer from 'redux/reducers/evaluationReducer'
import notificationsReducer from 'redux/reducers/notificationsReducer'
import peopleReducer from 'redux/reducers/peopleReducer'
import templateBuilderReducer from 'redux/reducers/templateBuilderReducer'
import systemToolsReducer from './reducers/systemToolsReducer'
import importReducer from './reducers/importReducer'
import templateReducer from './reducers/templateReducer'
import scheduleFormReducer from './reducers/scheduleFormReducer'
import goalFormReducer from './reducers/goalFormReducer'
import focusViewReducer from './reducers/focusViewReducer'
import modalReducer from './reducers/modalReducer'

const rootReducer = combineReducers({
  evaluation: evaluationReducer,
  goalForm: goalFormReducer,
  import: importReducer,
  notifications: notificationsReducer,
  people: peopleReducer,
  templateBuilder: templateBuilderReducer,
  scheduleForm: scheduleFormReducer,
  systemTools: systemToolsReducer,
  templates: templateReducer,
  focusView: focusViewReducer,
  modal: modalReducer,
})

export default rootReducer
