import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ClearIcon from '@material-ui/icons/Clear'
import DoneIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'
import PeopleIcon from '@material-ui/icons/People'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import ListAltIcon from '@material-ui/icons/ListAlt'
import PaymentIcon from '@material-ui/icons/Payment'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import SettingsIcon from '@material-ui/icons/Settings'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import RepeatIcon from '@material-ui/icons/Repeat'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PersonIcon from '@material-ui/icons/Person'
import BusinessIcon from '@material-ui/icons/Business'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import ScheduleIcon from '@material-ui/icons/Schedule'
import EventIcon from '@material-ui/icons/Event'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import SupervisorIcon from '@material-ui/icons/SupervisorAccount'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import AdjustIcon from '@material-ui/icons/Adjust'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import OfflinePinIcon from '@material-ui/icons/OfflinePin'
import RepeatOneIcon from '@material-ui/icons/RepeatOne'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Icon } from '@material-ui/core'
import { RateReview } from '@material-ui/icons'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import CreateIcon from '@material-ui/icons/Create'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import LinkIcon from '@material-ui/icons/Link'
import MapIcon from '@material-ui/icons/Map'
import AccountTreeIcon from '@material-ui/icons/AccountTree'

export const getIcon = (text, color) => {
  switch (text) {
    case 'add':
      return <AddIcon />
    case 'addTo':
      return <AddToPhotosIcon />
    case 'admin':
      return <Icon fontSize="small" style={{ height: 'auto', width: 'auto' }} className="fa fa-user-cog" />
    case 'arrowDown':
      return <KeyboardArrowDown />
    case 'arrowRight':
      return <KeyboardArrowRight />
    case 'arrowUp':
      return <KeyboardArrowUp />
    case 'back':
      return <ArrowBackIcon />
    case 'complete':
      return <CheckCircleIcon style={{ color: color }} />
    case 'signed':
      return <OfflinePinIcon style={{ color: color }} />
    case 'clear':
      return <ClearIcon />
    case 'create':
      return <CreateIcon />
    case 'date':
      return <EventIcon />
    case 'dashboard':
      return <DashboardIcon />
    case 'delete':
      return <DeleteIcon />
    case 'department':
      return <AccountTreeIcon />
    case 'done':
      return <DoneIcon />
    case 'download':
      return <GetAppIcon />
    case 'dropdown':
      return <ArrowDropDownCircleIcon />
    case 'edit':
      return <EditIcon />
    case 'evaluation':
      return <ListAltIcon />
    case 'evaluationComplete':
      return <AssignmentTurnedInIcon />
    case 'event':
      return <EventIcon />
    case 'finalized':
      return <LibraryAddCheckIcon />
    case 'forward':
      return <ChevronRightIcon />
    case 'goal':
      return <TrackChangesIcon />
    case 'hire-date':
      return <DateRangeIcon />
    case 'incomplete':
      return <RemoveCircleOutlineIcon style={{ color: color }} />
    case 'jobTitle':
      return <AssignmentIndIcon />
    case 'kyt':
      return (
        <img
          alt="Know your talents icon"
          style={{ height: 20, width: 20 }}
          src="https://irp-cdn.multiscreensite.com/c7d976a8/site_favicon_16_1490678664971.ico"
        />
      )
    case 'lead':
      return <SupervisorIcon />
    case 'link':
      return <LinkIcon />
    case 'link-external':
      return <Icon style={{ fontSize: 10 }} className="fas fa-external-link-alt" />
    case 'location':
      return <MapIcon />
    case 'new':
      return <RadioButtonUncheckedIcon style={{ color: color }} />
    case 'org':
      return <BusinessIcon />
    case 'pdf':
      return <Icon fontSize="small" style={{ height: 'auto', width: 'auto' }} className="far fa-file-pdf" />
    case 'manager':
      return <Icon fontSize="small" style={{ height: 'auto', width: 'auto' }} className="fa fa-users" />
    case 'payment':
      return <PaymentIcon />
    case 'peer':
      return (
        <Icon fontSize="small" style={{ height: 'auto', width: 'auto', padding: 6 }} className="fa fa-people-arrows" />
      )
    case 'people':
      return <PeopleIcon />
    case 'person':
      return <PersonIcon color="inherit" />
    case 'pulse':
      return <GraphicEqIcon />
    case 'range':
      return <DateRangeIcon />
    case 'review':
      return <RateReview style={{ color: color }} />
    case 'recurring':
      return <RepeatIcon />
    case 'recurring-once':
      return <RepeatOneIcon />
    case 'report':
      return <InsertChartIcon />
    case 'requested':
      return <ContactMailIcon />
    case 'schedule':
      return <ScheduleIcon />
    case 'self':
      return <PermIdentityIcon />
    case 'setting':
      return <SettingsIcon />
    case 'signature':
      return (
        <Icon
          fontSize="small"
          style={{ height: 'auto', width: 'auto', color: 'white', fontSize: '.9rem', paddingLeft: '4px' }}
          className="fa fa-file-signature"
        />
      )
    case 'signature-circle':
      return (
        <Icon
          fontSize="small"
          style={{
            height: 'auto',
            width: 'auto',
            color: 'rgb(13, 71, 161)',
            fontSize: '1rem',
            paddingLeft: '4px',
            marginTop: 2,
          }}
          className="fa fa-file-signature"
        />
      )
    case 'started':
      return <AdjustIcon style={{ color: color }} />
    case 'team':
      return <PeopleIcon />
    case 'template':
      return <FeaturedPlayListIcon />
    case 'uncheckedRadio':
      return <FiberManualRecordOutlinedIcon />
    case 'uncheckedCheckbox':
      return <CheckBoxOutlinedIcon />
    default:
      return null
  }
}
