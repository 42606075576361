import React from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import Reports from '../data/AllReports'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  evalTable: {
    width: '100%',
  },
}))

const ReportsTable = () => {
  const classes = useStyles()
  const history = useHistory()
  const headCells = [
    {
      field: 'title',
      title: 'Reports (click a row to view report)',
      render: (row) => {
        return (
          <div>
            <Typography variant="h4">{row.title}</Typography>
            <Typography variant="caption">{row.description}</Typography>
          </div>
        )
      },
    },
  ]

  return (
    <MaterialTable
      className={classes.evalTable}
      columns={headCells}
      data={Reports}
      options={{
        actionsColumnIndex: -1,
        draggable: false,
        emptyRowsWhenPaging: false,
        pageSize: 50,
        pageSizeOptions: [50],
        tableLayout: 'fixed',
        toolbar: false,
        selection: false,
        showFirstLastPageButtons: false,
        showTitle: false,
        sorting: false,
      }}
      title="All Reports"
      onRowClick={(event, row) => history.push(`/reports/view/${row.route}`)}
    />
  )
}

export default ReportsTable
