import { createSchedule, updateSchedule, getScheduleEvaluationDetails } from 'api'
import { getIn } from 'immutable'
import actionTypes from 'redux/actionTypes'
import validate from 'validate.js'
import { scheduleDrawerValidations } from 'validations'
import { FormatDate } from 'utils/DateTime'
import { enqueueSuccessNotification, enqueueErrorNotification } from 'redux/actions/notificationsActions'
import { initializeFocusView, updateUnsavedchanges } from './focusViewActions'
import { productOfferingTypes } from 'data/constants'

export const initializeScheduleFormSucceeded = (values) => ({
  type: actionTypes.initializeScheduleFormSucceeded,
  values,
})

export const initializePulseScheduleFormSucceeded = (values) => ({
  type: actionTypes.initializePulseScheduleFormSucceeded,
  values,
})

export const initializeEditScheduleFormSucceeded = (values) => ({
  type: actionTypes.initializeEditScheduleFormSucceeded,
  values,
})

export const updateScheduleFormSucceeded = (form) => ({
  type: actionTypes.updateScheduleFormSucceeded,
  form,
})

export const validateScheduleFormStarted = () => ({
  type: actionTypes.validateScheduleFormStarted,
})

export const validateScheduleFormSucceeded = (isValid, errors) => ({
  type: actionTypes.validateScheduleFormSucceeded,
  isValid,
  errors,
})

export const setScheduleFormErrors = (errors) => ({
  type: actionTypes.setScheduleFormErrors,
  errors,
})

export const resetScheduleForm = () => ({
  type: actionTypes.resetScheduleForm,
})

export const startSaving = () => ({
  type: actionTypes.startSavingScheduleForm,
})

export const endSaving = () => ({
  type: actionTypes.endSavingScheduleForm,
})

export const initializeScheduleFormFocusedView = (isEdit, isPulse) => async (dispatch, getState) => {
  if (isPulse && isEdit){
    dispatch(initializeFocusView('Edit Pulse Survey', '/pulse/schedules', '/pulse/schedules', null, null, productOfferingTypes.engagement))
  } else if (isPulse){
    dispatch(initializeFocusView('Create New Pulse Survey', '/pulse/schedules', '/pulse/schedules', null, null, productOfferingTypes.engagement))
  } else if (!isPulse && isEdit){
    dispatch(initializeFocusView('Edit Evaluation Schedule', '/schedules', '/schedules'))
  } else {
      dispatch(initializeFocusView('New Evaluation Schedule', '/schedules', '/schedules'))  
  }
  dispatch(updateUnsavedchanges(false))
}

export const initializePulseScheduleForm = () => async (dispatch, getState) => {
  const scheduleFormValues = getIn(getState(), ['scheduleForm', 'values'])
  dispatch(initializePulseScheduleFormSucceeded(scheduleFormValues))
  dispatch(
    initializeFocusView(
      'Create Pulse Schedule',
      '/pulse/schedules',
      '/pulse/schedules',
      null,
      null,
      productOfferingTypes.engagement,
    ),
  )
  dispatch(updateUnsavedchanges(false))
}

export const initializeEditScheduleForm = (scheduleId) => async (dispatch, getState) => {
  getScheduleEvaluationDetails(scheduleId).then((response) => {
    if (response.status === 200) {
      const form = {
        ...response.data,
        startOffsetDirection: response.data.startDateOffset > 0 ? 'after' : 'before',
        endOffsetDirection: response.data.endDateOffset > 0 ? 'after' : 'before',
      }
      dispatch(initializeEditScheduleFormSucceeded(form))
      dispatch(initializeFocusView('Edit Schedule', '/schedules', '/schedules'))
    }
  })
  dispatch(updateUnsavedchanges(false))
}

export const updateScheduleForm = (event, type, name) => async (dispatch, getState) => {
  const scheduleForm = getIn(getState(), ['scheduleForm'])
  if (type === 'value') {
    dispatch(
      updateScheduleFormSucceeded({
        ...scheduleForm,
        values: {
          ...scheduleForm.values,
          [name]: name === 'dueDate' ? FormatDate(event) : event,
        },
        touched: {
          ...scheduleForm.touched,
          [name]: true,
        },
        isSaving: false,
      }),
    )
  } else {
    dispatch(
      updateScheduleFormSucceeded({
        ...scheduleForm,
        values: {
          ...scheduleForm.values,
          [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        },
        touched: {
          ...scheduleForm.touched,
          [event.target.name]: true,
        },
      }),
    )
  }
  dispatch(validateScheduleForm())
  dispatch(updateUnsavedchanges(true))
}

export const validateScheduleForm = () => async (dispatch, getState) => {
  dispatch(validateScheduleFormStarted())
  const errors = validate(getIn(getState(), ['scheduleForm', 'values']), scheduleDrawerValidations())

  dispatch(validateScheduleFormSucceeded(errors ? false : true, errors || []))
}

export const handleCreateSchedule = (history) => async (dispatch, getState) => {
  dispatch(startSaving())
  const scheduleForm = getIn(getState(), ['scheduleForm'])
  if (scheduleForm.isValid) {
    createSchedule(scheduleForm.values).then((response) => {
      if (response.status === 201) {
        dispatch(updateUnsavedchanges(false))
        dispatch(resetScheduleForm())
        dispatch(enqueueSuccessNotification('Your schedule was successfully created.'))
        dispatch(endSaving())

        if (scheduleForm.values.isPulse) {
          history.push('/pulse/schedules')
        } else {
          history.push('/schedules')
        }
      } else {
        dispatch(setScheduleFormErrors(response.data.errors))
        dispatch(enqueueErrorNotification('The system encountered an error. Your schedule was not created.'))
        dispatch(endSaving())
      }
    })
  }
}

export const handleUpdateSchedule = (history) => async (dispatch, getState) => {
  dispatch(startSaving())
  const scheduleForm = getIn(getState(), ['scheduleForm'])
  if (scheduleForm.isValid) {
    updateSchedule(scheduleForm.values.id, scheduleForm.values).then((response) => {
      if (response.status === 201) {
        dispatch(updateUnsavedchanges(false))
        dispatch(resetScheduleForm())
        dispatch(enqueueSuccessNotification('Your schedule was successfully updated.'))
        dispatch(endSaving())
        if (scheduleForm.values.isPulse) {
          history.push('/pulse/schedules')
        } else {
          history.push('/schedules')
        }
      } else {
        dispatch(setScheduleFormErrors(response.data.errors))
        dispatch(enqueueErrorNotification('The system encountered an error. Your schedule was not updated.'))
        dispatch(endSaving())
      }
    })
  }
}

export const handleUpdateSubmissionPeriod = (type, value) => async (dispatch, getState) => {
  const startDirection = getIn(getState(), ['scheduleForm', 'values', 'startOffsetDirection'])
  const endDirection = getIn(getState(), ['scheduleForm', 'values', 'endOffsetDirection'])
  if (type === 'start') {
    let updateValue
    if (startDirection === 'before') {
      updateValue = value * -1
    } else {
      updateValue = value
    }
    dispatch(updateScheduleForm(parseInt(updateValue), 'value', 'startDateOffset'))
  } else {
    let updateValue
    if (endDirection === 'before') {
      updateValue = value * -1
    } else {
      updateValue = value
    }
    dispatch(updateScheduleForm(parseInt(updateValue), 'value', 'endDateOffset'))
  }
  dispatch(updateUnsavedchanges(true))
}

export const handleUpdatePeriodDirection = (event) => async (dispatch, getState) => {
  const startValue = getIn(getState(), ['scheduleForm', 'values', 'startDateOffset'])
  const endValue = getIn(getState(), ['scheduleForm', 'values', 'endDateOffset'])
  if (event.target.name === 'startOffsetDirection') {
    dispatch(updateScheduleForm(event))
    dispatch(updateScheduleForm(parseInt(startValue * -1), 'value', 'startDateOffset'))
  } else {
    dispatch(updateScheduleForm(event))
    dispatch(updateScheduleForm(parseInt(endValue * -1), 'value', 'endDateOffset'))
  }
  dispatch(updateUnsavedchanges(true))
}

export const handleUpdateDueDateType = (event) => async (dispatch, getState) => {
  const startValue = getIn(getState(), ['scheduleForm', 'values', 'startDateOffset'])
  const repeat = getIn(getState(), ['scheduleForm', 'values', 'repeat'])
  if (event.target.value === 'date') {
    dispatch(updateScheduleForm(event))
    if (startValue >= 0) {
      dispatch(updateScheduleForm(startValue * -1, 'value', 'startDateOffset'))
      dispatch(updateScheduleForm('before', 'value', 'startOffsetDirection'))
    }
  } else {
    dispatch(updateScheduleForm(event))
    if (!repeat) {
      dispatch(updateScheduleForm(Math.abs(startValue), 'value', 'startDateOffset'))
      dispatch(updateScheduleForm('after', 'value', 'startOffsetDirection'))
    }
  }
  dispatch(updateUnsavedchanges(true))
}
