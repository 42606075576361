import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import MyEvaluationsTable from './components/MyEvaluationsTable'
import AllEvaluationsTable from './components/AllEvaluationsTable'
import TabView from 'layouts/Main/components/TabView/TabView'
import Toolbar from '../../layouts/Main/components/Toolbar/Toolbar'
import { Link as RouterLink } from 'react-router-dom'
import PermissionCheck from 'utils/PermissionCheck'

import { Button, Container } from '@material-ui/core'
import PastEvaluationsTable from './components/PastEvaluationsTable'
import { roles } from 'utils/roles'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  scheduleTable: {
    width: '100%',
  },
}))

const Evaluations = () => {
  const classes = useStyles()

  const tabs = [
    {
      label: 'All Active Evaluations',
      view: <AllEvaluationsTable />,
      roles: [roles.Admin, roles.TeamLead],
      route: '/evaluations/all',
    },
    {
      label: 'My Feedback Requests',
      view: <MyEvaluationsTable />,
      roles: [roles.Member],
      route: '/evaluations',
    },
    {
      label: 'Finalized Evaluations',
      view: <PastEvaluationsTable />,
      roles: [roles.Member],
      route: '/evaluations/past',
    },
  ]

  const buttons = [
    <PermissionCheck
      key={`schedule-eval-button`}
      roles={['org_admin']}
      yes={() => (
        <Button component={RouterLink} to={'/templates'} variant="contained">
          Evaluation Templates
        </Button>
      )}
    />,
    <PermissionCheck
      key={`schedule-eval-button`}
      roles={['org_admin']}
      yes={() => (
        <Button variant="contained" color="primary" component={RouterLink} to={'/schedules'}>
          Evaluation Schedules
        </Button>
      )}
    />,
  ]

  return (
    <div className={classes.root} spacing={2}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Evaluations | Express Evaluations</title>
      </Helmet>
      <Toolbar title="Evaluations" buttons={buttons} />
      <Container>
        <TabView tabs={tabs}/>
      </Container>
    </div>
  )
}

export default Evaluations
