import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar, Typography, Hidden } from '@material-ui/core'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import hexToRGB from 'helpers/hexToRGB'
import AccountMenu from 'layouts/Main/components/Topbar/components/AccountMenu'

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      'linear-gradient(90deg,' +
      hexToRGB(theme.palette.primary.main) +
      '0%,' +
      hexToRGB(theme.palette.secondary.main) +
      ' 120%)',
    boxShadow: 'none',
    opacity: 0.9,
    backdropFilter: 'blur(5px)',
  },
  logoAndTitle: {
    display: 'flex',
    width: '140px',
  },
  button: {
    ...theme.typography.button,
    color: 'white',
    marginRight: theme.spacing(2),
  },
  logo: {
    height: '30px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
    width: '40px',
    height: '20px',
    fontSize: '16px',
    lineHeight: 1,
  },
}))

const Topbar = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary" position="fixed">
      <Toolbar>
        <AnchorLink href="#top" className={classes.logoAndTitle}>
          <img alt="Express Evaluations Logo" src="/Express-Evaluations-Logo-Thick.svg" className={classes.logo} />
          <Typography variant="h2" className={classes.title}>
            Express Evaluations
          </Typography>
        </AnchorLink>
        <Hidden xsDown>
          <AnchorLink className={classes.button} href={`#features`} offset="80">
            Features
          </AnchorLink>
        </Hidden>
        <AnchorLink className={classes.button} href={`#pricing`} offset="80">
          Pricing
        </AnchorLink>

        <div className={classes.flexGrow} />
        <AccountMenu />
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
}

export default Topbar
