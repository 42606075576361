import moment from 'moment'
import API from '../api'
import fileDownload from 'js-file-download'

export const getAllPeople = async (
  page = 0,
  pageSize = 0,
  searchQuery = '',
  sortBy = '',
  sortDirection = '',
  managers = false,
  tags = [],
  schedules = [],
) => {
  const tagsParam = tags.reduce((total, tag) => `${total}&tags=${tag}`, '')
  const schedulesParam = schedules.reduce((total, schedule) => `${total}&schedules=${schedule}`, '')
  return await API.get(
    `people?currentPage=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchQuery=${searchQuery}&managers=${managers}${tagsParam}${schedulesParam}`,
  ).then((response) => {
    return response
  })
}

export const getArchivedPeople = async (page = 0, pageSize = 0, searchQuery = '', sortBy = '', sortDirection = '') => {
  return await API.get(
    `people/archived?currentPage=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`,
  ).then((response) => {
    return response
  })
}

export const getPersonById = async (id) => {
  return await API.get(`people/${id}`).then((response) => {
    return response.data
  })
}

export const deactivatePerson = async (id) => {
  return await API.put(`people/deactivate/${id}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const activatePerson = async (id) => {
  return await API.put(`people/activate/${id}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const updateEmployee = async (person) => {
  return await API.put('people/update', person)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getEmployeeByUserId = async (userId, token) => {
  return await API.get(`people/user/${userId}`).then((response) => {
    return response.data
  })
}

export const validateImportPeople = async (data) => {
  return await API.post('people/import/validate', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const importPeople = async (data) => {
  return await API.post('import/people', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const exportPeople = async () => {
  return await API.post(
    `people/export`,
    {},
    {
      responseType: 'blob',
    },
  )
    .then((response) => {
      fileDownload(response.data, `people_export_${moment().format()}.csv`)
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const createEmployee = async (data) => {
  return await API.post('people/create', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const createUserAccount = async (data) => {
  return await API.post('people/create/user', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const createBatchUserAccounts = async () => {
  return await API.post('people/create/batchUsers')
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getPeers = async () => {
  return await API.get('people/peer').then((response) => {
    return response.data
  })
}

export const addPeer = async (data) => {
  return await API.post('people/peer/add', data).then((response) => {
    return response.data
  })
}

export const removePeer = async (data) => {
  return await API.post('people/peer/remove', data).then((response) => {
    return response.data
  })
}

export const getDirectReports = async (
  id,
  page = 0,
  pageSize = 0,
  searchQuery = '',
  sortBy = '',
  sortDirection = '',
) => {
  return await API.get(
    `people/${id}/reports/direct?currentPage=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`,
  ).then((response) => {
    return response
  })
}

export const getExtendedReports = async (
  id,
  page = 0,
  pageSize = 0,
  searchQuery = '',
  sortBy = '',
  sortDirection = '',
) => {
  return await API.get(
    `people/${id}/reports/extended?currentPage=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`,
  ).then((response) => {
    return response
  })
}

export const getExternalIds = async () => {
  return await API.get('people/externalIds')
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}
