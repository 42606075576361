import API from '../api'

export const getEvaluationSignatures = async (batchId) => {
  return await API.get(`evaluations/${batchId}/signatures`).then((response) => {
    return response
  })
}

export const createSignature = async (batchId, data) => {
  return await API.post(`evaluations/${batchId}/signatures/create`, data).then((response) => {
    return response
  })
}

export const deleteSignature = async (batchId, signatureId) => {
  return await API.delete(`evaluations/${batchId}/signatures/${signatureId}/delete`).then((response) => {
    return response
  })
}

export const signEvaluation = async (batchId, data) => {
  return await API.patch(`evaluations/${batchId}/signatures/sign`, data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}
