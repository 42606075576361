import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonGroup, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'immutable'
import {
  createTemplateVersionAction,
  archiveTemplateAction,
  loadPulseTemplatesTableAction,
} from 'redux/actions/templateActions'
import TemplateTable from './TemplateTable'

const ActivePulseTemplates = (props) => {
  const templates = useSelector((store) => getIn(store, ['templates', 'pulse']))
  const isLoading = useSelector((store) => getIn(store, ['templates', 'loading']))
  const history = useHistory()
  const dispatch = useDispatch()

  const actionBtn = (row) => {
    return (
      <ButtonGroup size="small" variant="text" color="primary" aria-label="text primary button group">
        <Button onClick={() => history.push(`/pulse/template/preview/${row.id}`)}>Preview</Button>
        {row.evaluationCount > 0 && (
          <Button onClick={() => dispatch(createTemplateVersionAction(row.id, row.title, history))}>
            Create Version
          </Button>
        )}
        <Button onClick={() => history.push(`/pulse/template/build/${row.id}`)}>Edit</Button>
        {row.evaluationCount > 0 ? (
          <Button onClick={() => dispatch(archiveTemplateAction(row.id, row.title))}>Archive</Button>
        ) : (
          <Button>Delete</Button>
        )}
      </ButtonGroup>
    )
  }
  const loadActiveTables = useCallback(() => dispatch(loadPulseTemplatesTableAction()), [dispatch])
  useEffect(() => {
    loadActiveTables()
  }, [loadActiveTables])

  return <TemplateTable title="Pulse Templates" templates={templates} isLoading={isLoading} buttons={actionBtn} />
}

export default ActivePulseTemplates
