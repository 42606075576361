export const updateUserNotifications = (notifications) => ({
  type: 'UPDATE_USER_NOTIFICATIONS',
  notifications,
})

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key

  return {
    type: 'ENQUEUE_SNACKBAR',
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  }
}

export const closeSnackbar = (key) => ({
  type: 'CLOSE_SNACKBAR',
  dismissAll: !key, // dismiss all if no key has been defined
  key,
})

export const removeSnackbar = (key) => ({
  type: 'REMOVE_SNACKBAR',
  key,
})

export const enqueueDefaultNotification = (message) => async (dispatch, getState) => {
  dispatch(
    enqueueSnackbar({
      message: message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'default',
      },
    }),
  )
}

export const enqueueSuccessNotification = (message) => async (dispatch, getState) => {
  dispatch(
    enqueueSnackbar({
      message: message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
      },
    }),
  )
}

export const enqueueInfoNotification = (message) => async (dispatch, getState) => {
  dispatch(
    enqueueSnackbar({
      message: message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'info',
      },
    }),
  )
}

export const enqueueErrorNotification = (message) => async (dispatch, getState) => {
  dispatch(
    enqueueSnackbar({
      message: message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
      },
    }),
  )
}

export const enqueueWarningNotification = (message) => async (dispatch, getState) => {
  dispatch(
    enqueueSnackbar({
      message: message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
      },
    }),
  )
}
