import { makeStyles } from '@material-ui/styles'

export const useActionCardStyles = makeStyles((theme) => ({
  root: { maxWidth: 300, borderRadius: '15px', minWidth: 200 },
  header: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  performanceHeader: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  engagementHeader: {
    backgroundColor: theme.palette.engagement.primary.light,
    color: theme.palette.primary.contrastText,
  },
  goalHeader: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
  },
  body: {
    padding: theme.spacing(2),
  },
  headerText: { color: 'white' },
  actions: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },

  title: {
    marginBottom: theme.spacing(1),
  },
  actionBtn: {
    borderRadius: '12px',
    paddingRight: '15px',
    paddingLeft: '15px',
  },
}))
