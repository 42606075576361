import { makeStyles } from '@material-ui/styles'

export const useTemplateBuilderStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  addSectionButton: {
    paddingTop: '10px',
    marginTop: theme.spacing(2),
  },
  templatePaper: {
    backgroundColor: theme.palette.divider,
  },
  builderRoot: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  summarySection: {
    color: theme.palette.primary.main,
  },
  summaryQuestion: {
    padding: '0px',
  },
  templateSettingsTitle: {
    marginTop: theme.spacing(2),
  },
  templateSettings: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  templateTypeWrapper: {
    width: '320px',
    [theme.breakpoints.down('xs')]: {
      width: '240px',
    },
  },
  toggleDivider: {
    marginRight: theme.spacing(2),
  },
  scoreDescription: {
    paddingLeft: theme.spacing(2),
  },
  lastUpdateTimestamp: {
    marginLeft: theme.spacing(3),
  },
  summary: {
    marginTop: theme.spacing(4),
    borderTopColor: theme.palette.primary.main,
    borderTopWidth: theme.spacing(1),
    borderTopStyle: 'solid',
  },
}))
