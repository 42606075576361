import React, { useEffect } from 'react'
import { TextField } from '@material-ui/core'
import OverdueEvaluations from './OverdueEvaluations'
import CompletedEvaluationsTable from './CompletedEvaluations'
import IncompleteEvaluationsTable from './IncompleteEvaluations'
import PeopleTable from './People'
import ResultsByTemplate from './ResultsByTemplate'
import SignedEvaluationsReport from './SignedEvaluations'
import { useParams, useHistory } from 'react-router-dom'
import AverageEvaluationScoreTable from './AvgEvaluationScores'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Reports from '../data/AllReports'

const ReportNav = (props) => {
  const { setCurrentReport } = props
  const history = useHistory()
  const handleReportChange = (e, selectedReport) => {
    selectedReport && history.push(`/reports/view/${selectedReport.route}`)
  }
  const { category, report } = useParams()

  useEffect(() => {
    switch (category) {
      case 'general':
        switch (report) {
          case 'by-template':
            setCurrentReport(<ResultsByTemplate />)
            break
          default:
            setCurrentReport(<ResultsByTemplate />)
            break
        }
        break
      case 'evaluations':
        switch (report) {
          case 'completed':
            setCurrentReport(<CompletedEvaluationsTable />)
            break
          case 'incomplete':
            setCurrentReport(<IncompleteEvaluationsTable />)
            break
          case 'average-scores':
            setCurrentReport(<AverageEvaluationScoreTable />)
            break
          case 'average-score-manager':
            setCurrentReport(<AverageEvaluationScoreTable showManagers={true} />)
            break
          case 'overdue':
            setCurrentReport(<OverdueEvaluations />)
            break
          case 'signed':
            setCurrentReport(<SignedEvaluationsReport />)
            break
          default:
            setCurrentReport(<CompletedEvaluationsTable />)
            break
        }
        break
      case 'people':
        switch (report) {
          case 'all':
            setCurrentReport(<PeopleTable />)
            break
          default:
            setCurrentReport(<PeopleTable />)
            break
        }
        break
      default:
        break
    }
  }, [category, report, setCurrentReport])

  return (
    <Autocomplete
      id="grouped-demo"
      disableClearable
      options={Reports}
      groupBy={(report) => report.category}
      getOptionLabel={(report) => report.title}
      onChange={handleReportChange}
      style={{ width: 250 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a Report"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}

export default ReportNav
