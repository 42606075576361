import React, { useState, useEffect } from 'react'
import { Typography, CardContent, TextField, Button, InputAdornment } from '@material-ui/core'
import { useSignatureCardStyles } from 'styles'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import TodayIcon from '@material-ui/icons/Today'
import { FormatDate, FormatDateIgnoreTz } from 'utils/DateTime/FormatDate'
import { signEvaluation } from 'api'
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
  enqueueWarningNotification,
} from 'redux/actions/notificationsActions'
import { useDispatch } from 'react-redux'
import { getSignatureSettings } from 'api'
import RichTextBlock from 'components/RichText'
import { useAuth0 } from 'react-auth0-spa'
import { ModernCard } from '.'
const SignatureCard = (props) => {
  const { name, date, isSigned, evaluationId, signedBy, setSigned, evaluationBatchId } = props
  const classes = useSignatureCardStyles()
  const [signature, setSignature] = useState({
    name: name,
    date: date,
    signedBy: signedBy,
  })
  const [description, setDescription] = useState('')
  const [isSigning, setIsSigning] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const dispatch = useDispatch()
  const { user } = useAuth0()

  const handleSigning = () => {
    setIsSigning(true)
    signEvaluation(evaluationBatchId, {
      evaluationId: evaluationId,
      signature: signature.name,
    }).then((response) => {
      if (response.status === 200) {
        dispatch(
          enqueueSuccessNotification(
            'Evaluation signature saved! This signed evaluation can be viewed in past evaluations',
          ),
        )
        setSigned(true)
        setSignature({ ...signature, date: new Date().toISOString().slice(0, -1), signedBy: user.name })
      } else if (response.status === 400) {
        dispatch(enqueueWarningNotification('Unable to sign evaluation. No signature request found for current user'))
      } else {
        dispatch(enqueueErrorNotification('The system has encountered an error. Your signature was not saved.'))
      }
      setIsSigning(false)
    })
  }

  const handleNameChange = (e) => {
    setSignature({ ...signature, name: e.target.value })
  }

  useEffect(() => {
    getSignatureSettings().then((response) => {
      setDescription(response.data.description)
      setIsEnabled(response.data.isEnabled)
    })
  }, [])

  return isEnabled ? (
    <ModernCard headerTitle={isSigned ? 'Signature' : 'Sign to finalize your evaluation'} className={classes.root}>
      {isSigned && (
        <CardContent>
          <Typography className={classes.title} color="textSecondary">
            Signature
          </Typography>
          <div className={classes.signature}>{signature.name}</div>
          <Typography variant="overline">Signed By: {signature.signedBy}</Typography>
          <Typography variant="subtitle2" className={classes.signatureDate}>
            Date: {FormatDate(signature.date, true)}
          </Typography>
        </CardContent>
      )}
      {!isSigned && (
        <>
          <RichTextBlock content={description} />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ArrowRightAltIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder="Type your full name"
            label="Signature"
            id="signature_box"
            fullWidth
            className={classes.input}
            value={signature.name}
            onChange={handleNameChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TodayIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            value={FormatDateIgnoreTz(Date.now(), true)}
            fullWidth
            className={classes.input}
          />
          <Button disabled={!signature.name || isSigning} onClick={handleSigning} variant="contained" color="primary">
            Submit Signature
          </Button>
        </>
      )}
    </ModernCard>
  ) : (
    ''
  )
}

export default SignatureCard
