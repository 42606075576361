import React from 'react'
import { Router } from 'react-router-dom'
import history from './utils/history'
import { Chart } from 'react-chartjs-2'
import validate from 'validate.js'
import ReactGA from 'react-ga'
import { chartjs } from './helpers'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import validators from './common/validators'
import Routes from './Routes'

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
})

validate.validators = {
  ...validate.validators,
  ...validators,
}

function initializeReactGA() {
  ReactGA.initialize('UA-163582225-1')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const App = () => {
  initializeReactGA()

  return (
    <Router history={history}>
      <Routes />
    </Router>
  )
}

export default App
