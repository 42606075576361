import React from 'react'
import { getGoals } from 'api'
import GoalTable from '../GoalTable'

const OrganizationGoals = () => {
  return (
    <GoalTable
      fetchData={async (personId = 0, isComplete = false, limit = 1000) => getGoals(personId, limit, isComplete)}
      type="team"
    />
  )
}

export default OrganizationGoals
