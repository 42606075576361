import React from 'react'
import { Chip, Paper, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getIn } from 'immutable'
import MaterialTable from 'material-table'
import { useImportStyles } from 'styles'
import Alert from '@material-ui/lab/Alert'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import moment from 'moment'

const ImportErrorsTable = () => {
  const importValidCount = useSelector((state) => getIn(state, ['import', 'countValidPeople']))
  const rows = useSelector((state) => getIn(state, ['import', 'invalidPeople'])) || []
  const classes = useImportStyles()
  const headCells = [
    {
      title: '',
      render: (rowData) => (
        <div className={classes.textIcon}>
          <HighlightOffIcon size="small" className={classes.errorColumnIcon} />
          {rowData.tableData.id + 1}
        </div>
      ),
    },
    {
      field: 'lastName',
      title: 'Name',
      render: (row) => (
        <>
          {row.firstName ? row.firstName : <Chip className={classes.missingChip} size="small" label="Missing First" />}{' '}
          {row.lastName ? row.lastName : <Chip className={classes.missingChip} size="small" label="Missing Last" />}
        </>
      ),
    },
    {
      field: 'email',
      title: 'Email',
      render: (row) => (row.email ? row.email : <Chip className={classes.missingChip} size="small" label="Missing" />),
    },
    {
      field: 'hireDate',
      title: 'Hire Date',
      render: (row) =>
        row.hireDate !== '0001-01-01T00:00:00' ? (
          moment(row.hireDate).format('MM/DD/yyyy')
        ) : (
          <Chip className={classes.missingChip} size="small" label="Missing" />
        ),
    },
  ]
  return (
    <div>
      {rows.length === 0 ? (
        <Alert severity="info">No errors found in records. Continue to the next step.</Alert>
      ) : importValidCount === 0 ? (
        <Alert severity="error">No valid records found within your imported file- Check your file and try again.</Alert>
      ) : (
        <>
          <Typography variant="h3" className={classes.title}>
            Records with Errors
          </Typography>
          <Alert severity="error">
            The following people will not be imported into your organization due to errors in the data. If you want the
            records below to be included with the import, please go into your file, enter valid data for the records
            listed, and upload your revised file.
          </Alert>
          <div className={classes.content}>
            <MaterialTable
              columns={headCells}
              data={rows}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                actionsColumnIndex: -1,
                emptyRowsWhenPaging: false,
                selection: false,
                showTitle: false,
                paging: false,
                padding: 'dense',
                headerStyle: { position: 'sticky', top: 0 },
                maxBodyHeight: '600px',
                search: false,
                exportButton: false,
                toolbar: false,
                cellStyle: {
                  paddingTop: 1,
                  paddingBottom: 1,
                },
              }}
              title="Scheduled Evaluations"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ImportErrorsTable
