import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  ctaButton: {
    marginLeft: 'auto',
    marginRigth: 'auto',
    color: theme.palette.secondary.contrastText,
  },
  ctaTitle: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  ctaIcon: {
    marginRight: theme.spacing(1),
  },
}))

export default function CallToAction() {
  const classes = useStyles()

  return (
    <Grid container className={classes.root} spacing={4} id="features">
      <Grid item xs={1} md={4}></Grid>
      <Grid item xs={10} md={4}>
        <Typography variant="h1" className={classes.ctaTitle}>
          Let's Talk Best Practices
        </Typography>
        <Button
          href="mailto:info@expressevaluations.com?subject=Information About Evaluations - Express Evaluations"
          className={classes.ctaButton}
          variant="contained"
          color="primary">
          <ArrowForwardIosIcon className={classes.ctaIcon} /> Contact Our Team
        </Button>
      </Grid>
      <Grid item xs={1} md={4}></Grid>
    </Grid>
  )
}
