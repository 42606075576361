import { getIn } from 'immutable'
import { st_getAllOrgs, st_actAsOrg } from 'api'

export const toggleShowSystemTools = () => ({
  type: 'TOGGLE_SHOW_SYSTEM_TOOLS',
})

export const fetchOrgsSucceeded = (orgs) => ({
  type: 'FETCH_ORGS_SUCCEEDED',
  orgs,
})

export const selectUseAsOrg = (org) => ({
  type: 'SELECT_USE_AS_ORG',
  org,
})

export const actingAsOrgSucceeded = (org) => ({
  type: 'ACTING_AS_ORG_SUCCEEDED',
  org,
})

export const getAllOrgs = () => async (dispatch, getState) => {
  st_getAllOrgs().then((response) => {
    if (!getIn(getState(), ['systemTools', 'orgs'])) {
      dispatch(fetchOrgsSucceeded(response))
    }
  })
}

export const startActingAsOrg = (refreshToken) => async (dispatch, getState) => {
  const org = getIn(getState(), ['systemTools', 'useAsOrg'])
  await st_actAsOrg({ orgId: org.id })
  await refreshToken()
  window.location.reload()
}

export const endActingAsOrg = (refreshToken) => async (dispatch, getState) => {
  await st_actAsOrg({ orgId: process.env.REACT_APP_EE_ORG_ID })
  await refreshToken()
  window.location.reload()
}

export const loadActingAsOrg = (org) => async (dispatch, getState) => {
  dispatch(actingAsOrgSucceeded(org))
  dispatch(selectUseAsOrg(org))
}
