import React from 'react'
import { Grid, Typography, Fade } from '@material-ui/core'
import { useImportStyles } from 'styles'
import { useSelector } from 'react-redux'
import { getIn } from 'immutable'
import CircularProgress from '@material-ui/core/CircularProgress'

const ImportComplete = () => {
                               const classes = useImportStyles()
                               // const peopleAdded = useSelector((state) => getIn(state, ['import', 'peopleAdded']))
                               // const peopleNotAdded = useSelector((state) => getIn(state, ['import', 'peopleNotAdded']))
                               // const peopleUpdated = useSelector((state) => getIn(state, ['import', 'peopleUpdated']))
                               // const countInvalidPeople = useSelector((state) => getIn(state, ['import', 'countInvalidPeople']))
                               // const teamsCreated = useSelector((state) => getIn(state, ['import', 'teamsAdded']))
                               const importComplete = useSelector((state) => getIn(state, ['import', 'importComplete']))

                               return importComplete ? (
                                 <Fade in={importComplete}>
                                   <Grid container className={classes.importContent} spacing={2}>
                                     <Grid item xs={12}>
                                       <Typography variant="h3" className={classes.title}>
                                         Import Completed Successfully
                                       </Typography>
                                       {/* <Typography variant="h5">People added to your organization: {peopleAdded}</Typography>
          <Typography variant="h5">People updated in your organization: {peopleUpdated}</Typography>
          {peopleNotAdded > 0 && (
            <Typography variant="h5">People already within your organization (no changes): {peopleNotAdded}</Typography>
          )}
          {countInvalidPeople > 0 && (
            <Typography variant="h5">People who were not imported due to errors: {countInvalidPeople}</Typography>
          )}
          <Typography variant="h5">New teams created: {teamsCreated}</Typography> */}
                                     </Grid>
                                   </Grid>
                                 </Fade>
                               ) : (
                                 <CircularProgress />
                               )
                             }

export default ImportComplete
