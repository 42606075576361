import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  StepLabel,
  Step,
  Stepper,
  StepContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { SaveCircularStatusButton } from 'components/Buttons'
import StickyBox from 'react-sticky-box'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useDispatch, useSelector } from 'react-redux'
import { addSection, addQuestion, saveTemplate } from 'redux/actions/templateBuilderActions'
import { getIn } from 'immutable'
import { useHistory } from 'react-router-dom'
import { useScrollData } from 'scroll-data-hook'
import { getIcon } from 'utils/GetIcon'

const useStyles = makeStyles((theme) => ({
  root: {},
  learnMoreButton: {
    paddingTop: 0,
    textDecoration: 'underline',
    fontSize: '12px',
  },
  scoreBreakdown: {
    marginBottom: theme.spacing(3),
  },
  summarySection: {
    color: theme.palette.primary.main,
  },
  summaryQuestion: {
    padding: '0px',
  },
  actions: {
    minHeight: '58px',
  },
  textColor: {
    color: theme.palette.secondary.main,
  },
  textColorPulse: {
    color: theme.palette.pulse.main,
  },
  buttonColor: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  buttonColorPulse: {
    borderColor: theme.palette.pulse.main,
    color: theme.palette.pulse.main,
  },
}))

const BuilderNav = (props) => {
  const { availableToEdit } = props
  const [activeSection, setActiveSection] = useState(0)
  useScrollData({
    onScrollStart: () => {
      var activeStep = 0
      for (var el of document.getElementsByClassName('section-block')) {
        if (document.getElementById(el.id).getBoundingClientRect().bottom <= 200) {
          activeStep = parseInt(el.getAttribute('stepid')) + 1
        }
      }
      setActiveSection(activeStep)
    },
    onScrollEnd: () => {
      var activeStep = 0
      for (var el of document.getElementsByClassName('section-block')) {
        if (document.getElementById(el.id).getBoundingClientRect().bottom <= 200) {
          activeStep = parseInt(el.getAttribute('stepid')) + 1
        }
      }
      setActiveSection(activeStep)
    },
  })

  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'loading']))
  const scoringEnabled = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'scoringEnabled']))
  const title = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'title']))
  const sections = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'sections']))
  const lastUpdated = useSelector((state) => getIn(state, ['templateBuilder', 'lastUpdated']))
  const unsavedChanges = useSelector((state) => getIn(state, ['templateBuilder', 'unsavedChanges']))
  const isPulse = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'isPulseTemplate']))
  const templatePointTotalSelf = sections.reduce((total, section) => {
    return section.showInSelf ? section.score + total : total
  }, 0)
  const templatePointTotalPeer = sections.reduce(
    (total, section) => (section.showInPeer ? section.score + total : total),
    0,
  )
  const templatePointTotalLead = sections.reduce(
    (total, section) => (section.showInLead ? section.score + total : total),
    0,
  )

  // const showTutorial = () => {
  //   alert('Coming Soon...')
  // }
  // TODO: Let's make this a "Summary" component that includes BuilderNav and ScoreBreakdown subcomponents
  return (
    <StickyBox offsetBottom={20} offsetTop={80} className={classes.root}>
      <Card>
        <CardContent>
          <Typography variant="h4">Summary</Typography>
          <Typography color="textSecondary">{title || 'Untitled Template'}</Typography>
        </CardContent>
        <Stepper activeStep={activeSection} orientation="vertical">
          {loading ? (
            <CircularProgress />
          ) : (
            sections.map((value) => (
              <Step key={`${value.sys_id}-menu-step`}>
                <StepLabel>
                  <AnchorLink className={classes.summarySection} href={`#${value.sys_id}_section_block`} offset="100">
                    {value.title || 'New Section'}
                  </AnchorLink>
                </StepLabel>
                <StepContent>
                  <List>
                    {value.questions.map((question, index) => (
                      <ListItem className={classes.summaryQuestion} key={`navItem-${value.sys_id}-${question.sys_id}`}>
                        <AnchorLink
                          className={classes.summarySection}
                          href={`#${question.sys_id}_question_block`}
                          offset="100">
                          <ListItemText
                            key={`nav-${value.sys_id}-${question.sys_id}`}
                            secondary={`${index + 1}. ${question.title || 'New Question'}`}
                          />
                        </AnchorLink>
                      </ListItem>
                    ))}
                    <Button
                      startIcon={getIcon('add')}
                      className={isPulse ? classes.buttonColorPulse : classes.buttonColor}
                      onClick={() => dispatch(addQuestion(value.sys_id))}
                      size="small"
                      variant="outlined"
                      disabled={!availableToEdit}>
                      Question
                    </Button>
                  </List>
                </StepContent>
              </Step>
            ))
          )}
          {!isPulse && (
            <StepLabel onClick={() => dispatch(addSection())}>
              <Button
                startIcon={getIcon('add')}
                color="primary"
                size="small"
                variant="outlined"
                disabled={!availableToEdit}>
                Section
              </Button>
            </StepLabel>
          )}
        </Stepper>
        <Divider />
        <CardContent>
          <Collapse in={scoringEnabled}>
            <div className={classes.scoreBreakdown}>
              <Typography
                align="center"
                className={isPulse ? classes.textColorPulse : classes.textColor}
                color="secondary"
                variant="h5">
                Total Score Breakdown
              </Typography>

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Eval Type</TableCell>
                    <TableCell>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{!isPulse ? 'Self' : 'Pulse'}</TableCell>
                    <TableCell>{templatePointTotalSelf}</TableCell>
                  </TableRow>
                  {!isPulse && (
                    <TableRow>
                      <TableCell>Peer</TableCell>
                      <TableCell>{templatePointTotalPeer}</TableCell>
                    </TableRow>
                  )}
                  {!isPulse && (
                    <TableRow>
                      <TableCell>Lead</TableCell>
                      <TableCell>{templatePointTotalLead}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* <Button size="small" fullWidth onClick={showTutorial} className={classes.learnMoreButton}>
                Learn how scoring works
              </Button> */}
            </div>
          </Collapse>
          <Typography align="center" className={classes.lastUpdateTimestamp} variant="body2">
            Status -{unsavedChanges ? ' Changes Pending' : ' No Changes to Save'}
          </Typography>
          <SaveCircularStatusButton
            postLabel="Template Saved"
            preLabel="Save Template"
            disabled={!unsavedChanges || !availableToEdit}
            onClick={() => dispatch(saveTemplate(history))}
          />
          <Typography align="center" className={classes.lastUpdateTimestamp} variant="body2">
            {lastUpdated ? `Last Updated: ${lastUpdated}` : 'Draft (Not Saved)'}
          </Typography>
        </CardContent>
      </Card>
    </StickyBox>
  )
}

export default BuilderNav
