import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonGroup, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'immutable'
import {
  loadActiveTemplatesTableAction,
  createTemplateVersionAction,
  archiveTemplateAction,
} from 'redux/actions/templateActions'
import TemplateTable from './TemplateTable'

const ScheduledEvaluationTable = (props) => {
  const templates = useSelector((store) => getIn(store, ['templates', 'active']))
  const isLoading = useSelector((store) => getIn(store, ['templates', 'loading']))
  const history = useHistory()
  const dispatch = useDispatch()

  const handleEdit = (id, currentVersionId, title, availableToEdit) => {
    if (availableToEdit) {
      history.push(`/evaluation/template/build/${id}`)
    } else {
      dispatch(createTemplateVersionAction(currentVersionId, title, history))
    }
  }

  const actionBtn = (row) => {
    return (
      <ButtonGroup size="small" variant="text" color="primary" aria-label="text primary button group">
        <Button onClick={() => history.push(`/evaluation/template/preview/${row.id}`)}>Preview</Button>
        <Button onClick={() => handleEdit(row.id, row.id, row.title, row.availableToEdit)}>Edit</Button>
        <Button onClick={() => dispatch(archiveTemplateAction(row.id, row.title))}>Archive</Button>
      </ButtonGroup>
    )
  }
  const loadActiveTables = useCallback(() => dispatch(loadActiveTemplatesTableAction()), [dispatch])
  useEffect(() => {
    loadActiveTables()
  }, [loadActiveTables])

  return <TemplateTable title="Evaluation Templates" templates={templates} isLoading={isLoading} buttons={actionBtn} />
}

export default ScheduledEvaluationTable
