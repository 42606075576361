import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container } from '@material-ui/core'
import KnowYourTalentsActiveTable from './KnowYourTalentsActiveTable'
import TabView from 'layouts/Main/components/TabView/TabView'
import Toolbar from '../../layouts/Main/components/Toolbar/Toolbar'
import PersonExternalIdTable from './PersonExternalIdTable'
import { Helmet } from 'react-helmet'
import { getKytSurveys } from 'api'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))

const KnowYourTalents = () => {
  const classes = useStyles()
  const buttons = []
  const [isLoading, setIsLoading] = useState(true)
  const [surveys, setSurveys] = useState([])
  const callback = useCallback(() => {
    getKytSurveys().then((response) => {
      setSurveys(response)
      setIsLoading(false)
    })
  }, [])
  const tabs = [
    {
      label: 'Active Templates',
      view: <KnowYourTalentsActiveTable callback={callback} isLoading={isLoading} surveys={surveys} />,
      roles: ['org_admin'],
      route: '/talents',
    },
    {
      label: 'External Ids',
      view: <PersonExternalIdTable callback={callback} isLoading={isLoading} surveys={surveys} />,
      roles: ['org_admin'],
      route: '/talents/external-ids',
    },
  ]
  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Know Your Talents™ | Express Evaluations</title>
      </Helmet>
      <Toolbar buttons={buttons} title="Know Your Talents™" />
      <Container>
        <TabView tabs={tabs} />
      </Container>
    </div>
  )
}

export default KnowYourTalents
