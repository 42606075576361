import { Select } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'

const SelectControlled = ({ control, name, defaultValue, placeholder, children, ...rest }) => {
  return (
    <Controller
      render={({ field, ref }) => (
        <Select {...field} name={name} {...rest}>
          {children}
        </Select>
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  )
}

export default SelectControlled
