import { makeStyles } from '@material-ui/styles'

export const useAlertStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(1, 0),
    paddingTop: theme.spacing(0),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontFamily: 'Roboto',
  },
  link: {
    textDecoration: 'underline',
    fontSize: '12px',
  },
}))
