import React from 'react'
import { Chip, Tooltip } from '@material-ui/core'
import { green, blue, red, purple, teal, cyan, brown } from '@material-ui/core/colors'
import PersonIcon from '@material-ui/icons/Person'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import { getIcon } from 'utils/GetIcon'

export const getTypeChip = (type) => {
  if (type === 'self') {
    return <Chip icon={<PersonIcon />} label={'Employee'} color="primary" size="small" variant="outlined" />
  } else if (type === 'peer') {
    return <Chip icon={getIcon('peer')} label={'Peer'} color="primary" size="small" variant="outlined" />
  } else if (type === 'lead') {
    return <Chip icon={<AccountTreeIcon />} label={'Manager'} color="primary" size="small" variant="outlined" />
  } else if (type === 'pulse') {
    return <Chip icon={<GraphicEqIcon />} label={'Pulse'} color="primary" size="small" variant="outlined" />
  }
}

export const getScoreChip = (score) => {
  return score > 0 ? <Chip label={`Score: ${score}%`} color="secondary" size="small" variant="outlined" /> : ''
}

export const getRecurrenceChip = (type) => {
  if (type === 'annually') {
    return <Chip icon={getIcon('recurring')} label={'Annually'} color="secondary" size="small" variant="outlined" />
  } else if (type === 'semi-annually') {
    return (
      <Chip icon={getIcon('recurring')} label={'Semi-Annually'} color="secondary" size="small" variant="outlined" />
    )
  } else if (type === 'quarter') {
    return <Chip icon={getIcon('recurring')} label={'Quarterly'} color="secondary" size="small" variant="outlined" />
  } else if (type === 'none') {
    return <Chip icon={getIcon('recurring-once')} label={'None'} color="secondary" size="small" variant="outlined" />
  }
}

export const getEvaluationStatusChip = (type) => {
  if (type === 'ready') {
    return (
      <Chip
        icon={getIcon('new', blue[200])}
        label={'Ready to Start'}
        style={{ backgroundColor: 'white' }}
        size="small"
        variant="outlined"
      />
    )
  } else if (type === 'inProgress') {
    return (
      <Chip
        icon={getIcon('started', 'white')}
        label={'In Progress'}
        style={{ color: 'white', backgroundColor: blue[700] }}
        size="small"
      />
    )
  } else if (type === 'complete') {
    return (
      <Chip
        icon={getIcon('complete', 'white')}
        label={'Submitted'}
        style={{ color: 'white', backgroundColor: green[400] }}
        size="small"
      />
    )
  } else if (type === 'waitingForReview') {
    return (
      <Chip
        icon={getIcon('review', 'white')}
        label={'Waiting For Review'}
        style={{ color: 'white', backgroundColor: blue[500] }}
        size="small"
      />
    )
  } else if (type === 'reviewed') {
    return (
      <Chip
        icon={getIcon('complete', 'white')}
        label={'Reviewed'}
        style={{ color: 'white', backgroundColor: blue[500] }}
        size="small"
      />
    )
  } else if (type === 'waitingForSignature') {
    return (
      <Chip
        icon={getIcon('signature')}
        label={'Signature Required'}
        style={{ color: 'white', backgroundColor: blue[900] }}
        size="small"
      />
    )
  } else if (type === 'assigned') {
    return (
      <Chip
        icon={getIcon('new', 'white')}
        label={'Ready to Start'}
        style={{ color: 'white', backgroundColor: blue[200] }}
        size="small"
      />
    )
  } else if (type === 'signed') {
    return (
      <Chip
        icon={getIcon('signed', 'white')}
        label={'Signed'}
        style={{ color: 'white', backgroundColor: green[800] }}
        size="small"
      />
    )
  } else if (type === 'finalized')
    return <Chip icon={getIcon('finalized', 'white')} label={'Finalized'} color="primary" size="small" />
}

export const getAssignedToChip = (type) => {
  if (type === 'person') {
    return <Chip icon={getIcon('person')} label={'People'} color="secondary" size="small" variant="default" />
  } else if (type === 'team') {
    return <Chip icon={getIcon('people')} label={'Teams'} color="secondary" size="small" variant="default" />
  } else if (type === 'org') {
    return <Chip icon={getIcon('org')} label={'Organization'} color="secondary" size="small" variant="default" />
  } else if (type === 'jobTitle') {
    return <Chip icon={getIcon('jobTitle')} label={'Job Title'} color="secondary" size="small" variant="default" />
  }
}

export const getScheduleDateTypeChip = (type) => {
  if (type === 'date') {
    return <Chip icon={getIcon('event')} label={'Date'} color="primary" size="small" variant="default" />
  } else if (type === 'hireDate') {
    return <Chip icon={getIcon('range')} label={'Hire Date'} color="primary" size="small" variant="default" />
  }
}

export const getStatusCircle = (status) => {
  switch (status) {
    case 'complete':
      return <Tooltip title="Submitted">{getIcon('complete', green[400])}</Tooltip>
    case 'signed':
      return <Tooltip title="Signed">{getIcon('signed', green[800])}</Tooltip>
    case 'inProgress':
      return <Tooltip title="Started">{getIcon('started', blue[700])}</Tooltip>
    case 'waitingForReview':
      return <Tooltip title="Waiting for Review">{getIcon('review', blue[500])}</Tooltip>
    case 'reviewed':
      return <Tooltip title="Reviewed">{getIcon('complete', blue[500])}</Tooltip>
    case 'waitingForSignature':
      return <Tooltip title="Signature Required">{getIcon('signature-circle', blue[900])}</Tooltip>
    case 'finalized':
      return <Tooltip title="Finalized">{getIcon('finalized', blue[900])}</Tooltip>
    default:
      return <Tooltip title="Ready to Start">{getIcon('new', blue[200])}</Tooltip>
  }
}

export const getGoalStatus = (status) => {
  switch (status) {
    case 'inProgress':
      return (
        <Chip
          icon={getIcon('started', 'white')}
          label={'In Progress'}
          style={{ color: 'white', backgroundColor: blue[700] }}
          size="small"
        />
      )
    case 'completed':
      return (
        <Chip
          icon={getIcon('complete', 'white')}
          label={'Completed'}
          style={{ color: 'white', backgroundColor: green[400] }}
          size="small"
        />
      )
    case 'inComplete':
      return (
        <Chip
          icon={getIcon('incomplete', red[700])}
          label={'Past Due'}
          style={{
            color: red[700],
            backgroundColor: 'white',
            borderColor: red[700],
            borderStyle: 'solid',
            borderWidth: 1,
          }}
          size="small"
        />
      )
    default:
      return (
        <Chip
          icon={getIcon('started', 'white')}
          label={'In Progress'}
          style={{ color: 'white', backgroundColor: blue[700] }}
          size="small"
        />
      )
  }
}

export const getGoalTypeChip = (type) => {
  if (type === 'person') {
    return <Chip icon={getIcon('person')} label={'Personal'} color="secondary" size="small" variant="default" />
  } else if (type === 'team') {
    return <Chip icon={getIcon('people')} label={'Team'} color="secondary" size="small" variant="default" />
  } else if (type === 'org') {
    return <Chip icon={getIcon('org')} label={'Organization'} color="secondary" size="small" variant="default" />
  } else if (type === 'jobTitle') {
    return <Chip icon={getIcon('jobTitle')} label={'Job Title'} color="secondary" size="small" variant="default" />
  }
}

export const getRoleChip = (role) => {
  switch (role) {
    case 'admin':
      return <Chip icon={getIcon('admin')} label={'Admin'} color="primary" size="small" variant="default" />
    default:
      return <Chip icon={getIcon('person')} label={'Employee'} color="primary" size="small" variant="default" />
  }
}

export const getSearchChip = (option, onDelete, dismissable = false, size = 'medium', props = null) => {
  const COLOR_GRADE = 100
  const getBaseSearchChip = (icon, color) => {
    return (
      <Chip
        id={`option_key_${option.name}`}
        deleteIcon={getIcon('clear')}
        icon={icon}
        label={`${option.name}`}
        style={{ backgroundColor: color }}
        variant="default"
        size={size}
        onDelete={dismissable ? onDelete : null}
        {...props}
      />
    )
  }

  switch (option?.type) {
    case 'Person':
      return getBaseSearchChip(getIcon('person'), blue[COLOR_GRADE])
    case 'Team':
      return getBaseSearchChip(getIcon('team'), red[COLOR_GRADE])
    case 'Location':
      return getBaseSearchChip(getIcon('location'), purple[COLOR_GRADE])
    case 'Department':
      return getBaseSearchChip(getIcon('department'), cyan[COLOR_GRADE])
    case 'JobTitle':
      return getBaseSearchChip(getIcon('jobTitle'), teal[COLOR_GRADE])
    case 'Organization':
      return getBaseSearchChip(getIcon('org'), brown[COLOR_GRADE])
    default:
      break
  }
}
