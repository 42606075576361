import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  Button,
} from '@material-ui/core'
import { getTypeChip, getEvaluationStatusChip } from 'utils/evaluations'
import { FormatDateIgnoreTz } from 'utils/DateTime'
import { getSingleEvaluationPdf } from 'api'
import { Link as RouterLink } from 'react-router-dom'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Check } from 'utils/PermissionCheck'
import { roles } from 'utils/roles'
import { getIcon } from 'utils/GetIcon'
import FeedbackActionsMenu from './FeedbackActionsMenu'

const useStyles = makeStyles((theme) => ({
  root: {},
  tableHeader: {
    backgroundColor: theme.palette.grey.main,
  },
  tableRow: {
    backgroundColor: theme.palette.grey.light,
  },
}))

const CombinedEvaluationsDetailTable = (props) => {
  const {
    allowUnlock,
    allowDelete,
    refreshCallback,
    rows,
    usePdfButton,
    evaluationBatchId,
    evaluatedPersonName,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleDownloadEvaluation = (row) => {
    getSingleEvaluationPdf({
      evaluationId: row.id,
    }).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Successfully generated evaluation PDF'))
      } else {
        dispatch(enqueueErrorNotification('The server encountered an error. Unable to generate PDF.'))
      }
    })
  }

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table aria-label={'Evaluation Details - '} size="small">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>Feedback By</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="left">Due Date</TableCell>
            <TableCell align="left">Completed</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableRow}>
          {rows.map((row, index) => (
            <TableRow key={`${row.name}_${index}`}>
              <TableCell component="th" scope="row">
                {row.completedByName}
              </TableCell>
              <TableCell align="left">{getTypeChip(row.type)}</TableCell>
              <TableCell align="left">{getEvaluationStatusChip(row.status)}</TableCell>
              <TableCell align="left">{FormatDateIgnoreTz(row.dueDate)}</TableCell>
              <TableCell align="left">{row.isComplete && row.completeDate !== "0001-01-01T00:00:00" ? FormatDateIgnoreTz(row.completeDate) : ''}</TableCell>
              <TableCell align="left">
                {usePdfButton ? (
                  <ButtonGroup size="small" variant="text">
                    <Button
                      startIcon={getIcon('download')}
                      onClick={() => handleDownloadEvaluation(row)}
                      size="small"
                      to={row.url}
                      disabled={!Check([roles.TeamLead, roles.Admin]) && row.type !== 'self'}>
                      Individual Results
                    </Button>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup size="small" variant="text">
                    <Button
                      disabled={row.status === 'inProgress' || row.status === 'ready'}
                      component={RouterLink}
                      to={`/evaluation/results/${row.id}`}>
                      Response
                    </Button>
                    {Check([roles.TeamLead, roles.Admin]) && (
                      <FeedbackActionsMenu
                        evaluation={row}
                        callback={refreshCallback}
                        evaluatedPersonName={evaluatedPersonName}
                        evaluationBatchId={evaluationBatchId}
                        allowUnlock={allowUnlock}
                        allowDelete={allowDelete}
                      />
                    )}
                  </ButtonGroup>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CombinedEvaluationsDetailTable
