import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Container, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  row: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'left',
      fontSize: '28px',
    },
  },
  buttonWrapper: {
    display: 'contents',
    [theme.breakpoints.down('xs')]: {},
  },
  breadcrumbs: { paddingLeft: theme.spacing(1), marginTop: theme.spacing(-1.5), marginBottom: theme.spacing(-1) },
  spacer: {
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Toolbar = (props) => {
  const { title, buttons = [], breadcrumbs, className, tabs, ...rest } = props

  const classes = useStyles()
  return (
    <Paper {...rest} className={clsx(classes.root, className)}>
      <Container>
        {breadcrumbs && <div className={classes.breadcrumbs}>{breadcrumbs}</div>}
        <div className={classes.row}>
          <Typography align="center" className={classes.title} color="primary" component="h1" variant="h1">
            {title}
          </Typography>
          <span className={classes.spacer} />
          <div className={classes.buttonWrapper}>
            {buttons &&
              buttons.map((button, index) => (
                <div className={classes.button} key={`ToolbarButton${index}`}>
                  {' '}
                  {button}{' '}
                </div>
              ))}
          </div>
        </div>
      </Container>
    </Paper>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
}

export default Toolbar
