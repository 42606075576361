import actionTypes from 'redux/actionTypes'

const initialState = {
  loading: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.loadTemplateTableStarted:
      return Object.assign({}, state, {
        loading: true,
      })
    case actionTypes.loadTemplateTableSucceeded:
      return Object.assign({}, state, {
        loading: false,
        active: action.templates,
      })
    case actionTypes.loadArchivedTemplateTableSucceeded:
      return Object.assign({}, state, {
        loading: false,
        archived: action.templates,
      })
    case actionTypes.loadPulseTemplateTableSucceeded:
      return Object.assign({}, state, {
        loading: false,
        pulse: action.templates,
      })
    default:
      return state
  }
}
