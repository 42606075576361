import React from 'react'
import { Switch, FormControlLabel } from '@material-ui/core'
import { useController } from 'react-hook-form'

const SwitchControlled = ({ control, name, label, defaultValue }) => {
  const {
    field: { onChange, value },
  } = useController({ name: name, control: control, defaultValue: defaultValue })
  return (
    <FormControlLabel
      control={<Switch checked={value} onChange={(e) => onChange(e.target.checked)} color="primary" />}
      label={label}
    />
  )
}

export default SwitchControlled
