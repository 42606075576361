import { makeStyles } from '@material-ui/styles'

export const useCombinedEvaluationsTableStyles = makeStyles((theme) => ({
  root: {},
  evalTable: {
    padding: theme.spacing(1),
  },
  filterBar: {
    overflow: 'unset',
  },
  filterTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  evaluationType: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
    fontVariant: 'small-caps',
    fontSize: 16,
    color: theme.palette.secondary.main,
  },
  peerNumber: {
    textDecoration: 'underline',
  },
  link: {
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
}))
