export default [
  {
    category: 'Evaluations',
    title: 'Completed Evaluations',
    description:
      'View all completed evaluations, along with important key information such as scoring (if enabled), completion time, and if the evaluation is signed.',
    route: 'evaluations/completed',
  },
  {
    category: 'Evaluations',
    title: 'Incomplete Evaluations',
    description: 'View all incomplete evaluations, as well as the current status, due date, and overdue status.',
    route: 'evaluations/incomplete',
  },
  {
    category: 'Evaluations',
    title: 'Overdue Evaluations',
    description: 'View all incomplete evaluations that are currently overdue.',
    route: 'evaluations/incomplete?defaultFilter=overdue',
  },
  {
    category: 'Evaluations',
    title: 'Avg Score by Person',
    description: 'View the average score for each person in your org that has completed an evaluation.',
    route: 'evaluations/average-scores',
  },
  {
    category: 'Evaluations',
    title: 'Avg Score by Manager',
    description:
      'View the average score given by each manager in your org that has completed evaluations of their team members.',
    route: 'evaluations/average-score-manager',
  },
  {
    category: 'People',
    title: 'All Employees',
    description:
      'View all employees in your organization, along with the ability to view system information on the user',
    route: 'people/all',
  },
]
// WIP and Broken Reports
// { title: 'Results By Template', category: 'General', route: 'general/by-template' },
