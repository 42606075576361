import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import TabView from 'layouts/Main/components/TabView/TabView'
import PermissionCheck from 'utils/PermissionCheck'
import { roles } from 'utils/roles'
import Toolbar from 'layouts/Main/components/Toolbar/Toolbar'
import AllSchedules from './components/AllSchedules'
import { Container } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { getIcon } from 'utils/GetIcon'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  scheduleTable: {
    width: '100%',
  },
}))

const tabs = [
  {
    label: 'Evaluation Schedules',
    view: <AllSchedules />,
    roles: [roles.Admin],
    route: '/evaluations/schedules',
  },
]

const SchedulesPage = () => {
  const classes = useStyles()
  const location = useLocation()
  const [createButton, setCreateButton] = useState({ label: 'Create Evaluation Schedule', url: '/schedules/create' })

  useEffect(() => {
    if (location.pathname.includes('/pulse/schedules')) {
      setCreateButton({ label: 'Create Pulse Schedule', url: '/pulse/schedules/create' })
    } else {
      setCreateButton({ label: 'Create New Evaluation Schedule', url: '/schedules/create' })
    }
  }, [location])

  return (
    <div className={classes.root} spacing={2}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Schedules | Express Evaluations</title>
      </Helmet>
      <Toolbar
        buttons={[
          <Button component={RouterLink} to={'/evaluations/all'} variant="outlined" startIcon={getIcon('back')}>
            Back to Evaluations
          </Button>,
          <PermissionCheck
            key={`schedule-eval-button`}
            roles={['org_admin']}
            yes={() => (
              <Button variant="contained" color="primary" component={RouterLink} to={createButton.url}>
                {createButton.label}
              </Button>
            )}
          />,      
        ]}
        title="Evaluation Schedules"
      />
      <Container>
        <TabView tabs={tabs} />
      </Container>
    </div>
  )
}

export default SchedulesPage
