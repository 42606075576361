import React from 'react'
import { Grid, ListItemText, List, ListItem, ListItemAvatar, Avatar, Divider } from '@material-ui/core'
import { useCreateScheduleStyles } from 'styles'
import { getIcon } from 'utils/GetIcon'

const ScheduleSummary = (props) => {
  const { peopleCount, employeeSignature, managerSignature, isPulse, isEdit, includeGoalCreation, includeGoalReview, template } = props
  const classes = useCreateScheduleStyles()

  const sendToSummary = () => {
    let summary = []
    if (template?.selfSections) {
      summary.push(' - Employee')
    }

    if (template?.leadSections) {
      summary.push(' - Manager')
    }

    if (template?.peerSections) {
      summary.push(' - Peer')
    }
    return summary.join('')
  }
  return (
    <Grid item sm={12} xs={12} className={classes.summary}>
      <List dense>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={isPulse ? classes.summaryAvatarPulse : classes.summaryAvatar}>
              {getIcon('evaluation')}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${
              isPulse ? 'Pulse Surveys' : 'Evaluations'
            }  will be sent to these groups:`}
            secondary={sendToSummary()}
          />
        </ListItem>
        {!isPulse &&
          <ListItem>
            <ListItemAvatar>
              <Avatar className={isPulse ? classes.summaryAvatarPulse : classes.summaryAvatar}>
                {getIcon('signature')}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Employee signature: ${employeeSignature ? 'Yes' : 'No'}`} secondary={`Manager signature: ${managerSignature ? 'Yes' : 'No'}`} />
          </ListItem>
        }
        <ListItem>
          <ListItemAvatar>
            <Avatar className={isPulse ? classes.summaryAvatarPulse : classes.summaryAvatar}>
              {getIcon('person')}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${peopleCount || 0} Employee(s)`} secondary={`will be ${isEdit ? 'added to' : 'included on'} this schedule`} />
        </ListItem>
        {!isPulse &&
          <ListItem>
            <ListItemAvatar>
              <Avatar className={isPulse ? classes.summaryAvatarPulse : classes.summaryAvatar}>
                {getIcon('goal')}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Goal Review: ${includeGoalReview ? 'Yes' : 'No'}`} secondary={`Goal Creation: ${includeGoalCreation ? 'Yes' : 'No'}`} />
          </ListItem>
        }
      </List>
      <Divider />
    </Grid>
  )
}

export default ScheduleSummary
