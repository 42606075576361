import React from 'react'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import PermissionCheck from 'utils/PermissionCheck'

const PermissionButton = (props) => {
  const { roles, tooltip, disabledTooltip, disabled, onClick, color, variant, text, startIcon } = props

  return (
    <PermissionCheck
      roles={roles}
      yes={() => (
        <Tooltip title={disabled ? disabledTooltip : tooltip || ''}>
          <div>
            <Button startIcon={startIcon} disabled={disabled} onClick={onClick} color={color} variant={variant}>
              {text}
            </Button>
          </div>
        </Tooltip>
      )}
    />
  )
}

export default PermissionButton

PermissionButton.propTypes = {
  roles: PropTypes.array.isRequired,
  name: PropTypes.string,
  savefunction: PropTypes.func,
  disabledTooltip: PropTypes.string,
}
