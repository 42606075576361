import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography,
  InputAdornment,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { getScoringBands, updateScoringBands } from 'api'
import ClearIcon from '@material-ui/icons/Clear'
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/notificationsActions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  notifyInput: {
    marginLeft: theme.spacing(2),
    textAlign: 'center',
  },
  notifyUnit: {
    minWidth: 80,
  },
  orgButton: {
    marginTop: theme.spacing(2),
  },
  orgEvents: {
    maxWidth: '350px',
  },
}))

const ScoringSettings = (props) => {
  const { className, ...rest } = props
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args))
  const dispatch = useDispatch()
  const classes = useStyles()
  const [bands, setBands] = useState([
    {
      low: 0,
      high: 50,
      label: 'Needs Improvement',
    },
    {
      low: 51,
      high: 70,
      label: 'Satisfactory',
    },
    {
      low: 71,
      high: 100,
      label: 'Exceeded Expectations',
    },
  ])
  const [bandsEnabled, setBandsEnabled] = useState(false)

  useEffect(() => {
    getScoringBands().then((response) => {
      if (response.data.bands) {
        setBands(response.data.bands)
      }

      setBandsEnabled(response.data.isEnabled)
    })
  }, [])

  const updateBandSettings = () => {
    updateScoringBands({ isEnabled: bandsEnabled, bands: bands }).then((response) => {
      if (response.status === 200) {
        enqueueSnackbar({
          message: `Your scoring settings have been saved successfully.`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      } else {
        enqueueSnackbar({
          message: `Something went wrong. Unable to save your scoring settings.`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      }
    })
  }

  const addBand = () => {
    let newBands = bands
    newBands.push({ low: 99, high: 100, label: 'New' })
    newBands = checkBands(newBands)
    setBands([...newBands])
  }

  const removeBand = (index) => {
    let newBands = bands
    newBands.splice(index, 1)
    newBands = checkBands(newBands)
    setBands([...newBands])
  }

  const checkBands = (processBands) => {
    let newBands = processBands

    newBands.forEach((band, index) => {
      if (index === 0) {
        band.low = 0
      }

      let maxHighAmount = 100 - (newBands.length - index)
      if (band.high > maxHighAmount) {
        band.high = maxHighAmount
      }
      if (index === newBands.length - 1) {
        band.high = 100
      }
      if (index !== 0) {
        if (band.low <= processBands[index - 1].high) {
          band.low = processBands[index - 1].high + 1
        }
      }
      if (band.low >= band.high) {
        band.low = band.high - 1
      }
    })

    return newBands
  }

  const handleValueUpdate = (value, index, type) => {
    let newBands = bands
    newBands[index][type] = parseInt(value)
    if (type === 'low') {
      newBands[index - 1]['high'] = parseInt(value) - 1
    }
    if (type === 'high') {
      newBands[index + 1]['low'] = parseInt(value) + 1
    }
    newBands = checkBands(newBands)
    setBands([...newBands])
  }

  const handleLabelChange = (value, index) => {
    let newBands = bands
    newBands[index]['label'] = value
    setBands([...newBands])
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subheader="Manage your company's general settings" title="General" />
      <Divider />
      <CardContent>
        <Grid container spacing={2} wrap="wrap">
          <Grid item xs={8}>
            <Typography variant="h6">Scoring Bands</Typography>
            <Typography variant="body1">
              Configure how your scored evalutions scores will appear on evaluation results. Add multiple ranges with a
              high and low percentage, with a corresponding label. Scores that fall within these ranges will receive the
              provided label. Make sure that your ranges do not contain any gaps.
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              control={<Switch checked={bandsEnabled} onChange={() => setBandsEnabled(!bandsEnabled)} />}
              label="Enable Scoring Bands"
            />
          </Grid>
          {bandsEnabled && (
            <>
              <Grid item xs={9}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Low</TableCell>
                        <TableCell align="left">High</TableCell>
                        <TableCell align="left">Label</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bands &&
                        bands.map((row, index) => (
                          <TableRow key={`score_band_${index}`}>
                            <TableCell align="left" style={{ width: 100 }}>
                              <TextField
                                value={row.low}
                                type="number"
                                InputProps={{
                                  inputProps: { min: 0, max: 100 - bands.length + index },
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                disabled={index === 0}
                                onChange={(e) => handleValueUpdate(e.target.value, index, 'low')}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell align="left" style={{ width: 100 }}>
                              <TextField
                                value={row.high}
                                type="number"
                                fullWidth
                                InputProps={{
                                  // inputProps: {
                                  //   min: row.low + 1,
                                  //   max: index === bands.length - 1 ? 100 : bands[index + 1].low - 1,
                                  // },
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                onChange={(e) => handleValueUpdate(e.target.value, index, 'high')}
                                disabled={index === bands.length - 1}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                variant="outlined"
                                value={row.label}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">Label</InputAdornment>,
                                }}
                                fullWidth
                                onChange={(e) => handleLabelChange(e.target.value, index)}
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton disabled={bands.length === 1} onClick={() => removeBand(index)}>
                                <ClearIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      <TableRow>
                        <TableCell>
                          <Button onClick={addBand} color="primary">
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
          <Grid item xs={8}>
            <Button variant="contained" color="primary" className={classes.orgButton} onClick={updateBandSettings}>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ScoringSettings
