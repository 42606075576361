import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from '@material-ui/core'
import { updateOrgSetting } from 'api'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

const OrgSettingListItem = (props) => {
  const { setting } = props

  const [settingItem, setSettingItem] = useState(setting)

  useEffect(() => {
    updateOrgSetting(settingItem)
  }, [settingItem])

  const handleSettingChange = (newValue) => {
    setSettingItem({ ...settingItem, inputValues: newValue })
  }

  const classes = useStyles()

  return (
    <ListItem className={classes.root}>
      <ListItemText primary={settingItem.displayName} />
      <ListItemSecondaryAction>
        {settingItem.inputType === 'switch' && (
          <Switch
            checked={settingItem.inputValues}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            name="checkedA"
            onChange={(e) => handleSettingChange(e.target.checked)}
          />
        )}
        {settingItem.inputType === 'date' && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              format="MMM dd"
              onChange={(e) => handleSettingChange(e)}
              value={settingItem.inputValues}
              variant="inline"
              views={['month', 'date']}
            />
          </MuiPickersUtilsProvider>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

OrgSettingListItem.propTypes = {
  className: PropTypes.string,
}

export default OrgSettingListItem
