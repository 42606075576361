import React from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import hexToRGB from 'helpers/hexToRGB'

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      'linear-gradient(90deg,' +
      hexToRGB(theme.palette.primary.main) +
      '0%,' +
      hexToRGB(theme.palette.secondary.main) +
      ' 120%)',
    height: '380px',
    color: 'white',
    position: 'relative',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '320px',
    },
  },
  content: {
    color: 'inherit',
    height: 'inherit',
  },
  heroText: { margin: theme.spacing(2), textAlign: 'center' },
}))

export default function BookDemo() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justify="center" className={classes.content}>
        <div>
          <Typography variant="h2" color="inherit" className={classes.heroText}>
            See how Express Evaluations can help
          </Typography>
          <Grid item align="center">
            <a
              href="https://outlook.office365.com/owa/calendar/ExpressEvaluations1@expressevaluations.com/bookings/"
              target="_blank"
              rel="noopener noreferrer">
              <Button variant="contained">Book a Demo</Button>
            </a>
          </Grid>
        </div>
      </Grid>
    </div>
  )
}
