import React, { useState, useEffect, useCallback, createRef } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { Grid, IconButton, TextField, Link, FormHelperText, Checkbox, Typography, Divider } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { signup } from 'api'
import loginUser from 'utils/Auth'
import { SaveCircularStatusButton } from 'components/Buttons'
import { InlineError } from 'helpers'
import PasswordValidation from 'components/Auth/PasswordValidation'
import { signUpValidations } from 'validations'
import { useSignUpStyles } from 'styles'
import { TimezoneSearch } from 'components/Search'
import DatePicker from 'components/Inputs/DatePicker'
import ReCAPTCHA from 'react-google-recaptcha'
import { timezones } from 'helpers/timezones'

const schema = signUpValidations()

const SignUp = (props) => {
  const { history } = props
  const recaptchaRef = createRef()
  const defaultTimezone = () => {
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return timezones.filter((value) => value.utc.includes(timezone))[0] || {}
  }
  const classes = useSignUpStyles()

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      timezone: defaultTimezone(),
      hireDate: new Date(),
    },
    touched: {},
    errors: {},
  })

  const [formErrors, setFormErrors] = useState([])
  const [passwordIsValid, setPasswordIsValid] = useState(false)

  const handlePasswordIsValid = useCallback((value) => {
    setPasswordIsValid(value)
  }, [])

  useEffect(() => {
    setFormErrors([])
    const errors = validate(formState.values, schema)

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleChange = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleTimezoneChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        timezone: value,
      },
      touched: {
        ...formState.touched,
        timezone: true,
      },
    }))
  }

  const handleReCAPTCHAChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        recaptcha: true,
      },
      touched: {
        ...formState.touched,
        recaptcha: true,
      },
    }))
  }

  const handleDateChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        hireDate: value,
      },
      touched: {
        ...formState.touched,
        hireDate: true,
      },
    }))
  }

  const handleBack = () => {
    history.goBack()
  }

  const loginErrorCallback = (error) => {
    let errorMessage
    if (error.code === 'too_many_attempts') {
      errorMessage = `Too many login attempts: ${error.description}`
    } else if (error.code === 'access_denied') {
      errorMessage = `Access Denied: ${error.description}`
    }

    setFormState({
      ...formState,
      isValid: false,
      errors: {
        confirmPassword: [errorMessage],
      },
      touched: {
        confirmPassword: true,
      },
    })
  }

  const handleSignUp = async () => {
    setFormErrors([])
    signup({
      firstName: formState.values.firstName,
      middleName: formState.values.middleName,
      lastName: formState.values.lastName,
      email: formState.values.email,
      password: formState.values.password,
      confirmPassword: formState.values.confirmPassword,
      orgName: formState.values.org,
      hireDate: formState.values.hireDate,
      jobTitle: formState.values.jobTitle,
      timezone: formState.values.timezone.utc[0] || 'America/Denver',
    }).then((response) => {
      if (response.status === 200) {
        loginUser(formState.values.email, formState.values.password, loginErrorCallback)
      } else {
        setFormErrors(response.data.errors)
      }
    })
  }

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false)

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Welcome to Express Evaluations
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignUp}>
                <Typography className={classes.title} variant="h2">
                  Create new account
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  Use your email to create new account
                </Typography>
                <Typography variant="h4">Your Information</Typography>
                <Divider />
                <TextField
                  className={classes.textField}
                  error={hasError('firstName')}
                  fullWidth
                  helperText={hasError('firstName') ? formState.errors.firstName[0] : null}
                  label="First name"
                  name="firstName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.firstName || ''}
                  variant="outlined"
                  required
                />
                <InlineError errors={formErrors} field="FirstName" />
                <TextField
                  className={classes.textField}
                  error={hasError('middleName')}
                  fullWidth
                  helperText={hasError('middleName') ? formState.errors.middleName[0] : null}
                  label="Middle name"
                  name="middleName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.firstName || ''}
                  variant="outlined"
                  required
                />
                <InlineError errors={formErrors} field="MiddleName" />
                <TextField
                  className={classes.textField}
                  error={hasError('lastName')}
                  fullWidth
                  helperText={hasError('lastName') ? formState.errors.lastName[0] : null}
                  label="Last name"
                  name="lastName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.lastName || ''}
                  variant="outlined"
                  required
                />
                <InlineError errors={formErrors} field="LastName" />
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={hasError('email') ? formState.errors.email[0] : null}
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                  required
                />
                <InlineError errors={formErrors} field="Email" />
                <DatePicker
                  setValue={handleDateChange}
                  title={'Hire Date'}
                  error={hasError('hireDate')}
                  value={formState.values.hireDate}
                  style={{ marginTop: 15 }}
                />
                <InlineError errors={formErrors} field="HireDate" />
                <TextField
                  className={classes.textField}
                  error={hasError('org')}
                  fullWidth
                  label="Job Title"
                  name="jobTitle"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.jobTitle || ''}
                  variant="outlined"
                />
                <InlineError errors={formErrors} field="jobTitle" />

                <Typography variant="h4" style={{ marginTop: 10 }}>
                  Organization Information
                </Typography>
                <Divider />
                <TextField
                  className={classes.textField}
                  error={hasError('org')}
                  fullWidth
                  label="Organization name"
                  name="org"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.org || ''}
                  variant="outlined"
                  helperText="All members of your organization will see this as the organization name"
                  required
                />
                <InlineError errors={formErrors} field="OrgName" />
                <TimezoneSearch
                  label="Organization Timezone"
                  required={true}
                  onChange={handleTimezoneChange}
                  value={formState.values.timezone}
                  name="timezone"
                />
                <InlineError errors={formErrors} field="Timezone" />
                <Typography variant="h4" style={{ marginTop: 10 }}>
                  Login Information
                </Typography>
                <Divider />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={hasError('password') ? formState.errors.password[0] : null}
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                  required
                />
                <InlineError errors={formErrors} field="Password" />
                <TextField
                  className={classes.textField}
                  error={hasError('confirmPassword')}
                  fullWidth
                  helperText={hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null}
                  label="Confirm Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.confirmPassword || ''}
                  variant="outlined"
                />
                <InlineError errors={formErrors} field="ConfirmPassword" />
                <PasswordValidation
                  confirmPassword={formState.values.confirmPassword}
                  password={formState.values.password}
                  isValid={passwordIsValid}
                  setIsValid={handlePasswordIsValid}
                />
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.policy || false}
                    className={classes.policyCheckbox}
                    color="secondary"
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography className={classes.policyText} color="textSecondary" variant="body1">
                    I have read the{' '}
                    <Link color="secondary" component={RouterLink} to="#" underline="always" variant="h6">
                      Terms and Conditions
                    </Link>
                  </Typography>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                  onChange={handleReCAPTCHAChange}
                  style={{ marginBottom: 20, marginTop: 20 }}
                />
                {hasError('policy') && <FormHelperText error>{formState.errors.policy[0]}</FormHelperText>}
                <SaveCircularStatusButton
                  className={classes.signUpButton}
                  size="large"
                  postlabel="Creating account..."
                  prelabel="Sign Up"
                  fullWidth
                  disabled={!formState.isValid}
                  savefunction={handleSignUp}
                />
                <Typography color="textSecondary" variant="body1">
                  Have an account?{' '}
                  <Link component={RouterLink} to="/login" variant="h6">
                    Sign in
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SignUp.propTypes = {
  history: PropTypes.object,
}

export default withRouter(SignUp)
