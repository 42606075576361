import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography, Link, Button } from '@material-ui/core'
import { getIcon } from 'utils/GetIcon'
import Topbar from '../../layouts/Minimal/components/Topbar'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: 'center',
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
  fourohfour: {
    fontSize: '90px',
    fontWeight: 450,
    color: theme.palette.primary.light,
    lineHeight: 'unset',
  },
  pageNotFound: {
    fontSize: '48px',
    fontWeight: 350,
    color: theme.palette.primary.light,
    lineHeight: 'unset',
    marginBottom: theme.spacing(2),
    marginTop: -theme.spacing(2),
  },
}))

const NotFound = (props) => {
  const classes = useStyles()
  const { history } = props

  const handleBack = () => {
    history.goBack()
  }

  return (
    <div className={classes.root}>
      <Topbar />
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1" className={classes.fourohfour}>
              404
            </Typography>
            <Typography variant="h1" className={classes.pageNotFound}>
              (page not found)
            </Typography>

            <Typography variant="subtitle1">
              If you think this was a mistake,{' '}
              <Link
                color="secondary"
                href="mailto:support@expressevaluations.com?subject=Oops - I got stuck on a 404 page">
                let us know
              </Link>
            </Typography>
            <Button startIcon={getIcon('back')} onClick={handleBack}>
              Go Back
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default NotFound
