import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { List, ListItem, ListItemText } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    maxHeight: '370px',
    overflow: 'auto',
  },
  emptyMessage: {
    margin: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  viewAllWrapper: {
    marginRight: theme.spacing(2.5),
    marginTop: theme.spacing(1),
  },
  viewAll: {
    color: theme.palette.secondary.primary,
  },
  title: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))

const ListWidget = (props) => {
  const { title, listItems = [], emptyListMessage, viewMoreText, viewMoreLinkText, viewMoreRoute } = props
  const classes = useStyles()
  const itemCount = listItems.length
  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Card component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        <List dense>
          {itemCount > 0 ? (
            listItems
          ) : (
            <ListItem>
              <ListItemText primary="No records to display" />
            </ListItem>
          )}
        </List>

        {listItems.length === 0 && (
          <Typography variant="h5" className={classes.emptyMessage}>
            {emptyListMessage}
          </Typography>
        )}
      </Card>
      <Typography variant="body2" align="right" className={classes.viewAllWrapper}>
        {viewMoreText}
        {viewMoreRoute && (
          <Typography variant="body2" color="secondary" component={Link} to={viewMoreRoute}>
            {viewMoreLinkText}
          </Typography>
        )}
      </Typography>
    </div>
  )
}

export default ListWidget
