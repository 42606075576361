import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { getExternalIds } from 'api'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  evalTable: {
    width: '100%',
  },
  inviteCard: {
    marginTop: theme.spacing(4),
    maxWidth: '50%',
  },
}))

const PersonExternalIdTable = (props) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [people, setPeople] = useState([])

  const headCells = [
    { field: 'fullName', title: 'Name', searchable: true },
    { field: 'email', title: 'Email', searchable: true },
    { field: 'externalId', title: 'External Id', searchable: true },
  ]

  useEffect(() => {
    setIsLoading(true)
    getExternalIds().then((response) => {
      if (response.status === 200) {
        setPeople(response.data)
        setIsLoading(false)
      }
    })
  }, [])

  return (
    <Grid container className={classes.root}>
      <Grid item md={12} xs={12}>
        <MaterialTable
          columns={headCells}
          data={people}
          options={{
            emptyRowsWhenPaging: false,
            selection: false,
            showTitle: false,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
          title="Scheduled Evaluations"
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  )
}

export default PersonExternalIdTable
