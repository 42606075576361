import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import withGoogleAnalytics from 'withGoogleAnalytics'
import { PrivateRouteWithLayout, RouteWithLayout } from './components'
import { Main as MainLayout, Minimal as MinimalLayout, Focus as FocusLayout } from './layouts'
import { roles } from 'utils/roles'
import {
  Evaluations as EvaluationsView,
  Dashboard as DashboardView,
  EditEmployee as EditEmployeeView,
  Profile as ProfileView,
  People as PeopleView,
  Evaluation as EvaluationView,
  TemplateBuilder as TemplateBuilderView,
  EvaluationResults as EvaluationResultsView,
  SelfEvaluationRedirect as SelfEvaluationRedirectView,
  Goals as GoalsView,
  GoalDetails as GoalDetailsView,
  GoalEdit as GoalEditView,
  GoalCreate as GoalCreateView,
  EvaluationTemplates as EvaluationTemplatesView,
  Reports as ReportsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Settings as SettingsView,
  CorsFallback as CorsFallbackView,
  PasswordReset as PasswordResetView,
  PulseSurvey as PulseSurveyView,
  NotAllowed as NotAllowedView,
  NotAvailable as NotAvailableView,
  FeatureNotEnabled as FeatureNotEnabledView,
  Import as ImportView,
  KnowYourTalents as KnowYourTalentsView,
  PulseResults as PulseResultsView,
  SchedulesPage as SchedulesPageView,
  ScheduleEdit as ScheduleEditView,
  ScheduleCreate as ScheduleCreateView,
} from './views'

const Routes = () => {
  return (
    <Switch>
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(DashboardView)}
        exact
        layout={MainLayout}
        path="/"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(SettingsView)}
        exact
        layout={MainLayout}
        path="/settings/:category/:page"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EditEmployeeView)}
        exact
        layout={MainLayout}
        path="/employee/edit/:employeeId"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(SelfEvaluationRedirectView)}
        exact
        layout={MainLayout}
        path={'/evaluation/self'}
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ProfileView)}
        exact
        layout={MainLayout}
        path="/profile/:personId"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ProfileView)}
        exact
        layout={MainLayout}
        path="/profile/:personId/evaluations"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ProfileView)}
        exact
        layout={MainLayout}
        path="/profile/:personId/goals"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ProfileView)}
        exact
        layout={MainLayout}
        path="/profile/:personId/kyt"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ProfileView)}
        exact
        layout={MainLayout}
        path="/profile/:personId/reports/extended"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ProfileView)}
        exact
        layout={MainLayout}
        path="/profile/:personId/reports/direct"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(GoalsView)}
        exact
        layout={MainLayout}
        path="/goals"
        roles={[roles.Member]}
        // disableRoute={!appMetadata.accessGoals}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(GoalCreateView)}
        exact
        layout={FocusLayout}
        path="/goals/create"
        roles={[roles.Member, roles.TeamLead, roles.Admin]}
        // disableRoute={!appMetadata.accessGoals}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(GoalEditView)}
        exact
        layout={FocusLayout}
        path="/goals/:goalId/edit"
        roles={[roles.Member]}
        // disableRoute={!appMetadata.accessGoals}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(GoalDetailsView)}
        exact
        layout={FocusLayout}
        path="/goals/:goalId/view"
        roles={[roles.Member]}
        // disableRoute={!appMetadata.accessGoals}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(GoalsView)}
        exact
        layout={MainLayout}
        path="/goals/team"
        roles={[roles.TeamLead]}
        // disableRoute={!appMetadata.accessGoals}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(GoalsView)}
        exact
        layout={MainLayout}
        path="/goals/organization"
        roles={[roles.Admin]}
        // disableRoute={!appMetadata.accessGoals}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(GoalsView)}
        exact
        layout={MainLayout}
        path="/goals/all"
        roles={[roles.Admin]}
        // disableRoute={!appMetadata.accessGoals}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PeopleView)}
        exact
        layout={MainLayout}
        path="/people"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PeopleView)}
        exact
        layout={MainLayout}
        path="/people/managers"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PeopleView)}
        exact
        layout={MainLayout}
        path="/people/teams"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PeopleView)}
        exact
        layout={MainLayout}
        path="/people/departments"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PeopleView)}
        exact
        layout={MainLayout}
        path="/people/locations"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PeopleView)}
        exact
        layout={MainLayout}
        path="/people/jobtitles"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PeopleView)}
        exact
        layout={MainLayout}
        path="/people/lead/teams"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PeopleView)}
        exact
        layout={MainLayout}
        path="/people/archived"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ImportView)}
        exact
        layout={MainLayout}
        path="/people/import"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationView)}
        exact
        layout={FocusLayout}
        path="/evaluation/view/:evaluationId"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationsView)}
        exact
        layout={MainLayout}
        path="/evaluations"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationsView)}
        exact
        layout={MainLayout}
        path="/evaluations/all"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationsView)}
        exact
        layout={MainLayout}
        path="/evaluations/teams"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationsView)}
        exact
        layout={MainLayout}
        path="/evaluations/past"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationsView)}
        exact
        layout={MainLayout}
        path="/evaluations/schedules"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(TemplateBuilderView)}
        exact
        layout={FocusLayout}
        path="/evaluation/template/build"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationResultsView)}
        exact
        layout={MainLayout}
        path="/evaluation/results/:id"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationResultsView)}
        exact
        layout={FocusLayout}
        path="/evaluation/results/combined/:id"
        roles={[roles.Admin, roles.TeamLead, roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PulseSurveyView)}
        exact
        layout={MainLayout}
        path="/pulse"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PulseSurveyView)}
        exact
        layout={MainLayout}
        path="/pulse/schedules"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ScheduleCreateView)}
        exact
        layout={FocusLayout}
        path="/pulse/schedules/create"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ScheduleEditView)}
        exact
        layout={FocusLayout}
        path="/pulse/schedules/edit/:scheduleId"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PulseResultsView)}
        exact
        layout={FocusLayout}
        path="/pulse/results/:scheduleId"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(PulseSurveyView)}
        exact
        layout={MainLayout}
        path="/pulse/templates"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(TemplateBuilderView)}
        exact
        layout={FocusLayout}
        path="/pulse/template/build/:urlTemplateId"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(TemplateBuilderView)}
        exact
        layout={FocusLayout}
        path="/pulse/template/build"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationView)}
        exact
        layout={FocusLayout}
        path="/pulse/template/preview/:evaluationId"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(TemplateBuilderView)}
        exact
        layout={FocusLayout}
        path="/evaluation/template/build/:urlTemplateId"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationView)}
        exact
        layout={FocusLayout}
        path="/evaluation/template/preview/:evaluationId"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(DashboardView)}
        exact
        layout={MainLayout}
        path="/dashboard"
        roles={[roles.Member]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(DashboardView)}
        exact
        layout={MainLayout}
        path="/dashboard/team"
        roles={[roles.Admin, roles.TeamLead]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(DashboardView)}
        exact
        layout={MainLayout}
        path="/dashboard/org"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ReportsView)}
        exact
        layout={MainLayout}
        path="/reports"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ReportsView)}
        exact
        layout={MainLayout}
        path="/reports/view/:category/:report"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationTemplatesView)}
        exact
        layout={MainLayout}
        path="/templates"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationTemplatesView)}
        exact
        layout={MainLayout}
        path="/templates/archive"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(EvaluationTemplatesView)}
        exact
        layout={MainLayout}
        path="/templates/pulse"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(KnowYourTalentsView)}
        exact
        layout={MainLayout}
        path="/talents"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(KnowYourTalentsView)}
        exact
        layout={MainLayout}
        path="/talents/external-ids"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(SchedulesPageView)}
        exact
        layout={MainLayout}
        path="/schedules"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(SchedulesPageView)}
        exact
        layout={MainLayout}
        path="/schedules/timeline"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ScheduleCreateView)}
        exact
        layout={FocusLayout}
        path="/schedules/create"
        roles={[roles.Admin]}
      />
      <PrivateRouteWithLayout
        component={withGoogleAnalytics(ScheduleEditView)}
        exact
        layout={FocusLayout}
        path="/schedules/edit/:scheduleId"
        roles={[roles.Admin]}
      />
      <RouteWithLayout
        component={withGoogleAnalytics(process.env.REACT_APP_ACTIVATE_SIGNUP === 'true' ? SignUpView : SignInView)}
        exact
        layout={MinimalLayout}
        path="/signup"
      />
      <RouteWithLayout
        component={withGoogleAnalytics(EvaluationView)}
        layout={FocusLayout}
        path="/pulse/survey/:token"
      />
      <RouteWithLayout component={withGoogleAnalytics(SignInView)} exact layout={MinimalLayout} path="/login" />
      <RouteWithLayout component={withGoogleAnalytics(NotFoundView)} exact layout={MinimalLayout} path="/not-found" />
      <RouteWithLayout component={withGoogleAnalytics(NotAllowedView)} exact layout={MainLayout} path="/not-allowed" />
      <RouteWithLayout
        component={withGoogleAnalytics(NotAvailableView)}
        exact
        layout={MainLayout}
        path="/not-available"
      />
      <RouteWithLayout
        component={withGoogleAnalytics(FeatureNotEnabledView)}
        exact
        layout={MainLayout}
        path="/feature-not-enabled"
      />
      <RouteWithLayout layout={MinimalLayout} exact component={CorsFallbackView} path="/cors" />
      <RouteWithLayout layout={MinimalLayout} exact component={PasswordResetView} path="/password-reset" />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
