import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Card, CardHeader, CardContent, Grid, Divider, Typography, FormControlLabel, Switch } from '@material-ui/core'
import { getSignatureSettings, updateSignatureSettings } from 'api'
import { SaveCircularStatusButton } from 'components/Buttons'
import { enqueueSnackbar } from 'redux/actions/notificationsActions'
import { useDispatch } from 'react-redux'
import { RichTextArea } from 'components/Inputs'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  notifyInput: {
    marginLeft: theme.spacing(2),
    textAlign: 'center',
  },
  notifyUnit: {
    minWidth: 80,
  },
  settingSectionHeader: {
    marginBottom: theme.spacing(2),
  },
}))

const SignatureSettings = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [signaturesEnabled, setSignaturesEnabled] = useState(false)
  const [signatureDescription, setSignatureDescription] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    getSignatureSettings().then((response) => {
      setSignaturesEnabled(response.data.isEnabled)
      setSignatureDescription(response.data.description)
      setIsLoading(false)
    })
  }, [])

  const handleEditorChange = (content, editor) => {
    setSignatureDescription(content)
  }

  const updateSettings = async () => {
    updateSignatureSettings({ description: signatureDescription, isEnabled: signaturesEnabled }).then((response) => {
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: `Evaluation signature setting updated `,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        )
      } else {
        dispatch(
          enqueueSnackbar({
            message: `The system has encountered an error. Your setting was not saved.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          }),
        )
      }
    })
  }

  return (
    !isLoading && (
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader subheader="Manage evaluation signature settings" title="Signature Settings" />
        <Divider />
        <CardContent>
          <Grid container spacing={4} wrap="wrap">
            <Grid item xs={12}>
              <Typography variant="body1">
                When enabled, this feature allows people to review and sign their evaluation after it has been
                completed. The description below will be displayed as instructions in the signature block area
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch checked={signaturesEnabled} onChange={() => setSignaturesEnabled(!signaturesEnabled)} />
                }
                label="Enable Signatures"
              />
            </Grid>
            {signaturesEnabled && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.settingSectionHeader}>
                    Description Displayed in Signature Block
                  </Typography>
                  <RichTextArea
                    initialValue={
                      '<p>By signing below, you acknowledge that you have reviewed the results and agree to the current contents of this evaluation.</p>'
                    }
                    value={signatureDescription}
                    onChange={handleEditorChange}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <SaveCircularStatusButton
                className={classes.signInButton}
                size="small"
                postLabel="Saving Settings..."
                preLabel="Save Settings"
                onClick={updateSettings}
                type="submit"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  )
}

export default SignatureSettings
