import { getIcon } from 'utils/GetIcon'

export const getMultiChoiceIcon = (type, allowMultiple) => {
  switch (type) {
    case 'MultiChoice':
      return allowMultiple ? getIcon('uncheckedCheckbox') : getIcon('uncheckedRadio')
    case 'Dropdown':
      return getIcon('dropdown')
    default:
      return getIcon('uncheckedRadio')
  }
}

export const calculateMaxScore = (config) => {
  let maxScore = 0
  if (config.options) {
    config.options.forEach((option) => {
      if (config.allowMultiple) {
        maxScore = option.score > 0 ? maxScore + option.score : maxScore
      } else {
        if (option.score > maxScore) {
          maxScore = option.score
        }
      }
    })
    return maxScore
  }
  return config.maxScore
}

export const newSection = (trackingId, isPulse) => {
  return {
    title: ``,
    helpText: '',
    position: 0,
    isActive: true,
    evaluation: {},
    questions: [],
    score: 0,
    shouldAutoFocus: true,
    showInSelf: true,
    showInPeer: false,
    showInLead: !isPulse,
    isRequired: true,
    sys_id: trackingId,
  }
}

export const newQuestion = (trackingId) => {
  return {
    title: '',
    type: 'MultiChoice',
    helpText: '',
    position: 0,
    isActive: true,
    weight: 1,
    isRequired: true,
    section: {},
    config: { maxScore: 0, options: [], allowMultiple: false, showDescription: false },
    shouldAutoFocus: true,
    sys_id: trackingId,
  }
}
