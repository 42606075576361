import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { completedEvaluations } from 'api'
import { getIcon } from 'utils/GetIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  evalTable: {
    width: '100%',
  },
}))

const CompletedEvaluationsTable = () => {
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const classes = useStyles()

  const headCells = [
    { field: 'evaluationTitle', title: 'Evaluation' },
    { field: 'evaluatedPersonName', title: 'Evaluated Person' },
    { field: 'evaluatorName', title: 'Completed By' },
    {
      field: 'evaluationType',
      title: 'Eval Type',
      lookup: { self: 'Self', lead: 'Lead' },
    },
    {
      field: 'status',
      title: 'Status',
      lookup: { signed: 'Signed', complete: 'Complete' },
      hidden: true,
    },
    {
      field: 'dueDate',
      title: 'Due Date',
      type: 'date',
      filtering: false,
      hidden: true,
    },
    {
      field: 'completedDate',
      title: 'Completed Date',
      type: 'date',
      filtering: false,
    },
    {
      field: 'numericScore',
      title: 'Raw Score',
      filtering: false,
      hidden: true,
    },
    {
      field: 'customScore',
      title: 'Custom Score',
      render: (row) => (row.customScore === 'Unscored' ? <i>Unscored</i> : row.customScore),
    },
    {
      field: 'isSigned',
      title: 'Signed?',
      type: 'boolean',
      lookup: { true: 'Yes', false: 'No' },
      render: (row) => row.isSigned && getIcon('done'),
    },
    {
      field: 'isOverdue',
      title: 'Overdue when Completed?',
      lookup: { true: 'Yes', false: 'No' },
      hidden: true,
    },
    {
      field: 'batchEvaluationId',
      title: 'Batch ID',
      hidden: true,
    },
  ]

  useEffect(() => {
    completedEvaluations().then((response) => {
      setRows(response)
      setIsLoading(false)
    })
  }, [])

  return (
    <MaterialTable
      className={classes.evalTable}
      columns={headCells}
      data={rows}
      options={{
        actionsColumnIndex: -1,
        columnsButton: true,
        emptyRowsWhenPaging: false,
        exportButton: true,
        exportAddData: true,
        exportFileName: `Completed Evaluations as of ${new Date().toDateString()}`,
        filtering: true,
        grouping: true,
        maxBodyHeight: '550px',
        pageSize: 100,
        pageSizeOptions: [100, 250, 500, 1000],
        search: false,
      }}
      title="Completed Evaluations"
      isLoading={isLoading}
    />
  )
}

export default CompletedEvaluationsTable
