import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Button, Container } from '@material-ui/core'
import { getPersonById, getAllPeople, updateEmployee } from 'api'
import EmployeeDetails from './components/employeeDetails'
import Toolbar from '../../layouts/Main/components/Toolbar/Toolbar'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    marginTop: 0,
  },
}))

const EditEmployee = () => {
  const classes = useStyles()
  const { employeeId } = useParams()
  const [employee, setEmployee] = useState({})
  const [allEmployees, setAllEmployees] = useState([])
  const buttons = [
    <Button className={classes.importButton} variant="outlined">
      Delete Employee
    </Button>,
  ]

  useEffect(() => {
    getPersonById(employeeId).then((response) => {
      setEmployee(response)
    })

    getAllPeople(employeeId).then((response) => {
      setAllEmployees(response)
    })
  }, [employeeId])

  const updateEmployeeProfile = (data, id) => {
    updateEmployee(data, id).then((updatedEmployee) => {
      setEmployee(updatedEmployee)
    })
  }

  return (
    <div className={classes.root}>
      <Toolbar buttons={buttons} title={`Edit Employee: ${employee.firstName} ${employee.lastName} `} />
      <Container>
        <Grid className={classes.content} container spacing={4}>
          <Grid item xs={12}>
            <EmployeeDetails
              allEmployees={allEmployees}
              employee={employee}
              setEmployee={setEmployee}
              updateEmployeeProfile={updateEmployeeProfile}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default EditEmployee
