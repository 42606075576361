import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useController } from 'react-hook-form'

const CheckboxControlled = ({ control, name, label, defaultValue, disabled }) => {
  const {
    field: { onChange, value },
  } = useController({ name: name, control: control, defaultValue: defaultValue })
  return (
    <FormControlLabel
      control={<Checkbox checked={value} disabled={disabled} onChange={(e) => onChange(e.target.checked)} color="primary" />}
      label={label}
    />
  )
}

export default CheckboxControlled
