import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Divider,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
} from '@material-ui/core'
import AlarmOnIcon from '@material-ui/icons/AlarmOn'
import AlarmAddIcon from '@material-ui/icons/AlarmAdd'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { getNotificationScheduleItems, createNotificationScheduleItem, deleteNotificationScheduleItem } from 'api'
import { useDispatch } from 'react-redux'
import { enqueueSuccessNotification, enqueueErrorNotification } from 'redux/actions/notificationsActions'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  notifyInput: {
    marginLeft: theme.spacing(2),
    textAlign: 'center',
  },
  notifyUnit: {
    minWidth: 80,
  },
  icon: {
    minWidth: 'auto',
    marginRight: theme.spacing(1),
  },
}))

const NotificationSchedule = (props) => {
  const { type, title } = props
  const classes = useStyles()
  const [notificationScheduleItems, setNotificationScheduleItems] = useState([])
  const [notificationItem, setNotificationItem] = useState({
    value: 1,
    unit: 'day',
    type: type,
  })
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const fetchNotificationItems = useCallback(() => {
    setNotificationScheduleItems([])
    setIsLoading(true)
    getNotificationScheduleItems(type).then((response) => {
      if (response.status === 200) {
        setNotificationScheduleItems(response.data)
        setIsLoading(false)
      } else {
        dispatch(enqueueErrorNotification('Server error. Unable to retrieve notification settings.'))
      }
    })
  }, [dispatch, type])

  const addScheduleItem = () => {
    createNotificationScheduleItem({
      value: notificationItem.value,
      unit: notificationItem.unit,
      type: type,
    }).then((response) => {
      if (response.status === 200) {
        fetchNotificationItems()
        dispatch(enqueueSuccessNotification('Successfully created notification item.'))
      } else {
        dispatch(
          enqueueErrorNotification(
            'Server error. Unable to create notification item. Please try again or contact support.',
          ),
        )
      }
    })
  }

  const removeScheduleItem = (deleteIndex, scheduleItem) => {
    deleteNotificationScheduleItem(scheduleItem.id).then((response) => {
      if (response.status === 200) {
        fetchNotificationItems()
        dispatch(enqueueSuccessNotification(`Successfully deleted notification item.`))
      } else {
        dispatch(enqueueErrorNotification('Server error. Unable to delete notification item.'))
      }
    })
  }

  useEffect(() => {
    fetchNotificationItems()
  }, [fetchNotificationItems])

  return (
    <List dense={true}>
      {isLoading && <CircularProgress color="secondary" />}
      {notificationScheduleItems.map((scheduleItem, index) => (
        <ListItem key={`${scheduleItem.value}_${index}_notification_item`} button>
          <ListItemIcon className={classes.icon}>
            <AlarmOnIcon />
          </ListItemIcon>
          <ListItemText
            primary={`Remind ${scheduleItem.value} ${scheduleItem.unit}${
              scheduleItem.value > 1 ? 's' : ''
            } before ${title.toLowerCase()} is due`}
          />
          <DeleteOutlineIcon onClick={() => removeScheduleItem(index, scheduleItem)} />
        </ListItem>
      ))}
      <Divider />
      <ListItem>
        <ListItemIcon className={classes.icon}>
          <AlarmAddIcon />
        </ListItemIcon>
        <Typography variant="body1">Remind</Typography>
        <TextField
          type="number"
          className={classes.notifyInput}
          onChange={(e) => setNotificationItem({ ...notificationItem, value: e.target.value })}
          value={notificationItem.value}
          InputProps={{
            inputProps: { min: 0, max: 90 },
          }}
        />
        <Typography variant="body1" style={{marginLeft: '8px'}}> day{notificationItem.value !== 1 && "s"} before {title.toLowerCase()} is due</Typography>
        <Button onClick={addScheduleItem} color="primary">
          Add
        </Button>
      </ListItem>
    </List>
  )
}

export default NotificationSchedule
