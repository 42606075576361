import API from '../../api'

export const getFeedbacks = async ({
  page = 0,
  pageSize = 0,
  searchQuery = '',
  excludedStatuses = [],
  includedStatuses = [],
  excludedRevieweeIds = [],
  includedRevieweeIds = [],
  excludedReviewerIds = [],
  includedReviewerIds = [],
  sortBy = '',
  sortDirection = '',
}) => {
  const searchParams = new URLSearchParams({
    page: page,
    pageSize: pageSize,
    searchQuery: searchQuery,
    sortBy: sortBy,
    sortDirection: sortDirection,
  })

  excludedStatuses.forEach((status) => searchParams.append('excludedStatuses', status))
  includedStatuses.forEach((status) => searchParams.append('includedStatuses', status))
  excludedRevieweeIds.forEach((id) => searchParams.append('excludedRevieweeIds', id))
  includedRevieweeIds.forEach((id) => searchParams.append('includedRevieweeIds', id))
  excludedReviewerIds.forEach((id) => searchParams.append('excludedReviewerIds', id))
  includedReviewerIds.forEach((id) => searchParams.append('includedReviewerIds', id))

  return await API.get(`feedbacks?${searchParams}`)
}

export const updateFeedbackDueDate = async (evaluationId, feedbackId, request) => {
  return await API.put(`evaluations/${evaluationId}/feedbacks/${feedbackId}/due-date`, request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}
