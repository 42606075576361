import API from '../api'

export const getGoals = async (
  personId = 0,
  limit = 0,
  isComplete = false,
  evaluationId = '00000000-0000-0000-0000-000000000000',
) => {
  return await API.get(
    `goals?limit=${limit}&isComplete=${isComplete}&personId=${personId}&evaluationId=${evaluationId}`,
  )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getGoalById = async (id) => {
  return await API.get(`goals/${id}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const createGoal = async (goal) => {
  return await API.post('goals/create', goal)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const deleteGoal = async (id) => {
  return await API.post(`goals/${id}/delete`).then((response) => {
    return response
  })
}

export const completeGoal = async (goalId) => {
  return await API.post(`goals/${goalId}/complete`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const updateGoal = async (id, request) => {
  return await API.post(`goals/${id}/update`, request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const updateMeasure = async (metric) => {
  return await API.put('goals/metric/update', metric)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const createMetric = async (metric) => {
  return await API.post('goals/metric/create', metric)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const removeMetric = async (id) => {
  return await API.delete(`goals/metric/${id}/remove`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const updateMeasureValue = async (request) => {
  return await API.put('goals/metric/update/value', request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getGoalActivityLog = async (id, filterTypes = []) => {
  const filteredTypeParam = filterTypes.reduce((total, type) => `${total}&filterTypes=${type}`, '')
  return await API.get(`goals/${id}/activity-log?${filteredTypeParam}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

//Goal Comments
export const addCommentToGoal = async (id, comment) => {
  return await API.post(`goals/${id}/activity-log/create`, comment).then((response) => {
    return response.data.result
  })
}

export const getTeamGoals = async (limit = 0, isComplete = false) => {
  return await API.get(`goals/team?limit=${limit}&isComplete=${isComplete}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}
