import React from 'react'
import { Helmet } from 'react-helmet'
import GoalForm from './GoalForm'
import { Container } from '@material-ui/core'
import { ModernCard } from 'components/Cards'

const GoalCreate = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create Goal | Express Evaluations</title>
      </Helmet>
      <Container style={{ marginTop: '32px' }}>
        <ModernCard headerTitle="New Goal">
          <GoalForm />
        </ModernCard>
      </Container>
    </div>
  )
}

export default GoalCreate
