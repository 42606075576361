import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { scoredEvaluations } from 'api'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  evalTable: {
    width: '100%',
  },
}))

const AverageEvaluationScoreTable = (props) => {
  const { showManagers } = props
  const [rows, setRows] = useState([])
  const classes = useStyles()

  const headCells = [
    { field: 'name', title: showManagers ? 'Team Lead' : 'Person' },
    { field: 'email', title: 'Email' },
    { field: 'count', title: 'Eval Count' },
    {
      field: 'score',
      title: 'Avg Eval Score',
      render: (row) => {
        return `${((row.totalScore / row.count) * 100).toFixed(1)}%`
      },
    },
  ]

  useEffect(() => {
    scoredEvaluations().then((response) => {
      setRows(response)
    })
  }, [])

  // Step 1: Filter by evaluation type
  const filteredEvals = rows.filter((e) => (showManagers ? e.evaluationType === 'lead' : true))
  // Step 2: Get set of unique persons
  let labels = [...new Set(filteredEvals.map((item) => (showManagers ? item.evaluatorId : item.evaluatedPersonId)))]
  // Step 3: Get average score per person id
  const ScoresByPerson = labels.map((label) => {
    return filteredEvals.reduce(
      (total, e) => {
        return (!showManagers ? e.evaluatedPersonId : e.evaluatorId) === label
          ? (total = {
              totalScore: total.totalScore + e.score,
              count: total.count + 1,
              name: !showManagers ? e.evaluatedPersonName : e.evaluatorName,
              email: !showManagers ? e.evaluatedPersonEmail : e.evaluatorEmail,
              id: !showManagers ? e.evaluatedPersonId : e.evaluatorId,
            })
          : total
      },
      { totalScore: 0, count: 0, name: '', email: '' },
    )
  })

  // Step 3: Transform data to match ChartJS
  // let completedEvalData = []

  // // Step 4: Create data for ChartJS
  // const livedata = {
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: 'Completed Evaluations',
  //       // backgroundColor: palette.primary.main,
  //       data: completedEvalData,
  //     },
  //     {
  //       label: 'Incomplete Evaluations',
  //       // backgroundColor: palette.neutral,
  //       data: incompleteEvalData,
  //     },
  //   ],
  // }

  return (
    <MaterialTable
      className={classes.evalTable}
      columns={headCells}
      data={ScoresByPerson}
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        exportButton: true,
        selection: false,
        showTitle: false,
        pageSize: 25,
        pageSizeOptions: [10, 25, 50, 100],
      }}
      title="Evaluation Scores"
    />
  )
}

export default AverageEvaluationScoreTable
