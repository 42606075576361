import { makeStyles } from '@material-ui/styles'

export const useEvaluationQuestionStyle = makeStyles((theme) => ({
  formGroup: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1),
  },
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdown: {
    minWidth: 150,
  },
}))
