import React from 'react'
import { Button, Typography, Stepper, Step, StepLabel } from '@material-ui/core'
import { useImportStyles } from 'styles'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'immutable'
import { updateImportStep } from 'redux/actions/importActions'
import { useHistory } from 'react-router-dom'
import { ModernCard } from 'components'

function getSteps() {
  return ['Upload File', 'Errors', 'Review People', 'Review Teams', 'Import Completed']
}

const ImportStepper = (props) => {
  const classes = useImportStyles()
  const dispatch = useDispatch()
  const activeStep = useSelector((state) => getIn(state, ['import', 'activeStep']))
  const importErrorCount = useSelector((state) => getIn(state, ['import', 'countInvalidPeople']))
  const importValidCount = useSelector((state) => getIn(state, ['import', 'countValidPeople']))
  const importInProgress = useSelector((state) => getIn(state, ['import', 'inProgress']))
  const steps = getSteps()
  const history = useHistory()
  const handleFinishButton = () => {
    history.push('/people')
  }

  return (
    <div className={classes.stepperRoot}>
      <ModernCard headerTitle="Import Steps" className={classes.importCard}>
        <Stepper activeStep={activeStep} className={classes.stepperRoot}>
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            const stepNumber = index === 1 ? importErrorCount : index === 2 ? importValidCount : ''

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>
                  {label} {stepNumber ? ` (${stepNumber})` : ''}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </ModernCard>
      <ModernCard headerTitle={steps[activeStep]} className={classes.importCard}>
        <div className={classes.root}>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
            </div>
          ) : (
            <div>
              {props.content}
              <div className={classes.stepperButtons}>
                <Button
                  disabled={activeStep === 0}
                  onClick={() => dispatch(updateImportStep('back'))}
                  className={classes.button}>
                  Back
                </Button>

                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFinishButton}
                    className={classes.button}
                    disabled={!importInProgress}>
                    Finish
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(updateImportStep('next'))}
                    className={classes.button}
                    disabled={!importInProgress || (activeStep > 0 && importValidCount === 0)}>
                    {activeStep === 3 ? 'Submit' : 'Next'}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </ModernCard>
    </div>
  )
}

export default ImportStepper
