import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core'
import { FormatDateIgnoreTz } from 'utils/DateTime'

const ReviewCyclesDetailPanel = (props) => {
  const { reviewCycles, dateType, startDateOffset, endDateOffset } = props

  const getHireDatePeriod = (startOffset, endOffset) => {
    let period = ''
    if (startOffset < 0) {
      period = period + `Starts ${Math.abs(startOffset)} day(s) before hire date`
    } else {
      period = period + `Starts ${Math.abs(startOffset)} day(s) after hire date`
    }

    period = period + ' - '

    if (endOffset < 0) {
      period = period + `Ends ${Math.abs(endOffset)} day(s) before hire date`
    } else {
      period = period + `Ends ${Math.abs(endOffset)} day(s) after hire date`
    }

    return period
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right"></TableCell>
            <TableCell>#</TableCell>
            <TableCell align="center">Survey Period</TableCell>
            <TableCell align="right">Completed</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reviewCycles.map((reviewCycle, index) => (
            <TableRow key={`${reviewCycle.name}_${index}`}>
              <TableCell align="right"></TableCell>
              <TableCell component="th" scope="row">
                {reviewCycle.interval}
              </TableCell>
              <TableCell align="center">
                {dateType === 'date'
                  ? `${FormatDateIgnoreTz(reviewCycle.startDate)} - ${FormatDateIgnoreTz(reviewCycle.endDate)}`
                  : getHireDatePeriod(startDateOffset, endDateOffset)}
              </TableCell>
              <TableCell align="right">{reviewCycle.completedEvaluations}</TableCell>
              <TableCell align="right">{reviewCycle.totalEvaluations}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReviewCyclesDetailPanel
