import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import { DialogActions, DialogContent, Button, ListItemSecondaryAction, Divider, ButtonGroup } from '@material-ui/core'
import { ButtonWithPrompt } from 'components/Buttons'
import { Grid, Typography, List, ListItem, ListItemText, CircularProgress } from '@material-ui/core'
import EmployeeSearch from 'components/Search/EmployeeSearch'
import { getEvaluationParticipants } from 'api'
import { useDispatch } from 'react-redux'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { Alert, AlertTitle } from '@material-ui/lab'
import { getIcon } from 'utils/GetIcon'
import { getEvaluationStatusChip, getTypeChip } from 'utils/evaluations'
import VerticalIconList from 'components/Lists/VerticalIconList'
import { addEvaluationParticipants, deleteEvaluation } from 'api/evaluation'
import { useAuth0 } from 'react-auth0-spa'

const useStyles = makeStyles((theme) => ({
  dialog: { minWidth: 600 },
  dialogContent: { minWidth: 580, padding: theme.spacing(2) },
}))

const ParticipantsModal = (props) => {
  const classes = useStyles()

  const { evaluationBatchId, setOpen, open, title, disableChanges } = props
  const dispatch = useDispatch()
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [participants, setParticipants] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [type, setType] = useState()
  const [typeIndex, setTypeIndex] = useState()
  const [disableEmployeeTypeOption, setDisableEmployeeTypeOption] = useState(false)
  const [disableManagerTypeOption, setDisableManagerTypeOption] = useState(false)
  const [disablePeerTypeOption, setDisablePeerTypeOption] = useState(false)
  const [showParticipantForm, setShowParticipantForm] = useState(false)
  const { appMetadata } = useAuth0()

  const handleClose = () => {
    setOpen(false)
    setType()
    setTypeIndex()
    setSelectedParticipants([])
  }

  const handleDeleteEvaluation = (participantEvaluationId) => {
    deleteEvaluation({ evaluationId: participantEvaluationId, type: 'evaluation' }).then(() => {
      dispatch(enqueueSuccessNotification(`The evaluation has been successfuly deleted`))
      fetchParticipants()
    })
  }

  const submitAddParticipant = () => {
    let request = selectedParticipants
    if (Array.isArray(request)) {
      request.forEach((item) => {
        item.personId = item.id
        item.type = type
      })
    } else {
      request.personId = request.id
      request.type = type
      request = [request]
    }

    addEvaluationParticipants(evaluationBatchId, request).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Participant was successfully added'))
        fetchParticipants()
        setSelectedParticipants([])
      } else {
        dispatch(enqueueErrorNotification('Server encountered an error. Participant was not added.'))
      }
    })
  }

  const fetchParticipants = useCallback(() => {
    setIsLoading(true)
    setDisableEmployeeTypeOption(false)
    setDisableManagerTypeOption(false)
    setDisablePeerTypeOption(false)
    if (evaluationBatchId) {
      getEvaluationParticipants(evaluationBatchId).then((response) => {
        console.log('Fetched participants', response)
        setParticipants(response.data.participants)
        setIsLoading(false)
        if (!response.data.employeeCanBeAdded) {
          setDisableEmployeeTypeOption(true)
        }
        if (!response.data.managersCanBeAdded) {
          setDisableManagerTypeOption(true)
        }
        if (!response.data.peersCanBeAdded) {
          setDisablePeerTypeOption(true)
        }
      })
    }
  }, [evaluationBatchId])

  useEffect(() => {
    fetchParticipants()
    setShowParticipantForm(false)
    setDisableEmployeeTypeOption(false)
    setDisableManagerTypeOption(false)
    setDisablePeerTypeOption(false)
  }, [fetchParticipants])

  const typeOptions = []

  typeOptions.push({
    name: 'self',
    displayName: 'Employee',
    icon: getIcon('person'),
    isSelected: type === 'employee',
    disabled: disableEmployeeTypeOption,
  })

  typeOptions.push({
    name: 'lead',
    displayName: 'Manager',
    icon: getIcon('manager'),
    isSelected: type === 'lead',
    disabled: disableManagerTypeOption,
  })

  appMetadata.accessPeerEvaluations &&
    typeOptions.push({
      name: 'peer',
      displayName: 'Peer',
      isSelected: type === 'peer',
      icon: getIcon('peer'),
      disabled: disablePeerTypeOption,
    })

  const handleTypeUpdate = (type, index) => {
    setType(type)
    setTypeIndex(index)
    setSelectedParticipants([])
  }

  return (
    <Dialog
      id={`${evaluationBatchId}_participants_modal`}
      onClose={handleClose}
      aria-labelledby="participants-dialog-title"
      open={open}
      className={classes.dialog}>
      <DialogContent>
        <Grid className={classes.dialogContent} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" id="participants-dialog-title">{`Evaluation: ${title}`}</Typography>
            <Typography variant="h4">Participants Assigned to Evaluation</Typography>
            <Divider style={{ marginTop: 10 }} />
            <Typography variant="body2"></Typography>
            {isLoading ? (
              <CircularProgress />
            ) : participants && participants.length > 0 ? (
              <List dense>
                {participants &&
                  participants.map((value, index) => (
                    <ListItem divider className={classes.participantListItem}>
                      <ListItemText
                        primary={
                          <>
                            <Typography variant="h5">{value.name}</Typography>
                            <Typography variant="caption">{value.email}</Typography>
                          </>
                        }
                        secondary={
                          <>
                            {getTypeChip(value.evaluationType)} {getEvaluationStatusChip(value.evaluationStatus)}
                          </>
                        }
                      />
                      {!disableChanges &&
                        <ListItemSecondaryAction>
                          <ButtonWithPrompt
                            iconButton
                            icon={getIcon('delete')}
                            title="Remove Participant and Delete Evaluation"
                            description="Are you sure you want to remove the selected participant and delete their evaluation?"
                            buttonText={getIcon('delete')}
                            color="primary"
                            clickFunction={() => handleDeleteEvaluation(value.evaluationId)}
                            positiveButtonLabel="Remove Participant"
                            positiveButtonFunction
                            disabledReason="Self evaluations & completed evaluations cannot be removed."
                            disabled={
                              value.evaluationStatus === 'complete' ||
                              value.evaluationStatus === 'signed' ||
                              value.evaluationStatus === 'waitingForReview' ||
                              value.evaluationStatus === 'waitingForSignature' ||
                              value.evaluationStatus === 'finalized' ||
                              value.evaluationType === 'self'
                            }
                          />
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
              </List>
            ) : (
              <Alert severity="info">
                <AlertTitle>No participants requested for this evaluation</AlertTitle>
              </Alert>
            )}
          </Grid>
          {showParticipantForm && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">Add a participant</Typography>
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  Evaluation types are only available if the template for this evaluation has sections assigned to that
                  type. Additionally, evaluations are only allowed to have one manager and one employee assigned to
                  them. If either of these already exist, you will be unable to select them.
                </Alert>
                <Typography variant="h6">Select a participant type</Typography>
                <VerticalIconList
                  listItems={typeOptions}
                  clickFunction={handleTypeUpdate}
                  value={type}
                  selectedIndex={typeIndex}
                />
              </Grid>
              <Grid item xs={12}>
                {type && (
                  <EmployeeSearch
                    label="Request feedback from additional people"
                    multiple={type === 'peer'}
                    onChange={(e) => setSelectedParticipants(e)} 
                    returnType="object"
                    value={selectedParticipants}
                    excludedIds={participants.map((a) => a.id)}
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <ButtonGroup variant="contained" disableElevation>
              {showParticipantForm && (
                <ButtonWithPrompt
                  title="Select people"
                  description="Are you sure you want to request the selected people to provide feedback for this evaluation?"
                  buttonText="Submit"
                  color="primary"
                  clickFunction={submitAddParticipant}
                  positiveButtonLabel="Request Feedback"
                  positiveButtonFunction
                  disabled={isLoading || (selectedParticipants && selectedParticipants.length === 0) || !type}
                />
              )}
              {!disableChanges && 
                <Button
                  onClick={() => setShowParticipantForm(!showParticipantForm)}
                  size="small"
                  startIcon={showParticipantForm ? getIcon('clear') : getIcon('add')}
                  variant="outlined"
                  color="secondary">
                  {showParticipantForm ? 'Cancel' : 'Participants'}
                </Button>
              }
            </ButtonGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ParticipantsModal.propTypes = {
  evaluationBatchId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default ParticipantsModal
