import actionTypes from 'redux/actionTypes'
import {
  getAllEvaluationTemplates,
  createTemplateVersion,
  deactivateTemplate,
  getArchivedTemplates,
  restoreTemplate,
  getPulseTemplates,
} from 'api'
import { getIn } from 'immutable'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { resetEvaluationToDefaults } from './evaluationActions'

export const loadTemplateTableStarted = () => ({
  type: actionTypes.loadTemplateTableStarted,
})

export const loadTemplateTableSucceeded = (templates) => ({
  type: actionTypes.loadTemplateTableSucceeded,
  templates,
})

export const loadArchivedTemplateTableSucceeded = (templates) => ({
  type: actionTypes.loadArchivedTemplateTableSucceeded,
  templates,
})

export const loadPulseTemplateTableSucceeded = (templates) => ({
  type: actionTypes.loadPulseTemplateTableSucceeded,
  templates,
})

export const loadActiveTemplatesTableAction = () => async (dispatch, getState) => {
  dispatch(loadTemplateTableStarted())
  getAllEvaluationTemplates().then((response) => {
    if (getIn(response, ['status']) === 200) {
      dispatch(loadTemplateTableSucceeded(getIn(response, ['data'])))
      dispatch(resetEvaluationToDefaults())
    } else {
      dispatch(
        enqueueErrorNotification(
          'An error occurred while loading the templates table. Please refresh your page or contact support.',
        ),
      )
    }
  })
}

export const loadArchivedTemplatesTableAction = () => async (dispatch, getState) => {
  dispatch(loadTemplateTableStarted())
  getArchivedTemplates().then((response) => {
    if (getIn(response, ['status']) === 200) {
      dispatch(loadArchivedTemplateTableSucceeded(getIn(response, ['data'])))
      dispatch(resetEvaluationToDefaults())
    } else {
      dispatch(
        enqueueErrorNotification(
          'An error occurred while loading the templates table. Please refresh your page or contact support.',
        ),
      )
    }
  })
}

export const loadPulseTemplatesTableAction = () => async (dispatch, getState) => {
  dispatch(loadTemplateTableStarted())
  getPulseTemplates().then((response) => {
    if (getIn(response, ['status']) === 200) {
      dispatch(loadPulseTemplateTableSucceeded(getIn(response, ['data'])))
      dispatch(resetEvaluationToDefaults())
    } else {
      dispatch(
        enqueueErrorNotification(
          'An error occurred while loading the Pulse templates table. Please refresh your page or contact support.',
        ),
      )
    }
  })
}

export const createTemplateVersionAction = (id, name, history) => async (dispatch, getState) => {
  createTemplateVersion({ templateId: id }).then((response) => {
    if (response.status === 200) {
      dispatch(enqueueSuccessNotification(`You successfully created a version of the ${name} template`))
      history.push(`/evaluation/template/build/${getIn(response, ['data', 'templateId'])}`)
    } else {
      dispatch(enqueueErrorNotification('The system encountered an error. Please try again later or contact support'))
    }
  })
}

export const archiveTemplateAction = (id, name) => async (dispatch, getState) => {
  deactivateTemplate(id).then((response) => {
    if (response.status === 200) {
      dispatch(enqueueSuccessNotification(`You successfully archived the ${name} template`))
      dispatch(loadActiveTemplatesTableAction())
    } else {
      dispatch(
        enqueueErrorNotification(
          'The system encountered an error while attempting to archive your template. Please try again later or contact support',
        ),
      )
    }
  })
}

export const restoreTemplateAction = (id, name) => async (dispatch, getState) => {
  restoreTemplate(id).then((response) => {
    if (response.status === 200) {
      dispatch(enqueueSuccessNotification(`You successfully restored the ${name} template`))
      dispatch(loadArchivedTemplatesTableAction())
    } else {
      dispatch(
        enqueueErrorNotification(
          'The system encountered an error while attempting to restore your template. Please try again later or contact support',
        ),
      )
    }
  })
}

export const updateTemplate = () => ({})
