import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Tooltip } from '@material-ui/core'
import { getStatusCircle } from 'utils/evaluations'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiPaper-root': {
      boxShadow: 'none',
      width: '100%',
    },
    width: '100%',
  },
  peerItem: {
    color: 'white',
  },
}))

const BatchMenuItem = (props) => {
  const { item } = props
  const classes = useStyles()

  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>{getStatusCircle(item.status)}</Grid>
      {item.type === 'peer' ? (
        <Grid item>
          Peers{' '}
          <Tooltip
            arrow
            title={
              <ol>
                {item.completedBy.map((peer, index) => (
                  <ul className={classes.peerItem} key={`menu_item_${index}_${peer.toString().trim()}`}>
                    <Typography variant="body1" className={classes.peerItem}>
                      {index + 1}. {peer}
                    </Typography>
                  </ul>
                ))}
              </ol>
            }>
            <span className={classes.peerNumber}>({item.completedBy.length})</span>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item>{item.completedBy[0]}</Grid>
      )}
    </Grid>
  )
}

export default BatchMenuItem
