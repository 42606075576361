import React from 'react'
import {
  Typography,
  Grid,
  Box,
  LinearProgress,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getIn } from 'immutable'
import { useEvaluationNavStyle } from 'styles'
import { PermissionButton } from 'components/Buttons'
import { changeSectionFromNav, startGoalCreation, startGoalReview } from 'redux/actions/evaluationActions'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { getIcon } from 'utils/GetIcon'
import { getCombinedEvaluationPdf } from 'api'
import PermissionCheck from 'utils/PermissionCheck'
import { roles } from 'utils/roles'
import StickyBox from 'react-sticky-box'

const EvaluationNav = (props) => {
  const dispatch = useDispatch()
  const evaluation = useSelector((state) => state.evaluation)
  const sections = useSelector((state) => getIn(state, ['evaluation', 'evaluation', 'template', 'sections']))
  const totalSections = useSelector((state) => getIn(state, ['evaluation', 'numberOfSections']))
  const completedSections = useSelector((state) => getIn(state, ['evaluation', 'completedSections'])) || []
  const currentSectionIndex = useSelector((state) => getIn(state, ['evaluation', 'currentSectionIndex']))
  const classes = useEvaluationNavStyle()
  const progress = (parseInt(currentSectionIndex + 1) / parseInt(totalSections)) * 100
  const evalType = useSelector((state) => getIn(state, ['evaluation', 'evaluation', 'type']))
  const evalPerson = useSelector((state) => getIn(state, ['evaluation', 'evaluation', 'evaluatedName']))
  const isPulse = useSelector((state) => getIn(state, ['evaluation', 'isPulse']))

  const navListItemIcon = (index) => {
    if (completedSections.includes(index)) {
      return <CheckCircleIcon htmlColor="green" />
    } else if (index < currentSectionIndex) {
      return <ErrorOutlineIcon color="error" />
    } else {
      return <RadioButtonUncheckedIcon htmlColor="gray" />
    }
  }

  return (
    <StickyBox offsetTop={65} offsetBottom={20}>
      <Grid container className={classes.root}>
        {!isPulse && <Grid item xs={12} sm={12} className={classes.evalInfo}>
          <Typography variant="h4" className={classes.title}>
            {evalType === "self" ? "Self Evaluation" : evalPerson && `Evaluating ${evalPerson}`}
          </Typography>
          <div>
            <Typography className={classes.evalInfoText}>{evaluation.title}</Typography>
          </div>
          <Divider/>
        </Grid>
        }
        <Grid item xs={12} sm={12}>
          <Typography variant="h4" className={classes.title}>
            Sections
          </Typography>
          <Box>
            <Box width="100%" mr={1}>
              <LinearProgress color="secondary" variant="determinate" value={progress || 0} />
            </Box>
            <Box minWidth={35}>
              <Typography className={classes.progressText} variant="body2">
                {currentSectionIndex + 1} / {totalSections}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider />
          <List>
            {sections &&
              sections.map((value, index) => (
                <ListItem
                  button
                  key={`${value.title}_${index}`}
                  onClick={() => dispatch(changeSectionFromNav(index, value))}
                  selected={!evaluation.reviewingGoals && !evaluation.creatingGoals && index === currentSectionIndex}>
                  <ListItemIcon>{navListItemIcon(index)}</ListItemIcon>
                  <ListItemText>{value.title}</ListItemText>
                </ListItem>
              ))}
            {evaluation.includeReviewingGoals && (
              <ListItem selected={evaluation.reviewingGoals} onClick={() => dispatch(startGoalReview())}>
                <ListItemIcon>
                  {evaluation.goalReviewComplete ? <CheckCircleIcon htmlColor="green" /> : getIcon('goal')}
                </ListItemIcon>
                <ListItemText>Review Goals</ListItemText>
              </ListItem>
            )}
            {evaluation.includeGoalCreation && (
              <ListItem selected={evaluation.creatingGoals} onClick={() => dispatch(startGoalCreation())}>
                <ListItemIcon>
                  {evaluation.goalCreationComplete ? <CheckCircleIcon htmlColor="green" /> : getIcon('addTo')}
                </ListItemIcon>
                <ListItemText>Create Goals</ListItemText>
              </ListItem>
            )}
          </List>
          <Divider />
          <Typography variant="caption">Please answer all required questions (*) </Typography>
        </Grid>
        {evaluation.evaluation.type === 'lead' && (
          <PermissionCheck
            roles={[roles.TeamLead]}
            yes={() => (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">Manager Tools</Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <PermissionButton
                    roles={[roles.TeamLead]}
                    text="View Completed Feedback"
                    align
                    variant="outlined"
                    color="primary"
                    fullWidth
                    startIcon={getIcon('pdf')}
                    onClick={() =>
                      getCombinedEvaluationPdf({
                        evaluationBatchId: evaluation.evaluation.batchId,
                        signatures: false,
                        scoring: false,
                        comments: true,
                        leadSections: false,
                        excludedSections: [],
                        excludedResponseTypes: [],
                      })
                    }
                    disabledTooltip="Employee has not completed their evaluation"
                  />
                </Grid>
                <Grid item xs={12}>
                  <List>
                    {evaluation.evaluation.hasEmployee && (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={classes.progressAvatar}>
                            {evaluation.evaluation.employeeIsComplete ? (
                              <CheckCircleIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Employee Feedback" />
                      </ListItem>
                    )}
                    {evaluation.evaluation.hasManager && (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={classes.progressAvatar}>
                            {evaluation.evaluation.managerIsComplete ? (
                              <CheckCircleIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Manager Feedback" />
                      </ListItem>
                    )}
                    {evaluation.evaluation.hasPeer && (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={classes.progressAvatar}>
                            {evaluation.evaluation.completePeerEvaluations}/{evaluation.evaluation.totalPeerEvaluations}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Peers Feedback" />
                      </ListItem>
                    )}
                  </List>
                </Grid>
              </>
            )}
          />
        )}
      </Grid>
    </StickyBox>
  )
}

export default EvaluationNav
