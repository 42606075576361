export const peopleDrawerValidations = () => {
  return {
    firstName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128,
      },
    },
    middleName: {
      length: {
        maximum: 128,
      },
    },
    lastName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128,
      },
    },
    email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true,
      length: {
        maximum: 128,
      },
    },
    secondaryEmail: {
      email: true,
      length: {
        maximum: 128,
      },
    },
    employeeId: {
      length: { maximum: 128 },
    },
    isOrgAdmin: {
      type: 'boolean',
      presence: true,
    },
  }
}
