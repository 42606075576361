import { makeStyles } from '@material-ui/styles'

export const useEmployeeDashboardStyles = makeStyles((theme) => ({
  pulseDivider: {
    marginTop: theme.spacing(1),
    height: 5,
    borderRadius: '2.5px',
    backgroundColor: theme.palette.engagement.primary.light,
    marginBottom: theme.spacing(1),
  },
  evaluationDivider: {
    marginTop: theme.spacing(1),
    height: 5,
    borderRadius: '2.5px',
    backgroundColor: theme.palette.primary.light,
    marginBottom: theme.spacing(1),
  },
  goalsDivider: {
    marginTop: theme.spacing(1),
    height: 5,
    borderRadius: '2.5px',
    backgroundColor: theme.palette.secondary.light,
    marginBottom: theme.spacing(1),
  },
  noPulseText: {
    marginTop: theme.spacing(1),
    fontStyle: 'italic',
  },
}))
