import React, { useCallback } from 'react'
import { getAutoCompleteTags } from 'api'
import BaseAutocompleteSearch from './BaseAutocompleteSearch'

export default function TagSearch(props) {
  const { filterTypes, onChange, value, label, variant, includeFilters, ...rest } = props
  const getOptionsFunc = useCallback(async () => getAutoCompleteTags({ filterTypes: filterTypes }), [filterTypes])

  return (
    <BaseAutocompleteSearch
      {...rest}
      fetchOptions={getOptionsFunc}
      filterSelectedOptions
      renderOption={(option) => option.name}
      getOptionLabel={(option) => `${option.name || ''}`}
      groupBy={(option) => option.firstLetter}
      label={label || 'Tags'}
      value={value}
      onChange={onChange}
      variant={variant}
    />
  )
}
