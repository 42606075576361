import React from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  ListItemIcon,
  TextField,
  InputAdornment,
  Divider,
  FormControl,
  Tooltip,
  MenuItem,
  Select,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined'
import ShortTextOutlinedIcon from '@material-ui/icons/ShortTextOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Options } from './Components'
import PropTypes from 'prop-types'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { RichTextArea } from 'components/Inputs'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { deleteQuestion, copyQuestion, updateQuestion, moveQuestion } from 'redux/actions/templateBuilderActions'
import { getIn } from 'immutable'
import { MultiChoice, Text } from './Components/QuestionTypes'
import { useQuestionBuilderStyles } from 'styles'

const Question = (props) => {
  const classes = useQuestionBuilderStyles()
  const dispatch = useDispatch()
  const { sectionSysId, sectionIndex, questionIndex, availableToEdit, shouldAutoFocus } = props
  const scoringEnabled = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'scoringEnabled']))
  const question = useSelector(
    (state) => getIn(state, ['templateBuilder', 'template', 'sections', sectionIndex, 'questions', questionIndex]),
    shallowEqual,
  )
  const isPulse = useSelector((state) => getIn(state, ['templateBuilder', 'template', 'isPulseTemplate']))

  return (
    <Card
      className={[classes.root, isPulse ? classes.rootColorPulse : classes.rootColor]}
      id={question.sys_id + '_question_block'}>
      <CardHeader
        action={
          <Options
            isRequired={question.isRequired}
            setIsRequired={() =>
              dispatch(updateQuestion(sectionSysId, { ...question, isRequired: !question.isRequired }))
            }
            showDescription={question.showDescription}
            setShowDescription={() =>
              dispatch(updateQuestion(sectionSysId, { ...question, showDescription: !question.showDescription }))
            }
            allowMultiple={question.allowMultiple}
            toggleAllowMultiple={() =>
              dispatch(
                updateQuestion(sectionSysId, {
                  ...question,
                  config: { ...question.config, allowMultiple: !question.allowMultiple },
                }),
              )
            }
            questionType={question.type}
            availableToEdit={availableToEdit}
          />
        }
        title={`Question ${questionIndex + 1} of ${props.numQuestions}`}
        subheader={
          scoringEnabled &&
          question.type !== 'Text' &&
          `Max possible points for question: ${getIn(question, ['config', 'maxScore'])}`
        }
        subheaderTypographyProps={{ color: 'secondary' }}
      />
      <CardContent>
        <Grid container spacing={1}></Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={scoringEnabled ? 12 : 8} lg={6}>
            <TextField
              className={classes.questionTitle}
              fullWidth
              label="Question Title"
              margin="dense"
              onChange={(e) => dispatch(updateQuestion(sectionSysId, { ...question, title: e.target.value }))}
              required
              value={question.title}
              disabled={!availableToEdit}
              autoFocus={shouldAutoFocus}
            />
          </Grid>

          <Grid item style={{ minWidth: '170px' }}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="question-type-label">Question Type</InputLabel>
              <Select
                fullWidth
                id="demo-simple-select-helper"
                label="Question Type"
                labelId="question-type-label"
                onChange={(e) => dispatch(updateQuestion(sectionSysId, { ...question, type: e.target.value }))}
                value={question.type}
                disabled={!availableToEdit}>
                <MenuItem value={'MultiChoice'}>
                  <ListItemIcon className={classes.dropdownIcon}>
                    <RadioButtonCheckedOutlinedIcon />
                  </ListItemIcon>
                  Multiple Choice
                </MenuItem>
                <MenuItem value={'Dropdown'}>
                  <ListItemIcon className={classes.dropdownIcon}>
                    <ArrowDropDownOutlinedIcon />
                  </ListItemIcon>
                  Dropdown
                </MenuItem>
                {!isPulse && (
                  <MenuItem value={'Text'}>
                    <ListItemIcon className={classes.dropdownIcon}>
                      <ShortTextOutlinedIcon />
                    </ListItemIcon>
                    Text
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {scoringEnabled && question.type !== 'Text' && (
            <Grid item>
              <TextField
                className={classes.weightInput}
                error={question.weight > 10 || question.weight < 1}
                helperText={(question.weight > 10 && 'Max of 10') || (question.weight < 1 && 'Min of 1')}
                id="weight"
                InputProps={{
                  endAdornment: <InputAdornment position="end">/10</InputAdornment>,
                  inputProps: { min: 1, max: 10 },
                }}
                label="Weight"
                margin="dense"
                onChange={(e) => dispatch(updateQuestion(sectionSysId, { ...question, weight: e.target.value }))}
                type="number"
                value={question.weight}
                disabled={!availableToEdit}
              />
              <Tooltip
                className={classes.infoIcon}
                title="Weight is used to calculate how much this response will factor into the assessment's total score. For example, if one question is 1/10, and another is 10/10, the higher will be worth 10 times as much when calculating a final score.">
                <InfoOutlinedIcon color="disabled" fontSize="small" />
              </Tooltip>
            </Grid>
          )}
          {question.showDescription && (
            <Grid item xs={12} md={scoringEnabled ? 9 : 12} lg={12}>
              <RichTextArea
                initialValue={''}
                value={question.helpText}
                onChange={(content, editor) =>
                  dispatch(updateQuestion(sectionSysId, { ...question, helpText: content }))
                }
                disabled={!availableToEdit}
              />
            </Grid>
          )}
        </Grid>
        {question.type !== 'Text' && (
          <MultiChoice
            scoringEnabled={scoringEnabled}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
            allowMultiple={question.config.allowMultiple}
            maxScore={question.config.maxScore}
            options={question.config.options}
            setOptions={(options) =>
              dispatch(updateQuestion(sectionSysId, { ...question, config: { ...question.config, options: options } }))
            }
            questionType={question.type}
            disabled={!availableToEdit}
          />
        )}
        {question.type === 'Text' && (
          <Text
            config={question.config}
            setConfig={(config) => dispatch(updateQuestion(sectionSysId, { ...question, config: config }))}
            scoringEnabled={scoringEnabled}
            allowMultiple={question.config.allowMultiple}
            maxScore={question.config.maxScore}
            disabled={!availableToEdit}
          />
        )}
      </CardContent>
      <Divider />
      <CardActions>
        <Hidden xsDown>
          <div style={{ flexGrow: '1' }} />
          <Divider flexItem orientation="vertical" />
        </Hidden>
        <IconButton
          aria-label="copy question"
          onClick={() => dispatch(copyQuestion(props.sectionSysId, question))}
          disabled={!availableToEdit}>
          <FileCopyIcon />
        </IconButton>
        <Divider flexItem orientation="vertical" />
        <IconButton
          aria-label="delete question"
          onClick={() => dispatch(deleteQuestion(props.sectionSysId, question.sys_id))}
          disabled={!availableToEdit}>
          <DeleteIcon />
        </IconButton>
        <Divider flexItem orientation="vertical" />
        <ButtonGroup size="small" variant="text">
          <Button
            onClick={() => dispatch(moveQuestion(sectionIndex, questionIndex, false))}
            disabled={!availableToEdit}>
            <ArrowDownwardIcon />
          </Button>
          <Button onClick={() => dispatch(moveQuestion(sectionIndex, questionIndex, true))} disabled={!availableToEdit}>
            <ArrowUpwardIcon />
          </Button>
        </ButtonGroup>
      </CardActions>
    </Card>
  )
}

Question.propTypes = {
  deleteQuestion: PropTypes.func,
  questionData: PropTypes.object,
}

export default Question
