import React from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Typography } from '@material-ui/core'
import { getIcon } from 'utils/GetIcon'
import { productOfferingTypes } from 'data/constants'
import { useActionCardStyles } from 'styles'

const ActionCard = (props) => {
  const { headerSubText, headerText, title, buttonText, productOfferingType, onClick } = props
  const classes = useActionCardStyles()

  return (
    <Paper className={classes.root}>
      <div
        className={`
          ${classes.header} 
          ${
            productOfferingType === productOfferingTypes.engagement
              ? classes.engagementHeader
              : productOfferingType === productOfferingTypes.performance
              ? classes.performanceHeader
              : classes.goalHeader
          }
        `}>
        <Typography variant="caption" className={classes.headerText}>
          {headerSubText}
        </Typography>
        <Typography variant="h5" className={classes.headerText}>
          {headerText}
        </Typography>
      </div>
        {
          title && <div className={classes.body}>
            <Typography variant="subtitle2">{title}</Typography>
          </div>
        }
      <div className={classes.actions}>
        <Button
          className={`
          ${classes.actionBtn} 
        `}
          endIcon={getIcon('forward')}
          onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </Paper>
  )
}

export default ActionCard

ActionCard.propTypes = {
  headerSubText: PropTypes.string,
  headerText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  productOfferingType: PropTypes.oneOf([
    productOfferingTypes.engagement,
    productOfferingTypes.performance,
    productOfferingTypes.goals,
  ]),
  onClick: PropTypes.func.isRequired,
}

ActionCard.defaultProps = {
  productOfferingTypes: productOfferingTypes.performance,
}
