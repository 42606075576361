import React from 'react'
import { Divider, Typography, Grid } from '@material-ui/core'
import { useQuestionResultStyles } from 'styles'
import RichTextBlock from 'components/RichText/RichTextBlock'
import { ModernCard } from 'components/Cards'

const QuestionResult = (props) => {
  const { index, question, displayControls } = props
  const classes = useQuestionResultStyles()

  const getEvaluatedType = (type) => {
    switch (type) {
      case 'self':
        return 'Employee'
      case 'lead':
        return 'Manager'
      case 'peer':
        return 'Peer'
      default:
        return ''
    }
  }
  return (
    <ModernCard headerTitle={`Question ${index + 1}`} disableElevation outlineHeader>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.questionTitle}>
            {question.title}
          </Typography>
          <RichTextBlock content={question.helpText} />
        </Grid>

        {question.results
          .filter((r) => !displayControls.excludedResponseTypes.includes(r.evaluatorType))
          .map((result, index) => (
            <>
              <Grid item xs={2}>
                <Typography variant="h6">{result.evaluatorName}</Typography>
                <Typography variant="overline">{getEvaluatedType(result.evaluatorType)}</Typography>
                {props.scoringEnabled && (
                  <Typography variant="body1">
                    ({result.score}/{result.maxScore} points)
                  </Typography>
                )}
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">{result.response}</Typography>
              </Grid>
              {result.comments && props.commentsEnabled && (
                <>
                  <Grid item xs={2}>
                    <Typography variant="h6">Comments:</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography varaint="body1">{result.comments}</Typography>
                  </Grid>
                </>
              )}
              {index < question.results.length - 1 && (
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              )}
            </>
          ))}
      </Grid>
    </ModernCard>
  )
}

export default QuestionResult
