import axios from 'axios'

const ZendeskAPI = () => {
  return axios.create({
    baseURL: 'https://expressevaluations.zendesk.com/api/v2/',
    auth: {
      username: 'brandon@expressevaluations.com/token',
      password: 'SHNfGEQ7GVhvXVo6WInWNwliVI75MzNyj12vByef',
    },
  })
}

export default ZendeskAPI()
