import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography,
  List,
} from '@material-ui/core'
import { getOrgSettings } from 'api'
import OrgSettingListItem from '../components/OrgSettingListItem'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  notifyInput: {
    marginLeft: theme.spacing(2),
    textAlign: 'center',
  },
  notifyUnit: {
    minWidth: 80,
  },
}))

const PermissionSettings = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const [orgSettings, setOrgSettings] = useState([])

  useEffect(() => {
    getOrgSettings('permission').then((response) => {
      setOrgSettings(response.data)
    })
  }, [])

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        subheader="Manage your company's permissions settings"
        title="Permissions"
      />
      <Divider />
      <CardContent>
        <Grid container spacing={6} wrap="wrap">
          <Grid item xs={6}>
            <Typography variant="h6">Manager Permission Settings</Typography>
            <List>
              {orgSettings.map((setting, index) => (
                <OrgSettingListItem
                  key={`org_setting_${index}`}
                  setting={setting}
                />
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

PermissionSettings.propTypes = {
  className: PropTypes.string,
}

export default PermissionSettings
