import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core'
import { getOrgSettings, updateOrgSetting } from 'api'
import { EvaluationTemplateSearch } from 'components/Search/EvaluationTemplateSearch'
import { SaveCircularStatusButton } from 'components/Buttons'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  notifyInput: {
    marginLeft: theme.spacing(2),
    textAlign: 'center',
  },
  notifyUnit: {
    minWidth: 80,
  },
  settingSectionHeader: {
    marginBottom: theme.spacing(2),
  },
}))

const EvaluationSettings = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const [pulseTemplateSetting, setPulseTemplateSetting] = useState({})
  const [pulseFrequencySetting, setPulseFrequencySetting] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getOrgSettings('evaluation').then((response) => {
      console.log('eval setting', response)
      setPulseTemplateSetting(response.data[0])
      setPulseFrequencySetting(response.data[1])
      setIsLoading(false)
    })
  }, [])

  const updateSettings = async () => {
    await updateOrgSetting(pulseTemplateSetting)
    await updateOrgSetting(pulseFrequencySetting)
  }

  return (
    !isLoading && (
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          subheader="Manage your pulse evaluation settings"
          title="Pulse Evaluation"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2} wrap="wrap">
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.settingSectionHeader}>
                Select Pulse Evaluation Template
              </Typography>
              <EvaluationTemplateSearch
                setTemplateId={(e) =>
                  setPulseTemplateSetting({
                    ...pulseTemplateSetting,
                    inputValues: e,
                  })
                }
              />
            </Grid>
            {pulseFrequencySetting && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  className={classes.settingSectionHeader}>
                  Select Pulse Evaluation Frequency
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    pulseFrequencySetting && pulseFrequencySetting.inputValues
                  }
                  onChange={(e) =>
                    setPulseFrequencySetting({
                      ...pulseFrequencySetting,
                      inputValues: e.target.value,
                    })
                  }>
                  <MenuItem value={'weekly'}>Weekly</MenuItem>
                  <MenuItem value={'biweekly'}>Biweekly</MenuItem>
                  <MenuItem value={'monthly'}>Monthly</MenuItem>
                </Select>
              </Grid>
            )}
            <Grid item xs={12}>
              <SaveCircularStatusButton
                className={classes.signInButton}
                size="large"
                postlabel="Saving Settings..."
                prelabel="Save Settings"
                savefunction={updateSettings}
                type="submit"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  )
}

EvaluationSettings.propTypes = {
  className: PropTypes.string,
}

export default EvaluationSettings
