import React, { useEffect, useState } from 'react'
import { getIn } from 'immutable'
import { Button, Grid, Select, MenuItem, ListSubheader, Fade, Typography, List } from '@material-ui/core'
import TeamMemberList from './TeamMemberList'
import { getCurrentUserLeadTeams, getTeamEvaluations } from 'api'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth0 } from 'react-auth0-spa'
import CombinedEvaluationsTable from 'components/Tables'
import { getIcon } from 'utils/GetIcon'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  flexGrow: {
    flexGrow: 1,
  },
  hiddenDropdown: {
    visibility: 'hidden',
    height: 0,
  },
  noTeamsMessage: {
    margin: theme.spacing(3),
  },
  teamHeader: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  teamTitle: {
    marginTop: theme.spacing(2),
    marginBottom: '5px',
  },
}))

const TeamDashboard = () => {
  const { appMetadata } = useAuth0()
  const classes = useStyles()
  const [teams, setTeams] = useState([{}])
  const [currentTeam, setCurrentTeam] = useState([{}])
  const [currentTeamId, setCurrentTeamId] = useState(0)
  const [isLoadingTeams, setIsLoadingTeams] = useState(true)
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    getCurrentUserLeadTeams()
      .then((response) => {
        setTeams(getIn(response, ['teams']))
        setCurrentTeam(getIn(response, ['teams', 0]))
        setCurrentTeamId(getIn(response, ['teams', 0, 'id']))
      })
      .then(setIsLoadingTeams(false))
  }, [])

  const fetchSelectedTeamDetails = (teamId) => {
    setCurrentTeam(teams.find((x) => x.id === teamId))
    setCurrentTeamId(teamId)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return teams.length > 0 ? (
    <Fade in={!isLoadingTeams}>
      <div>
        <div className={classes.teamHeader}>
          <Grid container spacing={4} className={classes.teamTitle}>
            <Grid item>
              <Typography variant={'h2'}>{currentTeam.name || 'Loading...'}</Typography>
            </Grid>
            <Grid item>
              <Button
                endIcon={getIcon('dropdown')}
                size="small"
                onClick={handleOpen}
                variant="outlined"
                color="primary">
                Change Team
              </Button>
            </Grid>
          </Grid>
          {/* <div className={classes.flexGrow} /> */}
          {teams.length > 1 && (
            <Select
              defaultValue={teams[0].id}
              id="team-select"
              open={open}
              onClose={handleClose}
              className={classes.hiddenDropdown}>
              <ListSubheader>My Teams</ListSubheader>
              {teams
                .filter((team) => getIn(team, ['lead', 'id']) === appMetadata.personId)
                .map((team) => (
                  <MenuItem value={team.id} onClick={() => fetchSelectedTeamDetails(team.id)} key={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
              <ListSubheader>Reporting Teams</ListSubheader>
              {teams
                .filter((team) => getIn(team, ['lead', 'id']) !== appMetadata.personId)
                .map((team) => (
                  <MenuItem value={team.id} onClick={() => fetchSelectedTeamDetails(team.id)} key={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
            </Select>
          )}
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            {currentTeamId !== 0 && (
              <CombinedEvaluationsTable
                fetchData={async (page = 0, pageSize = 0, searchQuery = '', excludedStatuses = []) =>
                  getTeamEvaluations(currentTeamId, 0, null, page, pageSize, searchQuery, excludedStatuses)
                }
                hideFilters
                dense
                refreshDataOnChange={currentTeamId}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <List dense>
              <TeamMemberList team={currentTeam} />
            </List>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </div>
    </Fade>
  ) : (
    <div className={classes.noTeamsMessage}>
      <Typography variant={'h3'}>No Teams Found</Typography>
      <Typography variant={'subtitle2'}>
        Check with your HR Manager to make sure you've been assigned as a team lead.
      </Typography>
    </div>
  )
}

export default TeamDashboard
