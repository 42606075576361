import React, { useCallback } from 'react'
import { getAutoCompletePeople } from 'api'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import BaseAutocompleteSearch from './BaseAutocompleteSearch'
export default function EmployeeSearch(props) {
  const { onChange, value, excludedIds, directReports, label, excludedTagTypes } = props
  const getOptionsFunc = useCallback(
    async () =>
      getAutoCompletePeople({
        excludedIds: excludedIds || [],
        filterDirectReports: directReports,
        excludePeopleWithTagType: excludedTagTypes || [],
      }),
    [directReports, excludedIds, excludedTagTypes],
  )
  console.log('Employee value', props)
  return (
    <BaseAutocompleteSearch
      {...props}
      fetchOptions={getOptionsFunc}
      filterSelectedOptions
      getOptionLabel={(option) => `${option.fullName || ''}`}
      groupBy={(option) => option.firstLetter}
      renderOption={(option) => (
        <div>
          {option.fullName}
          <br />
          <Typography variant="subtitle2">{option.email}</Typography>
        </div>
      )}
      label={label || 'Employees'}
      value={value}
      onChange={onChange}
    />
  )
}

EmployeeSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
}
