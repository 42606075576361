import React from 'react'
import auth0 from 'auth0-js'

const CorsFallback = () => {
  const webAuth = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  })

  webAuth.crossOriginVerification()

  return <div>CORS Fallback</div>
}

export default CorsFallback
