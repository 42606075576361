import { makeStyles } from '@material-ui/styles'

export const useGoalDetailsCardStyles = makeStyles((theme) => ({
  root: { paddingTop: theme.spacing(4) },
  goalDetailWrapper: { padding: theme.spacing(3) },
  goalButtons: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  goalHeader: {
    marginBottom: theme.spacing(2),
  },
  goalList: {
    padding: theme.spacing(2),
  },
  newMeasureButton: {
    marginLeft: theme.spacing(1),
  },
  paper: {
    borderRadius: '15px',
  },
  paperHeader: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  paperContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  metricsBlock: {
    paddingTop: theme.spacing(2),
  },
  focusBottomBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.white,
  },
}))
