import React from 'react'
import PropTypes from 'prop-types'
import Notifier from 'components/Notifier/Notifier'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Prompt, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getIn } from 'immutable'
import ClearIcon from '@material-ui/icons/Clear'
import { useFocusStyles } from 'styles'
import { useAuth0 } from 'react-auth0-spa'
import { CircularProgress } from '@material-ui/core'
import { productOfferingTypes } from 'data/constants'

const Focus = (props) => {
  const { children } = props
  const classes = useFocusStyles()
  const history = useHistory()

  const { loading } = useAuth0()

  const focusViewState = useSelector((state) => getIn(state, ['focusView']))

  if (loading) {
    return (
      <div className={classes.spinnerBox}>
        <CircularProgress className={classes.spinner} size={160} thickness={1} />
        <Typography className={classes.spinnerText} variant="h4">
          Express Evaluations
        </Typography>
      </div>
    )
  }

  return (
    <>
      <AppBar
        className={
          focusViewState.productOfferingType === productOfferingTypes.engagement
            ? classes.focusTopBarPulse
            : classes.focusTopBar
        }
        elevation={0}>
        <Toolbar>
          <IconButton onClick={history.goBack} edge="start" className={classes.menuButton}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            {focusViewState.title}
          </Typography>
          <div className={classes.grow} />
          {focusViewState.button}
          <IconButton onClick={() => history.push(focusViewState.closeUrl)}>
            <ClearIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>{children}</main>
      <Notifier />
      {focusViewState.bottomDescription && (
        <>
          <AppBar position="fixed" className={classes.focusBottomBar}>
            <Toolbar>
              <Typography color="primary" variant="h6" className={classes.title}>
                {focusViewState.bottomDescription}
              </Typography>
              <div className={classes.grow} />
              {focusViewState.actions}
            </Toolbar>
          </AppBar>
        </>
      )}
      <Prompt
        when={focusViewState.unsavedChanges}
        message="Are you sure you want to leave? All unsaved changes will be lost."
      />
    </>
  )
}

Focus.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Focus
