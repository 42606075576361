import React, { useState } from 'react'
import {
  Button,
  Divider,
  FormGroup,
  Paper,
  FormControlLabel,
  Switch,
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { useEvaluationResultsNavStyles } from 'styles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { useAuth0 } from 'react-auth0-spa'

const EvaluationResultsNav = (props) => {
  const classes = useEvaluationResultsNavStyles()
  const {
    displayControls,
    updateDisplayControls,
    featuresEnabled,
    sectionList,
    handleSectionFilter,
    handleResponseTypeFilter,
    resetFilters,
  } = props

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(true)
  const { appMetadata } = useAuth0()
  const sectionFilterTooltip = (showInSelf, showInLead, showInPeer) => {
    const title = 'Completed by: '
    let types = []
    if (showInSelf) {
      types.push('Employee')
    }
    if (showInLead) {
      types.push('Manager ')
    }
    if (showInPeer) {
      types.push('Peer')
    }
    return (
      <Typography variant="h6" className={classes.tooltipTitle}>
        {title + types.join(', ')}
      </Typography>
    )
  }

  return (
    <Paper className={classes.root}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="h2">Filters</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={showAdvancedFilters ? 'outlined' : 'contained'}
            color="primary"
            className={classes.button}
            startIcon={showAdvancedFilters ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            onClick={() => setShowAdvancedFilters((saf) => !saf)}>
            Advanced Filters
          </Button>
        </Grid>{' '}
        <Grid item>
          <Button
            variant={'outlined'}
            color="primary"
            className={classes.button}
            startIcon={<RotateLeftIcon />}
            onClick={resetFilters}>
            Reset Filters
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <span className={classes.resultsNavLabel}>Display: </span>
            <FormControlLabel
              className={classes.optionToggle}
              control={
                <Switch
                  checked={displayControls.comments}
                  name="comments"
                  color="primary"
                  onChange={(e) => updateDisplayControls(e)}
                />
              }
              label="Comments"
            />
            {featuresEnabled.scoring && (
              <FormControlLabel
                className={classes.optionToggle}
                control={
                  <Switch
                    checked={displayControls.scoring}
                    name="scoring"
                    color="primary"
                    onChange={(e) => updateDisplayControls(e)}
                  />
                }
                label="Scoring"
              />
            )}
            {featuresEnabled.signatures && (
              <FormControlLabel
                className={classes.optionToggle}
                control={
                  <Switch
                    checked={displayControls.signatures}
                    name="signatures"
                    color="primary"
                    onChange={(e) => updateDisplayControls(e)}
                  />
                }
                label="Signatures"
              />
            )}
          </FormGroup>
        </Grid>
        {showAdvancedFilters && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <span className={classes.resultsNavLabel}>Response Type: </span>
                <FormControlLabel
                  className={classes.optionToggle}
                  control={
                    <Switch
                      checked={!displayControls.excludedResponseTypes.includes('self')}
                      name="selfResponses"
                      color="primary"
                      onChange={() => handleResponseTypeFilter('self')}
                    />
                  }
                  label="Employee"
                />
                {featuresEnabled.leadSections && (
                  <FormControlLabel
                    className={classes.optionToggle}
                    control={
                      <Switch
                        checked={!displayControls.excludedResponseTypes.includes('lead')}
                        name="leadSections"
                        color="primary"
                        onChange={() => handleResponseTypeFilter('lead')}
                      />
                    }
                    label="Manager"
                  />
                )}
                {appMetadata.accessPeerEvaluations && (
                  <FormControlLabel
                    className={classes.optionToggle}
                    control={
                      <Switch
                        checked={!displayControls.excludedResponseTypes.includes('peer')}
                        name="peerResponses"
                        color="primary"
                        onChange={() => handleResponseTypeFilter('peer')}
                      />
                    }
                    label="Peers"
                  />
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <span className={classes.resultsNavLabel}>Sections: </span>
                {sectionList &&
                  sectionList.map((value, index) => (
                    <Tooltip
                      key={`section_nav_label_${index}`}
                      title={sectionFilterTooltip(value.showInSelf, value.showInLead, value.showInPeer)}>
                      <FormControlLabel
                        className={classes.optionToggle}
                        control={
                          <Switch
                            checked={!displayControls.excludedSections.includes(value.id)}
                            name="comments"
                            color="primary"
                            onChange={() => handleSectionFilter(value.id)}
                          />
                        }
                        label={value.title}
                      />
                    </Tooltip>
                  ))}
              </FormGroup>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  )
}

export default EvaluationResultsNav
