import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useAlertStyles } from 'styles'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import { Link } from 'react-router-dom'

const Hint = (props) => {
  const { severity, backgroundColor, icon, text, LinkText1, LinkURL1, LinkText2, LinkURL2 } = props

  const classes = useAlertStyles()
  const [open, setOpen] = React.useState(true)

  return (
    <Grid className={classes.root}>
      <Collapse in={open}>
        <Alert
          style={{ backgroundColor: backgroundColor }}
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          <div>
            <div className={classes.icon}>{icon}</div>
            <Typography> {text}</Typography>
            {(LinkURL1 || LinkURL2) && (
              <Grid item xs={12}>
                <Button className={classes.link} component={Link} to={LinkURL1}>
                  {' '}
                  {LinkText1}
                </Button>
                <Button className={classes.link} component={Link} to={LinkURL2}>
                  {LinkText2}
                </Button>
              </Grid>
            )}
          </div>
        </Alert>
      </Collapse>
    </Grid>
  )
}

export default Hint
