import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import StyledTreeItem from './components/StyledTreeItem'
import BusinessIcon from '@material-ui/icons/Business'
import PaymentIcon from '@material-ui/icons/Payment'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { NotificationSettings, GeneralSettings, ScoringSettings, LocationSettings } from '../CompanySettings'
import PermissionCheck from 'utils/PermissionCheck'
import SettingsIcon from '@material-ui/icons/Settings'
import ListAltIcon from '@material-ui/icons/ListAlt'
import GradeIcon from '@material-ui/icons/Grade'
import { getStripePortal } from 'api'
import { Typography, CircularProgress, Modal } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { roles } from 'utils/roles'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import { SignatureSettings } from '../EvaluationSettings'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import { useHistory, useParams } from 'react-router-dom'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import { useAuth0 } from 'react-auth0-spa'

const useStyles = makeStyles({
  root: {
    minHeight: 150,
    flexGrow: 1,
    maxWidth: 400,
  },
})

const SettingsNav = (props) => {
  const classes = useStyles()
  const { setCurrentSettingsPage } = props
  const [portalLoading, setPortalLoading] = useState(false)
  const history = useHistory()
  const { category, page } = useParams()
  const { appMetadata } = useAuth0()

  const handlePageChange = (setting, route) => {
    setting && history.push(`/settings/${route}`)
    setCurrentSettingsPage(setting)
  }

  const handleStripeSessionChange = () => {
    setPortalLoading(true)
    getStripePortal().then((response) => {
      window.location = response.data.url
    })
  }

  useEffect(() => {
    console.log(category, page, 'ROUTE')
    switch (category) {
      case 'organization':
        switch (page) {
          case 'general':
            setCurrentSettingsPage(<GeneralSettings />)
            break
          case 'notifications':
            setCurrentSettingsPage(<NotificationSettings />)
            break
          case 'locations':
            setCurrentSettingsPage(<LocationSettings />)
            break
          default:
            setCurrentSettingsPage(<GeneralSettings />)
            break
        }
        break
      case 'evaluations':
        switch (page) {
          case 'scoring':
            setCurrentSettingsPage(<ScoringSettings />)
            break
          case 'signatures':
            setCurrentSettingsPage(<SignatureSettings />)
            break
          default:
            setCurrentSettingsPage(<GeneralSettings />)
            break
        }
        break
      case 'notifications':
        switch (page) {
          case 'evaluation':
            setCurrentSettingsPage(<NotificationSettings type="evaluation" title="Evaluation" />)
            break
          case 'pulse':
            setCurrentSettingsPage(<NotificationSettings type="pulse" title="Pulse Survey" />)
            break
          case 'goal':
            setCurrentSettingsPage(<NotificationSettings type="goal" title="Goal" />)
            break
          default:
            setCurrentSettingsPage(<NotificationSettings type="evaluation" title="Evaluation" />)
            break
        }
        break
      default:
        setCurrentSettingsPage(<GeneralSettings />)
        break
    }
  }, [category, page, setCurrentSettingsPage])

  return (
    <>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        defaultExpanded={['1', '2', '3', '4']}
        defaultExpandIcon={<ArrowRightIcon />}>
        {/* <StyledTreeItem labelIcon={AccountBoxIcon} labelText="Account" nodeId="1">
          <StyledTreeItem
            labelIcon={PersonIcon}
            labelText="Profile"
            nodeId="11"
            onClick={() => handlePageChange(<ProfileSettings />)}
          />
        </StyledTreeItem> */}
        <PermissionCheck
          key={`edit-person-button`}
          roles={[roles.Admin]}
          yes={() => (
            <>
              <StyledTreeItem labelIcon={BusinessIcon} labelText="Company" nodeId="2">
                <StyledTreeItem
                  labelIcon={SettingsIcon}
                  labelText="General"
                  nodeId="20"
                  onClick={() => handlePageChange(<GeneralSettings />, 'organization/general')}
                />
                <StyledTreeItem
                  labelIcon={PaymentIcon}
                  labelText="Billing"
                  nodeId="23"
                  onClick={() => handleStripeSessionChange()}
                />
                <StyledTreeItem
                  labelIcon={LocationCityIcon}
                  labelText="Locations"
                  nodeId="24"
                  onClick={() => handlePageChange(<LocationSettings />, 'organization/locations')}
                />
              </StyledTreeItem>
              <StyledTreeItem labelIcon={ListAltIcon} labelText="Evaluations" nodeId="3">
                <StyledTreeItem
                  labelIcon={GradeIcon}
                  labelText="Scoring"
                  nodeId="30"
                  onClick={() => handlePageChange(<ScoringSettings />, 'evaluations/scoring')}
                />
                <StyledTreeItem
                  labelIcon={BorderColorIcon}
                  labelText="Signatures"
                  nodeId="31"
                  onClick={() => handlePageChange(<SignatureSettings />, 'evaluations/signatures')}
                />
              </StyledTreeItem>
              <StyledTreeItem labelIcon={NotificationsIcon} labelText="Notifications" nodeId="4">
                <StyledTreeItem
                  labelIcon={ListAltIcon}
                  labelText="Evaluation"
                  nodeId="40"
                  onClick={() =>
                    handlePageChange(
                      <NotificationSettings type="evaluation" title="Evaluation" />,
                      'notifications/evaluation',
                    )
                  }
                />
                {appMetadata.accessPulse && (
                  <StyledTreeItem
                    labelIcon={GraphicEqIcon}
                    labelText="Pulse Survey"
                    nodeId="41"
                    onClick={() =>
                      handlePageChange(
                        <NotificationSettings type="pulse" title="Pulse Survey" />,
                        'notifications/pulse',
                      )
                    }
                  />
                )}
                {appMetadata.accessGoals && (
                  <StyledTreeItem
                    labelIcon={TrackChangesIcon}
                    labelText="Goal"
                    nodeId="42"
                    onClick={() =>
                      handlePageChange(<NotificationSettings type="goal" title="Goal" />, 'notifications/goal')
                    }
                  />
                )}
              </StyledTreeItem>
            </>
          )}
        />
      </TreeView>
      <Modal open={portalLoading} onClose={() => {}}>
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#ffffff',
            top: 250,
            left: '36%',
            width: 500,
            padding: 40,
            outline: 0,
            borderRadius: 5,
          }}>
          <LockIcon />
          <Typography variant="h5">Secure billing portal is loading...</Typography>
          <CircularProgress />
        </div>
      </Modal>
    </>
  )
}

export default SettingsNav
