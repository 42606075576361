import React from 'react'
import { Typography } from '@material-ui/core'
import QuestionResult from './QuestionResult'
import { useSectionResultStyles } from 'styles'
import RichTextBlock from 'components/RichText/RichTextBlock'
import { ModernCard } from 'components'

const SectionResult = (props) => {
  const { section, index, displayControls } = props
  const classes = useSectionResultStyles()
  let sectionSubtitle
  let sectionNumber = `Section ${index + 1}`
  if (section.showInLead && !section.showInSelf && !section.showInPeer) {
    sectionSubtitle = '(Manager Only)'
  } else if (section.showInSelf && !section.showInLead && !section.showInPeer) {
    sectionSubtitle = '(Employee Only)'
  } else if (section.showInPeer && !section.showInLead && !section.showInSelf) {
    sectionSubtitle = '(Peer Only)'
  }
  return (
    <div className={classes.root}>
      <ModernCard
        headerTitle={section.title}
        leftSubtitle={
          <Typography color="inherit" variant="overline" className={classes.sectionNumber}>
            {sectionNumber} {sectionSubtitle}
          </Typography>
        }
        rightSubtitle={
          props.scoringEnabled &&
          section.possibleScore > 0 && (
            <Typography color="inherit" variant="h5" className={classes.sectionHeadingText} align="right">
              {section.score}/{section.possibleScore} points
            </Typography>
          )
        }>
        {section.description && <RichTextBlock content={section.description} />}
        {section.questions.map((question, index) => (
          <div className={classes.subItem}>
            <QuestionResult
              key={`question_${index}_${section.id}_${question.id}_${question.title}`}
              index={index}
              question={question}
              scoringEnabled={props.scoringEnabled && section.possibleScore > 0}
              commentsEnabled={props.commentsEnabled}
              displayControls={displayControls}
            />
          </div>
        ))}
      </ModernCard>
      <div className={classes.headingSpacing}></div>
    </div>
  )
}

export default SectionResult
