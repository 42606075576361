import { getEvaluationById, submitEvaluation, getEvaluationTemplateById, saveEvaluationProgress } from 'api'
import { getIn } from 'immutable'
import { normalizeArray } from 'helpers/arrays'
import actionTypes from 'redux/actionTypes'
import {
  enqueueSuccessNotification,
  enqueueErrorNotification,
  enqueueWarningNotification,
} from 'redux/actions/notificationsActions'
import { initializeFocusView, updateUnsavedchanges } from './focusViewActions'
import React from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ActionButtons from 'views/Evaluation/components/Section/ActionButtons'
import { FormatDate } from 'utils/DateTime/FormatDate'
import { getSurveyById, submitSurvey } from 'linkTokenApi'
import { productOfferingTypes } from 'data/constants'
export const loadEvaluationPageStarted = (isPreview) => ({
  type: 'LOAD_EVALUATION_PAGE_STARTED',
  isPreview,
})

export const loadEvaluationPageSucceeded = (
  evaluation,
  currentSectionIndex,
  isPreview,
  progress,
  goals,
  reviewGoalInstructions,
  includeReviewingGoals,
  goalCreationInstructions,
  includeGoalCreation,
) => ({
  type: 'LOAD_EVALUATION_PAGE_SUCCEEDED',
  evaluation,
  currentSectionIndex,
  isPreview,
  progress,
  goals,
  reviewGoalInstructions,
  includeReviewingGoals,
  goalCreationInstructions,
  includeGoalCreation,
})

export const checkRequiredQuestionsSucceeded = (answered) => ({
  type: 'CHECK_REQUIRED_QUESTIONS_SUCCEEDED',
  answered,
})

export const changeEvaluationCurrentSection = (sectionIndex, section, progress) => ({
  type: 'CHANGE_EVALUATION_CURRENT_SECTION',
  sectionIndex,
  section,
  progress,
})

export const updateEvaluationResultsSucceeded = (results, unsavedChanges) => ({
  type: 'UPDATE_EVALUATION_RESULTS_SUCCEEDED',
  results,
  unsavedChanges,
})

export const updateLastUpdatedAtTime = (time) => ({
  type: 'UPDATE_LAST_UPDATED_TIME',
  time,
})

export const updateCurrentProgress = (progress) => ({
  type: 'UPDATE_CURRENT_PROGRESS',
  progress,
})

export const markEvaluationAsComplete = () => ({
  type: 'MARK_EVALUATION_AS_COMPLETE',
})

export const loadAsCompleted = () => ({
  type: 'LOAD_AS_COMPLETED',
})

export const resetEvaluationToDefaults = () => ({
  type: actionTypes.resetEvaluationToDefaults,
})

export const updateCompletedSectionsSucceeded = (sections) => ({
  type: actionTypes.updateCompletedSectionsSucceeded,
  sections,
})

export const startGoalReviewSucceeded = () => ({
  type: actionTypes.startGoalReviewSucceeded,
})

export const endGoalReviewSucceeded = () => ({
  type: actionTypes.endGoalReviewSucceeded,
})

export const startGoalCreationSucceeded = () => ({
  type: actionTypes.startGoalCreationSucceeded,
})

export const endGoalCreationSucceeded = () => ({
  type: actionTypes.endGoalCreationSucceeded,
})

export const updateReadyToSubmit = (isReady) => ({
  type: actionTypes.updateReadyToSubmit,
  isReady,
})

export const completeGoalReview = () => ({
  type: actionTypes.completeGoalReviewSucceeded,
})

export const completeGoalCreation = () => ({
  type: actionTypes.completeGoalCreationSucceeded,
})

export const addUnsavedCommentsGoalId = (goalId) => ({
  type: actionTypes.addUnsavedCommentsGoalId,
  goalId,
})

export const removeUnsavedCommentsGoalId = (goalId) => ({
  type: actionTypes.removeUnsavedCommentsGoalId,
  goalId,
})

export const toggleEvaluationAutoSave = () => ({
  type: actionTypes.toggleAutosave,
})

export const updateEvaluationGoals = (goals) => ({
  type: actionTypes.updateEvaluationGoals,
  goals,
})

export const loadEvaluationPage = (id, isPreview, history, token = false) => async (dispatch, getState) => {
  dispatch(loadEvaluationPageStarted(isPreview))
  dispatch(updateUnsavedchanges(false))
  if (isPreview) {
    getEvaluationTemplateById(id).then((response) => {
      dispatch(loadEvaluationPageSucceeded({ template: getIn(response, ['data']) }, 0, true, 0))
      dispatch(
        initializeFocusView(
          `Preview - ${getIn(response, ['data', 'title'])}`,
          '/templates',
          '/templates',
          `Last Updated: ${FormatDate(getIn(response, ['data', 'evaluation', 'lastUpdatedAt']), true)}`,
          !getIn(response, ['data', 'evaluation', 'isComplete']) ? (
            <ActionButtons />
          ) : (
            <Button
              component={Link}
              to={'/dashboard'}
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIosIcon />}>
              Go back to dashboard
            </Button>
          ),
            getIn(response, ['data', 'evaluation', 'isPulse']) || getIn(response, ['data', 'isPulseTemplate'])
              ? productOfferingTypes.engagement
              : productOfferingTypes.performance,
        ),
      )
      dispatch(updateLastUpdatedAtTime(getIn(response, ['data', 'lastUpdatedAt'])))
      dispatch(checkRequiredQuestionsSucceeded(true))
    })
  } else {
    let evaluationFunc = () => {}
    if (token) {
      evaluationFunc = () => getSurveyById(id, token)
    } else {
      evaluationFunc = () => getEvaluationById(id)
    }

    evaluationFunc().then((response) => {
      if (getIn(response, ['status']) === 200) {
        if (getIn(response, ['data', 'evaluation', 'isComplete'])) {
          dispatch(
            loadEvaluationPageSucceeded(
              getIn(response, ['data', 'evaluation']),
              0,
              false,
              0,
              getIn(response, ['data', 'goals']),
              getIn(response, ['data', 'goalReviewInstructions']),
              getIn(response, ['data', 'includeGoalReview']),
              getIn(response, ['data', 'goalCreationInstructions']),
              getIn(response, ['data', 'includeGoalCreation']),
            ),
          )
          dispatch(loadAsCompleted())
        } else {
          dispatch(
            loadEvaluationPageSucceeded(
              getIn(response, ['data', 'evaluation']),
              0,
              false,
              0,
              getIn(response, ['data', 'goals']),
              getIn(response, ['data', 'goalReviewInstructions']),
              getIn(response, ['data', 'includeGoalReview']),
              getIn(response, ['data', 'goalCreationInstructions']),
              getIn(response, ['data', 'includeGoalCreation']),
            ),
          )
          dispatch(
            updateEvaluationResultsSucceeded(normalizeArray(getIn(response, ['data', 'results']), 'questionId'), false),
          )
          dispatch(updateLastUpdatedAtTime(getIn(response, ['data', 'evaluation', 'lastUpdatedAt'])))
          dispatch(checkRequiredQuestionsForCurrentSection())
          dispatch(updateCompletedSections())
        }
        dispatch(
          initializeFocusView(     
            // TODO: Tech debt here, we probably need to simplify this sometime soon     
            `${getIn(response, ['data', 'evaluation', 'type']) === "lead" ? "Manager" : getIn(response, ['data', 'evaluation', 'type'])} ${getIn(response, ['data', 'evaluation', 'isPulse']) ? "Survey | " + getIn(response, ['data', 'evaluation', 'template', 'title']) : " Evaluation | " + getIn(response, ['data', 'evaluation', 'evaluatedName'])}`,
            '/evaluations',
            '/evaluations',
            `Last Updated: ${FormatDate(getIn(response, ['data', 'evaluation', 'lastUpdatedAt']), true)}`,
            !getIn(response, ['data', 'evaluation', 'isComplete']) ? (
              <ActionButtons />
            ) : (
              <Button
                component={Link}
                to={'/dashboard'}
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIosIcon />}>
                Go back to dashboard
              </Button>
            ),
            getIn(response, ['data', 'evaluation', 'isPulse'])
              ? productOfferingTypes.engagement
              : productOfferingTypes.performance,
          ),
        )
      } else if (getIn(response, ['status']) === 204) {
        history.push('/not-available')
      } else if (getIn(response, ['status']) === 403) {
        history.push('/not-allowed')
      } else if (getIn(response, ['status']) === 400) {
        dispatch(enqueueWarningNotification(getIn(response, ['data', 'errors', 'SurveyId', 0])))
      } else {
      }
    })
  }
}

export const updateStep = (type) => async (dispatch, getState) => {
  if (type === 'next') {
    const nextSectionIndex = getIn(getState(), ['evaluation', 'currentSectionIndex']) + 1
    const nextSection = getIn(getState(), ['evaluation', 'evaluation', 'template', 'sections'])[nextSectionIndex]
    const progress = (nextSectionIndex / getIn(getState(), ['evaluation', 'numberOfSections'])) * 100
    dispatch(changeEvaluationCurrentSection(nextSectionIndex, nextSection))
    dispatch(saveEvaluation())
    dispatch(updateCurrentProgress(progress))

    if (getIn(getState(), ['evaluation', 'numberOfSections']) !== nextSectionIndex) {
      dispatch(checkRequiredQuestionsForCurrentSection())
    } else {
      dispatch(markEvaluationAsComplete())
    }
  } else if (type === 'previous') {
    const previousSectionIndex = getIn(getState(), ['evaluation', 'currentSectionIndex']) - 1
    const progress = (previousSectionIndex / getIn(getState(), ['evaluation', 'numberOfSections'])) * 100
    const previousSection = getIn(getState(), ['evaluation', 'evaluation', 'template', 'sections'])[
      previousSectionIndex
    ]
    dispatch(changeEvaluationCurrentSection(previousSectionIndex, previousSection))
    dispatch(saveEvaluation())
    dispatch(updateCurrentProgress(progress))
    dispatch(checkRequiredQuestionsForCurrentSection())
  } else if (type === 'reviewGoalsPrevious') {
    const previousSectionIndex = getIn(getState(), ['evaluation', 'numberOfSections']) - 1
    const progress = (previousSectionIndex / getIn(getState(), ['evaluation', 'numberOfSections'])) * 100
    const previousSection = getIn(getState(), ['evaluation', 'evaluation', 'template', 'sections'])[
      previousSectionIndex
    ]

    dispatch(changeEvaluationCurrentSection(previousSectionIndex, previousSection))
    dispatch(updateCurrentProgress(progress))
    dispatch(checkRequiredQuestionsForCurrentSection())
    dispatch(endGoalReviewSucceeded())
  } else if (type === 'createGoalsPrevious') {
    dispatch(endGoalCreationSucceeded())
    dispatch(startGoalReviewSucceeded())
  }
  dispatch(updateCompletedSections())
}

export const updateEvaluationResults = (questionId, sectionId, type, response, score, maxScore) => async (
  dispatch,
  getState,
) => {
  const questionResults = getIn(getState(), ['evaluation', 'results'])[questionId]
  const newResult = {
    ...questionResults,
    evaluationId: getIn(getState(), ['evaluation', 'evaluation', 'id']),
    templateversionId: getIn(getState(), ['evaluation', 'evaluation', 'template', 'id']),
    sectionId: sectionId,
    questionId: questionId,
    type: type,
    response: response,
    maxScore: maxScore,
    score: score,
    scoringEnabled: getIn(getState(), ['evaluation', 'evaluation', 'template', 'scoringEnabled']),
  }
  const results = {
    ...getIn(getState(), ['evaluation', 'results']),
    [questionId]: newResult,
  }
  dispatch(updateEvaluationResultsSucceeded(results, true))
  dispatch(checkRequiredQuestionsForCurrentSection())
  dispatch(updateCompletedSections())
  dispatch(updateUnsavedchanges(true))
}

export const updateQuestionComments = (questionId, comments) => async (dispatch, getState) => {
  const questionResults = getIn(getState(), ['evaluation', 'results'])[questionId]
  const newResult = { ...questionResults, comments: comments }
  const results = {
    ...getIn(getState(), ['evaluation', 'results']),
    [questionId]: newResult,
  }

  dispatch(updateEvaluationResultsSucceeded(results, true))
  dispatch(updateUnsavedchanges(true))
}

export const submitCompletedEvaluation = (token) => async (dispatch, getState) => {
  const submitFunc = token
    ? () => {
        return submitSurvey(
          getIn(getState(), ['evaluation', 'evaluation', 'id']),
          {
            evaluation: getIn(getState(), ['evaluation', 'evaluation']),
            results: Array.from(Object.values(getIn(getState(), ['evaluation', 'results']))),
          },
          token,
        )
      }
    : () => {
        return submitEvaluation({
          evaluation: getIn(getState(), ['evaluation', 'evaluation']),
          results: Array.from(Object.values(getIn(getState(), ['evaluation', 'results']))),
          reviewedGoalsIds: getIn(getState(), ['evaluation', 'goals']).map((goal) => goal.id),
        })
      }
  submitFunc().then((response) => {
    if (getIn(response, ['status']) === 200) {
      const nextSectionIndex = getIn(getState(), ['evaluation', 'currentSectionIndex']) + 1
      const nextSection = getIn(getState(), ['evaluation', 'evaluation', 'template', 'sections'])[nextSectionIndex]
      const progress = (nextSectionIndex / getIn(getState(), ['evaluation', 'numberOfSections'])) * 100

      dispatch(changeEvaluationCurrentSection(nextSectionIndex, nextSection))
      dispatch(updateCurrentProgress(progress))
      dispatch(markEvaluationAsComplete())
      dispatch(
        initializeFocusView(
          getIn(response, ['data', 'evaluation', 'template', 'title']),
          '/evaluations',
          '/evaluations',
          `Last Updated: ${FormatDate(getIn(getState(), ['evaluation', 'lastUpdatedAt']), true)}`,
          <Button
            component={Link}
            to={'/dashboard'}
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}>
            Go back to dashboard
          </Button>,
          getIn(getState(), ['evaluation', 'isPulse'])
            ? productOfferingTypes.engagement
            : productOfferingTypes.performance,
        ),
      )
    } else if (getIn(response, ['status']) === 400) {
      dispatch(
        enqueueErrorNotification(
          'Your evaluation was not submitted. There was an problem with the request. Make sure if you provided comments that you also provided a response to the question.',
        ),
      )
    } else {
      dispatch(enqueueErrorNotification('Your evaluation did not submit. The server encountered an error.'))
    }
  })
  dispatch(updateUnsavedchanges(false))
}

export const saveEvaluation = () => async (dispatch, getState) => {
  if (!getIn(getState(), ['evaluation', 'isPreview'])) {
    saveEvaluationProgress({
      evaluationId: getIn(getState(), ['evaluation', 'evaluation', 'id']),
      results: Array.from(Object.values(getIn(getState(), ['evaluation', 'results']))),
    }).then((response) => {
      if (getIn(response, ['status']) === 200) {
        dispatch(updateLastUpdatedAtTime(response.data.lastUpdatedAt))
        const normalizedResults = normalizeArray(response.data.results, 'questionId')
        dispatch(updateEvaluationResultsSucceeded(normalizedResults, false))
        dispatch(enqueueSuccessNotification('Your evaluation has been saved'))
        dispatch(updateUnsavedchanges(false))
      } else if (getIn(response, ['status']) === 400) {
        dispatch(enqueueErrorNotification('Your evaluation was not saved. There was an problem with the request.'))
      } else {
        dispatch(enqueueErrorNotification('Your evaluation was not saved. The server encountered an error.'))
      }
    })
  }
}

export const updateCompletedSections = () => async (dispatch, getState) => {
  const sections = getIn(getState(), ['evaluation', 'evaluation', 'template', 'sections'])
  let completedSections = []

  sections &&
    sections.forEach((value, index) => {
      const currentRequiredQuestionIds =
        getIn(getState(), ['evaluation', 'evaluation', 'template', 'requiredQuestions', [index]]) || []

      const responses = currentRequiredQuestionIds
        .map((value, index) => getIn(getState(), ['evaluation', 'results', [value], 'response']))
        .filter((value, index) => value !== undefined && value !== '')

      if (responses.length === currentRequiredQuestionIds.length) {
        completedSections.push(index)
      }
    })
  dispatch(updateCompletedSectionsSucceeded(completedSections))
  dispatch(checkReadyToSubmit())
}

export const checkRequiredQuestionsForCurrentSection = () => async (dispatch, getState) => {
  const currentRequiredQuestionIds =
    getIn(getState(), [
      'evaluation',
      'evaluation',
      'template',
      'requiredQuestions',
      [getIn(getState(), ['evaluation', 'currentSectionIndex'])],
    ]) || []

  const responses = currentRequiredQuestionIds
    .map((value, index) => getIn(getState(), ['evaluation', 'results', [value], 'response']))
    .filter((value, index) => value !== undefined && value !== '')

  if (responses.length === currentRequiredQuestionIds.length) {
    dispatch(checkRequiredQuestionsSucceeded(true))
  } else {
    dispatch(checkRequiredQuestionsSucceeded(false))
  }
}

export const changeSectionFromNav = (index, value) => async (dispatch, getState) => {
  dispatch(endGoalReviewSucceeded())
  dispatch(endGoalCreationSucceeded())
  dispatch(changeEvaluationCurrentSection(index, value))
  dispatch(updateCompletedSections())
}

export const startGoalReview = () => async (dispatch, getState) => {
  dispatch(saveEvaluation())
  dispatch(startGoalReviewSucceeded())
  dispatch(completeGoalReview())
  dispatch(checkReadyToSubmit())
  dispatch(endGoalCreationSucceeded())
}

export const startGoalCreation = () => async (dispatch, getState) => {
  dispatch(saveEvaluation())
  dispatch(startGoalCreationSucceeded())
  dispatch(completeGoalCreation())
  dispatch(checkReadyToSubmit())
  dispatch(endGoalReviewSucceeded())
}

export const checkReadyToSubmit = () => async (dispatch, getState) => {
  const isPreview = getIn(getState(), ['evaluation', 'isPreview'])
  const completedSections = getIn(getState(), ['evaluation', 'completedSections'])
  const numberOfSections = getIn(getState(), ['evaluation', 'numberOfSections'])
  const goalReviewIncludedAndComplete = getIn(getState(), ['evaluation', 'includeReviewingGoals'])
    ? getIn(getState(), ['evaluation', 'goalReviewComplete'])
    : true
  dispatch(
    updateReadyToSubmit(!isPreview && completedSections.length === numberOfSections && goalReviewIncludedAndComplete),
  )
}

export const syncCompletedGoalStatus = (goalId) => (dispatch, getState) => {
  let goals = getIn(getState(), ['evaluation', 'goals'])
  const goalIndex = goals.findIndex((g) => g.id === goalId)
  goals[goalIndex].isComplete = true
  dispatch(updateEvaluationGoals(goals))
}
