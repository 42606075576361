import React, { useState } from 'react'
import { createUserAccount, createBatchUserAccounts } from 'api'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { useDispatch } from 'react-redux'
import ButtonWithPrompt from './ButtonWithPrompt'
import PropTypes from 'prop-types'

export const InviteToJoinButton = (props) => {
  const { deletetype, name, personId, buttonType, callback } = props
  const [isInviting, setIsInviting] = useState(false)
  const dispatch = useDispatch()

  const handleInvite = () => {
    setIsInviting(true)
    if (deletetype === 'single') {
      createUserAccount({ personId: personId }).then((response) => {
        if (response.status === 200) {
          dispatch(enqueueSuccessNotification(`Invitation sent to ${name} successfully!`))
          callback()
        } else if (response.status === 500) {
          dispatch(
            enqueueErrorNotification(
              'The user was not able to be invited to join. The user already exists or another error occurred. Please try again or contact support.',
            ),
          )
        }
        setIsInviting(false)
      })
    } else if (deletetype === 'batch') {
      createBatchUserAccounts().then((response) => {
        if (response.status === 200) {
          dispatch(enqueueSuccessNotification('Invitations to users was sent successfully.'))
          callback()
        } else if (response.status === 500) {
          dispatch(
            enqueueErrorNotification(
              'An error occured on the server.  The users were not able to be invited. Please try again, or contact support',
            ),
          )
        }
        setIsInviting(false)
      })
    }
  }

  return (
    <ButtonWithPrompt
      buttonText={deletetype === 'single' ? 'Invite' : 'Send Invites'}
      description={
        isInviting
          ? ''
          : deletetype === 'batch'
          ? 'This action will send an email to every employee who has not been invited to the system.'
          : ''
      }
      title={
        isInviting
          ? 'Sending invitation(s), please wait until this process completes...'
          : `Are you sure you want to send an invitation to ${deletetype === 'single' ? name : 'all uninvited people'}?`
      }
      variant={buttonType}
      clickFunction={handleInvite}
      positiveButtonLabel={'Send Invite'}
      {...props}
    />
  )
}

InviteToJoinButton.propTypes = {
  buttonType: PropTypes.string,
  callback: PropTypes.func,
  deletetype: PropTypes.oneOf(['batch', 'single']).isRequired,
  name: PropTypes.string,
  personId: PropTypes.number,
}

InviteToJoinButton.defaultProps = {
  buttonType: 'text',
}
