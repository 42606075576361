import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  ButtonGroup,
} from '@material-ui/core'
import { getTagsTree, createTag, updateTag, deleteTag } from 'api'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import { Alert } from '@material-ui/lab'
import { useDispatch } from 'react-redux'
import { enqueueSuccessNotification, enqueueErrorNotification } from 'redux/actions/notificationsActions'
import { DeleteButton } from 'components/Buttons'
import AddIcon from '@material-ui/icons/Add'
import { InlineError } from 'helpers'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  notifyInput: {
    marginLeft: theme.spacing(2),
    textAlign: 'center',
  },
  notifyUnit: {
    minWidth: 80,
  },
}))

const LocationSettings = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const [locations, setLocations] = useState([])
  const [currentLocation, setCurrentLocation] = useState({ name: '', parentId: null })
  const [isEdit, setIsEdit] = useState(false)
  const [errors, setErrors] = useState(false)
  const dispatch = useDispatch()

  const resetLocation = () => {
    setCurrentLocation({ name: '', parentId: null })
  }

  const fetchLocations = useCallback(() => {
    getTagsTree('Location').then((response) => {
      setLocations(response.data)
    })
  }, [])

  useEffect(() => {
    fetchLocations()
  }, [fetchLocations])

  useEffect(() => {
    setErrors()
  }, [currentLocation])
  const handleLocationUpdate = () => {
    const location = currentLocation
    updateTag(location.id, { id: location.id, name: location.name, parentTagId: location.parentId }).then(
      (response) => {
        if (response.status === 200) {
          dispatch(enqueueSuccessNotification('Location has been successfully updated'))
          fetchLocations()
        } else if (response.status === 400) {
          setErrors(response.data.errors)
        } else {
          dispatch(enqueueErrorNotification('Server encountered an error. The location was not updated'))
        }
      },
    )
  }

  const handleLocationCreate = () => {
    const location = currentLocation
    createTag({ name: location.name, parentTagId: location.parentId, type: 'Location' }).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Location has been successfully created'))
        fetchLocations()
        resetLocation()
        setIsEdit(false)
      } else if (response.status === 400) {
        setErrors(response.data.errors)
      } else {
        dispatch(enqueueErrorNotification('Server encountered an error. The location was not created'))
      }
    })
  }

  const handleDeleteLocation = () => {
    deleteTag(currentLocation.id).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('Location has been successfully deleted'))
        fetchLocations()
        resetLocation()
      } else {
        dispatch(enqueueErrorNotification('Server encountered an error. The location was not deleted'))
      }
    })
  }

  const handleStartEdit = (location) => {
    setCurrentLocation(location)
    setIsEdit(true)
  }

  const handleStartAdd = () => {
    resetLocation()
    setIsEdit(false)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subheader="Manage your company's locations" title="Locations" />
      <Divider />
      <CardContent>
        <Grid container spacing={4} wrap="wrap">
          <Grid item xs={3}>
            <Typography variant="h6">Select a location to edit</Typography>
            <TreeView
              onClick={(e) => console.log(e)}
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={locations && locations.map((l) => l.id.toString())}>
              {locations &&
                locations.map((value, index) => (
                  <TreeItem
                    key={`${index}_${value.id}`}
                    onClick={() => handleStartEdit(value)}
                    nodeId={`${value.id}`}
                    label={value.name}>
                    {value.childTags &&
                      value.childTags.map((value, index) => (
                        <TreeItem
                          key={`${index}_${value.id}`}
                          onClick={() => handleStartEdit(value)}
                          nodeId={`${value.id}`}
                          label={value.name}
                        />
                      ))}
                  </TreeItem>
                ))}
              <TreeItem
                key={'add_new_location_key'}
                onClick={() => handleStartAdd()}
                nodeId={10000}
                label={<Button variant="text">Add New Location</Button>}
                icon={<AddIcon />}
              />
            </TreeView>
          </Grid>
          <Divider />
          <Grid item xs={9}>
            {!isEdit && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3">Add a location</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Location Name</Typography>
                  <TextField
                    onChange={(e) => setCurrentLocation({ ...currentLocation, name: e.target.value })}
                    fullWidth
                    value={currentLocation.name || ''}
                    variant="outlined"
                    error={errors}
                  />
                  <InlineError field="Name" errors={errors} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Parent Location</Typography>
                  <Select
                    onChange={(e) => setCurrentLocation({ ...currentLocation, parentId: e.target.value })}
                    value={currentLocation.parentId}
                    fullWidth
                    variant="outlined">
                    {locations.map((value) => (
                      <MenuItem key={`tag_location_${value.id}`} value={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup>
                    <Button color="primary" variant="contained" onClick={handleLocationCreate} disabled={errors}>
                      Create Location
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}
            {currentLocation && isEdit && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3">Edit Location</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Location Name</Typography>
                  <TextField
                    onChange={(e) => setCurrentLocation({ ...currentLocation, name: e.target.value })}
                    fullWidth
                    value={currentLocation.name || ''}
                    variant="outlined"
                    error={errors}
                  />
                  <InlineError field="Name" errors={errors} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Parent Location</Typography>
                  {currentLocation.childTags && currentLocation.childTags.length !== 0 ? (
                    <Alert severity="info">Locations with sub-locations cannot be assigned a parent location</Alert>
                  ) : (
                    <Select
                      onChange={(e) => setCurrentLocation({ ...currentLocation, parentId: e.target.value })}
                      value={currentLocation.parentId}
                      fullWidth
                      variant="outlined">
                      {currentLocation.parentId !== 0 && (
                        <MenuItem key={`tag_location_make_parent`} value={0}>
                          <i>Remove Parent</i>
                        </MenuItem>
                      )}
                      {locations.map((value) => (
                        <MenuItem key={`tag_location_${value.id}`} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  <InlineError field="Id" errors={errors} />
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup>
                    <Button color="primary" variant="contained" onClick={handleLocationUpdate} disabled={errors}>
                      Save Changes
                    </Button>
                    <DeleteButton
                      buttonType="contained"
                      deleteFunction={() => handleDeleteLocation()}
                      name={currentLocation.name}
                    />
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

LocationSettings.propTypes = {
  className: PropTypes.string,
}

export default LocationSettings
