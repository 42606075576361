import moment from 'moment'

export const goalDrawerValidations = () => {
  return {
    name: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128,
      },
    },
    description: {
      length: {
        maximum: 600,
      },
    },
    dueDate: {
      datetime: {
        dateOnly: true,
        latest: moment().add(1, 'years'),
        earliest: moment().subtract(1, 'days'),
      },
    },
    assignedPerson: (value, attributes, attributeName, options, constraints) => {
      if (attributes.isPersonal) return null
      return {
        presence: { allowEmpty: false, message: 'Non personal goals need an employee selected' },
      }
    },
  }
}
