import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import ScheduleForm from './components/ScheduleForm'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(8),
    paddingBottom: theme.spacing(2),
  },
  scheduleTable: {
    width: '100%',
  },
}))

const ScheduleCreate = () => {
  const classes = useStyles()

  return (
    <div className={classes.root} spacing={2}>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <Grid className={classes.formControl}>
        <ScheduleForm />
      </Grid>
    </div>
  )
}

export default ScheduleCreate
