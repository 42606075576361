import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Line } from 'react-chartjs-2'

export const PulseQuestionResult = (props) => {
  const { question, groupBy, timeLabels } = props

  const scoreData = {
    labels: timeLabels,
    datasets: [
      {
        label: question.title,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: question.scores,
      },
    ],
  }

  const options = {
    scales: {
      xAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            suggestedMax: question.maxScore,
            suggestedMin: question.minSocre,
            beginAtZero: true,
            stepSize: 1,
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
        },
      ],
    },
  }
  return (
    <Grid container spacing={4}>
      <Grid item xs={5}>
        <Typography variant="h4">{question.title}</Typography>
        {question.options.map((option, index) => (
          <Typography variant="subtitle2" style={{ marginLeft: 20 }}>
            {option.score} - {option.label}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={1}>
        <Typography variant="h5">This {groupBy === 'month' ? 'Month' : 'Week'}'s Average </Typography>
        <Typography variant="h3">{question.currentPeriodScore}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h5">Last {groupBy === 'month' ? 'Month' : 'Week'}'s Average </Typography>
        <Typography variant="h3">{question.lastPeriodScore}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h5">Change Since Last {groupBy === 'month' ? 'Month' : 'Week'}</Typography>
        <Typography variant="h3">{question.percentChange}%</Typography>
      </Grid>
      <Grid item xs={2}>
        <Line legend={{ display: false }} data={scoreData} width={100} height={50} options={options} />
      </Grid>
    </Grid>
  )
}
