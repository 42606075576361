import React, { useCallback } from 'react'
import { Container, Grid } from '@material-ui/core'
import EmployeeTable from './components/EmployeeTable'
import Toolbar from '../../layouts/Main/components/Toolbar/Toolbar'
import { Helmet } from 'react-helmet'
import TabView from 'layouts/Main/components/TabView/TabView'
import PeopleDrawer from './components/PeopleDrawer'
import PermissionCheck from 'utils/PermissionCheck'
import { usePeopleStyles } from 'styles'
import { useHistory } from 'react-router-dom'
import { roles } from 'utils/roles'
import ArchivedEmployeeTable from './components/ArchivedEmployeeTable'
import { useAuth0 } from 'react-auth0-spa'
import PermissionButton from 'components/Buttons/PermissionButton'
import { Hint } from 'components'
import TagTable from 'components/Tables/TagTable'
import { exportPeople } from 'api'

const People = () => {
  const classes = usePeopleStyles()
  const history = useHistory()
  const { appMetadata } = useAuth0()

  const peopleTeamsCombinedCallback = useCallback(() => {}, [])

  const tabs = [
    {
      label: 'People',
      view: <EmployeeTable peopleExternallyManaged={appMetadata.peopleExternallyManaged} />,
      roles: ['org_admin', 'team_lead'],
      route: '/people',
    },
    {
      label: 'Teams',
      view: <TagTable title="Teams" type="Team" pageSize={100} />,
      roles: ['org_admin'],
      route: '/people/teams',
    },
    {
      label: 'Departments',
      view: <TagTable title="Departments" type="Department" />,
      roles: ['org_admin'],
      route: '/people/departments',
    },
    {
      label: 'Locations',
      view: <TagTable title="Locations" type="Location" />,
      roles: ['org_admin'],
      route: '/people/locations',
    },
    {
      label: 'Job Titles',
      view: <TagTable title="Job Titles" type="JobTitle" pageSize={100} />,
      roles: ['org_admin'],
      route: '/people/jobtitles',
    },
    {
      label: 'Managers',
      view: <EmployeeTable title="Managers" peopleExternallyManaged={appMetadata.peopleExternallyManaged} managers />,
      roles: ['org_admin'],
      route: '/people/managers',
    },
    {
      label: 'Terminated People',
      view: <ArchivedEmployeeTable />,
      roles: ['org_admin'],
      route: '/people/archived',
    },
  ]

  const buttons = [
    <PermissionButton
      roles={[roles.Admin]}
      text="Export People"
      onClick={() => exportPeople()}
      variant="contained"
      disabledTooltip="Export unavailable since people are managed by Employee Naviagtor"
      disabled={appMetadata.peopleExternallyManaged}
    />,
    <PermissionButton
      roles={[roles.Admin]}
      text="Import People"
      onClick={() => history.push('/people/import')}
      variant="contained"
      disabledTooltip="Import tool unavailable since people are managed by Employee Naviagtor"
      disabled={appMetadata.peopleExternallyManaged}
    />,
    <PermissionCheck
      roles={['org_admin']}
      yes={() =>
        !appMetadata.peopleExternallyManaged && (
          <PeopleDrawer callback={peopleTeamsCombinedCallback} type="new" buttonType="button" />
        )
      }
    />,
    // <PermissionCheck roles={['org_admin']} yes={() => <InviteToJoinButton deletetype="batch" />} />,
  ]

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>People | Express Evaluations</title>
      </Helmet>
      <Toolbar buttons={buttons} title="People" />

      <Container>
        <Grid item xs={12}>
          <Hint
            severity={'info'}
            backgroundColor={'#e7f5fe'}
            text={
              'Employee Navigator integration is live! Automatically manage your organization’s employee information, teams, and job roles today.'
            }
          />
        </Grid>
        <TabView tabs={tabs} />
      </Container>
    </div>
  )
}

export default People
