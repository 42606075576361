import { makeStyles } from '@material-ui/styles'

export const useSectionResultStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(4),
  },
  subHeaderCard: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
    borderRadius: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sectionNumber: {
    fontWeight: 'lighter',
  },
  sectionPoints: {
    color: theme.palette.text.grey,
    fontSize: '14px',
    fontWeight: '400px',
  },
  sectionTitle: {
    display: 'flex',
    color: theme.palette.white,
  },
  spacer: {
    flexGrow: 1,
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  sectionHeading: {},

  sectionHeadingText: {},
  headingSpacing: {
    height: theme.spacing(2),
  },
  subItem: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))
