import React, { useCallback, useEffect } from 'react'
import { ButtonGroup, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'immutable'
import { loadArchivedTemplatesTableAction, restoreTemplateAction } from 'redux/actions/templateActions'
import TemplateTable from './TemplateTable'

const ArchivedTemplatesTable = (props) => {
  const templates = useSelector((store) => getIn(store, ['templates', 'archived']))
  const isLoading = useSelector((store) => getIn(store, ['templates', 'loading']))
  const dispatch = useDispatch()

  const actionBtn = (row) => {
    return (
      <ButtonGroup size="small" variant="text" color="primary" aria-label="text primary button group">
        <Button onClick={() => dispatch(restoreTemplateAction(row.id, row.title))}>Restore</Button>
      </ButtonGroup>
    )
  }

  const loadActiveTables = useCallback(() => dispatch(loadArchivedTemplatesTableAction()), [dispatch])
  useEffect(() => {
    loadActiveTables()
  }, [loadActiveTables])

  return (
    <TemplateTable
      title="Archived Templates"
      isLoading={isLoading}
      templates={templates}
      buttons={actionBtn}
      archived={true}
    />
  )
}

export default ArchivedTemplatesTable
