export const signUpValidations = () => {
  return {
    firstName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 32,
      },
    },
    middleName: {
      length: {
        maximum: 32,
      },
    },
    lastName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 32,
      },
    },
    email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true,
      length: {
        maximum: 64,
      },
    },
    password: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128,
      },
    },
    confirmPassword: {
      presence: { allowEmpty: false, message: 'is required' },
      equality: 'password',
    },
    policy: {
      presence: { allowEmpty: false, message: 'is required' },
      checked: true,
    },
    // recaptcha: {
    //   presence: { allowEmpty: false, message: 'is required' },
    // },
  }
}
