import React, { useState, useEffect, useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'

export default function BaseAutocompleteSearch(props) {
  const {
    fetchOptions,
    multiple,
    onChange,
    excludedItems,
    label,
    variant,
    filters,
    filterOptionsFunc,
    error,
    helperText,
    ref,
    ...otherProps
  } = props
  const [options, setOptions] = useState([])
  const [resetOptions, setResetOptions] = useState([])
  const excludedIdList = excludedItems?.map(({id}) => id)

  const getOptions = useCallback(() => {
    return fetchOptions().then((response) => {
      setOptions(response)
      setResetOptions(response)
    })
  }, [fetchOptions])

  useEffect(() => {
    getOptions()
  }, [getOptions])

  useEffect(() => {
    if (filterOptionsFunc) {
      setOptions(resetOptions.filter(filterOptionsFunc))
    }
  }, [filters, resetOptions, filterOptionsFunc])

  return (
    <Autocomplete
      {...otherProps}
      fullWidth
      multiple={multiple}
      options={options}
      getOptionDisabled={(option) =>
        excludedIdList?.includes(option.id)
      }
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} helperText={helperText} inputRef={ref} />
      )}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={(e, newValue) => {
        onChange(newValue)
      }}
    />
  )
}

BaseAutocompleteSearch.propTypes = {
  fetchOptions: PropTypes.func.isRequired,
}
