import React, { useState, useEffect } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { Grid, IconButton, TextField, Link, Typography, Button } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import loginUser from 'utils/Auth'
import { SaveCircularStatusButton } from 'components/Buttons'
import { signInValidations } from 'validations'
import { useSignInStyles } from 'styles'
import { resetPassword } from 'api'

const SignIn = (props) => {
  const { history } = props
  const classes = useSignInStyles()

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [resetPasswordComplete, setResetPasswordComplete] = useState(false)
  const [resetEmail, setResetEmail] = useState('')

  useEffect(() => {
    const errors = validate(formState.values, signInValidations())

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleBack = () => {
    history.goBack()
  }

  const handleChange = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const loginErrorCallback = (error) => {
    let errorMessage
    if (error.code === 'too_many_attempts') {
      errorMessage = `Too many login attempts: ${error.description}`
    } else if (error.code === 'access_denied') {
      errorMessage = `Access Denied: ${error.description}`
    }

    setFormState({
      ...formState,
      isValid: false,
      errors: {
        password: [errorMessage],
      },
      touched: {
        password: true,
      },
    })
  }

  const handleSignIn = (e) => {
    e.preventDefault()
    loginUser(formState.values.email, formState.values.password, loginErrorCallback)
  }

  const handlePasswordReset = async () => {
    resetPassword({ email: resetEmail }).then((response) => {
      setResetPasswordComplete(true)
    })
  }

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false)

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Welcome to Express Evaluations
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                {!showResetPassword && (
                  <>
                    <Typography className={classes.title} variant="h2">
                      Sign in
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      Sign in using your email and password
                    </Typography>
                    <TextField
                      className={classes.textField}
                      error={hasError('email')}
                      fullWidth
                      helperText={hasError('email') ? formState.errors.email[0] : null}
                      label="Email address"
                      name="email"
                      onChange={handleChange}
                      type="email"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={hasError('password')}
                      fullWidth
                      helperText={hasError('password') ? formState.errors.password[0] : null}
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.password || ''}
                      variant="outlined"
                    />
                    <SaveCircularStatusButton
                      className={classes.signInButton}
                      size="large"
                      postlabel="Signing in..."
                      prelabel="Sign In"
                      fullWidth
                      disabled={!formState.isValid}
                      savefunction={async () => {}}
                      type="submit"
                    />
                    {process.env.REACT_APP_ACTIVATE_SIGNUP === 'true' ? (
                      <Typography color="textSecondary" variant="body1">
                        Don't have an account?{' '}
                        <Link component={RouterLink} to="/signup" variant="h6">
                          Sign up
                        </Link>
                      </Typography>
                    ) : (
                      <Typography color="textSecondary" variant="body1">
                        Right now we're invite only, but general sign up is coming soon!
                      </Typography>
                    )}
                    <Typography color="textSecondary" variant="body1">
                      Trouble logging in? <Button onClick={() => setShowResetPassword(true)}>Reset Password</Button>
                    </Typography>
                  </>
                )}
                {showResetPassword && (
                  <>
                    {!resetPasswordComplete && (
                      <>
                        <Typography className={classes.title} variant="h2">
                          Reset Password
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                          Please enter the email address you used when creating your account and we’ll send you a link
                          to reset your password.
                        </Typography>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          label="Email address"
                          onChange={(e) => setResetEmail(e.target.value)}
                          type="email"
                          value={resetEmail}
                          variant="outlined"
                        />
                        <SaveCircularStatusButton
                          className={classes.signInButton}
                          size="large"
                          postlabel="Password reset sent"
                          prelabel="Submit"
                          fullWidth
                          savefunction={handlePasswordReset}
                          disabled={!resetEmail}
                          type="submit"
                        />
                        <Typography color="textSecondary" variant="body1">
                          Go back to login <Button onClick={() => setShowResetPassword(false)}>Back</Button>
                        </Typography>
                      </>
                    )}

                    {resetPasswordComplete && (
                      <>
                        <Typography className={classes.title} variant="h2">
                          Reset password sent
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                          Check your email with a link to reset your password
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SignIn.propTypes = {
  history: PropTypes.object,
}

export default withRouter(SignIn)
