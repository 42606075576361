import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { FormatDate } from 'utils/DateTime'
import { useHistory } from 'react-router-dom'
import { getTypeChip } from 'utils/evaluations'
import { getSignedEvaluationsReport } from 'api'
import { getIcon } from 'utils/GetIcon'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  evalTable: {
    width: '100%',
  },
}))

const SignedEvaluationsReport = () => {
  const [rows, setRows] = useState([])
  const classes = useStyles()
  const history = useHistory()

  const headCells = [
    { field: 'evaluationTitle', title: 'Evaluation' },
    { field: 'evaluatedPersonName', title: 'Employee' },
    { field: 'evaluatorName', title: 'Evaluator' },
    {
      field: 'evaluationType',
      title: 'Eval Type',
      render: (row) => getTypeChip(row.evaluationType),
    },

    {
      field: 'dateCompleted',
      title: 'Completed Date',
      render: (row) => {
        return FormatDate(row.dateCompleted)
      },
    },
    {
      field: 'isSigned',
      title: 'Signed',
      render: (row) => row.isSigned && getIcon('done'),
    },
  ]

  useEffect(() => {
    getSignedEvaluationsReport().then((response) => {
      setRows(response)
    })
  }, [])
  console.log('e', rows)

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h3">Signed Evaluations Report</Typography>
              <Typography variant="body1">
                Displays all completed evaluations with check mark to show if it is signed. Clicking on the row will
                take you to the evaluation results.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            className={classes.evalTable}
            columns={headCells}
            data={rows}
            onRowClick={(e, row) => history.push(`/evaluation/results/${row.evaluationId}`)}
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              exportButton: true,
              selection: false,
              showTitle: false,
              pageSize: 25,
              pageSizeOptions: [10, 25, 50, 100],
            }}
            title="Signed Evaluations Report - "
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SignedEvaluationsReport
