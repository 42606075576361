import API from '../api'

export const getPulseSurveys = async () => {
  return await API.get('pulse/surveys').then((response) => {
    return response
  })
}

export const getPulseSchedules = async () => {
  return await API.get('pulse/schedules').then((response) => {
    return response
  })
}

export const getMyPulseAssignments = async () => {
  return await API.get('pulse/my').then((response) => {
    return response
  })
}

export const createPulseSurvey = async (data) => {
  return await API.post('pulse/create', data).then((response) => {
    return response
  })
}

export const getPulseResults = async (scheduleId, groupBy) => {
  return await API.get(`pulse/results?scheduleId=${scheduleId}&groupBy=${groupBy}`).then((response) => {
    return response
  })
}
