import { Button, Menu, MenuItem } from '@material-ui/core'
import { DeleteButton } from 'components/Buttons'
import React, { useState } from 'react'
import { getIcon } from 'utils/GetIcon'
import { useDispatch } from 'react-redux'
import { deleteGoal } from 'api'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { Link as RouterLink } from 'react-router-dom'

const GoalActionsMenu = (props) => {
  const { goal, callback } = props
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [menuIcon, setMenuIcon] = useState(getIcon('arrowRight'))
  const handleClick = (event) => {
    setMenuIcon(getIcon('arrowDown'))
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setMenuIcon(getIcon('arrowRight'))
    setAnchorEl(null)
  }

  const handleDeleteGoal = (id) => {
    deleteGoal(id).then((response) => {
      if (response.status === 200) {
        dispatch(enqueueSuccessNotification('The goal was successfully deleted'))
        callback()
      } else {
        dispatch(enqueueErrorNotification('The system encountered an issue. The goal was not deleted'))
      }
    })
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} endIcon={menuIcon}>
        Actions
      </Button>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem component={RouterLink} to={`/goals/${goal.id}/edit`}>
          Edit
        </MenuItem>
        <DeleteButton
          buttonType="text"
          buttonText="Delete"
          deleteFunction={() => handleDeleteGoal(goal.id)}
          name={goal.name}
          disabled={!goal.userCanEdit}
        />
      </Menu>
    </div>
  )
}

export default GoalActionsMenu
