/* eslint-disable no-use-before-define */
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { timezones } from 'helpers/timezones'

const TimezoneSearch = (props) => {
  const { value, label, onChange, required } = props
  const handleTimezoneChange = (e, value) => {
    onChange(value)
  }

  const defaultValue = () => {
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return timezones.filter((value) => value.utc.includes(timezone))[0] || {}
  }

  return (
    <Autocomplete
      getOptionLabel={(option) => `${option.text || ''}`}
      groupBy={(option) => option.firstLetter}
      id="timezone-search"
      onChange={handleTimezoneChange}
      freeSolo
      selectOnFocus
      clearOnBlur
      options={timezones}
      renderInput={(params) => <TextField {...params} label={label || 'Timezone'} variant="outlined" />}
      style={{ width: '100%', marginTop: 20 }}
      value={typeof value === 'string' ? { jobTitle: value } : value}
      defaultValue={defaultValue()}
      required={required}
    />
  )
}

export default TimezoneSearch
