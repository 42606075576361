import React, { useEffect } from 'react'
import { createEvaluation } from 'api'
import { Typography } from '@material-ui/core'
import { useAuth0 } from 'react-auth0-spa'
import { useHistory } from 'react-router-dom'

const SelfEvaluationRedirect = () => {
  const { appMetadata } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    createEvaluation({
      templateId: 1000,
      evaluatedEmployees: [appMetadata.personId],
      peerEvaluators: [],
      isPeerEvaluation: false,
      isSelfEvaluation: true,
      results: {},
    }).then((data) => {
      history.push(`/evaluation/view/${data[0]}`)
    })
  }, [appMetadata.personId, history])
  return <Typography>Loading Self Evaluation</Typography>
}

export default SelfEvaluationRedirect
