import { getIn } from 'immutable'

const initialState = {
  activeStep: 0,
  fields: [],
  inProgress: false,
  people: [],
  sendInvites: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_ACTIVE_IMPORT_STEP':
      return Object.assign({}, state, {
        activeStep: action.step,
      })
    case 'PRE_IMPORT_PEOPLE_STARTED':
      return Object.assign({}, state, {
        inProgress: true,
        people: [],
      })
    case 'PRE_IMPORT_PEOPLE_SUCCEEDED':
      return Object.assign({}, state, {
        validPeople: action.data.validPeople,
        invalidPeople: action.data.invalidPeople,
        countInvalidPeople: action.data.countInvalidPeople,
        countValidPeople: action.data.countValidPeople,
        validTeams: action.data.validTeams,
        validJobTitles: action.data.validJobTitles,
        validLocations: action.data.validLocations,
        validDepartments: action.data.validDepartments,
        inProgress: true,
      })
    case 'INITIALIZE_IMPORT_DRAWER':
      return Object.assign({}, state, {
        activeStep: 0,
        fields: [
          'firstName',
          'middleName',
          'lastName',
          'email',
          'secondaryEmail',
          'employeeId',
          'hireDate',
          'jobTitle',
          'department',
          'managerEmail',
        ],
        inProgress: false,
      })
    case 'PRE_IMPORT_COLUMN_CHECK':
      return Object.assign({}, state, {
        fileColumns: action.columns,
      })
    case 'TOGGLE_SEND_IMPORT_INVITES':
      return Object.assign({}, state, {
        sendInvites: !getIn(state, ['sendInvites']),
      })
    case 'IMPORT_PEOPLE_STARTED':
      return Object.assign({}, state, {
        importComplete: false,
      })
    case 'IMPORT_PEOPLE_SUCCEEDED':
      return Object.assign({}, state, {
        peopleAdded: action.peopleAdded,
        peopleNotAdded: action.peopleNotAdded,
        peopleUpdated: action.peopleUpdated,
        teamsAdded: action.teamsAdded,
        importComplete: true,
      })
    default:
      return state
  }
}
