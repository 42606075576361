import { makeStyles } from '@material-ui/styles'
import { colors } from '@material-ui/core'

export const useSidebarNavStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
  feedbackDrawer: {
    padding: theme.spacing(4),
    maxWidth: '500px',
  },
  feedbackRoot: {
    padding: theme.spacing(4),
    maxWidth: '500px',
  },
  formControl: {
    minWidth: 400,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  feedbackInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
