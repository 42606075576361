import { makeStyles } from '@material-ui/styles'

export const useQuestionBuilderStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderLeftWidth: theme.spacing(1),
    borderLeftStyle: 'solid',
    marginLeft: theme.spacing(2),
  },
  rootColor: {
    borderLeftColor: theme.palette.secondary.light,
  },
  rootColorPulse: {
    borderLeftColor: theme.palette.pulse.main,
  },
  chip: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  dropdownIcon: {
    verticalAlign: 'middle',
    minWidth: '30px',
  },
  infoIcon: {
    marginTop: '32px',
    marginLeft: '8px',
  },
  questionTitle: {
    paddingBottom: '10px',
    paddingTop: '7px',
  },
  requiredToggle: {
    minWidth: '135px',
    paddingLeft: theme.spacing(2),
  },
  weightInput: {
    minWidth: '60px',
    paddingBottom: '10px',
    paddingTop: '7px',
  },
  weightTooltip: {
    marginTop: '16px',
  },
}))
