import { makeStyles } from '@material-ui/styles'

export const useSectionBuilderStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  requiredToggle: {
    minWidth: '135px',
    paddingLeft: theme.spacing(2),
  },
  sectionHeader: {
    flexGrow: 1,
  },
  sectionCard: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderTopColor: theme.palette.primary.main,
    borderTopWidth: theme.spacing(1),
    borderTopStyle: 'solid',
  },
  sectionInstructionTitle: {
    paddingLeft: theme.spacing(1),
  },
  showSectionDropdown: {
    minWidth: '180px',
  },
  showSectionWrapper: {
    padding: theme.spacing(2),
  },
  buttonColor: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  buttonColorPulse: {
    backgroundColor: theme.palette.pulse.main,
    color: theme.palette.pulse.contrastText,
  },
}))
