import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import { useDispatch } from 'react-redux'
import { loadActingAsOrg } from 'redux/actions/systemToolsActions'
import ReactGA from 'react-ga'
import { API } from 'api'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [claim, setClaim] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const [appMetadata, setAppMetadata] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        API.interceptors.request.use(
          async (config) => {
            // Do something before request is sent
            config.headers = { Authorization: `Bearer ${await auth0FromHook.getTokenSilently()}` }
            return config
          },
          function (error) {
            // Do something with request error
            return Promise.reject(error)
          },
        )
        const user = await auth0FromHook.getUser()
        setUser(user)
        const claim = await auth0FromHook.getIdTokenClaims()
        setClaim(claim)
        const claimAppMetadata = claim['https://expressevaluations.com/app_metadata']
        const roles = claim['https://expressevaluations.com/roles'] || []
        const isSysAdmin = roles.includes('system_admin') || false
        setAppMetadata(claimAppMetadata || {})
        // TODO: Fix this ugly logic
        if (claimAppMetadata.organizationId !== parseInt(process.env.REACT_APP_EE_ORG_ID) && isSysAdmin) {
          dispatch(loadActingAsOrg({ id: claimAppMetadata.organizationId, name: claimAppMetadata.organization }))
        }
        ReactGA.set({ userId: appMetadata.personId })
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        claim,
        appMetadata,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}>
      {children}
    </Auth0Context.Provider>
  )
}
