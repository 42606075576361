import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getIn } from 'immutable'
import { ModernCard } from 'components'
import { getSearchChip } from 'utils/evaluations/chips'

const ImportValidTags = () => {
  const teams = useSelector((state) => getIn(state, ['import', 'validTeams'])) || []
  const jobTitles = useSelector((state) => getIn(state, ['import', 'validJobTitles'])) || []
  const locations = useSelector((state) => getIn(state, ['import', 'validLocations'])) || []
  const departments = useSelector((state) => getIn(state, ['import', 'validDepartments'])) || []
  // const classes = useImportStyles()

  return (
    <>
      <Typography variant="h6">
        The following tags will be used in this import. If the tag does not already exist in the system, a new tag will
        be created. No tags will be deleted during the import.
      </Typography>
      <ModernCard headerTitle="Teams" disableElevation outlineHeader>
        {teams.map((team) => getSearchChip(team))}
      </ModernCard>
      <ModernCard headerTitle="Job Titles" disableElevation outlineHeader>
        {jobTitles.map((jobTitle) => getSearchChip(jobTitle))}
      </ModernCard>
      <ModernCard headerTitle="Locations" disableElevation outlineHeader>
        {locations.map((location) => getSearchChip(location))}
      </ModernCard>
      <ModernCard headerTitle="Departments" disableElevation outlineHeader>
        {departments.map((department) => getSearchChip(department))}
      </ModernCard>
    </>
  )
}

export default ImportValidTags
