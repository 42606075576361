import { Grid, Paper, Button } from '@material-ui/core'
import { createTag, deleteTag, getTags, updateTag } from 'api'
import EmployeeSearch from 'components/Search/EmployeeSearch'
import MaterialTable from 'material-table'
import React, { createRef } from 'react'
import { Link } from 'react-router-dom'
import { useCombinedEvaluationsTableStyles } from 'styles'
import { getIcon } from 'utils/GetIcon'

const TagTable = (props) => {
  const { title, type, hidePaper, pageSize, pageSizeOptions } = props

  const fetchTags = async (page, pageSize, search, orderBy, orderDirection) => {
    console.log('Page: ', page)
    return getTags(page, pageSize, search, orderBy, orderDirection, type)
  }

  const tableRef = createRef()
  const classes = useCombinedEvaluationsTableStyles()

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true,
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'relatedEntities',
      title: 'Employees',
      render: (row) => (
        <Link
          to={{
            pathname: '/people',
            search: `?tags=${row.id}`,
          }}>
          <Button size="small" endIcon={getIcon('link')} variant="text">
            {row.relatedEntities.length} employees assigned
          </Button>
        </Link>
      ),
      initialEditValue: [],
      editComponent: (row) => {
        return (
          <EmployeeSearch
            excludedTagTypes={[row.rowData.type]}
            value={row.value}
            onChange={(e) => row.onChange(e)}
            variant="standard"
            multiple
          />
        )
      },
    },
    {
      field: 'type',
      title: 'Type',
      editable: 'never',
      initialEditValue: type,
    },
  ]

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <MaterialTable
          localization={{
            body: {
              addTooltip: `Add ${type}`,
              editRow: {
                deleteText: `Are you sure you want to delete this ${type.toLowerCase()}?`,
              },
            },
          }}
          tableRef={tableRef}
          editable={{
            onRowAdd: (data) =>
              new Promise((resolve, reject) => {
                createTag({
                  name: data.name.trim(),
                  type: type,
                  entityType: 'Person',
                  relatedEntities: data.relatedEntities,
                }).then((response) => {
                  resolve()
                })
              }),
            onRowUpdate: (data) =>
              new Promise((resolve, reject) => {
                updateTag(data.id, { id: data.id, name: data.name.trim(), relatedEntities: data.relatedEntities }).then(
                  (response) => {
                    resolve()
                  },
                )
              }),
            onRowDelete: (data) =>
              new Promise((resolve, reject) => {
                deleteTag(data.id).then((response) => {
                  resolve()
                })
              }),
          }}
          components={{ Container: (props) => <Paper {...props} elevation={hidePaper ? 0 : 1} /> }}
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              fetchTags(
                query.page,
                query.pageSize,
                query.search,
                (query.orderBy && query.orderBy.field) || '',
                query.orderDirection || '',
              ).then((response) => {
                if (response.status === 200) {
                  resolve({
                    data: response.data.data,
                    page: query.page,
                    totalCount: response.data.totalCount,
                  })
                } else {
                  reject()
                }
              })
            })
          }
          options={{
            emptyRowsWhenPaging: false,
            selection: false,
            pageSize: pageSize || 25,
            pageSizeOptions: pageSizeOptions,
            padding: 'dense',
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '600px',
            debounceInterval: 600,
            actionsColumnIndex: -1,
          }}
          title={title}
        />
      </Grid>
    </Grid>
  )
}

export default TagTable

TagTable.defaultProps = {
  title: 'Tags',
}
