import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Helmet } from 'react-helmet'
import TabView from 'layouts/Main/components/TabView/TabView'
import { EmployeeDashboard } from './components'
import { roles } from 'utils/roles'

const useStyles = makeStyles((theme) => ({
  root: { paddingLeft: theme.spacing(8), paddingRight: theme.spacing(8) },
  greeting: {
    minWidth: '200px',
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end',
  },
  header: {
    padding: theme.spacing(3),
  },
  spacer: {
    flexGrow: 1,
  },
}))

const tabs = [
  {
    label: 'My Dashboard',
    view: <EmployeeDashboard />,
    roles: [roles.Member],
    route: '/dashboard',
  },
]

const Dashboard = () => {
  const classes = useStyles()
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Overview | Express Evaluations</title>
        <meta
          content="View important information regarding your upcoming evaluations, team members, etc."
          name="description"
        />
      </Helmet>
      <div className={classes.root}>
        <TabView tabs={tabs} />
      </div>
    </>
  )
}

export default Dashboard
