import React, { useState, useEffect, useCallback } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Container, Fade, Paper, Grid } from '@material-ui/core'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import EvaluationNav from './components/EvaluationNav'
import Section from './components/Section'
import { useEvaluationStyles } from 'styles'
import { useDispatch, useSelector } from 'react-redux'
import { loadEvaluationPage, saveEvaluation } from 'redux/actions/evaluationActions'
import { getIn } from 'immutable'
import ThankYou from './components/Section/ThankYou'
import EvaluationGoalReview from './components/EvaluationGoalReview'
import EvaluationGoalCreation from './components/EvaluationGoalCreation'
import moment from 'moment'
import { updateBottomDescription } from 'redux/actions/focusViewActions'
import { FormatDateIgnoreTz } from 'utils/DateTime'

export default function Evaluation() {
  const AUTOSAVE_TIME = 300000
  const classes = useEvaluationStyles()
  const dispatch = useDispatch()
  const evaluation = useSelector((state) => state.evaluation)
  const activeStep = getIn(evaluation, ['currentSectionIndex'])
  const isPreview = getIn(evaluation, ['isPreview'])
  const [evaluationResults, setEvaluationResults] = useState([])
  const { evaluationId, token } = useParams()
  const isLoading = useSelector((state) => getIn(state, ['evaluation', 'isLoading']))
  const isComplete = useSelector((state) => getIn(state, ['evaluation', 'isComplete']))
  const isPulse = useSelector((state) => getIn(state, ['evaluation', 'isPulse']))
  let location = useLocation()
  const history = useHistory()
  function parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )

    return JSON.parse(jsonPayload)
  }

  useEffect(() => {
    if (evaluation.autoSaveEnabled) {
      const interval = setInterval(() => {
        dispatch(saveEvaluation())
        dispatch(updateBottomDescription(`Autosaved at: ${FormatDateIgnoreTz(moment.now(), true)}`))
      }, AUTOSAVE_TIME)
      return () => clearInterval(interval)
    }
  }, [AUTOSAVE_TIME, evaluation.autoSaveEnabled, dispatch])

  const loadEval = useCallback(() => {
    if (evaluationId) {
      dispatch(
        loadEvaluationPage(
          evaluationId,
          location.pathname.includes('/evaluation/template/preview/') ||
            location.pathname.includes('/pulse/template/preview/'),
          history,
        ),
      )
    } else {
      const parsedToken = parseJwt(token)
      dispatch(
        loadEvaluationPage(
          parsedToken.survey_id,
          location.pathname.includes('/evaluation/template/preview/'),
          history,
          token,
        ),
      )
    }
  }, [dispatch, evaluationId, location.pathname, history, token])

  useEffect(() => {
    loadEval()
  }, [loadEval])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const updateResults = (questionId, sectionId, type, response, score, maxScore, comments) => {
    const newResult = {
      evaluationId: evaluation.id,
      templateversionId: evaluation.templateId,
      sectionId: sectionId,
      questionId: questionId,
      type: type,
      response: response,
      maxScore: maxScore,
      score: score,
      scoringEnabled: evaluation.template.scoringEnabled,
      comments: comments,
    }
    setEvaluationResults({
      ...evaluationResults,
      [questionId]: newResult,
    })
  }

  return (
    <Container className={classes.root} direction="column">
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{isPulse ? 'Pulse Survey' : 'Evaluation'} | Express Evaluations</title>
        </Helmet>
        <CssBaseline />
        {isLoading || (
          <Fade in={!isLoading}>
            <Grid className={classes.builderRoot} container spacing={2}>
              {!isComplete && !isPulse && (
                <Grid item xs={12} md={3} lg={3} className={classes.gridItem}>
                  <Paper className={classes.gridItemPaper}>
                    <EvaluationNav />
                  </Paper>
                </Grid>
              )}
              {!isComplete && isPulse && <Grid xs={0} md={2} lg={2} />}
              {evaluation && evaluation.isComplete ? (
                <Grid item xs={12} md={12} lg={12} className={classes.gridItem}>
                  <Paper className={classes.gridItemPaper}>
                    <ThankYou isPulse={isPulse} loadAsComplete={getIn(evaluation, ['loadAsCompleted'])} />
                  </Paper>
                </Grid>
              ) : (
                <Grid item xs={12} md={isPulse ? 8 : 9} lg={isPulse ? 8 : 9} className={classes.gridItem}>
                  {!evaluation.reviewingGoals && !evaluation.creatingGoals && (
                    <Section
                      activeStep={activeStep}
                      isPreview={isPreview}
                      isSelf={evaluation.type === 'self'}
                      numberOfSections={getIn(evaluation, ['evaluation', 'numberOfSections'])}
                      section={getIn(evaluation, ['currentSection'])}
                      title={getIn(evaluation, ['title'])}
                      type={evaluation.type}
                      updateResults={updateResults}
                    />
                  )}

                  {!isPulse && evaluation.reviewingGoals && <EvaluationGoalReview />}
                  {!isPulse && evaluation.creatingGoals && <EvaluationGoalCreation />}
                </Grid>
              )}
            </Grid>
          </Fade>
        )}
      </>
    </Container>
  )
}
