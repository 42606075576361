export const FormatDate = (date, includeTime = false) => {
  const utcDate = new Date(new Date(date).toLocaleString() + ' UTC')
  const localDate = utcDate.toLocaleDateString()
  const localTime = utcDate.toLocaleTimeString()

  return includeTime ? `${localDate} ${localTime}` : localDate
}

export const FormatDateIgnoreTz = (date, includeTime = false) => {
  const formatDate = new Date(date)
  const localDate = formatDate.toLocaleDateString()
  const localTime = formatDate.toLocaleTimeString()

  return includeTime ? `${localDate} ${localTime}` : localDate
}

export const addDays = (date, days) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const addMonths = (date, months) => {
  var result = new Date(date)
  result.setMonth(result.getMonth() + months)
  return result
}
