import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuth0 } from 'react-auth0-spa'
import { Check } from 'utils/PermissionCheck'
import { useHistory } from 'react-router-dom'

const PrivateRouteWithLayout = ({ layout: Layout, component: Component, path, roles, disableRoute, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, claim } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  if (disableRoute || !Check(roles, claim, loading)) {
    history.push('/not-allowed')
  }

  const render = (matchProps) => {
    if (isAuthenticated === true) {
      return (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )
    } else {
      return null
    }
  }

  return <Route {...rest} path={path} render={render} />
}

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
}

export default PrivateRouteWithLayout
