import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import OmniSearch from 'components/Search/OmniSearch'
import { removeItem } from 'helpers/arrays'
import React from 'react'
import { useController } from 'react-hook-form'
import { getSearchChip } from 'utils/evaluations/chips'
import { getIcon } from 'utils/GetIcon'

const PeopleSelectionTool = ({ control, defaultChecked, excludedItems, customListTitle }) => {
  const {
    field: { onChange: peopleOnChange, value: people },
    fieldState: { error: peopleError },
  } = useController({ name: 'reviewees', control, defaultValue: [] })
  const {
    field: { onChange: tagsOnChange, value: tags },
    fieldState: { error: tagsError },
  } = useController({ name: 'tags', control, defaultValue: [] })
  const handleClear = () => {
    peopleOnChange([])
    tagsOnChange([])
  }
  let excludedIdList = excludedItems?.map(({id}) => id)

  const hanldeOmniSelection = (options) => {
    let uniquePeople = []
    if (options.length === 0) {
      peopleOnChange([])
      tagsOnChange([])
    } else {
      options.forEach((option) => {
        option.people.forEach((person) => {
          if (!uniquePeople.some((p) => p.id === person.id) && !excludedIdList?.includes(person.id) ){
            uniquePeople.push({ ...person, option: { ...option, people: null } })
          }
        })

        uniquePeople = uniquePeople.sort((a, b) => {
          if (a.fullName < b.fullName) {
            return -1
          }
          if (a.fullName > b.fullName) {
            return 1
          }
          return 0
        })
        peopleOnChange(uniquePeople)
        tagsOnChange(options)
      })
    }
  }

  const handleDismissTag = (tag) => {
    hanldeOmniSelection(tags.filter((option) => tag.id !== option.id))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Select which types to include in search </Typography>
          </Grid>
          <Grid item xs={12}>
            <OmniSearch
              value={tags || []}
              onChange={hanldeOmniSelection}
              excludedItems={excludedItems}
              variant="outlined"
              multiple
              defaultChecked={defaultChecked}
              error={peopleError || tagsError}
              helperText={(peopleError && peopleError.message) || (tagsError && tagsError.message)}
              handleDismissTag={handleDismissTag}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            title={`${customListTitle ? customListTitle : "Selected People"} (${people.length})`}
            action={
              <Button
                style={{ borderColor: red[400], color: red[400] }}
                size="small"
                variant="outlined"
                onClick={handleClear}
                endIcon={getIcon('clear')}>
                Clear All
              </Button>
            }
          /> 
          <CardContent>
            <List dense style={{ maxHeight: 300, overflowY: 'scroll' }}>
              {people.length === 0 && (
                <ListItem>
                  <Typography variant="subtitle2">Select groups or inviduals to add to schedule</Typography>
                </ListItem>
              )}
              {people &&
                people.map((person, index) => (
                  <ListItem button id={`selected_list_item_${index}`}>
                    <ListItemIcon>{getIcon('person')}</ListItemIcon>
                    <ListItemText 
                      primary={<Typography variant="h6">{index + 1}. {person.fullName}</Typography>} 
                      secondary={
                        person.option && person.option.type !== 'Person' ?
                        getSearchChip(person.option, () => {}, false, 'small') :
                        <Chip size="small" variant="outlined" label="Manual Selection"/>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => peopleOnChange([...removeItem(people, person)])} edge="end">
                        {getIcon('clear')}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PeopleSelectionTool
