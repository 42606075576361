import { makeStyles } from '@material-ui/styles'

export const usePeopleStyles = makeStyles((theme) => ({
  root: {},
  content: {
    marginTop: theme.spacing(2),
  },
  tips: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
}))
