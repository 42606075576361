import React, { useCallback, useState } from 'react'
import { getAutoCompleteOmni } from 'api'
import PropTypes from 'prop-types'
import BaseAutocompleteSearch from './BaseAutocompleteSearch'
import { getSearchChip } from 'utils/evaluations/chips'
import { Checkbox, FormControlLabel } from '@material-ui/core'
export default function OmniSearch(props) {
  const STANDARD_TAG_FILTERS = ['Person', 'Team', 'Department', 'Location', 'JobTitle', 'Organization']
  const { onChange, value, label, variant, includeFilters, excludedItems, customListTitle, handleDismissTag, defaultChecked=STANDARD_TAG_FILTERS, ...rest } = props
  const getOptionsFunc = useCallback(async () => getAutoCompleteOmni(), [])
  const [tagFilters, setTagFilters] = useState(defaultChecked)

  const handleFilterChange = (event) => {
    const { checked, name } = event.target
    if (checked) {
      setTagFilters([...tagFilters, name])
    } else {
      setTagFilters(tagFilters.filter((tag) => tag !== name))
    }
  }

  return (
    <div>
      {STANDARD_TAG_FILTERS.map((tag) => (
        <FormControlLabel
          id={`id_for_tag_${tag}`}
          control={<Checkbox name={tag} onChange={handleFilterChange} defaultChecked={defaultChecked.includes(tag)} />}
          label={getSearchChip({ type: tag, name: tag, dismissalbe: true })}
          key={`key_for_tag_${tag}`}
        />
      ))}
      <BaseAutocompleteSearch
        {...rest}
        fetchOptions={getOptionsFunc}
        filterSelectedOptions
        renderOption={(option) => getSearchChip(option)}
        getOptionLabel={(option) => `${option.name || ''}`}
        renderTags={(tags, getTagProps) => {
          return tags.map((option, index) =>
            getSearchChip(option, () => handleDismissTag(option), true, 'medium', getTagProps),
          )
        }}
        groupBy={(option) => option.firstLetter}
        label={label || 'Search'}
        value={value}
        onChange={onChange}
        excludedItems={excludedItems}
        variant={variant}
        filterOptionsFunc={(option) => tagFilters.includes(option.type)}
        filters={tagFilters}
      />
    </div>
  )
}

OmniSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
}
