import React, { useState } from 'react'
import { useModernCardStyles } from 'styles'
import { Paper, Typography } from '@material-ui/core'

const ModernHeaderCard = (props) => {
  const classes = useModernCardStyles()
  const [showDetails, setShowDetails] = useState(true)
  const { children, title, rightSubtitle, leftSubtitle, collapsable } = props

  return (
    <Paper className={classes.headerPaper}>
      <div
        className={`${classes.paperHeaderWhite} ${!children && classes.childlessSectionHeading}`}
        onClick={() => collapsable && setShowDetails(!showDetails)}>
        {leftSubtitle ||
          (rightSubtitle && (
            <div className={classes.subtitleBox}>
              <div className={classes.alignLeft}>{leftSubtitle}</div>
              <div className={classes.alignRight}>{rightSubtitle}</div>
            </div>
          ))}
        <div>
          <Typography variant="h2" color="inherit" className={classes.sectionHeadingText}>
            {title}
          </Typography>
        </div>
      </div>
      {children && <div className={classes.paperContent}>{children}</div>}
    </Paper>
  )
}

export default ModernHeaderCard
