import { makeStyles } from '@material-ui/styles'

export const usePeopleDrawerStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    maxWidth: '500px',
  },
  fullList: {
    width: 'auto,',
    height: '250px',
  },
  title: {
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
}))
