import API from '../api'

export const getTags = async (page = 0, pageSize = 0, searchQuery = '', sortBy = '', sortDirection = '', type = '') => {
  return await API.get(
    `tags?type=${type}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`,
  )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const getTagsTree = async (type) => {
  return await API.get(`tags/tree/${type}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const createTag = async (request) => {
  return await API.post(`tags/create`, request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const updateTag = async (id, request) => {
  return await API.put(`tags/${id}/update`, request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export const deleteTag = async (id) => {
  return await API.delete(`tags/${id}/delete`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}
