import React, { useState, useEffect, useCallback } from 'react'
import { getPulseResults } from 'api'
import { Grid, Paper, Typography, Divider, Select, MenuItem, Button } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { Line, Doughnut, Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { PulseQuestionResult } from './PulseQuestionResult'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch } from 'react-redux'
import { initializeFocusView } from 'redux/actions/focusViewActions'
import { Alert, AlertTitle } from '@material-ui/lab'
import { getIcon } from 'utils/GetIcon'
import { useHistory } from 'react-router-dom'
import { productOfferingTypes } from 'data/constants'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  formControl: {
    width: '100%',
  },
  scheduleTable: {
    width: '100%',
  },
  resultCard: {
    padding: theme.spacing(4),
  },
  question: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterInput: {
    minWidth: 100,
  },
  filterDivider: {
    marginTop: 10,
    marginBottom: 10,
  },
  centerGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  graphGrid: {
    height: '100%',
  },
  participationRatePaper: {
    height: 200,
  },
}))

const PulseResults = () => {
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [notEnoughResults, setNotEnoughResults] = useState(false)
  const [groupBy, setGroupBy] = useState('month')
  const classes = useStyles()
  const { scheduleId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const fetchSurveys = useCallback(() => {
    setIsLoading(true)
    getPulseResults(scheduleId, groupBy || 'month').then((response) => {
      if (response.status === 200) {
        setResults(response.data)
        setIsLoading(false)
        dispatch(
          initializeFocusView(
            'Pulse Result',
            '/pulse/surveys',
            '/pulse/surveys',
            null,
            null,
            productOfferingTypes.engagement,
          ),
        )
      } else if (response.status === 204) {
        setIsLoading(false)
        setNotEnoughResults(true)
      }
    })
  }, [groupBy, scheduleId, dispatch])

  useEffect(() => {
    fetchSurveys()
  }, [fetchSurveys])

  const data = {
    labels: ['Surveys Completed', 'Surveys Outstanding'],
    datasets: [
      {
        data: [results.overallCompleted || 0, results.overallNotCompleted || 0],
        backgroundColor: ['#17BD7C', '#36A2EB'],
        hoverBackgroundColor: ['#AD343E', '#AD343E'],
      },
    ],
    text: '34%',
  }

  const scoreData = {
    labels: results.timeLabels,
    datasets: [
      {
        label: 'Scores',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: results.scores,
      },
    ],
  }

  const participationRateData = {
    labels: results.timeLabels,
    datasets: [
      {
        label: 'Completed Surveys',
        data: results.groupedCompleted,
        backgroundColor: '#17BD7C',
      },
      {
        label: 'Incomplete Surveys',
        data: results.groupedNotCompleted,
        backgroundColor: '#aaaaaa',
      },
    ],
  }

  const participationRateOptions = {
    scales: {
      xAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin: results.minOverallAverageValue,
            suggestedMax: results.maxOverallAverageValue,
            beginAtZero: true,
            stepSize: 1,
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
        },
      ],
    },
  }

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.resultCard}>
          {!isLoading && notEnoughResults && (
            <Alert
              severity="info"
              action={
                <Button onClick={() => history.push('/pulse')} startIcon={getIcon('back')}>
                  Back to Pulse Schedules
                </Button>
              }>
              <AlertTitle>Not enough surveys completed</AlertTitle>
              This schedule does not have enough completed surveys. Pulse surveys must be completed by more than 5
              participants to protect anonymity. If you have a use case for Pulse surveys for less than 5 people, please
              contact support.
            </Alert>
          )}
          {!notEnoughResults && (
            <>
              <Typography variant="h1">
                <GraphicEqIcon />
                {isLoading && !notEnoughResults && 'Loading...'}
                {!isLoading && !notEnoughResults && results.title}
              </Typography>
              <Divider className={classes.filterDivider} />
              <Grid container spacing={1}>
                <Grid item className={classes.filterGrid}>
                  <Typography variant="h5">View By:</Typography>
                </Grid>
                <Grid item className={classes.filterGrid}>
                  <Select value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
                    <MenuItem value={'month'}>Monthly</MenuItem>
                    <MenuItem value={'week'}>Weekly</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
      {isLoading && <CircularProgress color="inherit" />}
      {!isLoading && !notEnoughResults && (
        <>
          <Grid item xs={4} className={classes.graphGrid}>
            <Paper className={classes.resultCard}>
              <Typography variant="h2">Overall Participation {results.participationRate || 0}%</Typography>
              <Doughnut data={data} height={150} with={800} />
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper className={classes.resultCard}>
              <Typography variant="h2">Participation Over Time</Typography>
              <Bar data={participationRateData} height={180} width={800} />
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.graphGrid}>
            <Paper className={classes.resultCard}>
              <Typography variant="h2">Scores Over Time</Typography>
              <Line data={scoreData} width={800} height={100} options={participationRateOptions} />
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.resultCard}>
            <Paper className={classes.resultCard}>
              <Typography variant="h2">Questions</Typography>
              <Divider className={classes.question} />
              {results.questions &&
                results.questions.map((value, index) => (
                  <PulseQuestionResult
                    question={value}
                    index={index}
                    timeLabels={results.timeLabels || []}
                    className={classes.question}
                    groupBy={groupBy}
                  />
                ))}
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default PulseResults
