import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Container } from '@material-ui/core'
import ProfileSummary from './components/profileSummary'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import TabView from 'layouts/Main/components/TabView/TabView'
import KnowYourTalentsSummary from './components/KnowYourTalentsSummary'
import { useAuth0 } from 'react-auth0-spa'
import EmployeeTable from 'views/People/components/EmployeeTable'
import { getEvaluations, getGoals } from 'api'
import CombinedEvaluationsTable from 'components/Tables'
import GoalTable from 'views/Goals/components/GoalTable'
import { Check } from 'utils/PermissionCheck'
import { roles } from 'utils/roles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))

const Profile = () => {
  const classes = useStyles()
  const { personId } = useParams()
  const { appMetadata } = useAuth0()
  const history = useHistory()

  if (parseInt(personId) !== appMetadata.personId && !Check([roles.Admin, roles.TeamLead])) {
    history.push('/not-allowed')
  }

  const tabs = [
    {
      label: 'Evaluations',
      view: (
        <CombinedEvaluationsTable
          disableRowClick
          fetchData={(params) => getEvaluations({...params, includedRevieweeIds:[personId]})}
          pageSize={10}
          hideFilters
          pageSizeOptions={[10, 20, 30]}
          hideDetailPanel={parseInt(personId) === appMetadata.personId}
        />
      ),
      roles: ['team_member', 'team_lead', 'org_admin'],
      route: `/profile/${personId}/evaluations`,
    },
  ]

  if (appMetadata.accessKyt) {
    tabs.push({
      label: 'Know Your Talents™',
      view: <KnowYourTalentsSummary personId={personId} />,
      roles: ['team_member', 'team_lead', 'org_admin'],
      route: `/profile/${personId}/kyt`,
    })
  }

  if (appMetadata.accessGoals) {
    tabs.push({
      label: 'Goals',
      view: (
        <GoalTable
          fetchData={async (isComplete = false, limit = 1000) => getGoals(personId, limit, isComplete)}
          type="personal"
        />
      ),
      roles: ['team_member', 'team_lead', 'org_admin'],
      route: `/profile/${personId}/goals`,
    })
  }

  tabs.push({
    label: 'Direct Reports',
    view: <EmployeeTable type={'direct'} personId={personId} title="Driect Reports" />,
    roles: ['team_lead', 'org_admin'],
    route: `/profile/${personId}/reports/direct`,
  })
  tabs.push({
    label: 'Extended Reports',
    view: <EmployeeTable type={'extended'} personId={personId} title="Extended Reports" />,
    roles: ['team_lead', 'org_admin'],
    route: `/profile/${personId}/reports/extended`,
  })

  return (
    <Container className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Profile | Express Evaluations</title>
      </Helmet>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ProfileSummary personId={personId} />
        </Grid>
        <TabView tabs={tabs} />
      </Grid>
    </Container>
  )
}

Profile.propTypes = {
  personId: PropTypes.number,
}
export default Profile
