import React from 'react'
import { Button, List, ListItem, ListItemIcon, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ScoreInput } from '../../../Shared/ScoreInput'
import { getMultiChoiceIcon } from 'helpers/templateBuilderHelper'

export const MultiChoice = (props) => {
  const { questionType, options, setOptions, questionIndex, scoringEnabled, allowMultiple, disabled } = props
  const handleOptionChange = (index, e) => {
    let newOptions = [...options]
    newOptions[index] = { ...newOptions[index], label: e.target.value }
    setOptions(newOptions)
  }
  const addChoice = () => {
    setOptions([
      ...options,
      {
        label: '',
        isSelected: false,
        score: 0,
        shouldAutoFocus: true,
      },
    ])
  }

  const removeChoice = (removeIndex) => {
    setOptions(options.filter((v, index) => index !== removeIndex))
  }

  const handleScoreChange = (index, e) => {
    let newOptions = [...options]
    newOptions[index] = { ...newOptions[index], score: Number(e.target.value) }
    setOptions(newOptions)
  }

  return (
    <List>
      {options.map((option, index) => (
        <ListItem key={`option-${index}-${questionIndex}`}>
          <ListItemIcon style={{ minWidth: '30px' }}>{getMultiChoiceIcon(questionType, allowMultiple)}</ListItemIcon>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label={`Option ${index + 1}`}
            margin="dense"
            onChange={(e) => handleOptionChange(index, e)}
            placeholder={'Untitled Option'}
            value={option.label}
            variant="outlined"
            disabled={disabled}
            autoFocus={option.shouldAutoFocus}
          />
          {scoringEnabled && (
            <ScoreInput
              handleScoreChange={handleScoreChange}
              index={index}
              tooltip={
                allowMultiple
                  ? "Because 'Allow Multiple' is selected in this questions options,each selected value will be added together for a total score for this question. The higher the score, the more positive the response."
                  : 'If this response is selected, this point value will be assigned to the response for quick analysis of answers. The higher the score, the more positive the response.'
              }
              value={option.score}
              disabled={disabled}
            />
          )}
          {options.length > 1 && (
            <Button onClick={() => removeChoice(index)} disabled={disabled}>
              <CloseIcon />
            </Button>
          )}
        </ListItem>
      ))}
      <ListItem>
        <ListItemIcon style={{ minWidth: '30px' }}>{getMultiChoiceIcon(questionType, allowMultiple)}</ListItemIcon>
        <Button onClick={addChoice} variant="outlined" disabled={disabled}>
          Add an option
        </Button>
      </ListItem>
    </List>
  )
}

export default MultiChoice
