import MuiButton from './MuiButton'
import MuiIconButton from './MuiIconButton'
import MuiPaper from './MuiPaper'
import MuiTableCell from './MuiTableCell'
import MuiTableHead from './MuiTableHead'
import MuiTypography from './MuiTypography'
import MuiOutlinedInput from './MuiOutlinedInput'
import MuiInputLabel from './MuiInputLabel'
import MuiListItemIcon from './MuiListItemIcon'
import MuiTab from './MuiTab'

export default {
  MuiTab,
  MuiInputLabel,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
}
