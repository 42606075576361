import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography, CircularProgress, Button } from '@material-ui/core'
import RichTextBlock from 'components/RichText'
import { useEvaluationGoalReviewStyles } from 'styles/Evaluation/evaluationGoalReview'
import GoalDetailsCard from 'components/Goals/GoalDetailsCard'
import { getIn } from 'immutable'
import { ModernCard } from 'components/Cards'
import GoalForm from 'views/Goals/GoalForm'
import { useDispatch, useSelector } from 'react-redux'
import { startEvaluationGoalCreation } from 'redux/actions/goalFormActions'
import { getGoals } from 'api'
import { getIcon } from 'utils/GetIcon'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const EvaluationGoalCreation = () => {
  const classes = useEvaluationGoalReviewStyles()
  const [goals, setGoals] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const instructions = useSelector((state) => getIn(state, ['evaluation', 'goalCreationInstructions']))
  const dispatch = useDispatch()
  const evaluationId = useSelector((state) => getIn(state, ['evaluation', 'evaluation', 'batchId']))
  const evaluatedPersonId = useSelector((state) => getIn(state, ['evaluation', 'evaluation', 'evaluatedPersonId']))
  const fetchGoals = useCallback(() => {
    console.log('fetching goals...')
    getGoals(evaluatedPersonId, 50, false, evaluationId).then((response) => {
      if (response.status === 200) {
        console.log('Goals fetched!')
        setIsLoading(false)
        setGoals(response.data)
      }
    })
    dispatch(
      startEvaluationGoalCreation(evaluationId, {
        id: evaluatedPersonId,
      }),
    )
  }, [evaluationId, evaluatedPersonId, dispatch])

  useEffect(() => {
    fetchGoals()
  }, [fetchGoals])

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <ModernCard headerTitle="Create Goals">
          <Typography color="inherit" variant="h3">
            Instructions
          </Typography>
          <RichTextBlock content={instructions} />
          <AnchorLink href={`#create_goal_form`} offset="100">
            <Button color="secondary" variant="outlined" startIcon={getIcon('add')}>
              Create Goal
            </Button>
          </AnchorLink>
        </ModernCard>
      </Grid>

      {isLoading && <CircularProgress />}
      {goals.map((value, index) => (
        <Grid item xs={12} key={`evaluation_create_goal_card_${index}`}>
          <GoalDetailsCard
            goalId={value.id}
            personId={value.people.id}
            name={value.name}
            description={value.description}
            dueDate={value.dueDate}
            isComplete={value.isComplete}
            goalNumber={index + 1}
            comments={value.comments}
            createdBy={value.createdBy}
            disableComplete
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <ModernCard headerTitle="New Goal">
          <GoalForm callback={fetchGoals} inEvaluation />
        </ModernCard>
      </Grid>
    </Grid>
  )
}

export default EvaluationGoalCreation
