import React from 'react'
import { FormControlLabel, RadioGroup, Radio, Typography, Select, MenuItem, TextField } from '@material-ui/core'
import { QuestionComments } from './QuestionComments'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { updateEvaluationResults } from 'redux/actions/evaluationActions'
import { useEvaluationQuestionStyle } from 'styles'
import { getIn } from 'immutable'
import RichTextBlock from 'components/RichText'

const Question = (props) => {
  const { id } = props
  const classes = useEvaluationQuestionStyle()
  const dispatch = useDispatch()

  const { question, result, isPulse } = useSelector(
    (state) => ({
      question: getIn(state, ['evaluation', 'currentSection', 'questions', props.index]),
      result: getIn(state, ['evaluation', 'results', [id]]),
      isPulse: getIn(state, ['evaluation', 'isPulse']),
    }),
    shallowEqual,
  )

  const handleQuestionChange = (e) => {
    dispatch(
      updateEvaluationResults(
        getIn(question, ['id']),
        getIn(question, ['sectionId']),
        getIn(question, ['type']),
        e.target.value,
        getIn(question, ['type']) !== 'Text'
          ? getIn(question, ['config', 'options']).find((value) => value.label === e.target.value).score *
              getIn(question, ['weight'])
          : 0,
        getIn(question, ['config', 'maxScore']) * getIn(question, ['weight']),
      ),
    )
  }
  return (
    <>
      <div className={classes.formControl}>
        <Typography variant="h4">
          {getIn(question, ['title'])} {getIn(question, ['isRequired']) ? '*' : ''}
        </Typography>
        <RichTextBlock content={getIn(question, ['helpText'])} />
        {getIn(question, ['type']) === 'Dropdown' ? (
          <Select
            className={classes.dropdown}
            value={getIn(result, ['response']) || ''}
            onChange={handleQuestionChange}>
            {getIn(question, ['config', 'options']).map((value, index) => (
              <MenuItem key={`Dropdown-${index}`} value={value.label}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
        ) : null}
        {getIn(question, ['type']) === 'MultiChoice' ? (
          <>
            {getIn(question, ['config', 'options']) && (
              <RadioGroup
                name="question-answers"
                onChange={handleQuestionChange}
                className={classes.formGroup}
                value={getIn(result, ['response']) || ''}>
                {getIn(question, ['config', 'options']).map((value, index) => (
                  <FormControlLabel
                    key={`MultiChoiceOption-${index}`}
                    value={value.label}
                    score={value.score}
                    control={<Radio />}
                    label={value.label}
                  />
                ))}
              </RadioGroup>
            )}
          </>
        ) : null}
        {getIn(question, ['type']) === 'Text' ? (
          <TextField
            id="outlined-basic"
            className={classes.textField}
            value={getIn(result, ['response']) || ''}
            onChange={handleQuestionChange}
            fullWidth
            placeholder="Please enter your answer here"
            multiline
            variant="outlined"
          />
        ) : null}
      </div>
      {getIn(question, ['type']) !== 'Text' && !isPulse && <QuestionComments questionId={id} />}
    </>
  )
}

export default Question
