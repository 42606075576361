import API from '../api'

export const getAutoCompletePeople = async (request = { excludedIds: [], includedIds: [] }) => {
  return await API.post('search/autocomplete/people', request).then((response) => {
    return response.data
  })
}

export const getAutoCompleteTemplates = async (data) => {
  return await API.post('search/autocomplete/templates', data).then((response) => {
    return response.data
  })
}

export const getAutoCompleteJobTitles = async () => {
  return await API.post('search/autocomplete/people/jobTitles').then((response) => {
    return response.data
  })
}

export const getAutoCompleteTeams = async () => {
  return await API.post('search/autocomplete/people/teams').then((response) => {
    return response.data
  })
}

export const getAutoCompletePersonSchedules = async (request) => {
  return await API.post('search/autocomplete/schedules/occurrences', request).then((response) => {
    return response.data
  })
}

export const getAutoCompleteTags = async (request) => {
  return await API.post('search/autocomplete/tags', request).then((response) => {
    return response.data
  })
}

export const getAutoCompleteOmni = async () => {
  return await API.get('search/autocomplete/omni').then((response) => {
    return response.data
  })
}
