import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Toolbar, Typography, Button, FormControlLabel, Checkbox, FormGroup, CircularProgress } from '@material-ui/core'
import OrgSearch from 'components/Search/OrgSearch'
import BuildIcon from '@material-ui/icons/Build'
import { useSelector, useDispatch } from 'react-redux'
import { getIn } from 'immutable'
import { startActingAsOrg, endActingAsOrg } from 'redux/actions/systemToolsActions'
import { useAuth0 } from 'react-auth0-spa'
import { st_updateOrgFeatures } from 'api'
import { enqueueSuccessNotification } from 'redux/actions/notificationsActions'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background: theme.palette.info.main,
  },
  actAsRoot: {
    boxShadow: 'none',
    background: theme.palette.warning.main,
    maxHeight: 40,
    minHeight: 32,
  },
  logoAndTitle: {
    display: 'flex',
  },
  logo: {
    height: '30px',
  },
  title: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.info.contrastText,
    fontSize: '16px',
    lineHeight: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
  accountOptionsButton: {
    marginLeft: theme.spacing(1),
  },
  systemToolsButton: {
    marginLeft: theme.spacing(2),
    minWidth: 100,
  },
  endSessionButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    height: 20,
    fontSize: 12,
  },
  actAsName: {
    fontSize: 14,
  },
  featureCheckbox: {
    color: 'white',
  },
  featureGroup: {
    marginRight: theme.spacing(1),
    borderRadius: '15px',
    padding: theme.spacing(1),
  },
}))

export const SystemToolsMenu = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getTokenSilently } = useAuth0()
  const org = useSelector((state) => getIn(state, ['systemTools', 'useAsOrg']))
  const actingAsOrg = useSelector((state) => getIn(state, ['systemTools', 'currentlyActingAsOrg']))
  const [goalsEnabled, setGoalsEnabled] = React.useState(false)
  const [pulseEnabled, setPulseEnabled] = React.useState(false)
  const [peerEnabled, setPeerEnabled] = React.useState(false)
  const [isUpdatingFeatures, setIsUpdatingFeatures] = React.useState(false)

  const handleActAsOrg = () => {
    actingAsOrg
      ? dispatch(endActingAsOrg(async () => await getTokenSilently({ ignoreCache: true })))
      : dispatch(startActingAsOrg(async () => await getTokenSilently({ ignoreCache: true })))
  }

  const handleUpdateFeatures = () => {
    setIsUpdatingFeatures(true)
    st_updateOrgFeatures({
      organizationId: org.id,
      goalsEnabled: goalsEnabled,
      pulseEnabled: pulseEnabled,
      peerEnabled: peerEnabled,
    }).then((response) => {
      setIsUpdatingFeatures(false)
      if (response.status === 200) {
        dispatch(
          enqueueSuccessNotification('Update user job submitted. Updates make take a few minutes to take effect.'),
        )
      }
    })
  }

  useEffect(() => {
    if (org) {
      setGoalsEnabled(org.goalsEnabled)
      setPulseEnabled(org.pulseEnabled)
      setPeerEnabled(org.peerEnabled)
    }
  }, [org])

  return (
    <Toolbar className={actingAsOrg ? classes.actAsRoot : classes.root}>
      <div className={classes.logoAndTitle}>
        <BuildIcon className={classes.title} />
        <Typography variant="h2" className={classes.title}>
          System Tools
        </Typography>
      </div>
      <div className={classes.flexGrow} />
      {actingAsOrg && (
        <FormGroup aria-label="position" row className={classes.featureGroup}>
          <FormControlLabel
            className={classes.featureCheckbox}
            control={<Checkbox style={{ color: 'white' }} />}
            label={<Typography style={{ color: 'white' }}>Goals</Typography>}
            labelPlacement="end"
            disabled={!org}
            onChange={(e) => setGoalsEnabled(e.target.checked)}
            checked={goalsEnabled}
          />
          <FormControlLabel
            className={classes.featureCheckbox}
            control={<Checkbox style={{ color: 'white' }} />}
            label={<Typography style={{ color: 'white' }}>Pulse</Typography>}
            labelPlacement="end"
            disabled={!org}
            defaultChecked={org && org.pulseEnabled}
            onChange={(e) => setPulseEnabled(e.target.checked)}
            checked={pulseEnabled}
          />
          <FormControlLabel
            className={classes.featureCheckbox}
            control={<Checkbox style={{ color: 'white' }} />}
            label={<Typography style={{ color: 'white' }}>Peer</Typography>}
            labelPlacement="end"
            disabled={!org}
            onChange={(e) => setPeerEnabled(e.target.checked)}
            checked={peerEnabled}
          />
          {isUpdatingFeatures ? (
            <CircularProgress />
          ) : (
            <Button disabled={!org} size="small" variant="contained" onClick={handleUpdateFeatures} color="secondary">
              Update Features
            </Button>
          )}
        </FormGroup>
      )}

      {actingAsOrg ? (
        <Typography className={classes.actAsName} variant="h3">
          Acting As: {org.name}
        </Typography>
      ) : (
        <OrgSearch />
      )}
      <Button
        disabled={!getIn(org, ['name'])}
        onClick={() => handleActAsOrg()}
        className={actingAsOrg ? classes.endSessionButton : classes.systemToolsButton}
        variant="contained"
        size="small">
        {actingAsOrg ? 'End Session' : getIn(org, ['name']) ? `Act As ${getIn(org, ['name'])}` : 'Choose an Org'}
      </Button>
    </Toolbar>
  )
}
