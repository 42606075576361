import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuestionComments } from 'redux/actions/evaluationActions'
import { getIn } from 'immutable'
import { useQuestionCommentsStyle } from 'styles'

export const QuestionComments = (props) => {
  const { questionId } = props
  const [characterCount, setCharacterCount] = useState()
  const CHARACTER_LIMIT = 3000
  const classes = useQuestionCommentsStyle()
  const dispatch = useDispatch()
  const comments = useSelector((state) => getIn(state, ['evaluation', 'results', [questionId], 'comments']))

  useEffect(() => {
    setCharacterCount((comments && comments.length) || 0)
  }, [comments])

  return (
    <TextField
      className={classes.commentsBlock}
      fullWidth
      inputProps={{
        maxLength: CHARACTER_LIMIT,
      }}
      label="Comments"
      multiline
      name="comments"
      onChange={(e) => dispatch(updateQuestionComments(questionId, e.target.value))}
      value={comments || ''}
      variant="outlined"
      helperText={`${characterCount}/${CHARACTER_LIMIT}`}
    />
  )
}
