import React, { useState, useEffect, useCallback } from 'react'
import { Container } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { Box, Typography, Fade, Paper, Button, Grid } from '@material-ui/core'
import { getSelfEvaluationResults, getCombinedEvaluationResults, getSignatureSettings, askForSignature } from 'api'
import { useParams, useLocation } from 'react-router-dom'
import SectionResult from './components/SectionResult'
import { FormatDate } from 'utils/DateTime'
import { useEvaluationResultStyles } from 'styles'
import { useHistory } from 'react-router-dom'
import { Alert, AlertTitle } from '@material-ui/lab'
import { ModernCard, SignatureCard, ModernHeaderCard } from 'components/Cards'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import EvaluationResultsNav from './components/EvaluationResultsNav'
import { getSingleEvaluationPdf, getCombinedEvaluationPdf } from 'api'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import StickyBox from 'react-sticky-box'
import { useDispatch } from 'react-redux'
import { enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { initializeFocusView } from 'redux/actions/focusViewActions'
import SignaturesModal from 'components/Modals/SignaturesModal'
import { getIcon } from 'utils/GetIcon'
import RichTextBlock from 'components/RichText/RichTextBlock'
import GoalDetailsCard from 'components/Goals/GoalDetailsCard'

const EvaluationResults = () => {
  const classes = useEvaluationResultStyles()
  const { id } = useParams()
  const [evaluation, setEvaluation] = useState({ template: { sections: [] } })
  const [sections, setSections] = useState([])
  const [loading, setLoading] = useState(true)
  const [badRequest, setBadRequest] = useState(false)
  const [signed, setSigned] = useState(false)
  const [signatureModalOpen, setSignatureModalOpen] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [displayControls, setDisplayControls] = useState({
    scoring: true,
    leadSections: false,
    comments: true,
    signatures: true,
    excludedSections: [],
    excludedResponseTypes: [],
  })
  const [featuresEnabled, setFeaturesEnabled] = useState({
    scoring: true,
    leadSections: false,
    comments: true,
    signatures: true,
    excludedSections: true,
    excludedResponseTypes: true,
  })

  const handleEvaluationDownload = () => {
    if (location.pathname.includes('/evaluation/results/combined/')) {
      getCombinedEvaluationPdf({
        evaluationBatchId: id,
        signatures: displayControls.signatures,
        scoring: displayControls.scoring,
        comments: displayControls.comments,
        leadSections: displayControls.leadSections,
        excludedSections: displayControls.excludedSections,
        excludedResponseTypes: displayControls.excludedResponseTypes,
      })
    } else if (location.pathname.includes('/evaluation/results/')) {
      getSingleEvaluationPdf({
        evaluationId: id,
        signatures: displayControls.signatures,
        scoring: displayControls.scoring,
        comments: displayControls.comments,
        leadSections: displayControls.leadSections,
        excludedSections: displayControls.excludedSections,
        excludedResponseTypes: displayControls.excludedResponseTypes,
      })
    }
  }
  const processResultResponse = useCallback(
    (response) => {
      if (response.status === 200) {
        setEvaluation(response.data)
        if (response.data.template.scoringEnabled) {
          setDisplayControls((dc) => ({ ...dc, scoring: true }))
          setFeaturesEnabled((fe) => ({ ...fe, scoring: true }))
        } else {
          setDisplayControls((dc) => ({ ...dc, scoring: false }))
          setFeaturesEnabled((fe) => ({ ...fe, scoring: false }))
        }
        if (response.data.type === 'Combined Results') {
          setDisplayControls((dc) => ({ ...dc, leadSections: true }))
          setFeaturesEnabled((fe) => ({ ...fe, leadSections: true }))
        } else {
          setDisplayControls((dc) => ({ ...dc, leadSections: false }))
          setFeaturesEnabled((fe) => ({ ...fe, leadSections: false }))
        }
        setSections(response.data.template.sections)
      } else if (response.status === 403) {
        history.push('/not-allowed')
      } else if (response.status === 400) {
        setBadRequest(true)
      }
    },
    [history],
  )

  const resetFilters = () => {
    setDisplayControls({
      scoring: featuresEnabled.scoring ? true : false,
      leadSections: false,
      comments: true,
      signatures: featuresEnabled.signatures ? true : false,
      excludedSections: [],
      excludedResponseTypes: [],
    })
    setSections(evaluation.template.sections)
  }

  const handleSectionFilter = (value) => {
    const index = displayControls.excludedSections.indexOf(value)
    let tempSections = [...evaluation.template.sections]

    if (index !== -1) {
      const excludedSections = [...displayControls.excludedSections]
      excludedSections.splice(index, 1)
      tempSections = tempSections.filter((section) => !excludedSections.includes(section.id))
      setDisplayControls((dc) => ({ ...dc, excludedSections: excludedSections }))
      setSections(tempSections)
    } else {
      const excludedSections = [...displayControls.excludedSections, value]
      tempSections = tempSections.filter((section) => !excludedSections.includes(section.id))
      setDisplayControls((dc) => ({ ...dc, excludedSections: [...displayControls.excludedSections, value] }))
      setSections(tempSections)
    }
  }

  const handleResponseTypeFilter = (value) => {
    const index = displayControls.excludedResponseTypes.indexOf(value)

    if (index !== -1) {
      const excludedResponseTypes = [...displayControls.excludedResponseTypes]
      excludedResponseTypes.splice(index, 1)
      setDisplayControls((dc) => ({ ...dc, excludedResponseTypes: excludedResponseTypes }))
    } else {
      setDisplayControls((dc) => ({ ...dc, excludedResponseTypes: [...displayControls.excludedResponseTypes, value] }))
    }
  }

  const fetchEvaluation = useCallback(() => {
    let askForSignature
    getCombinedEvaluationResults(id)
      .then((response) => {
        processResultResponse(response)
        askForSignature = response.data.askForSignature
      })
      .then(() => {
        setLoading(false)
        getSignatureSettings().then((response) => {
          if (response.data.isEnabled) {
            setDisplayControls((dc) => ({ ...dc, signatures: true }))
            setFeaturesEnabled((fe) => ({ ...fe, signatures: askForSignature }))
          }
        })
      })
  }, [id, processResultResponse])

  useEffect(() => {
    if (location.pathname.includes('/evaluation/results/combined/')) {
      fetchEvaluation()
    } else if (location.pathname.includes('/evaluation/results/')) {
      getSelfEvaluationResults(id)
        .then((response) => {
          processResultResponse(response)
        })
        .then(() => {
          setLoading(false)
        })
      getSignatureSettings().then((response) => {
        if (response.data.isEnabled) {
          setDisplayControls((dc) => ({ ...dc, signatures: false }))
          setFeaturesEnabled((fe) => ({ ...fe, signatures: false }))
        }
      })
    }
  }, [id, location.pathname, processResultResponse, fetchEvaluation])

  const actions = []
  if (evaluation.askForSignature && featuresEnabled.signatures && !signed) {
    actions.push(
      <AnchorLink href={"#signature_box"} className={classes.actionButton}>
        <Button fullWidth variant="contained" color="secondary" startIcon={<BorderColorIcon />}>
          Click to Sign
        </Button>
      </AnchorLink>,
    )
  }

  if (evaluation.status === 'waitingForReview') {
    actions.push(
      <Button
        key="review-btn"
        variant="contained"
        color="secondary"
        className={classes.actionButton}
        startIcon={getIcon('review')}
        onClick={() => {
          askForSignature({
            batchId: evaluation.batchId,
            reportFilters: {
              evaluationBatchId: id,
              signatures: displayControls.signatures,
              scoring: displayControls.scoring,
              comments: displayControls.comments,
              leadSections: displayControls.leadSections,
              excludedSections: displayControls.excludedSections,
              excludedResponseTypes: displayControls.excludedResponseTypes,
            },
          }).then((response) => {
            if (response.status === 200) {
              if (evaluation.scheduleRequiresSignatures) {
                dispatch(
                  enqueueSuccessNotification(
                    'Evaluation status has been updated. Email notifications have been sent to ask for signatures.',
                  ),
                )
              } else {
                dispatch(
                  enqueueSuccessNotification(
                    'Evaluations have been completed. You can view completed evaluations in your past evaluations.',
                  ),
                )
              }

              fetchEvaluation()
            }
          })
        }}>
        {evaluation.scheduleRequiresSignatures
          ? 'Mark as Reviewed and Ask for Signature'
          : 'Mark as Reviewed and Completed'}
      </Button>,
    )
    actions.push(
      <Button
        key={'request-signatures-btn'}
        className={classes.actionButton}
        variant="contained"
        color="secondary"
        startIcon={getIcon('signature')}
        onClick={() => setSignatureModalOpen(true)}>
        Request Additional Signatures
      </Button>,
    )
  }

  actions.push(
    <Button
      key="download-btn"
      className={classes.actionButton}
      variant="outlined"
      color="primary"
      startIcon={<SaveAltIcon />}
      onClick={handleEvaluationDownload}>
      Download PDF
    </Button>,
  )
  const toggleDisplayControl = (event) => {
    setDisplayControls({ ...displayControls, [event.target.name]: event.target.checked })
    const filteredSections = [
      ...evaluation.template.sections.filter((section) => !displayControls.excludedSections.includes(section.id)),
    ]
    setSections(filteredSections)
  }
  dispatch(
    initializeFocusView(
      (evaluation.evaluatedPersonName && `${evaluation.evaluatedPersonName} - ${evaluation.title}`) ||
        'Loading results...',
      '/evaluations/all',
      '/evaluations/all',
      ' ',
      actions,
    ),
  )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Results | Express Evaluations</title>
      </Helmet>
      <div className={classes.root}>
        {!loading && badRequest && (
          <Box>
            <Alert severity="warning">
              <AlertTitle>Evaluation Results Invalid Id</AlertTitle>
              The evaluation Id you provided was not valid. If you feel that you've reached this page in error, please{' '}
              <a href="mailto:support@expressevaluations.com">contact support</a>.
            </Alert>
          </Box>
        )}
        {loading || (
          <Fade in={!loading && !badRequest}>
            <Container>
              <Grid container spacing={2}>
                {evaluation.status === 'waitingForReview' && (
                  <Grid item xs={12}>
                    <StickyBox offsetTop={64} style={{ zIndex: '1000' }}>
                      <Alert
                        icon={false}
                        variant="filled"
                        style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
                        action={
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              askForSignature({
                                batchId: evaluation.batchId,
                                reportFilters: {
                                  evaluationBatchId: id,
                                  signatures: displayControls.signatures,
                                  scoring: displayControls.scoring,
                                  comments: displayControls.comments,
                                  leadSections: displayControls.leadSections,
                                  excludedSections: displayControls.excludedSections,
                                  excludedResponseTypes: displayControls.excludedResponseTypes,
                                },
                              }).then((response) => {
                                if (response.status === 200) {
                                  if (evaluation.scheduleRequiresSignatures) {
                                    dispatch(
                                      enqueueSuccessNotification(
                                        'Evaluation status has been updated. Email notifications have been sent to ask for signatures.',
                                      ),
                                    )
                                  } else {
                                    dispatch(
                                      enqueueSuccessNotification(
                                        'Evaluations have been completed. You can view completed evaluations in your past evaluations.',
                                      ),
                                    )
                                  }

                                  fetchEvaluation()
                                }
                              })
                            }}>
                            {evaluation.scheduleRequiresSignatures
                              ? 'Mark as Reviewed and Ask for Signature'
                              : 'Mark as Reviewed and Completed'}
                          </Button>
                        }
                        severity="warning"
                        className={classes.actionBanner}>
                        <AlertTitle>Reviewing Evaluation for {evaluation.evaluatedPersonName}</AlertTitle>
                      </Alert>
                    </StickyBox>
                  </Grid>
                )}
                {evaluation.askForSignature && featuresEnabled.signatures && !signed && (
                  <Grid item xs={12}>
                    <StickyBox offsetTop={64} style={{ zIndex: '1000' }}>
                      <Alert
                        variant="filled"
                        icon={false}
                        action={
                          <AnchorLink href={"#signature_box"} >
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              className={classes.signatureButton}
                              startIcon={<BorderColorIcon />}>
                              Click to Sign
                            </Button>
                          </AnchorLink>
                        }
                        severity="warning"
                        className={classes.actionBanner}>
                        <AlertTitle>Please sign your evaluation</AlertTitle>
                        After reviewing your evaluation, please click and sign the evaluation form.
                      </Alert>
                    </StickyBox>
                  </Grid>
                )}
                {!evaluation.isEvaluatedEmployee && !evaluation.askForSignature && (
                  <Grid item xs={12}>
                    <EvaluationResultsNav
                      displayControls={displayControls}
                      updateDisplayControls={toggleDisplayControl}
                      featuresEnabled={featuresEnabled}
                      sectionList={evaluation.template.sections}
                      handleSectionFilter={handleSectionFilter}
                      handleResponseTypeFilter={handleResponseTypeFilter}
                      resetFilters={resetFilters}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <div className={classes.evaluationResult}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Paper className={classes.headingCard}>
                          <Typography className={`${classes.evaluationTitle} ${classes.resultsSubTitle} `} variant="h1">
                          {evaluation.title}
                          </Typography>
                          <Typography className={`${classes.evaluationTitle} ${classes.resultsTitle} `} variant="h1">
                            {evaluation.evaluatedPersonName}
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <ModernCard headerTitle="Evaluation Information">
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Typography className={classes.evaluationTitle} variant="h5">
                                Completed On:{FormatDate(evaluation.completeDate)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.evaluationTitle} variant="h5">
                                Employee: {evaluation.evaluatedPersonName}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.evaluationTitle} variant="h5">
                                Team: {evaluation.evaluatedPersonTeam}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.evaluationTitle} variant="h5">
                                Manager: {evaluation.evaluatedPersonManagerName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ModernCard>
                      </Grid>
                      {displayControls.scoring && (
                        <Grid item xs={6}>
                          <ModernCard headerTitle="Scoring">
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography variant="h5">Employee Score:</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant="h5">{evaluation.score}</Typography>
                              </Grid>
                              {(evaluation.managerScore || evaluation.peerScore) && (
                                <>
                                  {evaluation.managerScore && (
                                    <>
                                      <Grid item xs={3}>
                                        <Typography variant="h5">Manager Score:</Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Typography variant="h5">{evaluation.managerScore}</Typography>
                                      </Grid>
                                    </>
                                  )}
                                  {evaluation.peerScore && (
                                    <>
                                      <Grid item xs={3}>
                                        <Typography variant="h5">Peer Score:</Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Typography variant="h5">{evaluation.peerScore}</Typography>
                                      </Grid>
                                    </>
                                  )}
                                </>
                              )}
                            </Grid>
                          </ModernCard>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <ModernHeaderCard
                          title="Feedback"
                          leftSubtitle={
                            <Typography variant="caption">
                              {evaluation.sectionCount} SECTION, {evaluation.questionCount} QUESTIONS
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {sections.map((section, index) => (
                          <SectionResult
                            key={`section_${index}_${section.id}`}
                            section={section}
                            scoringEnabled={displayControls.scoring}
                            commentsEnabled={displayControls.comments}
                            index={index}
                            displayControls={displayControls}
                          />
                        ))}
                      </Grid>
                      {(evaluation.includeGoalReview || evaluation.includeGoalCreation) && (
                        <Grid item xs={12}>
                          <ModernHeaderCard
                            title="Goals"
                            leftSubtitle={
                              <Typography variant="caption">
                                {evaluation.reviewedGoals?.length || 0} REVIEWED /{' '}
                                {evaluation.createdGoals?.length || 0} CREATED
                              </Typography>
                            }
                          />
                        </Grid>
                      )}
                      {evaluation.includeGoalReview && (
                        <>
                          <Grid item xs={12} className={classes.subTopItem}>
                            <ModernCard headerTitle="Goals Reviewed" collapsable>
                              <RichTextBlock content={evaluation.goalReviewInstructions} />
                              {evaluation.reviewedGoals &&
                                evaluation.reviewedGoals.map((goal, index) => (
                                  <Grid item xs={12} className={classes.subItem}>
                                    <GoalDetailsCard
                                      className={classes.goalCard}
                                      name={goal.name}
                                      description={goal.description}
                                      goalNumber={index + 1}
                                      goalId={goal.id}
                                      disableElevation
                                    />
                                  </Grid>
                                ))}
                            </ModernCard>
                          </Grid>
                        </>
                      )}
                      {evaluation.includeGoalCreation && (
                        <>
                          <Grid item xs={12} className={classes.subTopItem}>
                            <ModernCard headerTitle="Goals Created" collapsable>
                              <RichTextBlock content={evaluation.goalCreationInstructions} />
                              {evaluation.createdGoals &&
                                evaluation.createdGoals.map((goal, index) => (
                                  <Grid item xs={12} className={classes.subItem}>
                                    <GoalDetailsCard
                                      className={classes.goalCard}
                                      name={goal.name}
                                      description={goal.description}
                                      goalNumber={index + 1}
                                      goalId={goal.id}
                                      disableElevation
                                    />
                                  </Grid>
                                ))}
                            </ModernCard>
                          </Grid>
                        </>
                      )}
                      {displayControls.signatures && evaluation.status !== 'waitingForReview' && (
                        <>
                          <Grid item xs={12}>
                            <ModernHeaderCard title="Signatures" />
                          </Grid>
                          <Grid item xs={12} className={classes.subItem}>
                            <Grid container spacing={2}>
                              {evaluation &&
                                evaluation.signatures &&
                                evaluation.signatures.map((value, index) => (
                                  <Grid item xs={6} key={`results_signature_card_${index}`}>
                                    <SignatureCard
                                      name={value.name}
                                      signedBy={value.signedBy && value.signedBy.fullName}
                                      date={value.dateSigned}
                                      isSigned={true}
                                      evaluationId={evaluation.id}
                                      evaluationBatchId={evaluation.batchId}
                                    />
                                  </Grid>
                                ))}
                              <Grid item xs={6}>
                                {evaluation && evaluation.askForSignature && featuresEnabled.signatures && (
                                  <SignatureCard
                                    isSigned={signed}
                                    setSigned={setSigned}
                                    evaluationId={evaluation.id}
                                    evaluationBatchId={evaluation.batchId}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <SignaturesModal
                setOpen={setSignatureModalOpen}
                open={signatureModalOpen}
                evaluationBatchId={evaluation.batchId}
                evaluationId={evaluation.id}
              />
            </Container>
          </Fade>
        )}
      </div>
    </>
  )
}

export default EvaluationResults
