import React from 'react'
import PropTypes from 'prop-types'
import ButtonWithPrompt from './ButtonWithPrompt'

export const DeleteButton = (props) => {
  const { buttonText, description, buttonType, name, deleteFunction } = props

  return (
    <ButtonWithPrompt
      buttonText={buttonText}
      description={description}
      title={`Are you sure you want to ${buttonText ? buttonText.toLowerCase() : 'delete'} ${name}?`}
      variant={buttonType}
      clickFunction={deleteFunction}
      positiveButtonLabel={buttonText}
      className={props.className}
    />
  )
}

DeleteButton.propTypes = {
  deleteFunction: PropTypes.func,
  name: PropTypes.string,
  savefunction: PropTypes.func,
  description: PropTypes.string,
}

DeleteButton.defaultProps = {
  buttonText: 'Delete',
}
