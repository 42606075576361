import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, IconButton, TextField, Typography, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { SaveCircularStatusButton } from 'components/Buttons'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}))

const PasswordReset = (props) => {
  const { history } = props

  const classes = useStyles()

  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState({
    pLength: false,
    characters: false,
    special: false,
  })
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState({})

  useEffect(() => {
    setError({})
    const lengthRegEx = new RegExp('^(?=.{8,})')
    const charRegEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])')
    const specialRegEx = new RegExp('^(?=.*[!@#$%^&*])')

    setValidPassword({
      pLength: lengthRegEx.test(password),
      characters: charRegEx.test(password),
      special: specialRegEx.test(password),
    })
  }, [password])

  useEffect(() => {
    setError({})
    if (password !== confirmPassword) {
      setError({ confirmPassword: 'Your passwords do not match' })
    }
  }, [confirmPassword, password])

  const handleBack = () => {
    history.goBack()
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Welcome to Express Evaluations
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={() => {}}>
                <Typography className={classes.title} variant="h2">
                  Password Reset
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  Before continuing, please enter a new password to update your current temporary password
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  error={error.password}
                  helperText={error.password}
                  label="Password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  variant="outlined"
                />

                <TextField
                  className={classes.textField}
                  fullWidth
                  error={error.confirmPassword}
                  helperText={error.confirmPassword}
                  label="Confirm Password"
                  name="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  variant="outlined"
                />
                <List dense={true}>
                  <ListItem>
                    <ListItemIcon>
                      {validPassword.pLength ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                    </ListItemIcon>
                    <ListItemText primary="Longer than 8 characters" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {validPassword.characters ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                    </ListItemIcon>
                    <ListItemText primary="Contains a uppercase and lowercase character and a number" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {validPassword.special ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                    </ListItemIcon>
                    <ListItemText primary="Contains one special character (i.e. !@#$%^&*)" />
                  </ListItem>
                </List>
                <SaveCircularStatusButton
                  className={classes.signInButton}
                  size="large"
                  postLabel="Resetting Password"
                  preLabel="Reset Password"
                  fullWidth
                  disabled={
                    error.password || error.confirmPassword || password.length < 0 || confirmPassword.length < 0
                  }
                  onClick={async () => {}}
                  type="submit"
                />
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

PasswordReset.propTypes = {
  history: PropTypes.object,
}

export default withRouter(PasswordReset)
