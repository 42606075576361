import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Fade } from '@material-ui/core'
import KnowYourTalentsActiveTable from 'views/KnowYourTalents/KnowYourTalentsActiveTable'
import { getPersonKytSurveys } from 'api'

const KnowYourTalentsSummary = (props) => {
  const { personId } = props
  const [isLoading, setIsLoading] = useState(true)
  const [surveys, setSurveys] = useState([])
  const callback = useCallback(() => {
    if (personId) {
      getPersonKytSurveys(personId).then((response) => {
        setSurveys(response)
        setIsLoading(false)
      })
    }
  }, [personId])

  useEffect(() => {
    callback()
  }, [callback])

  return (
    <Fade in={true}>
      <KnowYourTalentsActiveTable callback={callback} isLoading={isLoading} surveys={surveys} source="profile" />
    </Fade>
  )
}

KnowYourTalentsSummary.propTypes = {
  person: PropTypes.object,
  type: PropTypes.string,
}

export default KnowYourTalentsSummary
