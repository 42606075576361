import React, { useState } from 'react'
import { Typography, Chip } from '@material-ui/core'
import RichTextBlock from 'components/RichText/RichTextBlock'
import Comments from 'views/Goals/components/Comments'
import { ModernCard } from 'components/Cards'
import { getGoalStatus } from 'utils/evaluations/chips'
import moment from 'moment'
import { ButtonWithPrompt } from 'components/Buttons'
import { enqueueErrorNotification, enqueueSuccessNotification } from 'redux/actions/notificationsActions'
import { useDispatch } from 'react-redux'
import { completeGoal } from 'api'
const calculateGoalStatus = (isComplete, dueDate) => {
  if (!isComplete && moment(dueDate).isBefore(moment.now())) {
    return getGoalStatus('inComplete')
  } else if (isComplete) {
    return getGoalStatus('completed')
  } else {
    return getGoalStatus('inProgress')
  }
}
const GoalDetailsCard = (props) => {
  const [showComments, setShowComments] = useState(false)
  const dispatch = useDispatch()

  const {
    name,
    dueDate,
    description,
    isComplete,
    goalNumber,
    goalId,
    personId,
    disableComments,
    disableElevation,
    createdBy,
    disableComplete,
    callback,
  } = props

  const handleCompleteGoal = () => {
    completeGoal(goalId).then((response) => {
      if (response.status === 200) {
        callback(goalId)
        dispatch(enqueueSuccessNotification('Your goals has been marked as complete.'))
      } else if (response.status === 403) {
      } else {
        dispatch(enqueueErrorNotification('The server encountered an error. Unable to mark goal as complete'))
      }
    })
  }

  return (
    <ModernCard headerTitle={`Goal ${goalNumber}`} disableElevation={disableElevation} outlineHeader>
      <Typography variant="h3">{name}</Typography>
      <Typography variant="subtitle2">
        {isComplete
          ? `Completed on ${moment(dueDate).format('MM/DD/yyyy')}`
          : `Due on ${moment(dueDate).format('MM/DD/yyyy')}`}
        {createdBy && ` | Created By: ${createdBy.fullName}`}
      </Typography>
      {calculateGoalStatus(isComplete, dueDate)}
      <RichTextBlock content={description} />

      {!disableComments && (
        <>
          <Chip
            variant={showComments ? 'default' : 'outlined'}
            color="secondary"
            label={showComments ? 'Hide Comments' : 'View/Add Comment'}
            onClick={() => setShowComments(!showComments)}
          />
          {showComments && <Comments goalId={goalId} employeeId={personId} />}
        </>
      )}
      {!disableComplete && !isComplete && (
        <span style={{ marginLeft: disableComments ? 0 : 10 }}>
          <ButtonWithPrompt
            key="complete-goal-btn"
            title="Are you sure you want to mark this goal as complete?"
            clickFunction={handleCompleteGoal}
            buttonText="Mark As Complete"
            positiveButtonLabel="Mark As Complete"
          />
        </span>
      )}
    </ModernCard>
  )
}

export default GoalDetailsCard
