import { makeStyles } from '@material-ui/styles'

export const useImportStyles = makeStyles((theme) => ({
         root: {},
         import: {
           padding: theme.spacing(4),
           paddingBottom: theme.spacing(0),
           marginTop: theme.spacing(3),
         },
         stepperRoot: {
           width: '100%',
           paddingTop: '0px',
           paddingBottom: '0px',
         },
         section: {
           marginTop: theme.spacing(2),
           marginBottom: theme.spacing(2),
         },
         textIcon: {
           display: 'flex',
           alignItems: 'center',
         },
         content: {
           marginTop: theme.spacing(2),
           marginBottom: theme.spacing(2),
         },
         downloadCsvBtn: {
           marginTop: theme.spacing(4),
           marginBottom: theme.spacing(2),
           width: '100%',
         },
         missingChip: {
           backgroundColor: theme.palette.error.main,
           color: theme.palette.error.contrastText,
         },
         warningChip: {
           backgroundColor: theme.palette.warning.main,
           color: theme.palette.warning.contrastText,
         },
         doneAvatar: {
           backgroundColor: theme.palette.success.main,
         },
         fieldList: {
           listStyle: 'none',
         },
         columnIcon: {
           marginRight: theme.spacing(1),
         },
         confirmColumnIcon: {
           color: theme.palette.success.main,
           marginRight: theme.spacing(1),
         },
         errorColumnIcon: {
           color: theme.palette.error.main,
           marginRight: theme.spacing(1),
         },
         title: {
           marginBottom: theme.spacing(2),
         },
         stepperButtons: {
           marginTop: theme.spacing(2),
         },
         button: {
           marginRight: theme.spacing(1),
         },
         instructions: {
           marginTop: theme.spacing(1),
           marginBottom: theme.spacing(1),
         },
         options: {
           marginTop: theme.spacing(4),
         },
         importCard: {
           marginTop: theme.spacing(2),
         },
       }))
