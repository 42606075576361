import React, { useState, useEffect, useCallback } from 'react'
import { getMyPulseAssignments } from 'api'
import { useAuth0 } from 'react-auth0-spa'
import { getGoals, getEvaluationsActions } from 'api'
import { Divider, Grid, Fade, Typography, CircularProgress } from '@material-ui/core'
import MyEvaluationsTable from '../../../Evaluations/components/MyEvaluationsTable'
import { useEmployeeDashboardStyles } from 'styles'
import { ActionCard } from '../Shared'
import { FormatDate, FormatDateIgnoreTz } from 'utils/DateTime'
import { useHistory } from 'react-router-dom'
import { productOfferingTypes } from 'data/constants'

const EmployeeDashboard = () => {
  const { appMetadata } = useAuth0()
  const [pulseSurveys, setPulseSurveys] = useState([])
  const [signatureRequests, setSignatureRequests] = useState([])
  const [isLoadingSignatures, setIsLoadingSignatures] = useState(true)
  const [isLoadingPulse, setIsLoadingPulse] = useState(true)
  const [isLoadingGoals, setIsLoadingGoals] = useState(true)
  const [goals, setGoals] = useState([])
  const classes = useEmployeeDashboardStyles()
  const history = useHistory()

  const fetchGoals = useCallback(() => {
    getGoals(appMetadata.personId).then((response) => {
      if (response.status === 200) {
        setGoals(response.data)
      }
      setIsLoadingGoals(false)
    })
  }, [appMetadata.personId])

  const fetchPulseAssignments = () => {
    getMyPulseAssignments().then((response) => {
      if (response.status === 200) {
        setPulseSurveys(response.data)
      }
      setIsLoadingPulse(false)
    })
  }

  const fetchSignatureRequests = () => {
    getEvaluationsActions().then((response) => {
      if (response.status === 200) {
        setSignatureRequests(response.data)
      }
      setIsLoadingSignatures(false)
    })
  }

  const handleEvaluationActionClick = (url) => {
    history.push(url)
  }

  useEffect(() => {
    fetchGoals()
    fetchPulseAssignments()
    fetchSignatureRequests()
  }, [fetchGoals])

  return (
    <Grid container spacing={4} style={{ marginTop: 10 }}>
      <Grid item lg={12} xl={6} spacing={4}>
        <Typography variant="h3" style={{ marginLeft: '8px', marginBottom: '8px' }}>
          My Evaluations and Feedback Requests
        </Typography>
        <Divider className={classes.evaluationDivider} />
        <MyEvaluationsTable />
      </Grid>
      <Grid container spacing={4} lg={12} xl={6} style={{marginLeft: '4px'}}>
      <Grid item xs={12} lg={6} xl={12}>
        <Typography variant="h3" style={{ marginLeft: '8px', marginTop: '16px'}}>Review & Signature Requests</Typography>
        <Divider className={classes.evaluationDivider} />
        {isLoadingSignatures &&
          <CircularProgress />
        }
        <Fade in={!isLoadingSignatures}>
          <Grid container spacing={2}>
            {signatureRequests &&
              signatureRequests.map((action, index) => (
                <Grid item key={`action_card_grid_${index}`}>
                  <ActionCard
                    headerText={
                      action.title
                    }
                    headerSubText={action.type === 'Review' ? 'Review requested for' : 'Signature requested for'}
                    buttonText={action.type === 'Review' ? 'Review Evaluation' : 'Sign Evaluation'}
                    onClick={() => handleEvaluationActionClick(action.url)}
                    productOfferingType={productOfferingTypes.performance}
                  />
                </Grid>
              ))}
            {signatureRequests.length === 0 && (
              <Grid item xs={12}>
                <Typography className={classes.noPulseText} variant="subtitle2">
                  No signature or review requests currently available
                </Typography>
              </Grid>
            )}
          </Grid>
        </Fade>
      </Grid>
      {appMetadata.accessPulse && (
        <Grid item xs={12} lg={6} xl={12}>
          <Typography variant="h3" style={{ marginTop: '16px', marginLeft: '8px'}}>Pulse Surveys</Typography>
          <Divider className={classes.pulseDivider} />
          {isLoadingPulse &&
            <CircularProgress />
          }
          <Fade in={!isLoadingPulse}>
            <Grid container spacing={2}>
              {pulseSurveys.map((pulseEval, index) => (
                <Grid item key={`pulse_action_card_grid_${index}`}>
                  <ActionCard
                    headerText={pulseEval.title}
                    headerSubText={`Available until ${FormatDate(pulseEval.dueDate)}`}
                    buttonText="Take survey"
                    productOfferingType={productOfferingTypes.engagement}
                    onClick={() => history.push(`/evaluation/view/${pulseEval.id}`)}
                  />
                </Grid>
              ))}
              {pulseSurveys.length === 0 && (
                <Grid item xs={12}>
                  <Typography className={classes.noPulseText} variant="subtitle2">
                    No Pulse Surveys currently available
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Fade>
        </Grid>
      )}
      {appMetadata.accessGoals && (
        <Grid item xs={12} lg={6} xl={12}>
          <Typography variant="h3" style={{ marginTop: '16px', marginLeft: '8px'}}>Goals</Typography>
          <Divider className={classes.goalsDivider} />
          {isLoadingGoals && 
            <CircularProgress />
          }
          <Fade in={!isLoadingGoals}>
            <Grid container spacing={2}>
              {goals.map((goal, index) => (
                <Grid item key={`goal_action_card_grid_${index}`}>
                  <ActionCard
                    headerText={goal.name}
                    headerSubText={`Due ${FormatDateIgnoreTz(goal.dueDate)}`}
                    buttonText="View Details"
                    onClick={() => history.push(`/goals/${goal.id}/view`)}
                    productOfferingType={productOfferingTypes.goals}
                  />
                </Grid>
              ))}
              {goals.length === 0 && (
              <Grid item xs={12}>
                <Typography className={classes.noPulseText} variant="subtitle2">
                  No active goals
                </Typography>
              </Grid>
            )}
            </Grid>
          </Fade>
        </Grid>
      )}
    </Grid>
    </Grid>
  )
}

export default EmployeeDashboard
